<template>
    <div class='dp-flex-row'>
        <div class='bg-login-register-pages justify-content-center items-right dp-flex-column'>

            <div class="blur" style="opacity: 0.55;"></div>

            <img v-if="userType == null" src='@/assets/images/introduction-area/login/Mask Group 19.png' alt='Mask Group 19.png' />

            <img v-if="userType == 3" src='@/assets/images/introduction-area/login/Mask Group 19.png' alt='Mask Group 19.png' />

            <img v-if="userType == 2" src='@/assets/images/introduction-area/login/transporterLogin.png' alt='Mask Group 19.png' />


            <div class='tara-login-left dp-flex-column items-right'>

                <figure>
                    <img src='@/assets/images/introduction-area/login/Group 1385.svg' alt="loginPageIconSvg" />
                </figure>
                <h2>
                    {{$t("layout-register-login-page.Title")}}
                </h2>
                <p>{{$t("layout-register-login-page.Desc")}}</p>
            </div>
        </div>

        <slot />

    </div>
</template>


<script>
export default {
    name: "LayoutUserLoginRegister",
    components: {},
    data() {
        return {
            userType: localStorage.getItem("userType"),
        };
    },
};
</script>

<style>
@import "@/assets/css/introduction-area/login.css";
</style>