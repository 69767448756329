<template>
    <div class="dp-flex-column messages-card-row justify-content-between flex-wrap-49">
        <div class="dp-flex-row justify-content-between items-center">
            <h3 class="expedition-number font-size-16 text-semi-bold">{{expeditionNumber}}</h3>
            <p class="font-size-16">{{time}}</p>
        </div>
        <div class="dp-flex-row justify-content-between items-center">
            <h3 class="font-size-16">{{roadMap}}</h3>
            <p v-if="messageCount != null" class="border-radius-5 text-color-white font-size-14 message-count">{{messageCount}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "MessageRow",
    props: ["expeditionNumber", "time", "roadMap", "messageCount"],
};
</script>

<style>
</style>