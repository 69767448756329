import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class UnloadingLoadingAddressesService {
    resultModel = new ResultModel();

    errorResult = "";

    
    async getUnloadingLoadingAddressesByCustomerUserGuid(addressType, isActive) {
        await apiHelper.get(APIUrls.GET_UNLOADING_LOADING_ADDRESSES_BY_CUSTOMER_USER_GUID, {
            addressType: addressType,
            isActive: isActive
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

}