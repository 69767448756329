<template>
    <div class="dp-flex-column">
        <label v-if="isLabelActive" class="font-size-14 mt-mb-5 text-bold">
            {{label}}
        </label>

        <input v-if="type!='select'" class="new-addres-input" :type="type" :name="name" :placeholder="placeHolder" :value="value" v-bind="{min:type == 'number', max:type == 'number'}" @input="$emit('input', $event.target.value)" />

        <select v-if="type == 'select'" v-model="selected" class="new-addres-input" @input="$emit('input', $event.target.value)">
            <option v-bind:key="item.value" v-for="item in selectItems" v-bind:value="item.value">
                {{item.text}}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "LabelWithInput",

    props: ["isLabelActive", "label", "type", "placeHolder", "value", "min", "max", "name", "selectItems"],

    data() {
        return {
            selected: this.value,
        };
    },

    mounted() {
        //console.log(this.value);
    },

    updated() {
        //console.log(this.value);
    },

    methods: {},
};
</script>

<style>
</style>