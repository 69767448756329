<template>
    <div class="dp-flex-column">
        <Title :title="$t('ad-summary.Title')" :description="$t('ad-summary.Desc')"></Title>

        <div class="dp-flex-column justify-content-between">
            <div class="dp-flex-row justify-content-between">
                <SaveAd @setSaveAdDetails="getSaveAdDetails"></SaveAd>
                <AdDetail class="ml-y1"></AdDetail>
                <VehicleInfos class="ml-y1"></VehicleInfos>
            </div>

            <div class="dp-flex-row mt-mb-15 justify-content-between">
                <div class="panel-content width-50">
                    <TitleWithIcon :title="$t('ad-summary.LoadingPoint')" :actionName="$t('button.Edit')">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>
                    <div class="dp-flex-column mt-mb-y1">
                        <div class="dp-flex-column mt-mb-y25">
                            <div class="dp-flex-row">
                                <div class="dp-flex-column width-100">
                                    <h3 class="font-size-16 text-semi-bold">
                                        {{loadingAddress.title}}
                                    </h3>
                                    <h5 class="font-size-16">
                                        {{loadingAddress.description}}
                                    </h5>
                                </div>
                            </div>
                            <p class="font-size-14 mt-mb-y1">
                                {{loadingAddress.direction}}
                            </p>
                            <div class="dp-flex-column width-100 mt-mb-y1">
                                <h3 class="font-size-16 text-semi-bold">
                                    {{$t('unloading-points-row.EstimatedDeliveryTime')}}
                                </h3>

                                <div class="dp-flex-row font-size-14 width-100">
                                    <div class="dp-flex-row items-center">
                                        <i class="fa-solid color-1A5EF7 font-size-16 fa-clock"></i>
                                        <p class="ml-10 font-size-14">
                                            {{loadingAddress.earliestDate.split('T')[0]}}
                                        </p>
                                        <p class="ml-15 font-size-14">
                                            {{loadingAddress.earliestDate.split('T')[1]}}
                                        </p>
                                    </div>
                                    <div class="ml-y1 dp-flex-row items-center">
                                        <i class="fa-solid color-1A5EF7 font-size-16 fa-hourglass-end"></i>
                                        <p class="ml-10 font-size-14">
                                            {{loadingAddress.latestDate.split('T')[0]}}
                                        </p>
                                        <p class="ml-15 font-size-14">
                                            {{loadingAddress.latestDate.split('T')[1]}}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-content width-50 ml-y1">
                    <TitleWithIcon :title="$t('ad-summary.DischargePoints')" :actionName="$t('button.Edit')">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>

                    <div class="dp-flex-row">
                        <button @click="selectedUnloadingAddressId = unloadingAddress.id" :key="unloadingAddress.id" v-for="unloadingAddress in getUnloadingAddresses" :class="unloadingAddress.id == selectedUnloadingAddressId ? 'btn-first-unloading-point' : 'btn-other-unloading-point'" class="btn-none btn-first-unloading-point">
                            {{unloadingAddress.id == selectedUnloadingAddressId ? (unloadingAddress.sequence + 1) + '. ' + $t('unloading-points.Title') : (unloadingAddress.sequence + 1) + '.'}}
                        </button>
                    </div>

                    <template v-for="unloadingAddress in getUnloadingAddresses">
                        <div :key="unloadingAddress.id" v-if="selectedUnloadingAddressId == unloadingAddress.id" class="dp-flex-column mt-mb-y1">
                            <div class="dp-flex-column mt-mb-y25">
                                <div class="dp-flex-row">
                                    <div class="dp-flex-column width-100">
                                        <h3 class="font-size-16 text-semi-bold">
                                            {{unloadingAddress.title}}
                                        </h3>
                                        <h5 class="font-size-16">
                                            {{unloadingAddress.countryName + '-' + unloadingAddress.cityName + '-' + unloadingAddress.districtName}}
                                        </h5>
                                    </div>
                                </div>
                                <p class="font-size-14 mt-mb-y1">
                                    {{unloadingAddress.direction}}
                                </p>
                                <p class="font-size-14 mt-mb-y1">
                                    {{unloadingAddress.description}}
                                </p>
                                <div class="dp-flex-column width-100 mt-mb-y1">
                                    <h3 class="font-size-16 text-semi-bold">
                                        {{$t('unloading-points-row.EstimatedDeliveryTime')}}
                                    </h3>

                                    <div class="dp-flex-row font-size-14 width-100">
                                        <div class="dp-flex-row items-center">
                                            <i class="fa-solid color-1A5EF7 font-size-16 fa-clock"></i>
                                            <p class="ml-10 font-size-14">
                                                {{unloadingAddress.earliestDate.replace('T',' ')}}
                                            </p>
                                            <!-- <p class="ml-15 font-size-14">
                                                09.00 / 12.00
                                            </p> -->
                                        </div>
                                        <div class="ml-y1 dp-flex-row items-center">
                                            <i class="fa-solid color-1A5EF7 font-size-16 fa-hourglass-end"></i>
                                            <p class="ml-10 font-size-14">
                                                {{unloadingAddress.latestDate.replace('T', ' ')}}
                                            </p>
                                            <!-- <p class="ml-15 font-size-14">
                                                09.00 / 12.00
                                            </p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="dp-flex-column mt-mb-15 panel-content justify-content-between">
                <TitleWithIcon :title="$t('ad-summary.LoadInfo')" :actionName="$t('button.Edit')">
                    <template v-slot:action-icon>
                        <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                    </template>
                </TitleWithIcon>

                <div class="dp-flex-row dp-flex-wrap justify-content-between mt-mb-10">
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.CargoType')" :description="getLoadFeaturesInfo.loadItemTypeName"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LoadType')" :description="getLoadFeaturesInfo.loadTypeName"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LoadingShape')" :description="getLoadFeaturesInfo.loadingMethodName"></BlueBox>

                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Volume')" :description="getLoadFeaturesInfo.volume + ' m3'"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Desi')" :description="getLoadFeaturesInfo.decimeter"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LDM')" :description="getLoadFeaturesInfo.ldmLoadingMeter"></BlueBox>

                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.TonnageRange')" :description="getLoadFeaturesInfo.loadTonnageRange"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.CostValue')" :description="getLoadFeaturesInfo.value + ' ' + getCurrency.name + '(' + getCurrency.code + ')'"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LoadTypes')" :description="loadPropertiesText"></BlueBox>
                </div>
            </div>
        </div>

        <div class="dp-flex-row items-center justify-content-end">
            <YellowCheckbox class="width-30 ad-summary-required-checkbox" @input="yellowCheckboxEvent">
                <template v-slot:chekbox-label-text>
                    <p class="font-size-12">
                        <span class="font-size-12 text-bold">
                            {{$t("ad-summary.KvkkText")}}
                        </span>
                        {{$t("ad-summary.KvkkText1")}}
                        <span class="font-size-12 text-bold">
                            {{$t("ad-summary.KvkkText2")}}
                        </span>
                        {{$t("ad-summary.KvkkText3")}}
                    </p>
                </template>
            </YellowCheckbox>
            <button @click="createLoad(true)" class="btn-none font-size-13 border-radius-5 ml-y1 btn-post-ad">
                {{$t("ad-summary.PublishAd")}}
            </button>

            <button v-if="!getIsComingFromDraft" @click="createLoad(false)" class="btn-none font-size-13 border-radius-5 ml-y1 btn-post-ad">
                {{$t("ad-summary.Save_As_Draft")}}
            </button>
        </div>

    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import SaveAd from "./SaveAd.vue";
import AdDetail from "./AdDetail.vue";
import VehicleInfos from "./VehicleInfos.vue";
import TitleWithIcon from "@/components/Widgets/CustomerPanel/TitleWithIcon.vue";
import UnloadPointsRow from "../../Addresses/UnloadingPoint/UnloadPointsRow.vue";
import BlueBox from "@/components/Widgets/CustomerPanel/BlueBox.vue";
import YellowCheckbox from "@/components/Widgets/CustomerPanel/YellowCheckbox.vue";
import { mapGetters } from "vuex";
import { APIUrls } from "@/Helpers/APIUrls";
import { UnloadingLoadingAddress } from "@/Helpers/Models";

export default {
    components: {
        SaveAd,
        Title,
        AdDetail,
        VehicleInfos,
        TitleWithIcon,
        UnloadPointsRow,
        BlueBox,
        YellowCheckbox,
    },

    data() {
        return {
            isOpenNotification: false,
            loadPropertiesText: "",
            saveAdDetails: {},
            isKvkkChecked: false,
            unloadingLoadingAddressIds: [],
            loadingAddress: new UnloadingLoadingAddress(),
            selectedUnloadingAddressId: 0,
        };
    },

    mounted() {
        this.loadPropertiesText = (this.getLoadFeaturesInfo.isFlammable ? this.$t("draftAd.Is_Flammable") + " " : "") + (this.getLoadFeaturesInfo.isStacked ? this.$t("draftAd.Is_Stacked") + " " : "") + (this.getLoadFeaturesInfo.isRaw ? this.$t("draftAd.Is_Hamal") : "");

        this.loadingAddress = this.getLoadingAddresses[0];

        this.selectedUnloadingAddressId = this.getUnloadingAddresses[0].id;
    },

    computed: {
        ...mapGetters("createLoad", ["getLoadFeaturesInfo", "getVehicleInfos", "getShipmentType", "getVehicleType", "getCurrency", "getLoadTitleAndNote", "getIsComingFromDraft", "getLoadingAddresses", "getUnloadingAddresses"]),
    },

    methods: {
        yellowCheckboxEvent(event) {
            this.isKvkkChecked = event.checked;
        },

        getSaveAdDetails(saveAdDetails) {
            this.saveAdDetails = saveAdDetails;
        },

        openNotification() {
            this.isOpenNotification = !this.isOpenNotification;
        },

        createLoad(isPublish) {
            // yükleme ve boşaltma noktalarının id'lerini yeni bir list'e ekledik.
            this.getLoadingAddresses.forEach((element) => {
                this.unloadingLoadingAddressIds.push(element.id);
            });

            this.getUnloadingAddresses.forEach((element) => {
                this.unloadingLoadingAddressIds.push(element.id);
            });

            if (this.isKvkkChecked) {
                if (this.saveAdDetails.title != null) {
                    this.axios({
                        url: APIUrls.CREATE_LOAD,
                        method: "POST",
                        data: {
                            shipmentTypeId: this.getShipmentType,
                            vehicleTypeId: this.getVehicleType.id,
                            vehicleCount: this.getVehicleInfos.vehicleCount,
                            vehicleBodyTypeId: this.getVehicleInfos.trailerType,
                            bodyFloorTypeId: this.getVehicleInfos.bodyFloorType,
                            loadTypeId: this.getLoadFeaturesInfo.loadTypeId,
                            loadItemTypeId: this.getLoadFeaturesInfo.loadItemTypeId,
                            volume: parseInt(this.getLoadFeaturesInfo.volume),
                            decimeter: parseInt(this.getLoadFeaturesInfo.decimeter),
                            ldmLoadingMeter: parseInt(this.getLoadFeaturesInfo.ldmLoadingMeter),
                            loadTonnageRangeId: this.getLoadFeaturesInfo.loadTonnageRangeId,
                            loadingMethodId: this.getLoadFeaturesInfo.loadingMethodId,
                            value: parseInt(this.getLoadFeaturesInfo.value),
                            currencyId: parseInt(this.getCurrency.id),
                            isFlammable: this.getLoadFeaturesInfo.isFlammable,
                            isHammal: this.getLoadFeaturesInfo.isHamal,
                            isStacked: this.getLoadFeaturesInfo.isStacked,
                            isFavourite: this.getIsComingFromDraft == true ? false : this.saveAdDetails.isDraft,
                            isPublish: isPublish,
                            title: this.saveAdDetails.title,
                            note: this.saveAdDetails.note,
                            caseFeatures: this.getVehicleInfos.caseType,
                            unloadingLoadingAddressIds: this.unloadingLoadingAddressIds,
                        },
                    })
                        .then((response) => {
                            if (response.status == 200) {
                                //console.log(response);
                                if (response.data.code == 14) {
                                    this.$toast.success(this.$t("resultModel.Load_Created"));
                                    this.$router.push("/customer/my-ads");
                                } else {
                                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                                }
                            } else {
                                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    this.$toast.warning(this.$t("ad-summary.Load_Title_Not_Null"));
                }
            } else {
                this.$toast.warning(this.$t("ad-summary.Load_Kvkk_Accept"));
            }
        },
    },
};
</script>

<style>
@import url("@/assets/css/customer-area/my-ads/ad-summary/ad-summary.css");
</style>