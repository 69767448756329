const moduleHeader = {
    namespaced: true,
    state: {
        whichPage: "",
    },
    getters: {
        getWhichPage: (state) => {
            return state.whichPage;
        },
    },

    actions: {
        updateWhichPage: ({ commit }, [whichPage]) => {            
            commit('setWhichPage', [whichPage]);
        },
    },
    mutations: {
        setWhichPage: (state, [whichPage]) => {
            state.whichPage = whichPage;            
        },
    },
}

export default moduleHeader;