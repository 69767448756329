<template>
    <div>
        <Loading v-if="isLoading">

        </Loading>

        <div v-if="!isLoading" class="dp-flex-column">
            <Title :title="$t('load-types.Title')" :description="$t('load-types.Desc')"></Title>

            <div class="panel-content dp-flex-column justify-content-between">
                <div class="dp-flex-column width-100">
                    <div class="dp-flex-row width-100 justify-content-between">
                        <div class="dp-flex-column ml-y1 width-33 justify-content-between">
                            <label class="font-size-14 mt-mb-5 color-667085">
                                {{$t("load-types.CargoType")}}
                            </label>

                            <select v-model="selectedLoadItemType" class="new-addres-input red-border">
                                <option class="color-667085" :value="{id: 0, text:''}">
                                    {{$t("load-types.ChooseCargoType")}}
                                </option>
                                <option class="color-667085" :value="{id: loadItemType.id, text:loadItemType.name}" :key="loadItemType.id" v-for="loadItemType in loadItemTypes">
                                    {{loadItemType.name}}
                                </option>
                            </select>
                        </div>

                        <div class="dp-flex-column ml-y1 width-33 justify-content-between">
                            <label class="font-size-14 mt-mb-5 color-667085">{{$t("load-types.LoadType")}}</label>

                            <select v-model="selectedLoadType" class="new-addres-input red-border">
                                <option class="color-667085" :value="{id: 0, text: ''}" disabled selected>{{$t("load-types.ChooseLoadType")}}</option>
                                <option class="color-667085" :value="{id: loadType.id, text:loadType.name}" :key="loadType.id" v-for="loadType in loadTypes">{{loadType.name}}</option>
                            </select>
                        </div>

                        <div class="dp-flex-column ml-y1 width-33 justify-content-between">
                            <label class="font-size-14 mt-mb-5 color-667085">
                                {{$t("load-types.LoadDimensions")}}
                            </label>
                            <div class="dp-flex-row ml-y1 width-100 justify-content-between">
                                <!-- <AddresssLabelWithInput v-model="buildingNumber" :rules="'required'" :name="$t('new-address.BuildingNo')" class="width-35" :type="'number'" :label="$t('new-address.BuildingNo')"></AddresssLabelWithInput> -->
                                <AddresssLabelWithInput v-model="loadFeaturesInfo.volume" class="width-30" :type="'number'" :placeHolder="'Hacim'"></AddresssLabelWithInput>
                                <AddresssLabelWithInput v-model="loadFeaturesInfo.decimeter" class="width-30" :type="'number'" :placeHolder="'Desi'"></AddresssLabelWithInput>
                                <AddresssLabelWithInput v-model="loadFeaturesInfo.ldmLoadingMeter" class="width-30" :type="'number'" :placeHolder="'LDM'"></AddresssLabelWithInput>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dp-flex-column width-100 mt-mb-y1">
                    <div class="dp-flex-row width-100 justify-content-between">
                        <div class="dp-flex-column ml-y1 width-33 justify-content-between">
                            <label class="font-size-14 mt-mb-5 color-667085">
                                {{$t("load-types.TonnageRange")}}
                            </label>

                            <select v-model="selectedTonnageRange" class="new-addres-input red-border">
                                <option class="color-667085" :value="{id: 0, text: ''}" disabled selected>
                                    {{$t("load-types.TonnageRangePlaceHolader")}}
                                </option>
                                <option class="color-667085" :value="{id: loadTonnageRange.id, text:loadTonnageRange.range}" :key="loadTonnageRange.id" v-for="loadTonnageRange in loadTonnageRanges">
                                    {{loadTonnageRange.range}}
                                </option>
                            </select>
                        </div>

                        <div class="dp-flex-column ml-y1 width-33 justify-content-between">
                            <label class="font-size-14 mt-mb-5 color-667085">
                                {{$t("load-types.LoadingShape")}}
                            </label>

                            <select v-model="selectedLoadingMethod" class="new-addres-input red-border">
                                <option class="color-667085" :value="{id:0, text:''}" disabled selected>
                                    {{$t("load-types.LoadingShapePlaceHolder")}}
                                </option>
                                <option class="color-667085" :value="{id:loadingMethod.id, text:loadingMethod.name}" :key="loadingMethod.id" v-for="loadingMethod in loadingMethods">
                                    {{loadingMethod.name}}
                                </option>
                            </select>
                        </div>

                        <div class="dp-flex-column ml-y1 width-33 justify-content-between">
                            <label class="font-size-14 mt-mb-5 color-667085">
                                {{$t("load-types.CostValue")}}
                            </label>

                            <div class="dp-flex-row justify-content-between items-end">
                                <AddresssLabelWithInput v-model="loadFeaturesInfo.value" class="width-100" :type="'number'" :placeHolder="0"></AddresssLabelWithInput>

                                <select v-model="selectedCurrency" class="new-addres-input ml-y1 width-100 red-border">
                                    <option class="color-667085" :value="{id: 0, text: ''}">
                                        {{$t("load-types.CostValuePlaceHolader")}}
                                    </option>
                                    <option :key="currency.id" v-for="currency in currencies" :value="{id: currency.id, text: currency.name}" class="color-667085">
                                        {{currency.name + " " + "(" + currency.code + ")"}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dp-flex-column width-100 mt-mb-y1">
                    <div class="dp-flex-row width-100 justify-content-start">
                        <div class="dp-flex-column ml-y1 width-100 justify-content-between">
                            <label class="font-size-14 mt-mb-5 color-667085">
                                {{$t("load-types.Title")}}
                            </label>
                            <div class="dp-flex-row justify-content-start mt-mb-y25">
                                <div class="dp-flex-column justify-content-between mr-y5 items-left">
                                    <div class="dp-flex-row justify-content-start load-types-chckbox-content mb-50 mt-mb-y25">
                                        <label class="container">
                                            <input v-model="loadFeaturesInfo.isFlammable" id="chckbox_flammable_material" type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label for="chckbox_flammable_material" class="font-size-12 width-max-content text-semi-bold">
                                            {{$t("draftAd.Is_Flammable")}}
                                        </label>
                                    </div>
                                </div>
                                <div class="dp-flex-column justify-content-between mr-y5 items-left">
                                    <div class="dp-flex-row justify-content-start load-types-chckbox-content mt-mb-y25">
                                        <label class="container">
                                            <input v-model="loadFeaturesInfo.isHamal" id="chckbox_raw_material" type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label for="chckbox_raw_material" class="font-size-12 text-semi-bold">
                                            {{$t("draftAd.Is_Hamal")}}
                                        </label>
                                    </div>
                                    <!-- <div class="dp-flex-row justify-content-start load-types-chckbox-content mt-mb-y25">
                                    <BlueCheckbox>

                                    </BlueCheckbox>
                                </div> -->
                                </div>
                                <div class="dp-flex-column justify-content-between items-left">
                                    <div class="dp-flex-row justify-content-start load-types-chckbox-content mt-mb-y25">
                                        <label class="container">
                                            <input v-model="loadFeaturesInfo.isStacked" id="chckbox_is_stacked_material" type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label for="chckbox_is_stacked_material" class="font-size-12 text-semi-bold">
                                            {{$t("draftAd.Is_Stacked")}}
                                        </label>
                                    </div>
                                    <!-- <div class="dp-flex-row justify-content-start load-types-chckbox-content mt-mb-y25">
                                    <BlueCheckbox>

                                    </BlueCheckbox>
                                </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dp-flex-row justify-content-end mt-mb-y25">
                    <router-link to="/customer/unloading-points" class="btn-none continue-btn pl-pr-y1-4 previous-btn font-size-14 items-center">
                        {{$t("button.Back")}}
                    </router-link>
                    <button @click="setLoadPropertiesToVuex()" class="btn-none continue-btn pl-pr-y1-4 ml-y1 font-size-14 items-center">
                        {{$t("button.Continue")}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import AddresssLabelWithInput from "@/components/CustomerArea/Addresses/LoadingPoint/AddressLabelWithInput.vue";
import BlueCheckbox from "@/components/Widgets/CustomerPanel/BlueCheckbox.vue";
import { mapActions, mapGetters } from "vuex";
import LoadService from "@/services/LoadService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import { Currency, LoadFeaturesInfo } from "@/Helpers/Models";

export default {
    components: {
        Title,
        AddresssLabelWithInput,
        BlueCheckbox,
        Loading,
    },

    data() {
        return {
            selectedLoadItemType: {
                id: 0,
                text: "",
            },
            selectedLoadType: {
                id: 0,
                text: "",
            },
            selectedTonnageRange: {
                id: 0,
                text: "",
            },
            selectedLoadingMethod: {
                id: 0,
                text: "",
            },
            selectedCurrency: {
                id: 0,
                text: "",
            },

            loadItemTypes: [],
            loadTypes: [],
            loadingMethods: [],
            loadTonnageRanges: [],
            currencies: [],

            loadFeaturesInfo: new LoadFeaturesInfo(),
            currency: new Currency(),

            isLoading: false,
            isActive: true,
            loadService: new LoadService(),
        };
    },

    async mounted() {
        // this.isLoading = true;

        await this.getLoadItemTypes();

        await this.getLoadTypes();

        await this.getLoadingMethods();

        await this.getLoadTonnageRange();

        await this.getCurrencies(this.isActive);

        // this.isLoading = false;

        if (this.getIsComingFromDraft) {
            this.loadFeaturesInfo = this.getLoadFeaturesInfo;

            this.selectedLoadItemType.id = this.loadFeaturesInfo.loadItemTypeId;
            this.selectedLoadItemType.text = this.loadFeaturesInfo.loadItemTypeName;

            this.selectedLoadType.id = this.loadFeaturesInfo.loadTypeId;
            this.selectedLoadType.text = this.loadFeaturesInfo.loadTypeName;

            this.selectedTonnageRange.id = this.loadFeaturesInfo.loadTonnageRangeId;
            this.selectedTonnageRange.text = this.loadFeaturesInfo.loadTonnageRange;

            this.selectedLoadingMethod.id = this.loadFeaturesInfo.loadingMethodId;
            this.selectedLoadingMethod.text = this.loadFeaturesInfo.loadingMethodName;

            this.currency = this.getCurrency;

            this.selectedCurrency.id = this.currency.id;
            this.selectedCurrency.text = this.currency.name;
        }
    },

    computed: {
        ...mapGetters("createLoad", ["getLoadFeaturesInfo", "getIsComingFromDraft", "getCurrency"]),
    },

    methods: {
        ...mapActions("createLoad", ["updateLoadFeaturesInfo", "updateCurrency"]),

        setLoadPropertiesToVuex() {
            this.loadFeaturesInfo.loadItemTypeId = this.selectedLoadItemType.id;
            this.loadFeaturesInfo.loadItemTypeName = this.selectedLoadItemType.text;
            this.loadFeaturesInfo.loadingMethodId = this.selectedLoadingMethod.id;
            this.loadFeaturesInfo.loadingMethodName = this.selectedLoadingMethod.text;
            this.loadFeaturesInfo.loadTypeId = this.selectedLoadType.id;
            this.loadFeaturesInfo.loadTypeName = this.selectedLoadType.text;
            this.loadFeaturesInfo.loadTonnageRangeId = this.selectedTonnageRange.id;
            this.loadFeaturesInfo.loadTonnageRange = this.selectedTonnageRange.text;

            this.currency.id = this.selectedCurrency.id;
            this.currency.name = this.selectedCurrency.text;
            this.currency.code = this.selectedCurrency.id != 0 ? this.currencies.find((x) => x.id == this.selectedCurrency.id).code : "";

            if (this.selectedLoadItemType.id != 0 && this.selectedLoadingMethod.id != 0 && this.selectedLoadType.id != 0 && this.selectedTonnageRange.id != 0 && this.selectedCurrency.id != 0) {
                this.updateLoadFeaturesInfo([this.loadFeaturesInfo]);
                this.updateCurrency([this.currency]);
                this.$router.push("/customer/ad-summary");
            } else {
                this.$toast.error("Zorunlu Alanları Doldurunuz !");
            }
        },

        async getLoadItemTypes() {
            await this.loadService.getLoadItemTypes();

            if (this.loadService.resultModel.code == 14) {
                this.loadItemTypes = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getLoadTypes() {
            await this.loadService.getLoadTypes();

            if (this.loadService.resultModel.code == 14) {
                this.loadTypes = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getLoadingMethods() {
            await this.loadService.getLoadingMethods();

            if (this.loadService.resultModel.code == 14) {
                this.loadingMethods = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getLoadTonnageRange() {
            await this.loadService.getLoadTonnageRange();

            if (this.loadService.resultModel.code == 14) {
                this.loadTonnageRanges = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getCurrencies() {
            await this.loadService.getCurrencies(this.isActive);

            if (this.loadService.resultModel.code == 14) {
                this.currencies = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },
    },
};
</script>

<style>
</style>