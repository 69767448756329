<template>
    <div class="dp-flex-row width-100 items-center">
        <label v-if="isLabelActive" class="font-size-14 width-20 mr-y1 color-797979">{{label}}</label>
        <input :value="value" class="libr-input border-radius-5 font-size-12 width-100" :type="type" :placeholder='placeHolder' v-bind:min="{min:type == 'number', max:type == 'number'}" @input="$emit('input', $event.target.value)" />
        <input :value="value" v-if="type == 'date'" class="libr-input border-radius-5 font-size-12 width-100" type="date" :placeholder='placeHolder' @input="$emit('input', $event.target.value)" />
        <button v-if="isButtonActive" class="btn-none libr-button">
            {{buttonText}}
            <slot name="button-icon">

            </slot>
        </button>
    </div>
</template>

<script>
export default {
    props: ["isLabelActive", "label", "placeHolder", "type", "name", "isButtonActive", "buttonText", "min", "max", "value"],
};
</script>

<style>
.libr-label {
    margin-right: 1%;
}

.libr-input {
    border: 1px solid #d7dddf;
    padding: 1%;
    background-color: #f7f7f7;
    outline: none;
    height: 50px;
}

.libr-button {
    border-radius: 6px;
    border: 1px solid #d6dddf;
    height: 50px;
    width: 50px;
    margin-left: 1%;
    padding: 1%;
}
</style>