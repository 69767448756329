<template>
    <div v-bind:class="{'notif-danger': type == 'danger','notif-success': type == 'success', }" class="customer-panel-notif dp-flex-column justify-content-center items-center">
        <h2 class="font-size-15 text">{{title}}</h2>
        <h3 class="font-size-12 text">{{description}}</h3>
        <div class="notif-close dp-flex-row justify-content-end">
            <button class="btn-none text" @click="$emit('closeNotification')">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationPopup",
    components:{

    },
    props:['title', 'description', 'type'],
    data(){ 
        return{
            
        }
    },
    methods:{

    },
};
</script>

<style>
@import "@/assets/css/widgets/notification-popup.css";
</style>