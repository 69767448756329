<template>
    <div class="dp-flex-row width-100 justify-content-between p-y1 shipment-types-item">
        <div class="dp-flex-column justify-content-between">
            <h3 class="font-size-16 text-medium">{{ title }}</h3>
            <p class="font-size-14 color-667085">
                {{ description }}
            </p>
        </div>

        <div class="dp-flex-row items-center width-20 justify-content-between">
            <router-link :to="{ name: 'new-address', params: { id: id, selectedAddressTypeId: adressTypeId } }" class="panel-dashboard-button font-size-14 use-address-button btn-none">
                {{ $t("button.UseAddress") }}
            </router-link>
            <!-- <router-link to="/customer/unloading-points" class="panel-dashboard-button font-size-14 use-address-button btn-none">
                {{$t("button.UseAddress")}}
            </router-link> -->
            <div class="dp-flex-row justify-content-between width-30">
                <button @click="isOpenDeletePopup = true" class="btn-none">
                    <i class="fa-regular color-797979 font-size-20 fa-trash-can"></i>
                </button>
                <button class="btn-none">
                    <i class="fa-solid color-797979 font-size-20 fa-pen"></i>
                </button>
            </div>
        </div>

        <TransactionPopup v-if="isOpenDeletePopup" @closePopup="isOpenDeletePopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center">
                    {{ $t("registered-addresses.Delete_Registered_Addresses_Title") }}
                </p>
            </template>
            <template slot="popup-body">
                <div style="width:20vw!important" class="width-100 dp-flex-column">
                    <div class="dp-flex-row justify-content-center text-center items-center mt-y3">
                        <p class="font-size-16">
                            {{ $t("registered-addresses.Delete_Registered_Addresses_Are_You_Sure") }}
                            {{ $t("registered-addresses.Delete_Registered_Addresses_Description") }}
                        </p>
                    </div>
                </div>
            </template>
            <template slot="popup-body-button">
                <div class="dp-flex-row justify-content-center width-100 mt-y5 items-center">
                    <button @click="deleteUnloadingLoadingAddress()" class="dp-flex-row btn-none delte-user-button mr-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{ $t("button.Delete") }}
                    </button>
                    <button @click="isOpenDeletePopup = false" class="dp-flex-row btn-none border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{ $t("button.Cancel") }}
                    </button>
                </div>
            </template>
        </TransactionPopup>
    </div>
</template>

<script>
import TransactionPopup from "@/components/Widgets/ModelPopup/TransactionPopup.vue";
import { APIUrls } from "@/Helpers/APIUrls";

export default {
    name: "AddressRow",

    props: ["id", "title", "description", "adressTypeId"],

    components: {
        TransactionPopup,
    },

    data() {
        return {
            isOpenDeletePopup: false,
        };
    },

    methods: {
        deleteUnloadingLoadingAddress() {
            //console.log(this.id);
            if (this.id > 0) {
                this.axios({
                    url: APIUrls.DELETE_OR_RESTORE_DELETE_UNLOADING_LOADING_ADDRESS,
                    method: "POST",
                    params: {
                        id: this.id,
                        isActive: false,
                    },
                })
                    .then((response) => {
                        //console.log(response);
                        if (response.status == 200) {
                            if (response.data.code == 139) {
                                this.isOpenDeletePopup = false;
                                this.$toast.success("Kayıtlı Adres Silindi !");

                                // TODO: parent childe component force yapılacak. eleman silindikten sonra listeden de kaldırılacak.

                                // let deletedUnloadingLoadingAddress = this.users.map((x) => x.id).indexOf(this.id);
                                // this.splice(deletedUser, 1);
                            } else {
                                this.$toast.warning("Kayıtlı Adres Silinirken Hata Alındı !");
                            }
                        } else {
                            this.$toast.warning("Kayıtlı Adres Silinirken Hata Alındı !");
                        }
                    })
                    .catch((error) => {
                        //console.log(error);
                        this.$toast.error("Kayıtlı Adres Silinirken Hata Alındı !");
                    });
            }
        },
    },
};
</script>

<style></style>