<template>
    <div class="dp-flex-column">
        <LayoutUserLoginRegister>
            <div class="dp-flex-column items-center justify-content-center login-left">
                <div class='login-form'>
                    <h1 class='font-size-35 text-bold'>
                        Yeni Şifre Giriniz
                    </h1>

                    <h3 class='font-size-15 mb-20'>
                        Hesabınız İçin Yeni Şifre Giriniz
                    </h3>

                    <form @submit="postForgetPasswordInfoToAPI">
                        <div class='input-icons'>
                            <i class="fa-solid fa-xs fa-lock icon"></i>
                            <input class='btn-none login-input' name='password' type="password" placeholder="Şifre Giriniz" v-model="password" />
                            <p class='font-size-13 text-color-red'>{{errors.password}}</p>
                        </div>

                        <div class='input-icons'>
                            <i class="fa-solid fa-xs fa-lock icon"></i>
                            <input class='btn-none login-input' name='password' type="password" placeholder="Şifrenizi Tekrar Giriniz" v-model="passwordAgain" />
                            <p class='font-size-13 text-color-red'>{{errors.password}}</p>
                        </div>

                        <button class='btn-none mt-mb-25 login-input forget-password-input-submit login-input-submit' type="submit">
                            Kaydet
                        </button>
                    </form>

                    <div class='login-register-div text-center mt-mb-25 font-size-15'>
                        <p class='dp-inline-block font-size-13'>{{$t("forget-password-page.RememberPassword")}} </p>
                        <router-link to='/login' class='font-size-13 text-color-green2'>{{$t("forget-password-page.LoginLink")}}</router-link>
                    </div>

                    <div class='login-register-div text-center mt-mb-25 font-size-15'>
                        <p>Henüz bir hesabınız yok mu?</p>
                        <router-link to='/register'> Hesap Oluştur</router-link>
                    </div>

                    <div class='login-re-captcha-kvkk'>
                        <p class='font-size-13'>
                            ReCAPTCHA tarafından korunmaktadır ve
                            <br />
                            Google
                            <a href='#' class='text-color-blue font-size-13 btn-none'> Gizlilik Politikası </a>
                            ve
                            <a href='#' class='font-size-13 text-color-blue btn-none'> Hizmet Şartları</a>
                            ’na tabidir.
                        </p>
                    </div>
                </div>
            </div>
        </LayoutUserLoginRegister>
    </div>
</template>

<script>
import {
    validatePassword,
    validateRequired,
} from "@/Helpers/FormValidateFunctions";
import LayoutUserLoginRegister from "./_LayoutUserLoginRegister.vue";
import { APIUrls } from "@/Helpers/APIUrls";

export default {
    name: "ResetPasswordComponents",
    components: {
        LayoutUserLoginRegister,
    },
    data() {
        return {
            isOpenPopup: false,
            email: "",
            password: "",
            passwordAgain: "",
            errors: {},
            color: "",
            borderColor: "",
            resultModel: {
                code: 0,
                message: "",
                data: {},
            },
        };
    },
    methods: {
        formValidation() {
            let error = 0;

            let validateRequiredPasswordResult = validatePassword(
                this.password,
                this.passwordAgain
            );

            if (!validateRequiredPasswordResult.isValid) {
                this.$set(
                    this.errors,
                    "password",
                    validateRequiredPasswordResult.message
                );

                error++;
            }

            return error;
        },
        postForgetPasswordInfoToAPI(e) {
            e.preventDefault();

            let error = this.formValidation();

            if (error == 0) {
                console.log("başarılı");

                this.axios({
                    method: "POST",
                    url: APIUrls.CUSTOMER_RESET_PASSWORD_URL,
                    data: {
                        email: localStorage.getItem("email"),
                        password: this.password,
                        code: localStorage.getItem("forgetPasswordCode"),                        
                    },
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        changeFormInputIconColor() {
            this.color = "black";
            this.borderColor = "black";
        },
    },
};
</script>

<style>
</style>