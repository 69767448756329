import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

import createLoad from './createLoad';
import header from './header';
import completeRegistration from './completeRegistration';
import moduleCreateOrUpdateVehicle from './createOrUpdateVehicle';
import moduleCreateOrUpdateDriver from './createOrUpdateDriver';

// const state = {
//     shipmentType: 0,
// };


// const getters = {
//     getShipmentType: (state) => {
//         return state.shipmentType;
//     }
// };


// const actions = {
//     updateShipmentType: ({ commit }, [shipmentType]) => {
//         commit('setShipmentType', [shipmentType]);
//     }
// };


// const mutations = {
//     setShipmentType: (state, [shipmentType]) => {
//         state.shipmentType = shipmentType;
//     }
// };


// const vuecLocal = new VuexPersistence(State, Payload)({
//     storage: window.sessionStorage,
//     reducer: (sate) => ({
//         navigation: sate.navigation
//     }),
// })


const store = new Vuex.Store({
    plugins: [new VuexPersistence().plugin],
    modules: {
        createLoad: createLoad,
        header: header,
        completeRegistration: completeRegistration,
        createOrUpdateVehicle: moduleCreateOrUpdateVehicle,
        moduleCreateOrUpdateDriver: moduleCreateOrUpdateDriver,
    },
});

store.resetState = () => {
    console.log("resetState called");    
    localStorage.removeItem("vuex");
    // window.location.reload();
}


export default store

// TODO: indexedDb veya session storage kısımları kullanılaracak. localStorage yetmez
// TODO: vuex için localStorege sıfırlamak mantıklı değil. arada çalışıyor.