<template>
    <div>
        <Loading v-if="isLoading">

        </Loading>
        <div v-if="!isLoading">
            <div class="dp-flex-column">
                <Title :title="$t('customer-vehicle-type-page.VehicleTypeTitle')" :description="$t('customer-vehicle-type-page.VehicleTypeDesc')"></Title>

                <span v-if="selectedVehicleType == 0" class="vee-validate-error-message">
                    {{$t('inputValidation.Make_Choice')}}
                </span>

                <div class="dp-flex-row vehicle-types justify-content-start">
                    <div @click="getSelectedVehicleType(vehicleType)" class="dp-flex-column justify-content-between panel-content vehicle-type-item" :class="selectedVehicleType == vehicleType.id ? 'selected-vehicle-type' : ''" :key="vehicleType.id" v-for="vehicleType in vehicleTypes">
                        <p class="font-size-14 color-667085 text-semi-bold">
                            {{vehicleType.name}}
                        </p>
                        <figure>
                            <img :src="AssetUrls.VEHICLE_TYPES_URL + '/' + vehicleType.imgName " alt="vehicle-image" @error="handleImageError" />
                            <!-- <img :src="getImgPath(vehicleType.imgName)" alt="Tır resmi" /> -->
                        </figure>
                        <p class="font-size-14 color-667085">
                            {{vehicleType.description}}
                        </p>
                    </div>

                </div>
            </div>

            <!-- child component'ten verileri almak için "updateMultiple" ekledik. -->
            <VehicleInfo @updateMultiple="getSelectedVehicleInfoValues"></VehicleInfo>

            <div class="dp-flex-row justify-content-end">
                <button @click="getUnloadingLoadingAddresses()" class="btn-none continue-btn vehicle-info-continue-btn font-size-14 items-center">
                    {{$t("button.Continue")}}
                </button>
            </div>

            <Popup v-if="isOpenPopup" @closePopup="isOpenPopup = false">
                <template v-slot:popup-header>
                    <i class="fa-solid map-icon color-667085 fa-location-dot"></i>
                    <p class="font-size-16 color-667085">
                        {{$t("customer-vehicle-info-page.ChooseNewAddress")}}
                    </p>
                </template>
                <template v-slot:popup-body>
                    <NewAdderssMap :adressTypeId="1"></NewAdderssMap>
                </template>
            </Popup>
        </div>
    </div>
</template>

<script>
import VehicleInfo from "./VehicleInfo.vue";
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import Popup from "@/components/Widgets/ModelPopup/Popup.vue";
import NewAdderssMap from "@/components/CustomerArea/Addresses/LoadingPoint/NewAddressMap.vue";
import { mapActions, mapGetters } from "vuex";
import LoadService from "@/services/LoadService";
import { AssetUrls } from "@/Helpers/AssetUrls";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import UnloadingLoadingAddressesService from "@/services/UnloadingLoadingAddressesService";

export default {
    components: {
        VehicleInfo,
        Title,
        Popup,
        NewAdderssMap,
        Loading,
    },

    data() {
        return {
            selectedVehicleType: 0,
            selectedVehicleTypeName: "",
            isOpenPopup: false,
            vehicleTypes: [],
            vehicleInfos: {
                bodyFloorType: 0,
                bodyFloorTypeName: "",
                caseType: "",
                trailerType: 0,
                trailerTypeName: "",
                vehicleCount: 0,
            },

            isLoading: false,
            isActive: true,

            AssetUrls: AssetUrls,

            loadService: new LoadService(),
            unloadingLoadingAddressesService: new UnloadingLoadingAddressesService(),
        };
    },

    computed: {
        ...mapGetters("createLoad", ["getVehicleType", "getVehicleInfos", "getIsComingFromDraft"]),
    },

    async beforeMount() {
        await this.getVehicleTypes();
    },

    mounted() {
        this.selectedVehicleType = this.getVehicleType.id;
        this.selectedVehicleTypeName = this.getVehicleType.text;

        this.vehicleInfos.bodyFloorType = this.getVehicleInfos.bodyFloorType;
        this.vehicleInfos.bodyFloorTypeName = this.getVehicleInfos.bodyFloorTypeName;
        this.vehicleInfos.caseType = this.getVehicleInfos.caseType;
        this.vehicleInfos.trailerType = this.getVehicleInfos.trailerType;
        this.vehicleInfos.trailerTypeName = this.getVehicleInfos.trailerTypeName;
        this.vehicleInfos.vehicleCount = this.getVehicleInfos.vehicleCount;
    },

    methods: {
        ...mapActions("createLoad", ["updateVehicleType", "updateVehicleInfos"]),

        handleImageError(event) {
            event.target.src = require(`@/assets/images/customer-area/my-ads/create-new-ads/default-vehicle-type.png`);
        },

        async getVehicleTypes() {
            this.isLoading = true;

            await this.loadService.getVehicleTypes(this.isActive);

            if (this.loadService.resultModel.code == 14) {
                // console.log(this.loadService.resultModel);
                this.vehicleTypes = this.loadService.resultModel.data;
            } else {
                console.log("VEHICLE TYPES ERROR => " + this.loadService.resultModel.message);
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },

        getSelectedVehicleType(vehicleType) {
            this.selectedVehicleType = vehicleType.id;
            this.selectedVehicleTypeName = vehicleType.name;
        },

        // child componentten verileri almak için böyle bir yöntem kullandık.
        getSelectedVehicleInfoValues(vehicleInfos) {
            this.vehicleInfos = vehicleInfos;
        },

        setVehicleTypeAndVehicleInfoValuesToVuex() {
            this.updateVehicleType([this.selectedVehicleType, this.selectedVehicleTypeName]);
            this.updateVehicleInfos([this.vehicleInfos]);
        },

        validateSelectedValues() {
            if ((this.selectedVehicleType == 0 && this.vehicleInfos.vehicleCount == 0) || this.vehicleInfos.trailerType == 0 || this.vehicleInfos.bodyFloorType == 0) {
                return false;
            } else {
                return true;
            }
        },

        async getUnloadingLoadingAddresses() {
            if (this.validateSelectedValues()) {
                this.setVehicleTypeAndVehicleInfoValuesToVuex();

                await this.unloadingLoadingAddressesService.getUnloadingLoadingAddressesByCustomerUserGuid(1, true);

                if (this.unloadingLoadingAddressesService.resultModel.code == 14) {
                    if (this.unloadingLoadingAddressesService.resultModel.data.length > 0) {
                        if (this.getIsComingFromDraft) {
                            this.$router.push("/customer/new-address");
                        } else {
                            this.$router.push("/customer/registered-addresses");
                        }
                    } else {
                        this.openPopup();
                    }
                } else {
                    this.$toast.error(this.$t("resultModel." + this.unloadingLoadingAddressesService.resultModel.message));
                }

                if (this.unloadingLoadingAddressesService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            }
        },

        openPopup() {
            this.getUnloadingLoadingAddresses();
            this.isOpenPopup = true;
            //this.getCurrentLocation();
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/my-ads/create-new-ads/vehicle-type.css";

.selected-vehicle-type {
    border: 1px solid #929da7;
    box-shadow: 0px 3px 12px #00000029;
}
</style>