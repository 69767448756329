<template>
    <div class="dp-flex-column">
        <Loading v-if="isLoading">

        </Loading>

        <div class="dp-flex-column justify-content-between" v-if="!isLoading && load">
            <div class="dp-flex-row justify-content-between width-100">
                <div class="dp-flex-row justify-content-center font-size-20 text-color-white items-center load-price border-radius-5 width-40">
                    {{ load.averagePrice != null ? $t('transporterLoads.Avarage_Price') + ': ' + load.averagePrice + ' ' + load.currencyCode : $t('transporterLoadDetail.Offer_Title') }}
                </div>

                <div v-if="load.offer != null" class="dp-flex-row justify-content-center items-center font-size-20 text-color-white given-price border-radius-5 width-25">
                    {{ $t('transporterOffer.Given_Offer') + load.offer.amount + ' ' + load.currencyCode }}
                </div>

                <div v-else class="dp-flex-row justify-content-center items-center font-size-20 text-color-white given-price border-radius-5 width-25">
                    {{ $t('transporterOffer.You_Not_Made_An_Offer') }}
                </div>

                <button @click="isOpen = true" class="btn-none font-size-13 border-radius-5 btn-post-ad width-30">
                    {{ load.offer == null ? $t('button.Give_Offer') : $t('button.Update_Offer') }}
                </button>
            </div>

            <div class="panel-content mt-mb-y1">
                <Map :isLoad="true" :loadingUnloadingPoints="loadingUnloadingPoints"></Map>
            </div>

            <div class="dp-flex-row justify-content-between width-100">
                <div class="dp-flex-column panel-content justify-content-start">
                    <div class="dp-flex-row justify-content-start items-center bottom-border">
                        {{ $t('transporterLoadDetail.Loading_Unloading_Addresses') }}
                    </div>

                    <div v-if="load.loadAddressInfo" class="dp-flex-row justify-content-start width-100" style="overflow-x: scroll; height:100%;padding: 2% 0;">
                        <div style="min-width: 50%;" class="dp-flex-column width-100 justify-content-around konum">
                            <div class="dp-flex-column justify-content-between items-center">
                                <p class="font-size-20 text-medium">
                                    {{ load.loadAddressInfo.cityName }}
                                </p>
                                <p class="font-size-15 color-869094">
                                    {{ load.loadAddressInfo.districtName }}
                                </p>
                            </div>

                            <div class="dp-flex-row justify-content-center items-center">
                                <i class="fa-solid fa-calendar-week font-size-25 color-667085 mr-y5"></i>

                                <div class="dp-flex-column justify-content-center items-center">
                                    <p class="font-size-16 text-medium">
                                        {{ $t('transporterLoadDetail.Loading_Date') }}
                                    </p>
                                    <p class="font-size-13">
                                        {{ load.loadAddressInfo.loadDate.replace('T', ' ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div :key="index" v-for="(item, index) in load.unLoadAddressInfo" style="min-width: 50%;" class="dp-flex-row width-100 justify-content-between">
                            <div class="icon-arrow dp-flex-column items-center justify-content-center ml-mr-10">
                                <i class="fa-solid fa-arrow-right"></i>
                            </div>

                            <div style="padding:5%;" class="dp-flex-column width-100 justify-content-around konum">
                                <div class="dp-flex-column justify-content-between items-center">
                                    <p class="font-size-20 text-medium">
                                        {{ item.cityName }}
                                    </p>
                                    <p class="font-size-15 color-869094">
                                        {{ item.districtName }}
                                    </p>
                                </div>

                                <div class="dp-flex-row justify-content-center items-center">
                                    <i class="fa-solid fa-calendar-week font-size-25 color-667085 mr-y5"></i>

                                    <div class="dp-flex-column justify-content-center items-center">
                                        <p class="font-size-16 text-medium">
                                            {{ $t('transporterLoadDetail.Unloading_Date') }}
                                        </p>
                                        <p class="font-size-13">
                                            {{ item.unLoadDate.replace('T', ' ') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="dp-flex-row justify-content-evenly">

                        <div class="line">
                        </div> -->
                </div>

                <div class="dp-flex-column panel-content ml-y1">
                    <div class="dp-flex-row justify-content-start items-center bottom-border">
                        {{ $t('transporterLoadDetail.Load_Fatures') }}
                    </div>
                    <div class="dp-flex-row width-100 justify-content-start">
                        <div class="dp-flex-column width-50 justify-content-start">
                            <!-- <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    Mesafe:
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">                                    
                                    420 km
                                </p>
                            </div> -->

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Vehicle_Type') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.vehicleType }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Trailer_Type') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.trailerType }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Tonnage_Range') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.range }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Volume') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.volume }} m3
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Desi') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.decimeter }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.LDM') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.ldmLoadingMeter }}
                                </p>
                            </div>
                        </div>
                        <div class="dp-flex-column width-50 justify-content-start">

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Load_Item_Type') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.loadItemType }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Load_Type') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.loadType }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Is_Flammable') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.isFlammable ? $t('transporterLoadDetail.Yes') : $t('transporterLoadDetail.No') }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Is_Hamal') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.isHamal ? $t('transporterLoadDetail.Yes') : $t('transporterLoadDetail.No') }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Is_Stacked') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.isStacked ? $t('transporterLoadDetail.Yes') : $t('transporterLoadDetail.No') }}
                                </p>
                            </div>
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Value') }}
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.value }} {{ load.currencyCode }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dp-flex-column mt-mb-y1 panel-content">
                <div class="dp-flex-row font-size-20 justify-content-start items-center bottom-border">
                    {{ $t('transporterLoadDetail.Load_Note') }}
                </div>
                <p class="font-size-13">
                    {{ load.note != "" ? load.note : $t('transporterLoadDetail.Unspecified') }}
                </p>
            </div>

            <Popup v-if="isOpen" @closePopup="isOpen = false">
                <template slot="popup-header">
                    <p class="font-size-22 text-bold">
                        {{ offer == 0 ? $t('transporterOffer.Title') : $t('transporterOffer.Update_Title') }}
                    </p>
                </template>

                <template slot="popup-body-text">
                    <div class="dp-flex-row justify-content-center items-center width-100">
                        <p class="font-size-16">
                            {{ load.averagePrice == null ? $t('transporterOffer.Description') : ($t('transporterOffer.Offer_Given') + load.averagePrice + load.currencyCode + $t('transporterOffer.Offer_Given2')) }}
                        </p>
                    </div>
                    <div class="dp-flex-row justify-content-center items-center width-100">
                        <p v-if="offer != 0" class="font-size-16">
                            {{ $t('transporterOffer.Given_Offer') + offer + ' ' + load.currencyCode }}
                        </p>
                    </div>
                </template>

                <template slot="popup-body">
                    <div class="dp-flex-row justify-content-center items-end width-100">
                        <AddressLabelWithInput class="width-60" v-model="offer" :label="$t('transporterOffer.Give_Offer')" :placeHolder="$t('transporterOffer.Give_Offer')" :type="'text'" :name="'offer'">

                        </AddressLabelWithInput>
                        <p class="ml-y2 font-size-20">
                            {{ load.currencyName + ' ' + '(' + load.currencyCode + ')' }}
                        </p>
                    </div>
                </template>

                <template slot="popup-body-footer">
                    <div class="dp-flex-row justify-content-center items-center width-100 mt-mb-10">
                        <button @click="createOffer()" class="btn-none font-size-13 width-50 border-radius-5 ml-y1 btn-post-ad give-offer-input">
                            {{ offer == 0 ? $t('button.Give_Offer') : $t('button.Update_Offer') }}
                        </button>
                    </div>
                </template>
            </Popup>
        </div>

    </div>

</template>

<script>

// TODO: maskjs kütüphanesi eklendi fakat kullanılmadı. teklif ver inputuna mask js eklenecek.

import Map from "@/components/Widgets/Map/Map.vue";
import { gmapApi } from "vue2-google-maps";
import Popup from "@/components/Widgets/ModelPopup/Popup.vue";
import AddressLabelWithInput from "@/components/CustomerArea/Addresses/LoadingPoint/AddressLabelWithInput.vue";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import LoadService from "@/services/LoadService";
import OfferService from "@/services/OfferService";

export default {
    name: "ShipperLoadDetail",

    components: {
        Map,
        Popup,
        AddressLabelWithInput,
        Loading,
    },

    data() {
        return {
            id: 0,
            load: {},
            offer: 0,
            isOpen: false,
            isLoading: false,
            loadingUnloadingPoints: [],
            loadService: new LoadService(),
            offerService: new OfferService(),
        };
    },

    computed: {
        google: gmapApi,
    },

    async mounted() {
        this.id = this.$route.params.id ?? 0;

        if (this.id != 0) {
            await this.getLoadDetailForTransporter(this.id);
        } else {
            this.$router.push("/transporter");
        }
    },

    methods: {
        async getLoadDetailForTransporter() {
            this.isLoading = true;

            await this.loadService.getLoadDetailForTransporter(this.id);

            if (this.loadService.resultModel.code == 14) {
                this.load = this.loadService.resultModel.data;

                this.offer = this.load.offer != null ? this.load.offer.amount : 0;

                let marker = {
                    markerType: 1,
                    title: "1. Yükleme Nokatası",
                    position: {
                        lat: 0,
                        lng: 0,
                    },
                }

                marker.position.lat = this.load.loadAddressInfo.latitude;
                marker.position.lng = this.load.loadAddressInfo.longitude;

                this.loadingUnloadingPoints.push(marker);

                let order = 1;
                this.load.unLoadAddressInfo.forEach(element => {
                    let marker2 = {
                        markerType: 2,
                        title: "",
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    }

                    marker2.markerType = 2;
                    marker2.title = order + ".Boşaltma Noktası";
                    marker2.position.lat = element.latitude;
                    marker2.position.lng = element.longitude;

                    this.loadingUnloadingPoints.push(marker2);
                    order++;
                });

            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },

        async createOffer() {
            if (this.offer > 0) {

                let offer = {
                    id: this.load.offer != null ? this.load.offer.id : 0,
                    loadId: this.load.id,
                    amount: this.offer,
                };

                await this.offerService.createOrUpdateOffer(offer.id, offer.loadId, offer.amount);

                if (this.offerService.resultModel.code == 154 || this.offerService.resultModel.code == 155) {
                    this.$toast.success(this.$t("resultModel." + this.offerService.resultModel.message));
                } else {
                    this.$toast.error(this.$t("resultModel." + this.offerService.resultModel.message));
                }

                if (this.offerService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }

                this.isOpen = false;

            } else {
                this.$toast.warning(this.$t("transporterOffer.Offer_Must_Be_Up_Zero"), {
                    timeout: 2000,
                });
            }
        },
    },
};
</script>

<style>
.load-price {
    background-color: #5d95fe;
    height: 5vh;
}

.given-price {
    background-color: #169d16;
    height: 5vh;
}

.bottom-border {
    border-bottom: 1px solid #d7dddf;
}

.konum {
    border-radius: 6px;
    border-color: #c9ced3;
    background-color: #f4f5f6;
}

.icon-arrow {
    font-size: 25px;
    color: black;
    display: flex;
    justify-content: center;
    font-weight: bold;
}

.line {
    border-right: 2px solid #d7dddf;
}

.give-offer-input {
    padding: 3% !important;
}
</style>