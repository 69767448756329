export function validateRequired(validateDataName, validateData) {

    let isValid = true;
    let message = "";

    if (!validateData) {
        // console.log(validateDataName + " is required");
        message = validateDataName + " is required";
        isValid = false;
    }
    return { message, isValid };
}

export function validateEmail(email) {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;

    let isValid = true;
    let message = "";

    if (!regex.test(email)) {
        message = "This is not a valid email format!";
        isValid = false;
    }

    return { message, isValid };
}

export function validatePassword(password, passwordAgain) {
    let isValid = true;
    let message = "";

    let requiredResultPassword = validateRequired("Password", password);

    let requiredResultPasswordAgain = validateRequired("Password Again", passwordAgain);

    if (!requiredResultPassword.isValid) {
        isValid = false;
        message = requiredResultPassword.message;
    }

    if (!requiredResultPasswordAgain.isValid) {
        isValid = false;
        message = requiredResultPasswordAgain.message;
    }

    if (password.length < 6) {
        message = "FormValidateStrings.PasswordLengthMin";
        isValid = false;
    }
    else if (password.length > 256) {
        message = "FormValidateStrings.PasswordLengthMax";
        isValid = false;
    }
    else if (password != passwordAgain) {
        message = "FormValidateStrings.PasswordAndPaswordAgainMatch";
        isValid = false;
    }

    return { message, isValid }
}

export function validateIdentityNumber(identityNumber) {

    let isValid = true;
    let message = "";

    const regex2 = /[0-9]/;

    if (validateRequired("IdentityNumber", identityNumber).isValid) {
        if (!regex2.test(identityNumber)) {
            isValid = false;
            message = "FormValidateStrings.IdentityNumberIsIncorrect";
        }
    }
    else {
        isValid = validateRequired("identityNumber", identityNumber).isValid;
        message = validateRequired("identityNumber", identityNumber).message;
    }

    return { message, isValid }
}

export function isDigits(value, validateDataName) {

    let isValid = true;
    let message = "";

    const regexDigits = /[0-9]/;

    let result = validateRequired(validateDataName, value);

    if (result.isValid) {
        if (!regexDigits.test(value)) {
            isValid = false;
            message = "FormValidateStrings.IsNotNumber";
        }
    }
    else {
        isValid = result.isValid;
        message = result.message;
    }
    return { message, isValid };
}

export function isAlphabets(value, validateDataName) {
    let isValid = true;
    let message = "";

    const regexAlphabets = /^[A-Za-z]+$/;

    let result = validateRequired(validateDataName, value);

    if (result.isValid) {
        if (!regexAlphabets.test(value)) {
            isValid = false;
            message = "FormValidateStrings.IsNotAlphabets";
        }
    }
    else {
        isValid = result.isValid;
        message = result.message;
    }

    return { message, isValid };
}

export function isSameText(text1, text2) {

}