<template>
    <div class="dp-flex-row dp-flex-wrap items-center">
        <Loading v-if="isLoading"></Loading>

        <AdsSearch v-if="isLoading == false" v-model="searchName" :placeholder="$t('transporterDriver.Driver_Search_Box')" @input="searchDriver" class="vehicle-search mt-mb-y1 width-25"></AdsSearch>

        <EmptyAds class="width-100" v-if="isLoading == false && drivers.length <= 0" :title="$t('emptyAds.Empty_Drivers_Titile')" :description="$t('emptyAds.Empty_Drivers_Description')">

        </EmptyAds>

        <MyDirversItem v-for="driver in drivers" :key="driver.id" :driver="driver" v-on:deletedDriverId="getDeletedDriverId"></MyDirversItem>
    </div>
</template>

<script>

import MyDirversItem from "./MyDirversItem.vue";
import DriversService from "@/services/DriversService";
import { FilterDto } from "@/Helpers/Models";
import AdsSearch from "@/components/CustomerArea/MyAds/AdsSearch.vue";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import EmptyAds from "@/components/CustomerArea/MyAds/EmptyAds.vue";

export default {
    components: {
        MyDirversItem,
        AdsSearch,
        Loading,
        EmptyAds,
    },

    data() {
        return {
            drivers: [],
            searchName: "",
            driversService: new DriversService(),
            filterDto: new FilterDto(),
            isLoading: true,
        };
    },

    async mounted() {
        this.filterDto.IsLatest = true;
        this.filterDto.IsActive = true;
        this.filterDto.Page = 0;
        this.filterDto.Take = 12;

        this.getDriversByTransporterId(this.filterDto);

        window.addEventListener("scroll", this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        async getDriversByTransporterId(filterDto = new FilterDto()) {
            await this.driversService.getDriversByTransporterId(this.filterDto);

            if (this.driversService.resultModel.code == 14) {
                if (filterDto.Page > 0) {
                    this.drivers.push(...this.driversService.resultModel.data);
                } else if (filterDto.Search != "") {
                    this.drivers = this.driversService.resultModel.data;
                } else {
                    this.drivers = this.driversService.resultModel.data;
                }
                this.isLoading = false;
            } else {
                this.$toast.error(this.$t("resultModel." + this.driversService.resultModel.message));
            }

            if (this.driversService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        getDeletedDriverId(driverId) {
            let deletedDriver = this.drivers.map((x) => x.id).indexOf(driverId);

            this.drivers.splice(deletedDriver, 1);
        },

        async handleScroll() {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (documentHeight - (scrollTop + windowHeight) < 1) {
                this.filterDto.Page += 1;
                await this.getDriversByTransporterId(this.filterDto);
            }
        },

        async searchDriver() {
            this.filterDto.IsActive = true;
            this.filterDto.Page = 0;
            this.filterDto.Take = 12;

            this.filterDto.Search = this.searchName;

            await this.getDriversByTransporterId(this.filterDto);
        },
    },
};
</script>

<style></style>