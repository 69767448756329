<template>
    <div class="last-offres-content justify-content-between border-radius-5 dp-flex-column" :class="isAccept == false ? 'rejected-offer-card' : isAccept == true ? 'accepted-offer-card' : ''">
        <div class="dp-flex-row justify-content-between">

            <img v-if="imgUrl == null" v-bind:src="require('@/assets/images/customer-area/dashboard/ben.png')" alt="user-icon">

            <!-- <img v-bind:src="require('@/assets/images/customer-area/dashboard/' + imgUrl)" alt="user-icon"> -->

            <img class="offers-user-profile-photo-img" v-if="imgUrl != null" :src="profilePhoto" alt="user-icon">

            <div class="user-name-title dp-flex-column">
                <p class="name text-color-last-offres-card font-size-15">{{fulName}}</p>
                <p class="title font-size-12">{{company}}</p>
            </div>
            <div class="star-raiting dp-flex-column justify-content-between">
                <i class="star font-size-15 fa-solid fa-star"></i>
                <p class="raiting text-color-last-offres-card font-size-15 text-bold">{{starRating}}</p>
            </div>
        </div>

        <div class="dp-flex-column last-offres-card-middle mt-mb-25">
            <p class="middle-text font-style-italic font-size-15">
                {{$t("offers-card.MadeAnOffer")}}
            </p>

            <p class="middle-text text-color-last-offres-card font-size-15 text-bold">
                {{roadInfo}}
            </p>
        </div>

        <div class="font-size-22 text-center last-offres-price">
            {{price}}
        </div>

        <div v-if="isAccept == null" class="dp-flex-row justify-content-between width-100 items-center mt-mb-10">
            <button id="accept-offer-button" @click="acceptOrRejectOffer(id,true)" class="accept-offer-button btn-none font-size-15 border-radius-5 text-color-last-offres-card button-loading">
                <i class="fa-solid fa-check font-size-15"></i>
            </button>
            <button id="rejected-offer-button" @click="acceptOrRejectOffer(id,false)" class="rejected-offer-button btn-none font-size-15 border-radius-5 text-color-last-offres-card">
                <i class="fa-solid fa-xmark font-size-15"></i>
            </button>
        </div>

        <!-- <button v-if="isAccept == true" class="last-offres-send-message font-size-15 text-color-last-offres-card">
            <i class="fa-solid fa-message font-size-15"></i>
            {{$t("button.SendMessage")}}
        </button> -->

        <div v-if="isAccept == false" class="border-radius-5 p-y4 text-center font-size-14 rejected-offer text-color-white mt-mb-10">
            Teklif Reddedildi!
        </div>

        <div v-if="isAccept == true" class="border-radius-5 p-y4 text-center font-size-14 accepted-offer text-color-white mt-mb-10">
            Teklif Kabuledildi!
        </div>

    </div>
</template>

<script>
// TODO: kabul et veya reddet e basınca loading animasyonu çıkacak. buton üstünde de olabilir veya card komple blur'lanacak.

import { APIUrls } from "@/Helpers/APIUrls";
import { AssetUrls } from "@/Helpers/AssetUrls";

export default {
    name: "OffersCard",

    props: {
        id: 0,
        fulName: String,
        company: String,
        starRating: 0,
        price: String,
        imgUrl: String,
        roadInfo: String,
        isAccept: Boolean,
    },

    data() {
        return {
            profilePhoto: "",
        };
    },

    mounted() {
        this.profilePhoto = this.imgUrl != null ? AssetUrls.USER_ASSETS_URL + this.imgUrl : null;
        // console.log(this.profilePhoto);
    },

    methods: {
        acceptOrRejectOffer(id, isAccept) {
            if (id > 0) {
                // console.log(id);
                // console.log(isAccept);

                // this.$emit("offerAccept");

                isAccept ? document.querySelector("#accept-offer-button").classList.add("button--loading") : document.querySelector("#rejected-offer-button").classList.add("button--loading");                

                this.axios({
                    url: APIUrls.ACCEPT_OR_REJECT_OFFER,
                    method: "POST",
                    params: {
                        id: id,
                        isAccept: isAccept,
                    },
                })
                    .then((response) => {
                        if (response.status == 200) {
                            if (response.data.code == 32) {
                                this.$emit("offerAccept");

                                this.$toast.success("Teklif Kabul Edildi !", {
                                    timeout: 2000,
                                });
                                // console.log("Teklif Kabul Edildi !!");
                            }

                            if (response.data.code == 155) {
                                this.$toast("Teklif Reddedildi !", {
                                    timeout: 2000,
                                });
                            }

                            isAccept ? document.querySelector("#accept-offer-button").classList.remove("button--loading") : document.querySelector("#rejected-offer-button").classList.remove("button--loading");                
                        } else {
                            //console.log(response);

                            this.$toast.warning("Beklenmeyen Bir Hata Oluştu ! Lütfen Daha Sonra Tekrar Deneyiniz !", {
                                timeout: 2000,
                            });
                        }
                    })
                    .catch((error) => {
                        // console.log(error);
                        this.$toast.warning("Beklenmeyen Bir Hata Oluştu ! Lütfen Daha Sonra Tekrar Deneyiniz !", {
                            timeout: 2000,
                        });
                    });
            } else {
                //console.log("hata");
                this.$toast.warning("Beklenmeyen Bir Hata Oluştu ! Lütfen Daha Sonra Tekrar Deneyiniz !", {
                    timeout: 2000,
                });
            }
        },
    },
};
</script>

<style>
.offers-user-profile-photo-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.rejected-offer {
    background-color: #d01438;
}

.rejected-offer-card {
    border: 1px solid #d01438;
}

.rejected-offer-button {
    background-color: #d01438;
    color: white;
    width: 48%;
    padding: 3%;
}

.rejected-offer-button:hover {
    background-color: #a6102c;
}

.accepted-offer {
    background-color: #52c65e;
}

.accepted-offer-card {
    border: 1px solid #52c65e;
}

.accept-offer-button {
    background-color: #52c65e;
    color: white;
    width: 48%;
    padding: 3%;
}

.accept-offer-button:hover {
    background-color: #26b198;
}
</style>


// TODO: bir teklif kabul edildiğinde veya reddedildiğinde componentlerin yenilenmesi yapılacak.