let LOCAL_DOMAIN = "https://localhost:44342/api/";
let TEST_DOMAIN = "https://backend-test.daliport.com/api/";
let MAIN_DOMAIN = "https://backend.daliport.com/api/";
let MAIN_IP = "http://172.16.64.4:3011/api/";
let MAIN_URL = TEST_DOMAIN;

export const APIUrls = {

    // LOGIN_URL: MAIN_URL + "https://171.22.187.222:3011/Api/Auth/Authenticate",
    // REGISTER_URL: "http://171.22.187.222:3013/Api/User/Register",
    // FORGET_PASSWORD_URL: "http://171.22.187.222:3013/Api/User/ForgotPassword",
    // RESET_PASSWORD: "http://171.22.187.222:3013/Api/User/ResetPassword",
    // GET_COUNTRIES_CITIES_TAXOFFICES: "http://171.22.187.222:3013/Api/User/GetCountriesCitiesTaxOffices",
    // ADD_OTHER_INFORMATIONS_FOR_CUSTOMER_REGISTER: "http://171.22.187.222:3013/Api/User/AddOtherInformationsForCostumerRegister",
    // GET_ALL_DRIVER_BY_SHIPPER: "http://171.22.187.222:3014/Api/DriverVehicle/GetAllDriverByShipper",


    // CUSTOMER_USER
    CUSTOMER_LOGIN_URL: MAIN_URL + "CustomerAuthentication/login",
    CUSTOMER_REGISTER_URL: MAIN_URL + "CustomerAuthentication/register",
    GET_COUNTRIES_CITIES_TAX_OFFICES: MAIN_URL + "Country/get-counntries-with-cities-and-tax-offices",
    COMPLETE_CUSTOMER_REGISTRATION: MAIN_URL + "CustomerAuthentication/complete-customer-registration",
    CUSTOMER_FORGET_PASSWORD_URL: MAIN_URL + "CustomerAuthentication/forget-password",
    CUSTOMER_RESET_PASSWORD_URL: MAIN_URL + "CustomerAuthentication/reset-password",
    CUSTOMER_GET_USER_PROFILE: MAIN_URL + "CustomerAuthentication/get-user-profile",
    CUSTOMER_UPDATE_USER_PROFILE: MAIN_URL + "CustomerAuthentication/update-profile",

    // TRANSPORTER_USER
    TRANSPORTER_LOGIN_URL: MAIN_URL + "TransporterUser/login",
    TRANSPORTER_REGISTER_URL: MAIN_URL + "TransporterUser/register",
    TRANSPORTER_USE_DELETE_OR_RESTORE_DELETE_URL: MAIN_URL + "TransporterUser/delete-or-restore-delete-transporter-user",
    GET_TRANSPORTER_USER_BY_GUID: MAIN_URL + "TransporterUser/get-transporter-user-by-guid",
    UPDATE_TRANSPORTER_USER_PROFILE: MAIN_URL + "TransporterUser/update-transporter-user-profile",
    CHANGE_TRANSPORTER_USER_PASSWORD: MAIN_URL + "TransporterUser/change-transporter-user-password",

    GET_SHIPMENT_TYPES: MAIN_URL + "ShipmentType/get-shipment-types",
    GET_VEHICLE_TYPES: MAIN_URL + "VehicleType/get-vehicle-types",
    GET_VEHICLE_BODY_TYPES: MAIN_URL + "VehicleBodyType/get-vehicle-body-types",
    GET_VEHICLE_BRANDS: MAIN_URL + "VehicleBrand/get-vehicle-brands",

    GET_LOAD_TONNAGE_RANGES: MAIN_URL + "LoadTonnageRange/get-load-tonnage-ranges",
    GET_LOAD_TYPES: MAIN_URL + "LoadType/get-load-types",
    GET_LOAD_ITEM_TYPES: MAIN_URL + "LoadItemType/get-load-item-types",
    GET_LOADING_METHODS: MAIN_URL + "LoadingMethod/get-loading-methods",

    // UNLOADING_LOADING_ADDRESSES
    GET_UNLOADING_LOADING_ADDRESSES: MAIN_URL + "UnloadingLoadingAddress/get-unloading-loading-addresses",
    GET_UNLOADING_LOADING_ADDRESSES_BY_CUSTOMER_USER_GUID: MAIN_URL + "UnloadingLoadingAddress/get-unloading-loading-addresses-by-customer-user-guid",
    GET_UNLOADING_LOADING_ADDRESS_WITH_DETAILS: MAIN_URL + "UnloadingLoadingAddress/get-unloading-loading-address-with-details",
    CREATE_OR_UPDATE_UNLOADING_LOADING_ADDRESS: MAIN_URL + "UnloadingLoadingAddress/create-or-update-unloading-loading-address",
    DELETE_OR_RESTORE_DELETE_UNLOADING_LOADING_ADDRESS: MAIN_URL + "UnloadingLoadingAddress/delete-or-restore-delete-unloading-loading-address",

    GET_BODY_FLOOR_TYPES: MAIN_URL + "BodyFloorType/get-body-floor-types",


    // LOADS
    GET_LOADS_BY_CUSTOMER_USER_GUID: MAIN_URL + "Load/get-loads-by-customer-user-guid",
    GET_DRAFT_LOADS_BY_CUSTOMER_USER_GUID: MAIN_URL + "Load/get-draft-loads-by-customer-user-guid",
    GET_LOAD_WITH_DETAIL: MAIN_URL + "Load/get-load-with-detail",
    GET_LOADS_FOR_TRANSPORTER: MAIN_URL + "Load/get-loads-for-transporter",
    GET_LOAD_DETAIL_FOR_TRANSPORTER: MAIN_URL + "Load/get-load-detail-for-transporter",
    CREATE_LOAD: MAIN_URL + "Load/create-load",
    DELETE_OR_RESTORE_DELETE_LOAD: MAIN_URL + "Load/delete-or-restore-load-by-load-id",


    // OFFERS
    GET_LAST_OFFERS_BY_CUSTOMER_GUID: MAIN_URL + "Offer/get-offers-by-customer-user-guid",
    GET_OFFERS_BY_LOAD_ID: MAIN_URL + "Offer/get-offers-by-load-id",
    CREATE_OR_UPDATE_OFFER: MAIN_URL + "Offer/create-or-update-offer",
    ACCEPT_OR_REJECT_OFFER: MAIN_URL + "Offer/accept-or-reject-offer",
    GET_OFFERS_BY_TRANSPORTER_USER_GUID: MAIN_URL + "Offer/get-offers-by-transporter-user-guid",

    // VEHICLES
    GET_VEHICLES_BY_TRANSPORTER_USER_GUID: MAIN_URL + "Vehicle/get-vehicles-by-transporter-user-guid",
    DELETE_OR_RESTORE_DELETE_VEHICLE: MAIN_URL + "Vehicle/delete-or-restore-delete-vehicle",
    CREATE_OR_UPDATE_VEHICLE: MAIN_URL + "Vehicle/create-or-update-vehicle",
    GET_ASSIGNABLE_VEHICLES_BY_TRANSPORTER_GUID: MAIN_URL + "Vehicle/get-assignable-vehicles-by-transporter-guid",
    UPDATE_VEHICLE_STATUS: MAIN_URL + "Vehicle/update-vehicle-status",

    // DRIVERS 
    GET_DRIVERS_BY_TRANSPORTER_GUID: MAIN_URL + "TransporterUser/get-drivers-by-transporter-guid",
    GET_ASSIGNABLE_DRIVERS_BY_TRANSPORTER_GUID: MAIN_URL + "TransporterUser/get-assignable-drivers",
    ADD_DRIVERS_TO_VEHICLE: MAIN_URL + "Vehicle/add-drivers-to-vehicle",
    REMOVE_DRIVER_TO_VEHICLE: MAIN_URL + "Vehicle/remove-driver-to-vehicle",
    UPDATE_DRIVER_STATUS: MAIN_URL + "TransporterUser/update-driver-status",
    CREATE_OR_UPDATE_DRIVER: MAIN_URL + "TransporterUser/create-or-update-driver-by-transporter",

    // VoyageVehicleUser
    ADD_VEHICLES_TO_VOYAGE_WITH_DRIVERS: MAIN_URL + "VoyageVehicleUser/add-vehicles-to-voyage-with-drivers",
    GET_VOYAGE_VEHICLE_USERS_WITH_VOYAGE_ID: MAIN_URL + "VoyageVehicleUser/get-voyage-vehicle-users-with-voyage-id",

    // VOYAGES
    GET_VOYAGES_BY_TRANSPORTER_USER_GUID_WITH_STATUS: MAIN_URL + "Voyage/get-voyages-by-transporter-user-guid",
    GET_VOYAGE_DETAIL_FOR_TRANSPORTER_USER: MAIN_URL + "Voyage/get-voyage-detail-for-transporter-user",
    GET_VOYAGES_BY_CUSTOMER_USER_GUID_WITH_STATUS: MAIN_URL + "Voyage/get-voyages-by-customer-user-guid",
    GET_VOYAGE_DETAIL_FOR_CUSTOMER_USER: MAIN_URL + "Voyage/get-voyage-detail-for-customer-user",

    // MESSAGES
    GET_MESSAGES_WITH_VOYAGES: MAIN_URL + "Message/get-messages-with-voyages",
    GET_USERS_BY_SEND_MESSAGES: MAIN_URL + "Message/get-users-by-send-messages",
    GET_MESSAGES_BY_RECEIVER_AND_VOYAGE_IDS: MAIN_URL + "Message/get-messages-by-receiver-and-voyage-ids",
    CREATE_OR_UPDATE_MESSAGE: MAIN_URL + "Message/create-or-update-message",

    // USERS
    GET_OFFICIAL_USERS_BY_OWNER_ID: MAIN_URL + "User/get-official-users-by-owner-id",
    CREATE_OR_UPDATE_OFFICIAL_USER: MAIN_URL + "User/create-or-update-official-user",
    DELETE_OR_RESTORE_DELETE_OFFICIAL_USER: MAIN_URL + "User/delete-or-restore-delete-official-user",

    // STATISTICS
    GET_MONTHLY_LOADS_COUNT: MAIN_URL + "Statistics/get-monthly-loads-count",
    GET_MONTHLY_VOYAGES_COUNT: MAIN_URL + "Statistics/get-monthly-voyages-count",
    GET_MONTHLY_LOAD_VEHICLES_COUNT: MAIN_URL + "Statistics/get-monthly-load-vehicles-count",

    // NOTIFICATION SETTING
    GET_NOTIFICATION_SETTINGS_BY_USER_ID: MAIN_URL + "NotificationSetting/get-notification-by-user-id",
    CREATE_OR_UPDATE_NOTIFICATION_SETTINGS_BY_USER_ID: MAIN_URL + "NotificationSetting/create-or-update-notification-setting",

    // COMPANY
    GET_COMPANY_PROFILE: MAIN_URL + "Company/get-company-profile",

    //CURRENCY
    GET_CURRENCIES: MAIN_URL + "Currency/get-currencies",

    //BUUG
    CREATE_OR_UPDATE_BUG: MAIN_URL + "Bug/create-or-update-bug",

    //LANGUAGES
    GET_LANGUAGES: MAIN_URL + "Language/get-languages",

    //COUNTRIES
    GET_COUNTRIES: MAIN_URL + "Country/get-countries",

    //CITIES
    GET_CITIES_BY_COUNTRY_ID: MAIN_URL + "City/get-cities-by-country-id",

    //DISTRICTS
    GET_DISTRICTS_BY_CITY_ID: MAIN_URL + "District/get-districts-by-city-id",
    
}