<template>
    <div class="d-flex">
        <div class="cell width-20">
            <div class="d-flex flex-column">
                <h6 class="draft-ad-title">{{draftLoad.title =='' ? $t("draftAd.Features") : draftLoad.title}}</h6>
                <span class="draft-ad-description">{{loadingAddress + ' - ' + unLoadingAddress}}</span>
            </div>
        </div>
        <div class="cell width-20">
            <div class="d-flex flex-column">
                <h6 class="draft-ad-title">
                    {{$t("draftAd.Features")}}
                </h6>
                <span class="draft-ad-description">{{caseTypes}}</span>
            </div>
        </div>
        <div class="cell d-flex align-items-center width-60">
            <div class="d-flex flex-column">
                <h6 class="draft-ad-title">
                    {{$t("draftAd.Load_Information")}}
                </h6>
                <span class="draft-ad-description">
                    {{loadInfo}}
                </span>
            </div>
            <button @click="useDraftLoad()" class="btn mx-35">
                {{$t("draftAd.Use_Draft")}}
            </button>
            <!-- <i class="fa-regular fa-trash-can mx-20 draft-ad-icon"></i>
            <i class="fa-solid fa-pen mx-20 draft-ad-icon"></i> -->
        </div>
    </div>
</template>

<script>
import { Currency, LoadFeaturesInfo, UnloadingLoadingAddress } from "@/Helpers/Models";
import { mapActions, mapGetters } from "vuex";

export default {
    props: ["title", "locations", "specsDesc", "loadInfoDesc", "draftLoad"],

    data() {
        return {
            loadingAddresses: this.draftLoad.loadingAddresses,
            unLoadingAddresses: this.draftLoad.unLoadingAddresses,

            loadingAddress: "",
            unLoadingAddress: "",
            caseTypes: "",
            loadInfo: "",

            loadTypes: this.draftLoad.loadTypes,
        };
    },

    computed: {
        // ...mapGetters("createLoad", ["getShipmentType", "getVehicleType", "getVehicleInfos", "getDraftLoadingAddress", "getIsComingFromDraft", "getUnloadingAddresses"]),
    },

    mounted() {
        this.loadingAddress = this.draftLoad.loadingAddresses[0].cityName + "/" + this.draftLoad.loadingAddresses[0].districtName;
        this.unLoadingAddress = this.draftLoad.unLoadingAddresses[this.draftLoad.unLoadingAddresses.length - 1].cityName + "/" + this.draftLoad.unLoadingAddresses[this.draftLoad.unLoadingAddresses.length - 1].districtName;
        this.caseTypes = this.draftLoad.shipmentTypeName + " - " + this.draftLoad.vehicleTypeName + " - " + this.draftLoad.caseTypes.vehicleCount + " adet" + " - " + this.draftLoad.caseTypes.trailerTypeName + " - " + this.draftLoad.caseTypes.bodyFloortTypeName;
        this.loadInfo =
            this.draftLoad.loadTypes.loadItemTypeName + " - " + this.draftLoad.loadTypes.volume + " m³ - " + this.draftLoad.loadTypes.decimeter + " Desi - " + this.draftLoad.loadTypes.ldmLoadingMeter + " LDM - " + this.draftLoad.loadTypes.loadTonnageRange + " - " + this.draftLoad.loadTypes.loadingMethodName + " - " + this.draftLoad.loadTypes.loadTypeName;


        this.loadInfo += this.draftLoad.loadTypes.isFlammable ? " - " + this.$i18n.t("draftAd.Is_Flammable") : "";
        this.loadInfo += this.draftLoad.loadTypes.isHamal ? " - " + this.$i18n.t("draftAd.Is_Hamal") : "";
        this.loadInfo += this.draftLoad.loadTypes.isStacked ? " - " + this.$i18n.t("draftAd.Is_Stacked") : "";
    },

    methods: {
        ...mapActions("createLoad", ["updateVehicleType", "updateVehicleInfos", "updateIsComingFromDraft", "updateUnloadingAddresses", "updateLoadFeaturesInfo", "updateCurrency", "updateShipmentType", "updateLoadTitleAndNote", "updateLoadingAddresses", "updateUnloadingLoadingAddress"]),

        useDraftLoad() {
            this.updateLoadingAddresses([this.draftLoad.loadingAddresses]);

            this.draftLoad.unLoadingAddresses.forEach((element) => {
                element.earliestDate = undefined;
                element.latestDate = undefined;
            });

            this.updateUnloadingAddresses([this.draftLoad.unLoadingAddresses]);

            let unloadingLoadingAddress = new UnloadingLoadingAddress();

            unloadingLoadingAddress = this.draftLoad.loadingAddresses[0];

            unloadingLoadingAddress.earliestDate = undefined;
            unloadingLoadingAddress.latestDate = undefined;

            this.updateUnloadingLoadingAddress([unloadingLoadingAddress]);

            this.updateIsComingFromDraft([true]);

            this.updateLoadTitleAndNote([this.draftLoad.title, this.draftLoad.note]);

            this.updateShipmentType([this.draftLoad.shipmentTypeId]);

            this.updateVehicleType([this.draftLoad.vehicleTypeId, this.draftLoad.vehicleTypeName]);

            let vehicleInfos = {
                bodyFloorType: this.draftLoad.caseTypes.bodyFloorTypeId,
                bodyFloorTypeName: this.draftLoad.caseTypes.bodyFloortTypeName,
                caseType: this.draftLoad.caseTypes.caseFeatures,
                trailerType: this.draftLoad.caseTypes.vehicleBodyTypeId,
                trailerTypeName: this.draftLoad.caseTypes.trailerTypeName,
                vehicleCount: this.draftLoad.caseTypes.vehicleCount,
            };

            this.updateVehicleInfos([vehicleInfos]);

            this.updateLoadFeaturesInfo([this.draftLoad.loadTypes]);

            let currency = new Currency();

            currency.id = this.draftLoad.currencyId;
            currency.name = this.draftLoad.name;
            currency.code = this.draftLoad.code;

            this.updateCurrency([currency]);

            this.$router.push("/customer/vehicle-type");
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/my-ads/create-new-ads/shipment-type.css";
</style>