import Vue from 'vue';
import { APIUrls } from "@/Helpers/APIUrls";
import axios from "axios";
import i18n from "@/i18n.js";


// TODO: upate transporter veya customer kalkacak tek user olacak.

class NotificationSetting {
    isAppNotification = false;
    isMessageNotification = false;
    isVoyageNotification = false;
    isOfferNotification = false;
}

class UserProfile {
    id = 0;
    name = "";
    surname = "";
    email = "";
    phone = "";
    profilePhoto = "";
}

export default class ProfileService {
    changePassword(oldPassword = "", newPassword = "") {
        axios({
            url: APIUrls.CHANGE_TRANSPORTER_USER_PASSWORD,
            method: "POST",
            params: {
                oldPassword: oldPassword,
                newPassword: newPassword,
            },
        })
            .then((response) => {
                //console.log(response);                
                if (response.status == 200) {
                    if (response.data.code == 14) {
                        Vue.$toast.success(i18n.t("resultModel.Change_Password_Success"));

                        localStorage.removeItem("token");
                        localStorage.removeItem("guid");
                        localStorage.removeItem("userType");

                        this.$router.push({ name: "Home" });

                        window.location.reload();
                    } else if (response.data.code == 15) {
                        Vue.$toast.error(i18n.t("resultModel.Old_Password_Is_Wrong"));
                    }
                } else {
                    Vue.$toast.warning("Beklenmeyen Hata Oluştu !");
                }
            })
            .catch((error) => {
                //console.log(error);
                Vue.$toast.error("Beklenmeyen Hata Oluştu !");
            });
    }

    async getNotificationSettingsByUserId() {

        let notificationSettings = new NotificationSetting();

        await axios({
            method: "GET",
            url: APIUrls.GET_NOTIFICATION_SETTINGS_BY_USER_ID,
        })
            .then((response) => {
                //console.log(response);
                if (response.data.code == 14) {
                    notificationSettings.isAppNotification = response.data.data.isAppNotification;
                    notificationSettings.isMessageNotification = response.data.data.isMessageNotification;
                    notificationSettings.isOfferNotification = response.data.data.isOfferNotification;
                    notificationSettings.isVoyageNotification = response.data.data.isVoyageNotification;
                }
            })
            .catch((error) => {
                // console.log(error);
                Vue.$toast.error("Beklenmeyen Bir Hata Oluştu !");
            });

        return notificationSettings;
    }

    createOrUpdateNotificationSetting(notificationSettings = new NotificationSetting()) {
        axios({
            url: APIUrls.CREATE_OR_UPDATE_NOTIFICATION_SETTINGS_BY_USER_ID,
            method: "POST",
            data: {
                isAppNotification: notificationSettings.isAppNotification,
                isVoyageNotification: notificationSettings.isVoyageNotification,
                isOfferNotification: notificationSettings.isOfferNotification,
                isMessageNotification: notificationSettings.isMessageNotification,
            },
        })
            .then((response) => {
                // console.log(response);
                if (response.status == 200) {
                    if (response.data.code == 212 || response.data.code == 211) {
                        Vue.$toast.success(i18n.t("resultModel." + response.data.message));
                    } else {
                        Vue.$toast.error(i18n.t("resultModel." + response.data.message));
                    }
                }
            })
            .catch((error) => {
                // Vue.$toast.error(i18n.t("resultModel." + response.data.message));
                Vue.$toast.error("Beklenmeyen Bir Hata Oluştu ! Lütfen Daha Sonra Tekrar Deneyiniz!");
            });
    }

    async getTransporterUserByGuid() {
        let user = new UserProfile();

        await axios({
            url: APIUrls.GET_TRANSPORTER_USER_BY_GUID,
            method: "GET",
        })
            .then((response) => {
                // console.log(response);
                if (response.status == 200) {
                    if (response.data.code == 14) {
                        user = response.data.data;
                    } else {
                        Vue.$toast.error("Beklenmeyen Bir Hata Oluştu !");
                    }
                } else {
                    Vue.$toast.error("Beklenmeyen Bir Hata Oluştu !");
                }
            })
            .catch((error) => {
                //console.log(error);
                Vue.$toast.error("Beklenmeyen Bir Hata Oluştu !");
            });

        return user;
    }

    updateTransporterUserProfile(user = new UserProfile()) {
        axios({
            url: APIUrls.UPDATE_TRANSPORTER_USER_PROFILE,
            method: "POST",
            data: {
                Name: user.name,
                Surname: user.surname,
                Email: user.email,
                Phone: user.phone,
                ProfilePhoto: user.profilePhoto,
            },
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                // console.log(response);
                if (response.status == 200) {
                    if (response.data.code == 165) {
                        Vue.$toast.success(i18n.t("resultModel." + response.data.message));
                    } else {
                        Vue.$toast.warning(i18n.t("resultModel." + response.data.message));
                    }
                } else {
                    Vue.$toast.warning(i18n.t("resultModel.Transaction_Failed"));
                }
            })
            .catch((error) => {
                // console.log(error);
                Vue.$toast.error("Beklenmeyen Bir Hata Oluştu !");
            });
    }

    updateCustomerUserProfile(user = new UserProfile) {
        axios({
            url: APIUrls.CUSTOMER_UPDATE_USER_PROFILE,
            method: "POST",
            data: {
                Name: user.name,
                Surname: user.surname,
                Email: user.email,
                Phone: user.phone,
            },
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                console.log(response);
                if (response.status == 200) {
                    if (response.data.code == 170) {
                        Vue.$toast.success("Profil Bilgileri Güncellendi !");
                    } else {
                        Vue.$toast.warning("Beklenmeyen Bir Hata Oluştu !");
                    }
                } else {
                    Vue.$toast.warning("Beklenmeyen Bir Hata Oluştu !");
                }
            })
            .catch((error) => {
                console.log(error);
                this.$toast.error("Beklenmeyen Bir Hata Oluştu !");
            });
    }
}