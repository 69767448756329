<template>
    <div class="dp-flex-column active-ads">

        <Loading v-if="isLoading">

        </Loading>

        <EmptyAds v-if="!isLoading && loads.length == 0" :title="$t('emptyAds.Empty_Loads_Title')" :description="$t('emptyAds.Empty_Loads_Description')" :isAddNewAds="false">

        </EmptyAds>

        <div class="dp-flex-row justify-content-between dp-flex-wrap active-ads-content">
            <div class="panel-content active-ads-content-item dp-flex-column" v-for="load in loads" :key="load.id">
                <div class="dp-flex-row justify-content-between items-center">
                    <p class="label-bg-red font-size-14">
                        {{load.offersCount}} {{$t("active-ads.Offers")}}
                    </p>
                    <i @click="isOpenDeletePopup = true, loadId = load.id" class="fa-solid fa-trash btn-none color-D01438"></i>
                </div>
                <h4 class="font-size-16 there-offer-title text-bold">
                    {{load.title}}
                </h4>
                <div class="dp-flex-row justify-content-start width-100">
                    <h4 class="font-size-14 mr-y1">
                        {{load.loadAddress}}
                    </h4>

                    <h4 :key="index" v-for="(item,index) in load.unloadAddresses" class="font-size-14 mr-y1">
                        - {{item}}
                    </h4>
                </div>

                <div class="dp-flex-column justify-content-between mt-y3">
                    <h4 class="font-size-14 mt-mb-y25 text-bold">
                        {{$t("active-ads.LoadProperties")}}
                    </h4>
                    <div class="dp-flex-column justify-content-between">
                        <div class="dp-flex-row load-skils">
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.loadItemType}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.volume}} m3
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.tonnageRange}}
                                </p>
                            </div>
                            <div v-if="load.isFlammable" class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{$t("load-properties.FlammableMaterial")}}
                                </p>
                            </div>
                            <div v-if="load.isStacked" class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{$t("load-properties.Stackable")}}
                                </p>
                            </div>
                            <div v-if="load.isHamal" class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{$t("draftAd.Is_Hamal")}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.decimeter}} {{$t("load-properties.Desi")}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.loadingMethod}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.loadType}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dp-flex-row mt-y5 items-end justify-content-between">
                    <div class="dp-flex-column">
                        <p class="font-size-14">
                            {{$t("active-ads.BestOffer")}}
                        </p>
                        <p class="font-size-25 text-semi-bold">
                            {{load.bestOffer}} TL
                        </p>
                    </div>
                    <router-link :to="{name: 'ad-details', params:{id: load.id}}" class="btn-none font-size-15 add-new-ad-btn atext-center items-center text-semi-bold text-color-last-offres-card">
                        {{$t("active-ads.See_Other_Offers")}}
                        <i class="fa-solid fa-arrow-right font-size-15"></i>
                    </router-link>
                </div>
            </div>
            <!-- 
            <div class="panel-content active-ads-content-item dp-flex-column">
                <p class="label-bg-red font-size-14">
                    15 Teklif Var
                </p>
                <h4 class="font-size-16 there-offer-title text-bold">
                    Konya’dan İstanbul’a kuru yük taşıma
                </h4>
                <h4 class="font-size-14">
                    Konya - İstanbul
                </h4>
                <div class="dp-flex-column justify-content-between mt-y3">
                    <h4 class="font-size-14 mt-mb-y25 text-bold">
                        Yük Özellikleri
                    </h4>
                    <div class="dp-flex-column justify-content-between">
                        <div class="dp-flex-row load-skils">
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Varil/Bidon
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    1.000 m3
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    15-20 Ton
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Yanıcı Madde
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    İstiflenebilir
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Kimyasal
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    999 Desi
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Forkliftle yükleme
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Hammadde
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dp-flex-row mt-y5 items-end justify-content-between">
                    <div class="dp-flex-column">
                        <p class="font-size-14">
                            En iyi Teklif
                        </p>
                        <p class="font-size-25 text-semi-bold">
                            15.000
                        </p>
                    </div>
                    <button class="send-message font-size-15 text-color-last-offres-card">
                        <i class="fa-solid fa-message font-size-15"></i>
                        Mesaj Gönder
                    </button>
                </div>
            </div>
            <div class="panel-content active-ads-content-item dp-flex-column">
                <p class="label-bg-red font-size-14">
                    15 Teklif Var
                </p>
                <h4 class="font-size-16 there-offer-title text-bold">
                    Konya’dan İstanbul’a kuru yük taşıma
                </h4>
                <h4 class="font-size-14">
                    Konya - İstanbul
                </h4>
                <div class="dp-flex-column justify-content-between mt-y3">
                    <h4 class="font-size-14 mt-mb-y25 text-bold">
                        Yük Özellikleri
                    </h4>
                    <div class="dp-flex-column justify-content-between">
                        <div class="dp-flex-row load-skils">
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Varil/Bidon
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    1.000 m3
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    15-20 Ton
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Yanıcı Madde
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    İstiflenebilir
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Kimyasal
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    999 Desi
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Forkliftle yükleme
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Hammadde
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dp-flex-row mt-y5 items-end justify-content-between">
                    <div class="dp-flex-column">
                        <p class="font-size-14">
                            En iyi Teklif
                        </p>
                        <p class="font-size-25 text-semi-bold">
                            15.000
                        </p>
                    </div>
                    <button class="send-message font-size-15 text-color-last-offres-card">
                        <i class="fa-solid fa-message font-size-15"></i>
                        Mesaj Gönder
                    </button>
                </div>
            </div>
            <div class="panel-content active-ads-content-item dp-flex-column">
                <p class="label-bg-red font-size-14">
                    15 Teklif Var
                </p>
                <h4 class="font-size-16 there-offer-title text-bold">
                    Konya’dan İstanbul’a kuru yük taşıma
                </h4>
                <h4 class="font-size-14">
                    Konya - İstanbul
                </h4>
                <div class="dp-flex-column justify-content-between mt-y3">
                    <h4 class="font-size-14 mt-mb-y25 text-bold">
                        Yük Özellikleri
                    </h4>
                    <div class="dp-flex-column justify-content-between">
                        <div class="dp-flex-row load-skils">
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Varil/Bidon
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    1.000 m3
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    15-20 Ton
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Yanıcı Madde
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    İstiflenebilir
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Kimyasal
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    999 Desi
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Forkliftle yükleme
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Hammadde
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dp-flex-row mt-y5 items-end justify-content-between">
                    <div class="dp-flex-column">
                        <p class="font-size-14">
                            En iyi Teklif
                        </p>
                        <p class="font-size-25 text-semi-bold">
                            15.000
                        </p>
                    </div>
                    <button class="send-message font-size-15 text-color-last-offres-card">
                        <i class="fa-solid fa-message font-size-15"></i>
                        Mesaj Gönder
                    </button>
                </div>
            </div>
            <div class="panel-content active-ads-content-item dp-flex-column">
                <p class="label-bg-red font-size-14">
                    15 Teklif Var
                </p>
                <h4 class="font-size-16 there-offer-title text-bold">
                    Konya’dan İstanbul’a kuru yük taşıma
                </h4>
                <h4 class="font-size-14">
                    Konya - İstanbul
                </h4>
                <div class="dp-flex-column justify-content-between mt-y3">
                    <h4 class="font-size-14 mt-mb-y25 text-bold">
                        Yük Özellikleri
                    </h4>
                    <div class="dp-flex-column justify-content-between">
                        <div class="dp-flex-row load-skils">
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Varil/Bidon
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    1.000 m3
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    15-20 Ton
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Yanıcı Madde
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    İstiflenebilir
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Kimyasal
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    999 Desi
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Forkliftle yükleme
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    Hammadde
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dp-flex-row mt-y5 items-end justify-content-between">
                    <div class="dp-flex-column">
                        <p class="font-size-14">
                            En iyi Teklif
                        </p>
                        <p class="font-size-25 text-semi-bold">
                            15.000
                        </p>
                    </div>
                    <button class="send-message font-size-15 text-color-last-offres-card">
                        <i class="fa-solid fa-message font-size-15"></i>
                        Mesaj Gönder
                    </button>
                </div>
            </div> -->
        </div>

        <div class="dp-flex-row justify-content-end">
            <router-link class="btn-none text-center add-new-ad-btn add-new-active-ads font-size-15 items-center text-semi-bold" to="/customer/new-ads">
                <i class="fa-solid fa-plus icon-btn-ekle"></i>
                {{$t("button.NewAd")}}
            </router-link>
        </div>

        <TransactionPopup v-if="isOpenDeletePopup" @closePopup="isOpenDeletePopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center">
                    {{$t("active-ads.Delete_Ad")}}
                </p>
            </template>
            <template slot="popup-body">
                <div style="width:20vw!important" class="width-100 dp-flex-column">
                    <div class="dp-flex-row justify-content-center text-center items-center mt-y3">
                        <p class="font-size-16">
                            {{$t("active-ads.Delete_Ad_Description")}}
                        </p>
                    </div>
                </div>
            </template>
            <template slot="popup-body-button">
                <div class="dp-flex-row justify-content-center width-100 mt-y5 items-center">
                    <button @click="deleteLoad(loadId)" class="dp-flex-row btn-none delte-user-button mr-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{$t("button.Delete")}}
                    </button>
                    <button @click="isOpenDeletePopup = false" class="dp-flex-row btn-none border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{$t("button.Cancel")}}
                    </button>
                </div>
            </template>
        </TransactionPopup>
    </div>
</template>

<script>
import LayoutCustomerPanel from "@/components/CustomerArea/CustomerPanelLayout/_LayoutCustomerPanel.vue";
import EmptyAds from "./EmptyAds.vue";
import TransactionPopup from "@/components/Widgets/ModelPopup/TransactionPopup.vue";
import LoadService from "@/services/LoadService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";

export default {
    name: "ActiveAds",
    components: {
        LayoutCustomerPanel,
        EmptyAds,
        TransactionPopup,
        Loading,
    },

    data() {
        return {
            loads: [],
            isOpenDeletePopup: false,
            loadId: 0,
            isLoading: false,
            loadService: new LoadService(),
        };
    },

    async mounted() {
        await this.getActiveLoads();
    },

    methods: {
        async deleteLoad(loadId) {
            if (loadId > 0) {
                await this.loadService.deleteOrRestoreDeleteLoad(loadId, false);

                if (this.loadService.resultModel.code == 161) {
                    this.$toast.success(this.$t("resultModel." + this.loadService.resultModel.message));

                    let deletedLoad = this.loads.map((x) => x.id).indexOf(loadId);

                    this.loads.splice(deletedLoad, 1);
                } else {
                    this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
                }

                if (this.loadService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }

                this.isOpenDeletePopup = false;
            }
        },

        async getActiveLoads() {
            this.isLoading = true;

            await this.loadService.getLoadsByCustomerUserGuid(false, true);

            if (this.loadService.resultModel.code == 14) {
                this.loads = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/my-ads/active-ads.css";
</style>