 <template>
    <div class="dp-flex-column your-ads-content panel-content">
        <h4 class="panel-content-inside-title">
            {{$t('customer-dashboard-page.Ads')}}
        </h4>
        <div class="dp-flex-row mt-mb-15">
            <div class="dp-flex-column ml-mr-10">
                <p class="mt-mb-5 statistics-text-color font-size-14">{{getMonthInfo(new Date().getMonth() - 1)}} / {{new Date().getFullYear()}} <span class="text-bold font-size-14">{{$t('customer-dashboard-page.Ads')}}</span></p>
                <div class="dp-flex-column justify-content-center items-center your-ads-card">
                    <i class="fa-solid fa-truck-moving truck-icon-green"></i>
                    <div class="dp-flex-row justify-content-around your-ads-card-info">
                        <p class="font-size-13 statistics-text-color">{{getMonthInfo(new Date().getMonth() - 1)}} {{$t('customer-dashboard-page.Month_Total')}}</p>
                        <p class="font-size-20">21</p>
                    </div>
                </div>
            </div>
            <div class="dp-flex-column ml-mr-10">
                <p class="mt-mb-5 statistics-text-color font-size-14">{{getMonthInfo(new Date().getMonth())}} {{new Date().getFullYear()}} / <span class="text-bold font-size-14">{{$t('customer-dashboard-page.Ads')}}</span></p>
                <div class="dp-flex-column justify-content-center items-center your-ads-card">
                    <i class="fa-solid fa-truck-moving truck-icon-green"></i>
                    <div class="dp-flex-row justify-content-around your-ads-card-info">
                        <p class="font-size-13 statistics-text-color">{{getMonthInfo(new Date().getMonth())}} {{$t('customer-dashboard-page.Month_Total')}}</p>
                        <p class="font-size-20">21</p>
                    </div>
                </div>
            </div>
            <div class="dp-flex-column ml-mr-10">
                <p class="mt-mb-5 statistics-text-color font-size-14">{{getMonthInfo(new Date().getMonth() + 1)}} {{new Date().getFullYear()}} / <span class="text-bold font-size-14">{{$t('customer-dashboard-page.Ads')}}</span></p>
                <div class="dp-flex-column justify-content-center items-center your-ads-card">
                    <i class="fa-solid fa-truck-moving truck-icon-yellow"></i>
                    <div class="dp-flex-row justify-content-around your-ads-card-info">
                        <p class="font-size-13 statistics-text-color">{{getMonthInfo(new Date().getMonth() + 1)}} {{$t('customer-dashboard-page.Month_Total')}}</p>
                        <p class="font-size-20">{{currentMonthCount}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="dp-flex-row justify-content-end">
            <button class="panel-dashboard-button btn-none">
                {{$t("customer-dashboard-page.LastOffresSeeAllOffresButton")}}
            </button>
        </div>
    </div>
</template> 

<script>
// TODO: her ayın gösterildiği yeri ayrı component yap.
// TODO: eğer yıl geçtiyse ve 3 ay olmadıysa ona göre olan kadarını göster.

import StatisticService from "@/services/StatisticService";
export default {
    name: "YourAdds",

    data() {
        return {
            currentMonth: new Date().getMonth() + 1,
            beforeMonth: new Date().getMonth(),
            beforeMonthSecond: new Date().getMonth() - 1,

            months: [],
            monthNames: [],

            currentMonthCount: 0,
            beforeMonthCount: 0,
            beforeMonthSecondCount: 0,

            statisticService: new StatisticService(),
        };
    },

    async mounted() {
        this.currentMonthCount = await this.getMonthlyLoads(new Date().getMonth() + 1);
    },

    methods: {
        async getMonthlyLoads(month) {
            let loadCount = 1;

            await this.statisticService.getMonthlyLoads(month);

            if (this.statisticService.resultModel.code == 14) {
                this.loadCount = this.statisticService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.statisticService.resultModel.message));
            }

            if (this.statisticService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            return loadCount;
        },

        getMonthInfo(month) {
            let monthName = "";

            switch (month) {
                case 1:
                    monthName = this.$t("month.January");
                    break;

                case 2:
                    monthName = this.$t("month.February");
                    break;

                case 3:
                    monthName = this.$t("month.March");
                    break;

                case 4:
                    monthName = this.$t("month.April");
                    break;

                case 5:
                    monthName = this.$t("month.May");
                    break;

                case 6:
                    monthName = this.$t("month.June");
                    break;

                case 7:
                    monthName = this.$t("month.July");
                    break;

                case 8:
                    monthName = this.$t("month.August");
                    break;

                case 9:
                    monthName = this.$t("month.September");
                    break;

                case 10:
                    monthName = this.$t("month.October");
                    break;

                case 11:
                    monthName = this.$t("month.November");
                    break;

                case 12:
                    monthName = this.$t("month.December");
                    break;

                default:
                    monthName = this.$t("month.none");
                    break;
            }

            return monthName;
        },
    },
};
</script>

<style>
</style>