<template>
    <div class="dp-flex-column">
        <Loading v-if="isLoading">
        </Loading>

        <div v-if="!isLoading" class="dp-flex-column">
            <Title :title="$t('profile.Title')"></Title>

            <div class="dp-flex-row justify-content-start">
                <div class="dp-flex-column justify-content-between profile-content width-25">
                    <Title class="profile-title " :title="$t('profile.Profile_Edit_Title')">
                        <template slot="title-icon">
                            <i class="fa-solid fa-user font-size-16 color-667085 mr-y5"></i>
                        </template>
                    </Title>
                    <div class="dp-flex-column items-center edit-profile-user-info">
                        <img v-if="user.profilePhoto != null" class="width-50" :src="newProfilePhotoUrl != '' ? newProfilePhotoUrl : (AssetUrls.USER_ASSETS_URL + user.profilePhoto)" alt="profile-picture">
                        <div class="dp-flex-column mt-y3 items-center ml-y1">
                            <h3 class="font-size-20 text-center">
                                {{ user.name }}
                                <br>
                                {{ user.surname }}
                            </h3>
                            <p class="font-size-12 change-profile-photo-button color-6098FB text-semi-bold" @click="$refs.profilePhotoInput.click()">
                                {{ $t("profile.ChangeProfilePhoto") }}
                                <input ref="profilePhotoInput" @change="previewFiles(1, $event)" type="file" class="dp-none" value="Profil Fotoğrafı Ekleyin" accept="image/gif, image/jpeg, image/png, image/jpg">
                            </p>
                        </div>
                    </div>
                    <div class="dp-flex-column justify-content-between">
                        <AddressLabelWithInput v-model="user.name" class="mt-y3" :label="$t('profile.Name')" :name="'name'" :placeHolder="user.name" :type="'text'"></AddressLabelWithInput>
                        <AddressLabelWithInput v-model="user.surname" class="mt-y3" :label="$t('profile.SurName')" :name="'name'" :placeHolder="user.surname" :type="'text'"></AddressLabelWithInput>
                        <AddressLabelWithInput v-model="user.email" class="mt-y3" :label="$t('profile.Email')" :name="'name'" :placeHolder="user.email" :type="'text'"></AddressLabelWithInput>
                        <AddressLabelWithInput v-model="user.phone" class="mt-y3" :label="$t('profile.MobilePhone')" :name="'name'" :placeHolder="user.phone" :type="'text'"></AddressLabelWithInput>
                    </div>
                    <div class="dp-flex-row justify-content-center mt-y5 items-center">
                        <button @click="updateTransporterUserProfile()" class="btn-none width-60 p-y5 continue-btn font-size-14 items-center">
                            {{ $t('profile.SaveToChanges') }}
                        </button>
                    </div>
                </div>

                <div class="dp-flex-column justify-content-start profile-content width-25">
                    <Title class="profile-title " :title="$t('profile.ChangePassword')">
                        <template slot="title-icon">
                            <i class="fa-solid fa-lock font-size-16 color-667085 mr-y5"></i>
                        </template>
                    </Title>

                    <AddressLabelWithInput v-model="oldPassword" :label="$t('profile.Password')" :name="'password'" :placeHolder="$t('profile.Password')" :type="'password'"></AddressLabelWithInput>

                    <div class="dp-flex-row mt-mb-15 justify-content-end">
                        <p class="font-size-14 color-D01438">
                            {{ $t('profile.Forgot_Password') }}
                        </p>
                    </div>

                    <!-- <AddressLabelWithInput></AddressLabelWithInput> -->

                    <AddressLabelWithInput v-model="newPassword" class="mt-y3" :label="$t('profile.NewPassword')" :name="'password'" :placeHolder="$t('profile.Password_Label')" :type="'password'"></AddressLabelWithInput>

                    <AddressLabelWithInput v-model="newPasswordAgain" class="mt-y3" :label="$t('profile.NewPasswordAgain')" :name="'password'" :placeHolder="$t('profile.Password_Again_Label')" :type="'password'"></AddressLabelWithInput>

                    <div class="dp-flex-row justify-content-center mt-y5 items-center">
                        <button @click="changePassword()" class="btn-none width-60 p-y5 continue-btn font-size-14 items-center">
                            {{ $t('profile.SaveToChanges') }}
                        </button>
                    </div>
                </div>

                <div class="dp-flex-column justify-content-start profile-content width-25">
                    <Title class="profile-title " :title="$t('profile.Notification_Settings')">
                        <template slot="title-icon">
                            <i class="fa-solid fa-bell font-size-16 color-667085 mr-y5"></i>
                        </template>
                    </Title>
                    <p class="font-size-14 text-bold">
                        Uygulama
                    </p>
                    <div class="dp-flex-column mt-y3 justify-content-start profile-notif-settings-content">
                        <GreenCheckbox v-model="notificationSettings.isAppNotification" class="width-100 profile-notif-settings-item justify-content-between">
                            <template slot="label">
                                <label for="checkbox_green" class="font-size-14 mr-y1">
                                    Uygulama Bildirimleri
                                </label>
                            </template>
                        </GreenCheckbox>

                        <GreenCheckbox v-model="notificationSettings.isVoyageNotification" class="width-100 profile-notif-settings-item justify-content-between">
                            <template slot="label">
                                <label for="checkbox_green" class="font-size-14 mr-y1">
                                    Sefer Bildirimleri
                                </label>
                            </template>
                        </GreenCheckbox>

                        <GreenCheckbox v-model="notificationSettings.isOfferNotification" class="width-100 profile-notif-settings-item justify-content-between">
                            <template slot="label">
                                <label for="checkbox_green" class="font-size-14 mr-y1">
                                    Teklif Bildirimleri
                                </label>
                            </template>
                        </GreenCheckbox>

                        <GreenCheckbox v-model="notificationSettings.isMessageNotification" class="width-100 profile-notif-settings-item justify-content-between">
                            <template slot="label">
                                <label for="checkbox_green" class="font-size-14 mr-y1">
                                    Mesaj Bildirimleri
                                </label>
                            </template>
                        </GreenCheckbox>
                    </div>
                    <div class="dp-flex-row justify-content-center mt-y5 items-center">
                        <button @click="saveNotificationSettings()" class="btn-none width-60 p-y5 continue-btn font-size-14 items-center">
                            {{ $t('profile.SaveToChanges') }}
                        </button>
                    </div>
                </div>

                <div class="dp-flex-column justify-content-start profile-content width-25">
                    <Title class="profile-title " :title="$t('profile.App_Language')">
                        <template slot="title-icon">
                            <i class="fa-solid fa-language font-size-16 color-667085 mr-y5"></i>
                        </template>
                    </Title>

                    <div class="dp-flex-column justify-content-start items-left">
                        <GreenCheckbox v-for="(item, index) in languages" :key="index" @input="changeLanguage(item.code)" v-model="languageSelections[index].value" class="width-100 profile-notif-settings-item justify-content-between">
                            <template slot="label">
                                <label for="checkbox_green" class="font-size-14 mr-y1">
                                    {{ item.name + ' (' + item.code + ')' }}
                                </label>
                            </template>
                        </GreenCheckbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import GreenCheckbox from "@/components/Widgets/CustomerPanel/GreenCheckbox.vue";
import AddressLabelWithInput from "@/components/CustomerArea/Addresses/LoadingPoint/AddressLabelWithInput.vue";
import ProfileService from "@/services/ProfileService";
import { AssetUrls } from "@/Helpers/AssetUrls";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import LanguageService from "@/services/LanguageService";

export default {
    name: "TransporterProfile",

    components: {
        Title,
        AddressLabelWithInput,
        GreenCheckbox,
        Loading,
    },

    data() {
        return {
            user: {},
            oldPassword: "",
            newPassword: "",
            newPasswordAgain: "",
            notificationSettings: {},
            profileService: new ProfileService(),
            newProfilePhoto: undefined,
            newProfilePhotoUrl: "",
            AssetUrls: AssetUrls,
            isLoading: false,
            languages: [],
            languageSelections: [],
            languageService: new LanguageService(),
        };
    },

    async mounted() {
        this.isLoading = true;

        this.user = await this.profileService.getTransporterUserByGuid();

        this.notificationSettings = await this.profileService.getNotificationSettingsByUserId();

        this.getLanguages();

        this.isLoading = false;
    },

    methods: {
        async getLanguages() {
            await this.languageService.getLanguages();

            this.languages = this.languageService.resultModel.data;

            let selectedLanguage = localStorage.getItem("lng");

            this.languages.forEach(element => {
                let languageSelection = {
                    id: element.code,
                    value: selectedLanguage == element.code ? true : false,
                };

                this.languageSelections.push(languageSelection);
            });
        },

        changeLanguage(language) {
            this.languageSelections.forEach(element => {
                if (element.id != language) {
                    element.value = false;
                }
            });

            this.activeLanguage = language;
            
            localStorage.setItem("lng", language);

            window.location.reload();                    
        },

        saveNotificationSettings() {
            this.profileService.createOrUpdateNotificationSetting(this.notificationSettings);
        },

        updateTransporterUserProfile() {
            this.profileService.updateTransporterUserProfile(this.user);
        },

        changePassword() {
            if (this.oldPassword != "" && this.newPassword != "" && this.newPasswordAgain != "") {
                if (this.newPassword != this.newPasswordAgain) {
                    this.$toast.warning(this.$t('profile.Password_Password_Again_Not_Same'));
                } else if (this.newPassword.length < 6) {
                    this.$toast.warning(this.$t('profile.Password_Must_Be_Longer_Then_Six_Character'));
                } else {
                    this.profileService.changePassword(this.oldPassword, this.newPassword);
                }
            } else {
                this.$toast.warning(this.$t('resultModel.Model_Invalid'));
            }
        },

        previewFiles(whichFile, event) {
            if (event.target.files.length > 0) {
                this.newProfilePhoto = event.target.files[0];
                this.newProfilePhotoUrl = URL.createObjectURL(event.target.files[0]);
                this.user.profilePhoto = event.target.files[0];
            }
        },
    },
};
</script>

<style>
@import url("@/assets/css/customer-area/settings-components/profile.css");
</style>