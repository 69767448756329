<template>
    <div class="dp-flex-column">
        <label class="font-size-14 mt-mb-5 text-bold">{{ label }}</label>

        <ValidationProvider class="vee-validate-container" :rules="rules" v-slot="{ errors }">
            <select v-if="type == 'select'" class="new-addres-input" @change="$emit('change', $event.target.value)">
                <option v-if="placeHolder" class="color-667085" value="0" disabled selected>
                    {{ placeHolder }}
                </option>
                <option :key="item.value" v-for="item in selectItems" :value="item.value">
                    {{ item.text }}
                </option>
            </select>
            <textarea v-else-if="type == 'textarea'" :placeholder="placeHolder" :value="value" @input="$emit('input', $event.target.value)"></textarea>
            <input v-else-if="type != 'datetime-local'" class="new-addres-input" :readonly="readonly" :name="name" :type="type" :placeholder="placeHolder" :value="value" @input="$emit('input', $event.target.value)" />
            <span class="vee-validate-error-message">{{ errors[0] }}</span>
        </ValidationProvider>

        <!-- <input v-if="type == 'password'" class="new-addres-input" type="password" :placeholder='placeHolder' :value="value" @input="$emit('input', $event.target.value)"/>
        <input v-if="type == 'number'" class="new-addres-input" type="number" min="0" :placeholder='placeHolder':value="value" @input="$emit('input', $event.target.value)"/> -->
        <input v-if="type == 'datetime-local'" class="new-addres-input red-border" type="datetime-local" :placeholder="placeHolder" :value="value" @input="$emit('input', $event.target.value)" />
    </div>
</template>

<script>
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages as validationMessages } from "vee-validate/dist/locale/en.json";

Object.keys(rules).forEach((rule) => {
    extend(rule, {
        ...rules[rule],
        message: validationMessages[rule],
    });
});

export default {
    props: ["rules", "label", "placeHolder", "type", "name", "selectItems", "value", "readonly"],

    mounted() {
        // console.log(this.selectItems);
    },
};
</script>

<style>
.red-border {
    border: 1px solid red !important;
}
</style>