<template>
    <div class="dp-flex-row dp-flex-wrap">
        <Loading v-if="isLoading">

        </Loading>

        <div v-if="loads.length == 0 && !isLoading" class="dp-flex-row width-100 justify-content-center items-center">
            <EmptyAds :title="$t('emptyAds.Empty_Loads_For_Transporter')">

            </EmptyAds>
        </div>

        <LoadItem v-for="load in loads" :key="load.id" @click.native="goToDetailPage(load.id)" :load="load"></LoadItem>
    </div>

</template>

<script>
import LoadItem from "./LoadItem.vue";
import EmptyAds from "@/components/CustomerArea/MyAds/EmptyAds.vue";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import LoadService from "@/services/LoadService";

export default {
    name: "Loads",

    components: {
        LoadItem,
        EmptyAds,
        Loading,
    },

    data() {
        return {
            isLoad: true,
            isActive: true,
            isLoading: false,
            loads: [],
            loadService: new LoadService(),
        };
    },

    async mounted() {
        await this.getLoadsForTransport();
    },

    methods: {
        goToDetailPage(id) {
            if (id > 0) {
                this.$router.push({
                    name: "load-detail",
                    params: {
                        id,
                    },
                });
            } else {
                this.$toast.warning(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getLoadsForTransport() {
            this.isLoading = true;

            await this.loadService.getLoadsForTransporter();

            if (this.loadService.resultModel.code == 14) {
                this.loads = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },
    },
};
</script>

<style>
.loads-item-location {
    border-top: solid 1px;
    border-bottom: solid 1px;
    border-color: #c9ced3;
    margin: 5% 0;
    padding: 5% 0;
}
</style>