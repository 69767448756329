<template>
    <div class="dp-flex-row mt-mb-5 items-center justify-content-between">
        <label class="font-size-14 text-semi-bold text-color-green2">{{title}}</label>
        <button v-if="isButtonActive" class="btn-none dp-flex-row items-center font-size-13 text-semi-bold color-1A5EF7 justify-content-center">
            <slot name="action-icon"></slot>
            {{actionName}}
        </button>
    </div>
</template>

<script>
export default {
    props: ["title", "actionName", "isButtonActive"],
};
</script>

<style>
</style>