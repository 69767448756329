<template>
    <div class="dp-flex-column justify-content-between panel-content">
        <div class="dp-flex-row justify-content-between items-center width-100">
            <LabelWithInput v-model="vehicleCreateOrUpdateModel.licencePlate" class="flex-wrap-20 mr-y1" :isLabelActive="true" :label="$t('transporterVehicle.Vehicle_Plate')" :type="'text'"></LabelWithInput>

            <LabelWithInput v-model="vehicleCreateOrUpdateModel.vehicleBrandId" class="flex-wrap-20 mr-y1" :isLabelActive="true" :label="$t('transporterVehicle.Vehicle_Brand')" :type="'select'" :selectItems="vehicleBrands"></LabelWithInput>

            <LabelWithInput v-model="vehicleCreateOrUpdateModel.modelYear" class="flex-wrap-20 mr-y1" :isLabelActive="true" :label="$t('transporterVehicle.Model_Year')" :type="'text'" :placeHolder="'Model Yılınızı Yazınız'"></LabelWithInput>

            <LabelWithInput v-model="vehicleCreateOrUpdateModel.netWeight" class="flex-wrap-20 mr-y1" :isLabelActive="true" :label="$t('transporterVehicle.Max_Tonnage_Range')" :type="'text'" :placeHolder="'Azami Yük Yazınız'"></LabelWithInput>

            <GreenCheckbox v-model="vehicleCreateOrUpdateModel.isAdr" class="width-100 justify-content-between">
                <template slot="label">
                    <label for="checkbox_green" class="font-size-14 text-semi-bold mr-y1">
                        {{ $t('transporterVehicle.Is_Adr_Document') }}
                    </label>
                </template>
            </GreenCheckbox>
        </div>

        <div class="dp-flex-row justify-content-start mt-mb-y25 dp-flex-wrap items-center width-100">
            <LabelWithInput v-model="vehicleCreateOrUpdateModel.vehicleTypeId" class="flex-wrap-20" :isLabelActive="true" :label="$t('transporterVehicle.Vehicle_Type')" :type="'select'" :selectItems="vehicleTypes"></LabelWithInput>

            <LabelWithInput v-model="vehicleCreateOrUpdateModel.vehicleBodyTypeId" class="flex-wrap-20 ml-10" :isLabelActive="true" :label="$t('transporterVehicle.Vehicle_Body_Type')" :type="'select'" :selectItems="caseTypes"></LabelWithInput>

            <LabelWithInput v-model="vehicleCreateOrUpdateModel.caseFeatures" class="flex-wrap-20 ml-10" :isLabelActive="true" :label="$t('customer-vehicle-info-page.CaseFeatures')" :type="'text'" :placeHolder="$t('transporterVehicle.Case_Features_Place_Holder')"></LabelWithInput>

            <LabelWithInput v-model="vehicleCreateOrUpdateModel.bodyFloorTypeId" class="flex-wrap-20 ml-10" :isLabelActive="true" :label="$t('transporterVehicle.Body_Floor_Type')" :type="'select'" :selectItems="bodyFloorTypes"></LabelWithInput>

            <LabelWithInput v-model="vehicleCreateOrUpdateModel.insuranceExpiryDate" class="flex-wrap-20 ml-10" :isLabelActive="true" :label="$t('transporterVehicle.Insurance_Policy_Date')" :type="'date'"></LabelWithInput>
        </div>

        <div class="dp-flex-row justify-content-between items-center width-100">
            <div class="dp-flex-column justify-content-center items-center mr-y1 panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterVehicle.Licence_Front_Page') }}
                </h2>

                <button @click="$refs.licensePageFronFileInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="licensePageFronFileInput" @change="previewFiles(1, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="licensePageFrontUrl != ''" :src="licensePageFrontUrl" alt="licensePageFrontUrl" @error="handleImageError">
            </div>

            <div class="dp-flex-column justify-content-center items-center mr-y1 panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterVehicle.Licence_Back_Page') }}
                </h2>

                <button @click="$refs.licensePageBackFileInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="licensePageBackFileInput" @change="previewFiles(2, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="licensePageBackUrl != ''" :src="licensePageBackUrl" alt="licensePageBackUrl" @error="handleImageError">
            </div>

            <div class="dp-flex-column justify-content-center items-center mr-y1 panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterVehicle.Insurance_Policy') }}
                </h2>

                <button @click="$refs.insurancePolicyFileInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="insurancePolicyFileInput" @change="previewFiles(3, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="insurancePolicyUrl != ''" :src="insurancePolicyUrl" alt="insurancePolicyUrl" @error="handleImageError">
            </div>

            <div class="dp-flex-column justify-content-center items-center panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterVehicle.K_Document') }}
                </h2>

                <button @click="$refs.kDocumentFileInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="kDocumentFileInput" @change="previewFiles(4, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="kDocumentUrl != ''" :src="kDocumentUrl" alt="kDocumentUrl" @error="handleImageError">
            </div>
        </div>

        <div class="dp-flex-row justify-content-end mt-y1">
            <router-link to="/transporter/my-vehicles" class="btn-none continue-btn pl-pr-y1-4 previous-btn font-size-14 items-center">
                {{ $t('button.Back') }}
            </router-link>

            <button @click="createOrUpdateVehicle($event)" class="btn-none continue-btn pl-pr-y1-4 ml-y1 items-center button-loading">
                <span class="button-text font-size-14">
                    {{ getCreateOrUpdateVehicle.id == 0 ? $t('button.Add_Vehicle') : $t('button.Update_Vehicle') }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
// TODO: inputlara mask eklenecek
// TODO: inputlara validation eklenecek

import LabelWithInput from "@/components/Widgets/Inputs/LabelWithInput.vue";
import GreenCheckbox from "@/components/Widgets/CustomerPanel/GreenCheckbox.vue";
import { APIUrls } from "@/Helpers/APIUrls";
import VehiclesService, { VehicleCreateOrUpdateModel } from "@/services/VehiclesService";
import { mapGetters, mapActions } from "vuex";
import { CreateOrUpdateVehicleModel } from "@/Helpers/Models";
import { AssetUrls } from "@/Helpers/AssetUrls";

export default {
    name: "CreateVehicle",

    components: {
        LabelWithInput,
        GreenCheckbox,
    },

    data() {
        return {
            vehicleBrands: [{ text: "Seçiniz", value: 0 }],
            vehicleTypes: [{ text: "Seçiniz", value: 0 }],
            caseTypes: [{ text: "Seçiniz", value: 0 }],
            bodyFloorTypes: [{ text: "Seçiniz", value: 0 }],
            vehicleCreateOrUpdateModel: new VehicleCreateOrUpdateModel(),
            vehiclesService: new VehiclesService(),
            licensePageFrontUrl: "",
            licensePageBackUrl: "",
            insurancePolicyUrl: "",
            kDocumentUrl: "",
            AssetUrls: AssetUrls,
        };
    },

    computed: {
        ...mapGetters("createOrUpdateVehicle", ["getCreateOrUpdateVehicle"]),
    },

    beforeMount() {
        if (this.getCreateOrUpdateVehicle.id != 0) {
            let createOrUpdateVehicle = new CreateOrUpdateVehicleModel();
            createOrUpdateVehicle = this.getCreateOrUpdateVehicle;

            this.vehicleCreateOrUpdateModel = createOrUpdateVehicle;
            this.vehicleCreateOrUpdateModel.insuranceExpiryDate = createOrUpdateVehicle.insuranceExpiryDate.split("T")[0];
            this.vehicleCreateOrUpdateModel.netWeight = createOrUpdateVehicle.netWight;

            this.licensePageFrontUrl = createOrUpdateVehicle.licensePageFront != null ? AssetUrls.VEHICLE_DOCUMENTS_URL + createOrUpdateVehicle.id + "/" + createOrUpdateVehicle.licensePageFront : "";
            this.licensePageBackUrl = createOrUpdateVehicle.licensePageBack != null ? AssetUrls.VEHICLE_DOCUMENTS_URL + createOrUpdateVehicle.id + "/" + createOrUpdateVehicle.licensePageBack : "";
            this.insurancePolicyUrl = createOrUpdateVehicle.insurancePolicy != null ? AssetUrls.VEHICLE_DOCUMENTS_URL + createOrUpdateVehicle.id + "/" + createOrUpdateVehicle.insurancePolicy : "";
            this.kDocumentUrl = createOrUpdateVehicle.kDocument != null ? AssetUrls.VEHICLE_DOCUMENTS_URL + createOrUpdateVehicle.id + "/" + createOrUpdateVehicle.kDocument : "";
        }
    },

    mounted() {
        this.axios
            .get(APIUrls.GET_VEHICLE_BRANDS)
            .then((response) => {
                response.data.data.forEach((element) => {
                    this.vehicleBrands.push({ text: element.name, value: element.id });
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.axios
                    .get(APIUrls.GET_VEHICLE_TYPES)
                    .then((response) => {
                        response.data.data.forEach((element) => {
                            this.vehicleTypes.push({ text: element.name, value: element.id });
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        this.axios
                            .get(APIUrls.GET_VEHICLE_BODY_TYPES)
                            .then((response) => {
                                response.data.data.forEach((element) => {
                                    this.caseTypes.push({ text: element.name, value: element.id });
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                            .finally(() => {
                                this.axios
                                    .get(APIUrls.GET_BODY_FLOOR_TYPES)
                                    .then((response) => {
                                        response.data.data.forEach((element) => {
                                            this.bodyFloorTypes.push({ text: element.name, value: element.id });
                                        });
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            });
                    });
            });
    },

    methods: {
        ...mapActions("createOrUpdateVehicle", ["updateCreateOrUpdateVehicle"]),

        handleImageError(event) {
            event.target.src = require(`@/assets/images/img-not-found.avif`);
        },

        previewFiles(whichFile, event) {
            if (event.target.files.length > 0) {
                switch (whichFile) {
                    case 1:
                        this.vehicleCreateOrUpdateModel.licensePageFront = event.target.files[0];
                        this.licensePageFrontUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    case 2:
                        this.vehicleCreateOrUpdateModel.licensePageBack = event.target.files[0];
                        this.licensePageBackUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    case 3:
                        this.vehicleCreateOrUpdateModel.insurancePolicy = event.target.files[0];
                        this.insurancePolicyUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    case 4:
                        this.vehicleCreateOrUpdateModel.kDocument = event.target.files[0];
                        this.kDocumentUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    default:
                        break;
                }
            }
        },

        async createOrUpdateVehicle(e) {
            e.target.classList.add("button--loading");

            if (this.vehicleCreateOrUpdateModel.licencePlate != "") {
                this.updateCreateOrUpdateVehicle([new CreateOrUpdateVehicleModel()]);

                await this.vehiclesService.createOrUpdateVehicle(this.vehicleCreateOrUpdateModel);

                e.target.classList.remove("button--loading");

                if (this.vehiclesService.resultModel.code == 72 || this.vehiclesService.resultModel.code == 73) {
                    this.$toast.success(this.$t("resultModel." + this.vehiclesService.resultModel.message));
                    this.$router.push("/transporter/my-vehicles");
                } else {
                    this.$toast.error(this.$t("resultModel." + this.vehiclesService.resultModel.message));
                }

                if (this.vehiclesService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            } else {
                e.target.classList.remove("button--loading");
                this.$toast.error(this.$t("resultModel.Model_Invalid"));
            }
        },
    },
};
</script>

<style>
.btn-file-upload {
    margin: 10% 0;
    border: 1px solid #1a5ef7;
    border-radius: 5px;
    width: 50%;
    padding: 3%;
}
</style>
