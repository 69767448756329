<template>
    <div class="dp-flex-column justify-content-between">
        <Loading v-if="isLoading"></Loading>

        <div v-if="!isLoading" class="dp-flex-column">
            <Title :title="'Firma Profilim'"></Title>

            <BlueHeaderWhiteBodyContent :title="'FİRMA BİLGİLERİ'">
                <template slot="white-body">
                    <div class="dp-flex-row justify-content-between">
                        <AddressLabelWithInput class="width-50" :label="'Ticari Ünvan *'" :type="'text'" :value="company.title" :readonly="true"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-50 ml-y1" :label="'Vergi No *'" :type="'text'" :value="company.taxNumber" :readonly="true" :name="'commercial-title'"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-50 ml-y1" :label="'Vergi Dairesi *'" :type="'text'" :value="company.taxOfficeName" :readonly="true" :name="'commercial-title'"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-50 ml-y1" :label="'Email  *'" :type="'text'" :value="company.email" :readonly="true" :name="'commercial-title'"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-50 ml-y1" :label="'Phone  *'" :type="'text'" :value="company.phone" :readonly="true" :name="'commercial-title'"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-50 ml-y1" :label="'Lokasyon  *'" :type="'text'" :value="location" :readonly="true" :name="'commercial-title'"></AddressLabelWithInput>

                        <!-- <AddressLabelWithInput class="width-50 ml-y1" :label="'Müşteri No *'" :type="'text'" :placeHolder="'Müşteri No *'" :name="'commercial-title'"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-40 ml-y1" :label="'Vergi Dairesi İl*'" :type="'select'" :selectItems="options" :name="'commercial-title'"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-40 ml-y1" :label="'Vergi Dairesi Seçiniz*'" :type="'select'" :selectItems="options2" :name="'commercial-title'"></AddressLabelWithInput> -->

                    </div>
                </template>
            </BlueHeaderWhiteBodyContent>

            <BlueHeaderWhiteBodyContent class="mt-y1" :title="'FATURA ADRESİ'">
                <template slot="white-body">
                    <div class="dp-flex-column">
                        <div class="dp-flex-row justify-content-between">
                            <AddressLabelWithInput class="width-50" :label="'Fatura Adresi'" :type="'text'" :placeHolder="'Adresi Giriniz'"></AddressLabelWithInput>
                            <AddressLabelWithInput class="width-25 ml-y1" :label="'İl*'" :type="'select'" :selectItems="options" :name="'commercial-title'"></AddressLabelWithInput>
                            <AddressLabelWithInput class="width-25 ml-y1" :label="'İlçe*'" :type="'select'" :selectItems="options2" :name="'commercial-title'"></AddressLabelWithInput>
                        </div>
                        <div class="dp-flex-row justify-content-start">
                            <YellowCheckbox class="width-30 ad-summary-required-checkbox">
                                <template v-slot:chekbox-label-text>
                                    <p class="font-size-12">
                                        Sevk Adresi, Fatura Adresi ile aynı olsun
                                    </p>
                                </template>
                            </YellowCheckbox>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <AddressLabelWithInput class="width-50" :label="'Sevk Adresi'" :type="'text'" :placeHolder="'Adresi Giriniz'"></AddressLabelWithInput>
                            <AddressLabelWithInput class="width-15 ml-y1" :label="'İl*'" :type="'select'" :selectItems="options" :name="'commercial-title'"></AddressLabelWithInput>
                            <AddressLabelWithInput class="width-15 ml-y1" :label="'İlçe*'" :type="'select'" :selectItems="options2" :name="'commercial-title'"></AddressLabelWithInput>
                            <AddressLabelWithInput class="width-20 ml-y1" :label="'Telefon*'" :placeHolder="'+90'" :type="'text'" :name="'commercial-title'"></AddressLabelWithInput>
                        </div>
                    </div>
                </template>
            </BlueHeaderWhiteBodyContent>

            <BlueHeaderWhiteBodyContent class="mt-y1" :title="'FİNANSAL YETKİLİ BİLGİLERİ'">
                <template slot="white-body">
                    <div class="dp-flex-row justify-content-between">
                        <AddressLabelWithInput class="width-50" :label="'Fatura Adresi'" :type="'text'" :placeHolder="'Adresi Giriniz'"></AddressLabelWithInput>
                        <AddressLabelWithInput class="width-50 ml-y1" :label="'İl*'" :type="'select'" :selectItems="options" :name="'commercial-title'"></AddressLabelWithInput>
                    </div>
                </template>
            </BlueHeaderWhiteBodyContent>

            <div class="dp-flex-row mt-y1 justify-content-end items-center">
                <button class="btn-none continue-btn pl-pr-y1-4 ml-y1 font-size-14 items-center">
                    KAYDET
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import AddressLabelWithInput from "@/components/CustomerArea/Addresses/LoadingPoint/AddressLabelWithInput.vue";
import YellowCheckbox from "@/components/Widgets/CustomerPanel/YellowCheckbox.vue";
import BlueHeaderWhiteBodyContent from "@/components/Widgets/CustomerPanel/BlueHeaderWhiteBodyContent.vue";
import CompanyService from "@/services/CompanyService.js";
import Loading from "@/components/Widgets/Loadings/Loading.vue";

export default {
    components: {
        Title,
        AddressLabelWithInput,
        YellowCheckbox,
        BlueHeaderWhiteBodyContent,
        Loading,
    },

    data() {
        return {
            options: [
                { text: "Name", value: "1" },
                { text: "Name", value: "2" },
            ],

            options2: [
                { text: "Simav Vergi Dairesi", value: "1" },
                { text: "Demirci Vergi Dairesi", value: "2" },
                { text: "Meram Vergi Dairesi", value: "3" },
            ],

            isLoading: false,
            companyService: new CompanyService(),
            location: "",
            company: {},
        };
    },

    async mounted() {
        await this.getCompanyProfile();
    },

    methods: {
        async getCompanyProfile() {
            this.isLoading = true;
            await this.companyService.getCompanyProfile();

            if (this.companyService.resultModel.code == 14) {
                this.company = this.companyService.resultModel.data;

                console.log(this.company);

                this.location = (this.company.countryName ?? "-") + " / " + (this.company.cityName ?? "-") + " / " + (this.company.districtName ?? "-");

                console.log(this.location);

                this.isLoading = false;
            } else {
                this.$toast.error(this.$t("resultModel." + this.companyService.resultModel.message));
            }

            if (this.companyService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },
    },
};
</script>

<style>
.blue-header {
    background-color: #ddecfe;
    padding: 1%;
    border-radius: 5px 5px 0px 0px;
}

.white-body {
    background-color: white;
    padding: 1%;
    border-radius: 0 0 5px 5px;
}
</style>