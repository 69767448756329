<template>
    <div class='identity-information-form'>
        <form @submit="checkCompanyInformation" method="post">
            <div class='input-icons identity-information-input-icons'>
                <select class="identity-information-input" @change="changeCities($event)">
                    <option class="identity-information-input" :key="0" :value="0">
                        Ülke Seçiniz
                    </option>
                    <option class="identity-information-input" :key="item.countryId" v-for="item in countries" :value="item.countryId">
                        {{item.countryName}}
                    </option>
                </select>
                <p class='font-size-13 text-color-red'>{{errors.country}}</p>
            </div>

            <div class='input-icons identity-information-input-icons'>
                <select class="identity-information-input" @change="changeTaxOffices($event)">
                    <option class="identity-information-input" :key="0" :value="0">
                        Şehir Seçiniz
                    </option>
                    <option class="identity-information-input" v-bind:key="item.cityCode" v-for="item in cities" v-bind:value="item.cityId">
                        {{item.cityName}}
                    </option>
                </select>
                <p class='font-size-13 text-color-red'>{{errors.city}}</p>
            </div>

            <div v-if="isShowTaxOffice" class='input-icons identity-information-input-icons'>
                <select class="identity-information-input" @change="selectTaxOffice($event)">
                    <option class="identity-information-input" :key="0" :value="0">
                        Vergi Dairesi Seçiniz
                    </option>
                    <option class="identity-information-input" v-bind:key="item.taxOfficeName" v-for="item in taxOffices" v-bind:value="item.taxOfficeId">
                        {{item.taxOfficeName}} VERGİ DAİRESİ
                    </option>
                </select>
                <p class='font-size-13 text-color-red'>{{errors.taxOffice}}</p>
            </div>

            <div class='input-icons identity-information-input-icons'>
                <i class="identity-information-icon icon"></i>
                <input class='btn-none identity-information-input' name='taxNumber' type="text" :placeholder="$t('complete-registration-page.CompanyInformationTaxAdministrationNumberInput')" v-model="taxNumber" />
                <p class='font-size-13 text-color-red'>{{errors.taxNumber}}</p>
            </div>

            <div class='input-icons identity-information-input-icons'>
                <i class="identity-information-icon icon"></i>
                <input class='btn-none identity-information-input' name='companyName' type="text" :placeholder="$t('complete-registration-page.CompanyInformationCompanyTitle')" v-model="companyName" />
                <p class='font-size-13 text-color-red'>{{errors.companyName}}</p>
            </div>

            <div class="why-we-want-this-info dp-flex-row justify-content-around">
                <i class="fa-solid fa-circle-info identity-information-input-icons-red"></i>
                <div class="font-size-13 why-we-want-this-info-text">{{$t("complete-registration-page.WhyWeWantThisInfo")}}</div>
            </div>

            <div class='dp-flex-row mt-mb-25'>
                <input id='chckUyelikSoz' type='checkbox' defaultChecked />
                <label class='font-size-13 ml-10' for='chckUyelikSoz'>
                    <router-link class='font-size-13 text-bold text-color-black' to='/login'>{{$t("complete-registration-page.CompanyInformationKvkk")}}</router-link>
                    &
                    <router-link class='font-size-13 text-bold text-color-black' to='/login'>{{$t("complete-registration-page.CompanyInformationGdpr")}}</router-link>
                    {{$t("complete-registration-page.CompanyInformationKvkkDesc")}}
                </label>
            </div>

            <button class='btn-none mt-mb-35 identity-information-input-submit identity-information-input-submit login-input-submit' type='submit'>{{$t("complete-registration-page.ComplateRegistrationButton")}}</button>
        </form>
    </div>
</template>

<script>
// TODO: api'den çekilen kısımlara loading eklenecek.
// TODO: tc kimlik kısmına mask eklenecek.
// TODO: kaydınız alındı kısmından sonra logine yönlendir.
// TODO: complete apisi tek aynı apiye atılıyor burası düzeltilecek.

import { APIUrls } from "@/Helpers/APIUrls";
import { validateRequired, isDigits, isAlphabets } from "@/Helpers/FormValidateFunctions";

export default {
    name: "CompanyInformation",
    components: {},
    data() {
        return {
            errors: {},
            resultModel: {
                code: 0,
                message: "",
                data: {},
            },
            countries: [],
            cities: [],
            taxOffices: [],
            companyCountryId: 0,
            cityId: 0,
            taxOfficeId: 0,
            taxNumber: "",
            companyName: "",
            isShowTaxOffice: false,
        };
    },
    methods: {
        validateRequired,
        isDigits,
        isAlphabets,

        checkCompanyInformation(e) {
            e.preventDefault();

            let error = 0;

            let validateCountry = validateRequired("Country", this.companyCountryId);

            if (!validateCountry.isValid) {
                this.$set(this.errors, "country", validateCountry.message);
                error++;
            }

            let validateCity = validateRequired("City", this.cityId);

            if (!validateCity.isValid) {
                this.$set(this.errors, "city", validateCity.message);
                error++;
            }

            // let validateTaxOffice = validateRequired(
            //     "Tax Office",
            //     this.taxOfficeId
            // );

            // if (!validateTaxOffice.isValid) {
            //     this.$set(this.errors, "taxOffice", validateTaxOffice.message);
            //     error++;
            // }

            let validateResultTaxNumber = isDigits(this.taxNumber, "Tax Number");

            if (!validateResultTaxNumber.isValid && this.companyCountryId == 216) {
                this.$set(this.errors, "taxNumber", validateResultTaxNumber.message);
                error++;
            }

            // let validaResultCompanyName = isAlphabets(
            //     this.companyName,
            //     "Company Name"
            // );

            // if (!validaResultCompanyName.isValid) {
            //     this.$set(
            //         this.errors,
            //         "companyName",
            //         validaResultCompanyName.message
            //     );
            //     error++;
            // }

            if (error == 0) {
                // console.log(localStorage.getItem("guid"));
                // console.log(localStorage.getItem("identityNumber"));
                // console.log(localStorage.getItem("dateOfBirth"));
                // console.log(localStorage.getItem("countryId"));
                // console.log(this.companyCountryId);
                // console.log(this.taxNumber);
                // console.log(this.cityId);
                // console.log(this.taxOfficeId);
                // console.log(this.companyName);

                this.axios({
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    url: APIUrls.COMPLETE_CUSTOMER_REGISTRATION,
                    data: {
                        customerGuid: localStorage.getItem("guid"),
                        identityNumber: localStorage.getItem("identityNumber"),
                        birthDate: localStorage.getItem("dateOfBirth"),
                        countryId: localStorage.getItem("countryId"),
                        companyCountryId: this.companyCountryId,
                        taxNumber: this.taxNumber,
                        cityId: this.cityId,
                        taxOfficeId: this.taxOfficeId != 0 ? this.taxOfficeId : null,
                        companyTitle: this.companyName,
                    },
                }).then((response) => {
                    this.$set(this.resultModel, "code", response.data.code);

                    this.$set(this.resultModel, "message", response.data.message);

                    this.$set(this.resultModel, "data", response.data.data);

                    if (this.resultModel.code == 5) {
                        localStorage.removeItem("dateOfBirth");
                        localStorage.removeItem("identityNumber");
                        localStorage.removeItem("name");
                        localStorage.removeItem("countryId");
                        localStorage.removeItem("surname");

                        this.$emit("changeTab");
                    } else if (this.resultModel.code == 6) {
                        alert(this.resultModel.message);
                    }
                });
            }
        },

        changeCities(selectedCountry) {
            this.companyCountryId = selectedCountry.target.value;

            this.isShowTaxOffice = this.companyCountryId == 216 ? true : false;

            this.countries.forEach((element) => {
                if (element.countryId == selectedCountry.target.value) {
                    this.cities = element.cities;
                }
            });
        },

        changeTaxOffices(selectedCity) {
            this.cityId = selectedCity.target.value;

            this.cities.forEach((city) => {
                if (city.cityCode == selectedCity.target.value) {
                    this.taxOffices = city.taxOffices;
                }
            });
        },

        selectTaxOffice(selectedTaxOffice) {
            this.taxOfficeId = selectedTaxOffice.target.value;
        },
    },
    mounted() {
        this.axios({
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            url: APIUrls.GET_COUNTRIES_CITIES_TAX_OFFICES,
        })
            .then((response) => {
                this.$set(this.resultModel, "code", response.data.code);
                this.$set(this.resultModel, "message", response.data.message);
                this.$set(this.resultModel, "data", response.data.data);

                this.resultModel.data.forEach((element) => {
                    let c = {
                        countryCode: "",
                        countryName: "",
                        countryId: "",
                        cities: [],
                    };

                    c.countryCode = 0;
                    c.countryName = element.name;
                    c.countryId = element.id;
                    c.cities = element.cities;

                    this.countries.push(c);
                });
            })
            .catch(function (error) {
                console.log(error.data);
            });
    },
};
</script>

<style>
</style>