<template>
    <div class="dp-flex-column justify-content-between panel-content">
        <div class="dp-flex-row justify-content-between items-center width-100">
            <div class="dp-flex-column justify-content-between flex-wrap-31 mr-y1">
                <LabelWithInput v-model="createOrUpdateDriverModel.name" class="" :isLabelActive="true" :label="$t('transporterDriver.Driver_Name')" :type="'text'"></LabelWithInput>
                <p v-if="createOrUpdateDriverModel.name == ''" class="font-size-12 color-D01438">
                    {{ $t('inputValidation.Please_Fill') }}
                </p>
            </div>

            <div class="dp-flex-column justify-content-between flex-wrap-31 mr-y1">
                <LabelWithInput v-model="createOrUpdateDriverModel.surname" class="flex-wrap-31 mr-y1" :isLabelActive="true" :label="$t('transporterDriver.Driver_Surname')" :type="'text'"></LabelWithInput>
                <p v-if="createOrUpdateDriverModel.surname == ''" class="font-size-12 color-D01438">
                    {{ $t('inputValidation.Please_Fill') }}
                </p>
            </div>

            <div class="dp-flex-column justify-content-between flex-wrap-31 mr-y1">
                <LabelWithInput v-model="createOrUpdateDriverModel.identificationNumber" class="flex-wrap-31 mr-y1" :isLabelActive="true" :label="$t('transporterDriver.Drive_Identification_Number')" :type="'text'"></LabelWithInput>
                <p v-if="createOrUpdateDriverModel.identificationNumber == ''" class="font-size-12 color-D01438">
                    {{ $t('inputValidation.Please_Fill') }}
                </p>
            </div>
        </div>

        <div class="dp-flex-row justify-content-between mt-mb-y25 dp-flex-wrap items-center width-100">
            <div class="dp-flex-column justify-content-between flex-wrap-31 mr-y1">
                <LabelWithInput v-model="createOrUpdateDriverModel.email" class="flex-wrap-31 mr-y1" :isLabelActive="true" :label="$t('transporterDriver.Driver_Email')" :type="'text'"></LabelWithInput>
                <p v-if="createOrUpdateDriverModel.email == ''" class="font-size-12 color-D01438">
                    {{ $t('inputValidation.Please_Fill') }}
                </p>
            </div>

            <div class="dp-flex-column justify-content-between flex-wrap-31 mr-y1">
                <LabelWithInput v-model="createOrUpdateDriverModel.phone" class="flex-wrap-31 mr-y1" :isLabelActive="true" :label="$t('transporterDriver.Driver_Phone')" :type="'text'"></LabelWithInput>
                <p v-if="createOrUpdateDriverModel.phone == ''" class="font-size-12 color-D01438">
                    {{ $t('inputValidation.Please_Fill') }}
                </p>
            </div>

            <div class="dp-flex-column justify-content-between flex-wrap-31 mr-y1">
                <LabelWithInput v-model="createOrUpdateDriverModel.birthDate" class="flex-wrap-31 mr-y1" :isLabelActive="true" :label="$t('transporterDriver.Driver_Birth_Date')" :type="'date'"></LabelWithInput>
                <p v-if="createOrUpdateDriverModel.birthDate == ''" class="font-size-12 color-D01438">
                    {{ $t('inputValidation.Please_Fill') }}
                </p>
            </div>
        </div>

        <div class="dp-flex-row justify-content-between width-100">
            <div class="dp-flex-column justify-content-center items-center mr-y1 panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterDriver.Driving_Liccence_Front') }}
                </h2>
                
                <button @click="$refs.licensePageFronFileInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="licensePageFronFileInput" @change="previewFiles(1, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="licenseFrontPhotoUrl != ''" :src="licenseFrontPhotoUrl" alt="profile-picture">
            </div>

            <div class="dp-flex-column justify-content-center items-center mr-y1 panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterDriver.Driving_Liccence_Back') }}
                </h2>

                <button @click="$refs.licensePageBackFileInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="licensePageBackFileInput" @change="previewFiles(2, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="licenseBackPhotoUrl != ''" :src="licenseBackPhotoUrl" alt="profile-picture">
            </div>

            <div class="dp-flex-column justify-content-center items-center mr-y1 panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterDriver.Driver_Profile_Photo') }}
                </h2>

                <button @click="$refs.profilePhotoInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="profilePhotoInput" @change="previewFiles(3, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="profilePhotoUrl != ''" :src="profilePhotoUrl" alt="profile-picture">
            </div>

            <div class="dp-flex-column justify-content-center items-center panel-content">
                <h2 class="font-size-14">
                    {{ $t('transporterDriver.SRC_Document') }}
                </h2>

                <button @click="$refs.srcDocumentInput.click()" class="dp-flex-row justify-content-center width-100 btn-file-upload btn-none items-center">
                    <i class="fa-solid font-size-16 color-6098FB fa-cloud-arrow-up mr-15"></i>
                    {{ $t('button.Add_Document') }}
                    <input ref="srcDocumentInput" @change="previewFiles(4, $event)" class="dp-none" type="file" value="Belge Ekle" />
                </button>

                <img class="width-50" v-if="sRCDocumentUrl != ''" :src="sRCDocumentUrl" alt="profile-picture">
            </div>
        </div>

        <div class="dp-flex-row justify-content-end mt-y1">
            <router-link to="/transporter/my-drivers" class="btn-none continue-btn pl-pr-y1-4 previous-btn font-size-14 items-center">
                {{ $t('button.Back') }}
            </router-link>

            <button @click="createOrUpdateDriver($event)" class="btn-none continue-btn pl-pr-y1-4 ml-y1 items-center button-loading">
                <span class="button-text font-size-14">
                    {{ createOrUpdateDriverModel.id == 0 ? $t('button.Add_Driver') : $t('button.Update_Driver') }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
// TODO: input'lara mask eklenecek.
// TODO: input'lara validasyon eklenecek.

import LabelWithInput from "@/components/Widgets/Inputs/LabelWithInput.vue";
import DriversService, { CreateOrUpdateDriverModel } from "@/services/DriversService";
import { mapGetters } from "vuex";
import { AssetUrls } from "@/Helpers/AssetUrls";

export default {
    name: "CreateDriver",

    components: {
        LabelWithInput,
    },

    data() {
        return {
            driversService: new DriversService(),
            createOrUpdateDriverModel: new CreateOrUpdateDriverModel(),
            licenseFrontPhotoUrl: "",
            licenseBackPhotoUrl: "",
            profilePhotoUrl: "",
            sRCDocumentUrl: "",
            AssetUrls: AssetUrls,
        };
    },

    computed: {
        ...mapGetters("moduleCreateOrUpdateDriver", ["getCreateOrUpdateDriver"]),
    },

    beforeMount() {
        this.createOrUpdateDriverModel = this.getCreateOrUpdateDriver;
        this.createOrUpdateDriverModel.sRCDocument = this.getCreateOrUpdateDriver.srcdocument;
        this.createOrUpdateDriverModel.birthDate = this.getCreateOrUpdateDriver.birthDate.split("T")[0];

        this.licenseFrontPhotoUrl = this.createOrUpdateDriverModel.licenseFrontPhoto != null ? AssetUrls.USER_ASSETS_URL + this.createOrUpdateDriverModel.licenseFrontPhoto : "";
        this.licenseBackPhotoUrl = this.createOrUpdateDriverModel.licenseBackPhoto != null ? AssetUrls.USER_ASSETS_URL + this.createOrUpdateDriverModel.licenseBackPhoto : "";
        this.profilePhotoUrl = this.createOrUpdateDriverModel.profilePhoto != null ? AssetUrls.USER_ASSETS_URL + this.createOrUpdateDriverModel.profilePhoto : "";
        this.sRCDocumentUrl = this.createOrUpdateDriverModel.sRCDocument != null ? AssetUrls.USER_ASSETS_URL + this.createOrUpdateDriverModel.sRCDocument : "";
    },

    methods: {
        previewFiles(whichFile, event) {
            if (event.target.files.length > 0) {
                switch (whichFile) {
                    case 1:
                        this.createOrUpdateDriverModel.licenseFrontPhoto = event.target.files[0];
                        this.licenseFrontPhotoUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    case 2:
                        this.createOrUpdateDriverModel.licenseBackPhoto = event.target.files[0];
                        this.licenseBackPhotoUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    case 3:
                        this.createOrUpdateDriverModel.profilePhoto = event.target.files[0];
                        this.profilePhotoUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    case 4:
                        this.createOrUpdateDriverModel.sRCDocument = event.target.files[0];
                        this.sRCDocumentUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    default:
                        break;
                }
            }
        },

        async createOrUpdateDriver(e) {
            e.target.classList.add("button--loading");

            if (this.createOrUpdateDriverModel.name != "" && this.createOrUpdateDriverModel.surname != "" && this.createOrUpdateDriverModel.idNumber != "" && this.createOrUpdateDriverModel.email != "" && this.createOrUpdateDriverModel.phone != "" && this.createOrUpdateDriverModel.birthDate != "") {
                await this.driversService.createOrUpdateDriver(this.createOrUpdateDriverModel);

                e.target.classList.remove("button--loading");

                if (this.driversService.resultModel.code == 174 || this.driversService.resultModel.code == 175) {
                    this.$toast.success(this.$t("resultModel." + this.driversService.resultModel.message));
                    this.$router.push("my-drivers");
                } else {
                    this.$toast.error(this.$t("resultModel." + this.driversService.resultModel.message));
                }

                if (this.driversService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            } else {
                e.target.classList.remove("button--loading");
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },
    },
};
</script>

<style></style>