<template>
    <div class="dp-flex-column justify-content-between statistics-info-content panel-content mr-15">
        <h4 class="panel-content-inside-title">{{title}}</h4>
        <div class="dp-flex-row mt-mb-15 justify-content-around">
            <figure>
                <img v-if="title == 'Teslimatlar'" src="@/assets/images/customer-area/dashboard/Group_1521.svg">
                <img v-if="title == 'Araç Talep'" src="@/assets/images/customer-area/dashboard/Group_1681.png">
            </figure>
            <div class="dp-flex-row statistics-info-per-month-info justify-content-around items-center ml-15">
                <figure>
                    <img v-if="title == 'Teslimatlar'" src="@/assets/images/customer-area/dashboard/complete_point.png">
                    <img v-if="title == 'Araç Talep'" src="@/assets/images/customer-area/dashboard/continue_point.png">
                    <!-- <img src="../../../assets/images/customer-panel/dashboard/complete_point.png"> -->
                </figure>
                <div class="dp-flex-column justify-content-center">
                    <p class="font-size-15 statistics-text-color">
                        {{month}} ayı için
                    </p>
                    <p class="text-medium font-size-30 statistics-text-color">
                        {{value}} adet
                    </p>
                    <p v-if="title == 'Teslimatlar'" class="font-size-15 statistics-text-color">
                        teslimat yaptınız.
                    </p>
                    <p v-if="title == 'Araç Talep'" class="font-size-15 statistics-text-color">
                        araç talep ettiniz.
                    </p>
                </div>
            </div>
        </div>
        <div class="dp-flex-row justify-content-end">
            <button class="panel-dashboard-button btn-none">
                Tüm Ayları Gör
            </button>
        </div>
    </div>
</template>

<script>
// TODO p içerisindeki metinler dinamik hale getirilecek.
// TODO: if kontrolü istatistik tipine göre yapılacak.
export default {
    name: "StatisticsInfo",
    components: {},
    props: ["title", "month", "value"],
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
</style>