<template>
    <header id="sticky-header" class="dp-flex-column">
        <div class="responsive-header-banner" id="responsive-header">
            <div class="responsive-header-btn">
                <button class="responsive-header-open-close-btn dp-block" id="btn_header_open">
                    ☰
                </button>
                <button class="responsive-header-open-close-btn dp-none" id="btn_header_close">
                    X
                </button>
            </div>
            <div class="responsive-header-content dp-flex-column">
                <div class="responsive-header-logo"></div>
                <div class="responsive-header-menus">
                    <div class="responsive-header-menu-item">
                        <a class="responsive-header-menu-title text-upper font-size-13">
                            giriş yap
                        </a>
                    </div>
                    <div class="responsive-header-menu-item">
                        <a class="responsive-header-menu-title text-upper font-size-13">
                            kayıt ol
                        </a>
                    </div>
                    <div class="responsive-header-menu-item">
                        <a class="responsive-header-menu-title text-upper font-size-13">
                            taşıt ara
                        </a>
                    </div>
                    <div class="responsive-header-menu-item">
                        <a class="responsive-header-menu-title text-upper font-size-13">
                            sefer sorgula
                        </a>
                    </div>
                    <div class="responsive-header-menu-item">
                        <a class="responsive-header-menu-title text-upper font-size-13">
                            iletişim
                        </a>
                    </div>
                    <div class="responsive-header-menu-item">
                        <a class="responsive-header-menu-title text-upper font-size-13">
                            hakkımızda
                        </a>
                    </div>
                    <div class="responsive-header-menu-item">
                        <a class="responsive-header-menu-title text-upper font-size-13">
                            yardım merkezi
                        </a>
                    </div>

                    <div class="responsive-header-menu-item pb-0 drop-down">
                        <a class="
                responsive-header-menu-title
                drop-down-btn
                text-upper
                font-size-13
              ">
                            taşıt aranıyor <span class="font-size-20 ml-10">+</span>
                        </a>
                        <div class="drop-down-content font-size-13 text-capitalize text-center">
                            <a href="#">anasayfa</a>
                            <a href="#">taşıt ara</a>
                            <a href="#">sefer sorgula</a>
                            <a href="#">iletişim</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-banner-block dp-flex-row">
            <a href="#">
                <img src="@/assets/images/introduction-area/_layout/app-store-ust.svg" alt="app store app image" />
            </a>

            <a href="#">
                <img src="@/assets/images/introduction-area/_layout/google-play-ust.svg" alt="google play app image" />
            </a>
        </div>
        <div class="header-content">
            <div class="header-top-menu dp-flex-row">
                <div class="header-top-menu-item">
                    <div class="header-menu-item">
                        <img src="@/assets/images/introduction-area/_layout/logo-turuncu-siyah.svg" />
                    </div>
                </div>
                <div class="header-top-menu-item header-menu-top-search">
                    <div class="">
                        <div class="header-search-container">
                            <input id="header-search-input" class="header-top-menu-search-focus header-search-input" type="text" />
                            <button type="submit"><img src="@/assets/images/introduction-area/_layout/icon-search.svg" /></button>
                        </div>
                    </div>
                </div>
                <div class="header-top-menu-item font-size-13 text-capitalize dp-flex-row">
                    <div v-if="userToken == null" class="header-menu-item font-weight-normal border-right-black" @click="saveWhichPageInfo('Login')">
                        <router-link class="header-menu-a font-weight-normal" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">giriş yap</router-link>
                    </div>
                    <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Register')">
                        <router-link class="header-menu-a font-weight-normal" :to="{name: 'ChooseOne', params: {whichPageT: 'Register'}}">kayıt ol</router-link>
                    </div>

                    <div v-else-if="userToken!=null" class="header-menu-item font-ght-normal border-right-black">
                        Hoş Geldiniz
                    </div>

                    <button @click="changeLanguage('tr')">tr</button>
                    <button @click="changeLanguage('en')">en</button>
                </div>
                <div class="header-top-biz-sizi-arayalım">
                    <img src="@/assets/images/introduction-area/_layout/biz-sizi-arayalim.svg" />
                </div>
            </div>

            <div id="sticky-header" class="header-menu dp-flex-row">
                <div class="header-menu-left font-size-13 text-upper dp-flex-row">

                    <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Login')">
                        <!-- <a class="header-menu-a" href="#">taşıt ara</a> -->
                        <router-link class="header-menu-a" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">Taşıt Ara</router-link>
                    </div>
                    <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Login')">
                        <!-- <a class="header-menu-a" href="#">sefer sorgula</a> -->
                        <router-link class="header-menu-a" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">Sefer Sorgula</router-link>
                    </div>
                    <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Login')">
                        <!-- <a class="header-menu-a" href="#">paketler</a> -->
                        <router-link class="header-menu-a" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">Paketler</router-link>
                    </div>
                </div>
                <div class="header-menu-right font-size-13 text-upper dp-flex-row">
                    <!-- <div class="header-menu-item drop-down2">
                        <a class="header-menu-a drop-down2-btn">hakkımızda</a>
                        <div class="drop-down2-content dp-flex-column">
                            <div class="dp-flex-row">
                                <div class="point-0-5"></div>
                                <a class="itemm" href="#">deneme</a>
                            </div>

                            <a href="#">deneme2</a>
                            <a href="#">deneme3</a>
                            <a href="#">deneme4</a>
                        </div>
                    </div> -->
                    <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Login')">
                        <!-- <a class="header-menu-a" href="#">paketler</a> -->
                        <router-link class="header-menu-a" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">Hakkımızda</router-link>
                    </div>
                    <div class="header-menu-item">
                        <!-- <a class="header-menu-a" href="#">iletişim</a> -->
                        <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Login')">
                            <!-- <a class="header-menu-a" href="#">paketler</a> -->
                            <router-link class="header-menu-a" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">İletişim</router-link>
                        </div>
                    </div>

                    <div class="header-menu-item">
                        <!-- <a class="header-menu-a" href="#">sss</a> -->
                        <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Login')">
                            <!-- <a class="header-menu-a" href="#">paketler</a> -->
                            <router-link class="header-menu-a" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">SSS</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="header-banner-block dp-flex-row">
            <div class="header-block-left dp-flex-row justify-content-end">
                <div class="header-text border-right text-color-green font-size-13">
                    <span class="font-size-13 text-color-green text-underline">Sorunuz</span>
                    var mı ?
                </div>
            </div>
            <div class="header-block-right dp-flex-row">
                <div class="header-text font-size-13 text-upper">
                    7/24 çağrı merkezi
                    <span class="font-size-13"> </span>
                </div>
                <div class="header-text">
                    <img src="@/assets/images//introduction-area/_layout/orange24.svg" />
                </div>
                <div class="header-text font-size-13">0850 89 44 565</div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderComponent",
    components: {},
    data() {
        return {
            userToken: localStorage.getItem("userToken"),
        };
    },
    methods: {
        changeLanguage(lng) {
            localStorage.setItem("lng", lng);
            window.location.reload();
        },
        saveWhichPageInfo(pageName) {
            localStorage.setItem("whichPage", pageName);
        },
    },
    mounted() {
        if (this.userToken == null) {
        }
    },
};
</script>