import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class DistrictService {
    resultModel = new ResultModel();

    errorResult = "";

    async getDistrictsByCityId(cityId) {
        await apiHelper.get(APIUrls.GET_DISTRICTS_BY_CITY_ID, {
            cityId: cityId
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }


}