<template>
    <div class="dp-flex-column justify-content-between distance-per-month-content panel-content">
        <h4 class="panel-content-inside-title">NİSAN / KM</h4>
        <div class="dp-flex-row mt-mb-15 justify-content-between">
            <div class="dp-flex-column justify-content-center">
                <p class="font-size-15 statistics-text-color">
                    Nisan ayı için
                </p>
                <p class="text-medium font-size-30 statistics-text-color">
                    200 km
                </p>
                <p class="font-size-15 statistics-text-color">
                    ulaştınız
                </p>
            </div>
            <figure>
                <img src="@/assets/images/customer-area/dashboard/map.png">
            </figure>

        </div>
        <div class="dp-flex-row justify-content-end">
            <button class="panel-dashboard-button btn-none">
                Tüm Ayları Görr
            </button>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>