<template>
    <div class="dp-flex-column active-ads">
        <Loading v-if="isLoading"></Loading>

        <EmptyAds v-if="!isLoading && loads.length == 0" :title="$t('emptyAds.Empty_Deleted_Loads_Title')" :isAddNewAds="false"></EmptyAds>

        <div class="dp-flex-row justify-content-between dp-flex-wrap active-ads-content">
            <div class="panel-content active-ads-content-item dp-flex-column" v-for="load in loads" :key="load.id">
                <div class="dp-flex-row justify-content-between items-center">
                    <p class="label-bg-red font-size-14">
                        {{load.offersCount}} {{$t("active-ads.Offers")}}
                    </p>
                    <i @click="restoreDeleteLoad(load.id)" class="fa-solid fa-recycle btn-none color-6098FB"></i>
                </div>
                <h4 class="font-size-16 there-offer-title text-bold">
                    {{load.title}}
                </h4>
                <div class="dp-flex-row justify-content-start width-100">
                    <h4 class="font-size-14 mr-y1">
                        {{load.loadAddress}}
                    </h4>

                    <h4 :key="index" v-for="(item,index) in load.unloadAddresses" class="font-size-14 mr-y1">
                        - {{item}}
                    </h4>
                </div>

                <div class="dp-flex-column justify-content-between mt-y3">
                    <h4 class="font-size-14 mt-mb-y25 text-bold">
                        {{$t("active-ads.LoadProperties")}}
                    </h4>
                    <div class="dp-flex-column justify-content-between">
                        <div class="dp-flex-row load-skils">
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.loadItemType}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.volume}} m3
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.tonnageRange}}
                                </p>
                            </div>
                            <div v-if="load.isFlammable" class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{$t("load-properties.FlammableMaterial")}}
                                </p>
                            </div>
                            <div v-if="load.isStacked" class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{$t("load-properties.Stackable")}}
                                </p>
                            </div>
                            <div v-if="load.isHamal" class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{$t("draftAd.Is_Hamal")}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.decimeter}} {{$t("load-properties.Desi")}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.loadingMethod}}
                                </p>
                            </div>
                            <div class="dp-flex-row items-center load-skils-item">
                                <div class="point-6"></div>
                                <p class="font-size-14">
                                    {{load.loadType}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dp-flex-row mt-y5 items-end justify-content-between">
                    <div class="dp-flex-column">
                        <p class="font-size-14">
                            {{$t("active-ads.BestOffer")}}
                        </p>
                        <p class="font-size-25 text-semi-bold">
                            {{load.bestOffer}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayoutCustomerPanel from "@/components/CustomerArea/CustomerPanelLayout/_LayoutCustomerPanel.vue";
import EmptyAds from "./EmptyAds.vue";
import LoadService from "@/services/LoadService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";

export default {
    name: "DeleteAds",

    components: {
        LayoutCustomerPanel,
        EmptyAds,
        Loading,
    },

    data() {
        return {
            loads: [],
            isLoading: false,
            loadService: new LoadService(),
        };
    },

    async mounted() {
        await this.getDeletedLoads();
    },

    methods: {
        async getDeletedLoads() {
            this.isLoading = true;

            await this.loadService.getLoadsByCustomerUserGuid(true, false);

            if (this.loadService.resultModel.code == 14) {
                this.loads = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },

        async restoreDeleteLoad(loadId) {
            if (loadId > 0) {
                await this.loadService.deleteOrRestoreDeleteLoad(loadId, true);

                if (this.loadService.resultModel.code == 160) {
                    this.$toast.success("Yük İlanı Aktif Edildi !");

                    let deletedLoad = this.loads.map((x) => x.id).indexOf(loadId);

                    this.loads.splice(deletedLoad, 1);
                } else {
                    this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
                }

                if (this.loadService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }

                this.isOpenDeletePopup = false;
            }
        },
    },
};
</script>

<style>
</style>