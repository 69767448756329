<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="dp-flex-column">
        <Loading v-if="isLoading">

        </Loading>

        <div v-if="!isLoading" class="dp-flex-column">
            <div v-if="isAutoCompleteActive" class="dp-flex-row justify-content-end items-center m-y1 map-autocomplete-content">
                <GmapAutocomplete class="map-autocomplete" @place_changed="getLoadAddress">
                </GmapAutocomplete>

                <i class="fa-solid icon-map-autocomplete fa-magnifying-glass"></i>
            </div>

            <!-- POLYLINE DÜZ ÇİZGİ ÇİZDİRİYOR -->
            <!-- <GmapMap v-if="isLoad" :center="{ lat: 10.0, lng: 10.0 }" :zoom="7" style="width: 100%; height: 400px;">
            <GmapMarker :position="{ lat: 10.0, lng: 10.0 }" />
            <GmapMarker :position="{ lat: 11.0, lng: 11.0 }" />
            <GmapPolyline :path="pathCoordinates" :strokeColor="'#FF0000'" :strokeWeight="5" :strokeOpacity="0.6" />
            </GmapMap> -->

            <GmapMap ref="mapRef" class="offres-map" :options="mapOptions" map-type-id="roadmap" :center="center" :zoom="zoom" style="width: 100%; height: 500px;">
                <GmapPolyline v-if="pathCoordinates.length != 0" :path="pathCoordinates" :options="polylineOptions" />

                <GmapInfoWindow v-if="infoWindowPos != null" :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false">
                </GmapInfoWindow>

                <div v-if="loadingUnloadingPoints != null && loadingUnloadingPoints.length != 0">
                    <div v-for="(marker, index) in loadingUnloadingPoints" :key="index">
                        <GmapMarker v-if="marker.markerType == 1 && marker.position != null" :position="marker.position" :clickable="true" :draggable="false" @click="loadingUnloadingPotinClick(marker, index)" :icon="getMarkerIconOptions(require('@/assets/images/transporter-area/dirvers/location-time-red.gif'))" />
                        <GmapMarker v-if="marker.markerType == 2 && marker.position != null" :position="marker.position" :clickable="true" :draggable="false" @click="loadingUnloadingPotinClick(marker, index)" :icon="getMarkerIconOptions(require('@/assets/images/transporter-area/dirvers/location-time-blue.gif'))" />
                    </div>
                </div>

                <div v-if="vehicleLocations != null && vehicleLocations.length != 0">
                    <GmapMarker :key="vehicleLocation.vehicleId" v-for="vehicleLocation in vehicleLocations" :position="vehicleLocation.position" :clickable="true" :draggable="false" @click="toggleInfoWindow(vehicleLocation)" :icon="getMarkerIconOptions(require('@/assets/images/transporter-area/dirvers/truck-last.gif'))">
                    </GmapMarker>
                </div>
            </GmapMap>

            <div v-if="isBottomButtonActive" class="dp-flex-row justify-content-end popup-map-subtitle items-center">
                <p class="font-size-13">
                    Haritayı sürükleyin, işaretleyin ya da arama kısmından adresi arayın.
                </p>

                <router-link to="/customer/registered-addresses" class="btn-none continue-btn popup-map-button vehicle-info-continue-btn font-size-14 items-center">
                    Devam Et
                </router-link>
            </div>
        </div>
    </div>
</template>

// NOTE: google maps github repository link => https://github.com/xkjyeah/vue-google-maps

<script>
// eslint-disable-next-line no-unused-vars
import { gmapApi } from 'vue2-google-maps';
import Loading from "@/components/Widgets/Loadings/Loading.vue";

export default {
    props: ["isAutoCompleteActive", "isBottomButtonActive", "vehicleLocations", "loadingUnloadingPoints"],

    components: {
        Loading,
    },

    data() {
        return {
            markers: [],

            pathCoordinates: [],

            polylineOptions: {
                strokeColor: '#FF0000',  // Çizgi rengi
                strokeOpacity: 0.8,      // Çizgi saydamlığı
                strokeWeight: 10          // Çizgi kalınlığı
            },

            mapOptions: {
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
            },

            center: {
                lat: 0,
                lng: 0
            },

            loadAddress: {
                lat: 0,
                lng: 0,
            },

            infoWindowPos: null,

            infoWinOpen: false,

            currentMidx: null,

            infoOptions: {
                content: "",
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },

            zoom: 15,

            isLoading: false,
        };
    },

    created() {
        this.getCurrentLocation();
    },

    updated() {
        //console.log(this.vehicleLocations);
    },

    mounted() {
        this.isLoading = true;

        // eslint-disable-next-line no-undef
        if (typeof google !== 'undefined' && google.maps) {
            this.loadingUnloadingPoints.forEach(element => {
                if (element.markerType == 1) {
                    // this.center.lat = element != null ? element.position.lat : 0;
                    // this.center.lng = element != null ? element.position.lng : 0;
                }
            });

            this.zoom = this.loadingUnloadingPoints != null ? 5 : 15;

            this.isLoading = false;

            this.$nextTick(() => {
                this.fetchRoute();
            });

            this.zoom = this.vehicleLocations != null ? 15 : 5;
        }

        this.isLoading = true;
    },

    methods: {
        loadingUnloadingPotinClick(marker) {
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.title;
            this.infoWinOpen = true;
        },

        getCurrentLocation() {
            this.$getLocation({
                enableHighAccuracy: true,
                timeout: Infinity,
                maximumAge: 1,
            }).then((coordinates) => {
                this.center.lat = coordinates.lat;
                this.center.lng = coordinates.lng;
            });

            this.addMarkerToPosition(this.center);
        },

        getMarkerIconOptions(url) {
            // eslint-disable-next-line no-undef
            if (typeof google !== 'undefined' && google.maps) {

                let markerIconOption = {
                    url: "",
                    scaledSize: null,  // İkonun boyutunu belirle
                    origin: null,  // İkonun köken noktasını belirle
                    anchor: null, // İkonun çapa noktasını belirle
                };

                markerIconOption.url = url;
                // eslint-disable-next-line no-undef
                markerIconOption.scaledSize = new google.maps.Size(80, 80);  // İkonun boyutunu belirle                
                // eslint-disable-next-line no-undef
                markerIconOption.origin = new google.maps.Point(0, 0);  // İkonun köken noktasını belirle                
                // eslint-disable-next-line no-undef
                markerIconOption.anchor = new google.maps.Point(50, 50);  // İkonun çapa noktasını belirle

                return markerIconOption;
            } else {
                console.error("Google Maps henüz yüklenmedi.");
            }
        },

        addMarkerToPosition(element) {
            if (element) {
                this.markers.push({ element });
            }

            this.center = element;
        },

        getLoadAddress(place) {
            this.loadAddress.lat = place.geometry.location.lat();
            this.loadAddress.lng = place.geometry.location.lng();

            this.addMarkerToPosition(this.loadAddress);

            console.log(place);
        },

        toggleInfoWindow: function (vehicleLocation) {
            this.infoWindowPos = vehicleLocation.position;
            this.infoOptions.content = vehicleLocation.plateNumber;

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == vehicleLocation.vehicleId) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = vehicleLocation.vehicleId;
            }
        },

        fetchRoute() {
            // eslint-disable-next-line no-undef
            if (typeof google !== 'undefined' && google.maps) {
                // eslint-disable-next-line no-undef
                const directionsService = new google.maps.DirectionsService();

                const origin = { lat: this.loadingUnloadingPoints[0].position.lat, lng: this.loadingUnloadingPoints[0].position.lng };
                const destination = { lat: this.loadingUnloadingPoints[this.loadingUnloadingPoints.length - 1].position.lat, lng: this.loadingUnloadingPoints[this.loadingUnloadingPoints.length - 1].position.lng };

                const waypoints = [];

                for (let index = 1; index < this.loadingUnloadingPoints.length - 1; index++) {
                    const element = this.loadingUnloadingPoints[index];
                    waypoints.push({ location: { lat: element.position.lat, lng: element.position.lng }, stopover: true })
                }

                directionsService.route({
                    origin: origin,
                    destination: destination,
                    waypoints: waypoints,
                    optimizeWaypoints: false,  // En etkili rota sırası için optimize et
                    // eslint-disable-next-line no-undef
                    travelMode: google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    // eslint-disable-next-line no-undef
                    if (status === google.maps.DirectionsStatus.OK) {
                        this.pathCoordinates = result.routes[0].overview_path;
                        this.isLoading = false;
                    } else {
                        console.error(`error fetching directions ${result}`);
                        this.isLoading = false;
                    }
                });
            }
            else {
                this.isLoading = false;
            }
        }
    },
};
</script>

<style></style>