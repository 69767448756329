<template>
    <div class="mt-mb-35">
        <div class="dp-flex-row items-center">
            <slot name="title-icon">

            </slot>
            
            <h1 class="color-667085 font-size-25">{{title}}</h1>
        </div>
        
        <p class="color-667085 font-size-15 text-semi-bold">
            {{description}}
        </p>
    </div>
</template>

<script>
export default {
    props:["title", "description"],
};
</script>

<style>
</style>