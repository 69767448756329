<template>
    <div class="dp-flex-column panel-content width-50 justify-content-center items-center">
        <figure class="text-center">
            <slot name="image">
                <img class="width-70" src="@/assets/images/customer-area/my-ads/icon1.png" />
            </slot>            
        </figure>
        <p class="font-size-13 text-semi-bold">
            {{text}}
        </p>
    </div>
</template>

<script>
export default {
    props:["text"],
};
</script>

<style>
</style>