<template>
    <div class="dp-flex-row dp-flex-wrap justify-content-start">
        <Loading v-if="isLoading"></Loading>

        <EmptyAds v-if="isLoading == false && voyages.length == 0" :title="'Hiç Mesajınız Yok'" :description="'Henüz Mesajlaşabileceğiniz hiç aktif seferiniz yok.'" class="width-100">

        </EmptyAds>

        <div :key="voyage.id" v-for="voyage in voyages" @click="goToIncomingMessages(voyage.id, voyage.loadingCity +' - ' + voyage.unloadingCity)" class="dp-flex-column flex-wrap-33 mb-y1 mr-y1 justify-content-between panel-content">
            <div class="dp-flex-column voyage-content width-100 p-y3 border-radius-5">
                <div class="dp-flex-row justify-content-between items-center">
                    <h3 class="font-size-20 text-bold">
                        {{voyage.title}}' Nolu Sefer | {{voyage.loadTitle}}
                    </h3>
                    <p class="font-size-13">
                        {{voyage.status == 1 ? 'Sefere Araç Atandı.' : voyage.status == 2 ? 'Sefer Zamanı Bekleniyor' : voyage.status == 3 ? 'Yolda' : 'Bitti'}}
                    </p>
                </div>

                <div class="dp-flex-row width-100 voyage-address-info justify-content-between items-center">
                    <div class="dp-flex-row width-100 justify-content-between items-center">
                        <p class="font-size-16">
                            {{voyage.loadingCity}} - {{voyage.unloadingCity}}
                        </p>

                        <p class="font-size-16 color-1A5EF7">
                            {{voyage.count}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageService from "@/services/MessageService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import EmptyAds from "@/components/CustomerArea/MyAds/EmptyAds.vue";

export default {
    components: {
        Loading,
        EmptyAds,
    },
    name: "TransporterMessages",

    data() {
        return {
            isLoading: false,
            voyages: {},
            status: true,
            messageService: new MessageService(),
        };
    },

    mounted() {
        this.$watch(
            () => this.$route.query,
            (toParams, previousParams) => {
                console.log(this.$route.query.voyageMessageStatus);
                this.status = this.$route.query.voyageMessageStatus != undefined ? this.$route.query.voyageMessageStatus : null;
                this.getMessagesWithVoyages();
            }
        );

        this.getMessagesWithVoyages();
    },

    methods: {
        async getMessagesWithVoyages() {
            this.isLoading = true;

            await this.messageService.getMessagesWithVoyages(this.status);

            if (this.messageService.resultModel.code == 14) {
                this.voyages = this.messageService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.messageService.resultModel.message));
            }

            if (this.messageService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },

        goToIncomingMessages(voyageId, voyageRoute) {
            this.$router.push({
                name: "transporter-incoming-messages",
                params: {
                    voyageId,
                    voyageRoute,
                },
            });
        },
    },
};
</script>

<style>
</style>