<template>
    <div class="dp-flex-row dp-flex-wrap justify-content-start">

        <Loading v-if="isLoading"></Loading>

        <EmptyAds v-if="isLoading == false && voyages.length == 0" :title="$t('emptyAds.Empty_Voyage_Title')" :description="$t('emptyAds.Empty_Voyage_Description')" class="width-100"></EmptyAds>

        <div v-for="voyage in voyages" :key="voyage.id" class="dp-flex-column flex-wrap-24 mb-y1 mr-y1 justify-content-between panel-content">
            <div class="dp-flex-column voyage-content width-100 p-y3 border-radius-5">
                <div class="dp-flex-row justify-content-between items-center">
                    <h3 class="font-size-20 text-bold">
                        {{ voyage.title + ' ' + $t('messages.Voyage_Title') }}
                    </h3>
                </div>

                <div class="dp-flex-row width-100 voyage-address-info justify-content-between items-center">
                    <div class="dp-flex-column justify-content-between items-center">
                        <p class="font-size-16 text-bold">
                            {{ voyage.loads[0].loadAddressInfo.cityName }}
                        </p>
                        <p class="font-size-14">
                            {{ voyage.loads[0].loadAddressInfo.distrcitName }}
                        </p>
                    </div>
                    <div class="dp-flex-column justify-content-between items-center">
                        <p class="font-size-16 text-bold">
                            -----
                        </p>
                        <p class="font-size-14">
                            -----
                        </p>
                    </div>
                    <div class="dp-flex-column justify-content-between items-center">
                        <p class="font-size-16 text-bold">
                            {{ voyage.loads[0].unLoadAddressInfo.cityName }}
                        </p>
                        <p class="font-size-14">
                            {{ voyage.loads[0].unLoadAddressInfo.distrcitName }}
                        </p>
                    </div>
                </div>
            </div>

            <button @click="goToDetailPage(voyage.id)" class="mt-y3 voyage-finished-button btn-none border-radius-10 p-y4 color-0D3CA5">
                {{ $t('button.See_Detail') }}
            </button>

            <!-- <button v-if="voyage.status == 3" @click="getVoyageVehicleUsers(voyage.id)" class="mt-y3 voyage-finished-button btn-none border-radius-10 p-y4 color-0D3CA5">
                Durumu Görüntüle
            </button> -->

            <button v-if="voyage.status == 1" @click="getVehiclesForVoyage(voyage.id, voyage.loads[0].loadVehicleType)" class="add-vehicle-to-voyage-button">
                {{ $t('transporterOffer.Add_Vehicles_To_Voyage') }}
            </button>

        </div>

        <Popup v-if="isPopupOpen" @closePopup="isPopupOpen = false">
            <template slot="popup-header">
                <p class="font-size-22 text-bold">
                    Seferdeki Araçların Durumu
                </p>
            </template>

            <template slot="popup-body">
                <div v-for="voyageVehicleUser in voyageVehicleUsers" :key="voyageVehicleUser.vehicleId" class="voyage-info-card dp-flex-row width-100 mb-y3 justify-content-between items-center border-radius-5 p-y5 ">
                    <div class="dp-flex-column width-30">
                        <p class="font-size-15 mb-y3">
                            {{ voyageVehicleUser.licencePlate }}
                        </p>

                        <p :class="voyageVehicleUser.durum == 1 ? 'voyage-status-hold' : voyageVehicleUser.durum == 2 ? 'voyage-status-continue' : 'voyage-status-finished'" class="font-size-15 voyage-status-cards">
                            {{ voyageVehicleUser.durum == 1 ? 'Bekliyor' : voyageVehicleUser.durum == 2 ? 'Yolda' : 'Bitti' }}
                        </p>
                    </div>
                    <div class="dp-flex-column width-70 voyage-user-infos">
                        <div v-for="driver in voyageVehicleUser.drivers" :key="driver.name" class="dp-flex-row justify-content-start items-center mb-y3">
                            <img class="width-20 mr-y5 voyage-user-img" :src="getImgPath(driver.profilePhoto)">
                            <p class="font-size-15">
                                {{ driver.name }} {{ driver.surname }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </Popup>

        <Popup v-if="isAddVehicleToVoyagePopupOpen" @closePopup="isAddVehicleToVoyagePopupOpen = false">
            <template slot="popup-header">
                <p>
                    {{ $t('transporterOffer.Add_Vehicles_To_Voyage') }}
                </p>
            </template>

            <template slot="popup-body">
                <div class="dp-flex-column width-100 justify-content-start">
                    <div v-for="vehicle in vehicles" :key="vehicle.id" class="dp-flex-row justify-content-between items-center">
                        <i @click="openVehicleInfoPopup(vehicle.id)" class="font-size-20 fa-solid fa-circle-info"></i>
                        <div class="dp-flex-column justify-content-between items-center">
                            <p class="font-size-20">
                                {{ vehicle.licencePlate.toUpperCase() }} ({{ vehicle.vehicleTypeName.toUpperCase() }})
                            </p>

                            <span v-if="vehicle.vehicleDrivers == null" class="text-color-red font-size-10">
                                {{ $t('inputValidation.Vehicle_Cannot_Be_Assigned') }}

                            </span>
                        </div>

                        <input v-if="vehicle.vehicleDrivers != null" @change="changeCheckbox($event, vehicle.id)" type="checkbox" />

                        <i v-else class="fa-solid font-size-13 fa-circle-xmark"></i>
                    </div>

                    <div class="dp-flex-row justify-content-center items-center">
                        <button @click="addVehiclesToVoyageWithDrivers()" class="btn-none mt-y5 p-y3 border-radius-5 width-80 font-size-16 text-color-white color" style="background-color:orange;">
                            {{ $t('transporterOffer.Add_Vehicles_To_Voyage') }}
                        </button>
                    </div>
                </div>
            </template>
        </Popup>

        <Popup v-if="isVehicleInfoPopupOpen" @closePopup="isVehicleInfoPopupOpen = false">
            <template slot="popup-header">
                <p class="font-size-22 text-bold">
                    {{ selectedVehicle.licencePlate }}
                </p>
            </template>

            <template slot="popup-body">
                <div class="dp-flex-row width-100 dp-flex-wrap width-100 justify-content-start">
                    <div class="dp-flex-row width-33 items-center">
                        <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>
                        <p class="font-size-20">
                            {{ selectedVehicle.brandName }}
                        </p>
                    </div>

                    <div class="dp-flex-row width-33 items-center">
                        <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>
                        <p class="font-size-20">
                            {{ selectedVehicle.modelYear }}
                        </p>
                    </div>

                    <div class="dp-flex-row width-33 items-center">
                        <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>
                        <p class="font-size-20">
                            {{ selectedVehicle.vehicleTypeName }}
                        </p>
                    </div>

                    <div class="dp-flex-row width-33 items-center">
                        <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>
                        <p class="font-size-20">
                            {{ selectedVehicle.vehicleBodyTypeName }}
                        </p>
                    </div>

                    <div class="dp-flex-row width-33 items-center">
                        <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>
                        <p class="font-size-20">
                            {{ selectedVehicle.vehicleBoydFloorTypeName }}
                        </p>
                    </div>

                    <div class="dp-flex-column width-100 justify-content-start items-left">
                        <p class="font-size-16 text-bold mt-y3">
                            {{ $t('transporterOffer.Driver') }}
                        </p>

                        <div v-if="selectedVehicle.vehicleDrivers != null">
                            <p v-for="driver in selectedVehicle.vehicleDrivers" :key="driver.id" class="font-size-16">
                                {{ driver.name + ' ' + driver.surname }}
                            </p>
                        </div>

                        <p v-if="selectedVehicle.vehicleDrivers == null" class="font-size-16">
                            {{ $t('transporterOffer.There_Is_No_Driver') }}
                        </p>
                    </div>
                </div>
            </template>
        </Popup>

    </div>
</template>

<script>
// TODO: seçili tab renklendirilecek.
// TODO: make voyage item.

import Popup from "@/components/Widgets/ModelPopup/Popup.vue";
import EmptyAds from "@/components/CustomerArea/MyAds/EmptyAds.vue";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import { AssetUrls } from "@/Helpers/AssetUrls";
import VoyageService from "@/services/VoyageService";
import VehiclesService from "@/services/VehiclesService";
import OfferService from "@/services/OfferService";

export default {
    name: "TransporterVoyages",

    components: {
        Popup,
        EmptyAds,
        Loading,
    },

    data() {
        return {
            isLoading: false,
            isAddVehicleToVoyagePopupOpen: false,
            isVehicleInfoPopupOpen: false,
            vehicles: [],
            selectedVehicle: {},
            selectedVehicleIds: [],
            voyageId: 0,
            voyages: [],
            voyageStatus: 3,
            isPopupOpen: false,
            voyageVehicleUsers: [],
            voyageService: new VoyageService(),
            vehicleService: new VehiclesService(),
            offerService: new OfferService(),
            AssetUrls: AssetUrls,
        };
    },

    async mounted() {
        this.$watch(
            () => this.$route.query,
            // toParams, previousParams
            async () => {
                this.voyageStatus = this.$route.query.voyageStatus != undefined ? this.$route.query.voyageStatus : 1;

                await this.getVoyages();
            }
        );

        await this.getVoyages();
    },

    methods: {
        getImgPath(imgName) {
            try {
                return require(AssetUrls.USER_ASSETS_URL + "/" + imgName);
            } catch (error) {
                return require("@/assets/images/transporter-area/dirvers/driver.png");
            }
        },

        goToDetailPage(id) {
            if (id > 0) {
                this.$router.push({
                    name: "voyage-detail",
                    params: {
                        id,
                    },
                });
            } else {
                this.$toast.warning(this.$t("resultModel.Transaction_Failed"), {
                    timeout: 2000,
                });
            }
        },

        async getVoyages() {
            this.voyages = {};
            this.isLoading = true;

            await this.voyageService.getVoyagesByTransporterUserGuid(this.voyageStatus);

            if (this.voyageService.resultModel.code == 14) {
                this.voyages = this.voyageService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.voyageService.resultModel.message));
            }

            if (this.voyageService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },

        async getVoyageVehicleUsers(voyageId) {
            if (voyageId > 0) {
                await this.voyageService.getVoyageVehicleUsersWithVoyageId(voyageId);

                if (this.voyageService.resultModel.code == 14) {
                    this.voyageVehicleUsers = this.voyageService.resultModel.data;
                } else {
                    this.$toast.error(this.$t("resultModel." + this.voyageService.resultModel.message));
                }

                if (this.voyageService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }

                this.isPopupOpen = true;
            }
        },

        async getVehiclesForVoyage(voyageId, vehicleTypeId) {
            this.voyageId = voyageId;

            await this.vehicleService.getAssignableVehiclesByTransporterGuid(vehicleTypeId);

            if (this.vehicleService.resultModel.code == 14) {
                this.vehicles = this.vehicleService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.vehicleService.resultModel.message));
            }

            if (this.vehicleService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isAddVehicleToVoyagePopupOpen = true;
        },

        openVehicleInfoPopup(vehicleId) {
            this.selectedVehicle = this.vehicles.find((element) => {
                return element.id == vehicleId;
            });

            this.isVehicleInfoPopupOpen = true;
        },

        changeCheckbox(event, vehicleId) {
            if (event.target.checked) {
                this.selectedVehicleIds.push(vehicleId);
            } else {
                this.selectedVehicleIds.pop(vehicleId);
            }
        },

        async addVehiclesToVoyageWithDrivers() {
            await this.offerService.addVehiclesToVoyageWithDrivers(this.voyageId, this.selectedVehicleIds);

            if (this.offerService.resultModel.code == 179) {
                this.$toast.success(this.$t("resultModel." + this.offerService.resultModel.message));
                this.isAddVehicleToVoyagePopupOpen = false;
            } else {
                this.$toast.warning(this.offerService.resultModel.message);
            }

            if (this.offerService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },
    },
};
</script>

<style>
.voyage-content {
    background-color: #f0f0f0;
}

.voyage-content>h3 {
    padding: 5% 0;
}

.voyage-address-info {
    padding: 5% 0 2% 0;
    border-top: 1px solid black;
}

.voyage-finished-button {
    background-color: #e9f0f7;
    border: 1px solid #2268af;
}

.voyage-status-cards {
    border: 1px solid black;
    padding: 2%;
    text-align: center;
    margin: aü;
    width: 80%;
    border-radius: 5px;
}

.voyage-status-hold {
    color: #e6481b;
    border-color: #e6481b;
}

.voyage-status-continue {
    color: #ff8343;
    border-color: #ff8343;
}

.voyage-status-finished {
    color: #52c65e;
    border-color: #52c65e;
}

.voyage-info-card {
    background-color: #f4f5f6;
}

.voyage-user-infos {
    border-left: 1px solid black;
    padding-left: 3%;
}

.voyage-user-img {
    width: 50px;
    border-radius: 50px;
}
</style>