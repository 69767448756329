<template>
    <div class="dp-flex-column">
        <Title :title="'Faturalama Tercihlerim'"></Title>

        <BlueHeaderWhiteBodyContent :title="'FATURALAMA TERCİHLERİM'">
            <template slot="white-body">
                <div class="dp-flex-row justify-content-between">
                    <AddressLabelWithInput class="width-33" :label="'Faturalandırma Kategorisi *'" :type="'select'" :selectItems="options" :name="'commercial-title'"></AddressLabelWithInput>
                    <AddressLabelWithInput class="width-33 ml-y1" :label="'Faturalandırma Periyodu *'" :type="'select'" :selectItems="options2" :name="'commercial-title'"></AddressLabelWithInput>
                    <AddressLabelWithInput class="width-33 ml-y1" :label="'Faturalandırma Aşaması *'" :type="'select'" :selectItems="options3" :name="'commercial-title'"></AddressLabelWithInput>
                </div>
            </template>
        </BlueHeaderWhiteBodyContent>

        <BlueHeaderWhiteBodyContent class="mt-y1" :title="'FATURA TERCİHLERİ'">
            <template slot="white-body">
                <div class="dp-flex-column items-left">
                    <p class="font-size-14">
                        Fatura Satırı Açıklaması bölümünde gözükmesini istediğiniz alanlar
                    </p>
                    <p class="font-size-12 mt-y1 color-D01438">
                        * Birden fazla seçim yapabilirsiniz
                    </p>
                </div>
                <div class="dp-flex-row dp-flex-wrap">
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                    <YellowCheckbox class="flex-wrap-12 ad-summary-required-checkbox">
                        <template v-slot:chekbox-label-text>
                            <p class="font-size-12">
                                Yükleme Şekli
                            </p>
                        </template>
                    </YellowCheckbox>
                </div>
            </template>
        </BlueHeaderWhiteBodyContent>

        <div class="dp-flex-row mt-y1 justify-content-end items-center">
            <button class="btn-none continue-btn pl-pr-y1-4 ml-y1 font-size-14 items-center">
                KAYDET
            </button>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import BlueHeaderWhiteBodyContent from "@/components/Widgets/CustomerPanel/BlueHeaderWhiteBodyContent.vue";
import AddressLabelWithInput from "../Addresses/LoadingPoint/AddressLabelWithInput.vue";
import YellowCheckbox from "@/components/Widgets/CustomerPanel/YellowCheckbox.vue";

export default {
    components: {
        Title,
        BlueHeaderWhiteBodyContent,
        AddressLabelWithInput,
        YellowCheckbox,
    },
    data() {
        return {
            options: [
                { text: "Kullanıcı Bazlı", value: "1" },
                { text: "Firma Bazlı", value: "2" },
            ],
            options2: [
                { text: "Sefer Bazlı", value: "1" },
                { text: "Kur Bazlı", value: "2" },
                { text: "Mahmut Bazlı", value: "3" },
            ],
            options3: [
                { text: "Seçiniz", value: "1" },
                { text: "Devam Ediyor", value: "2" },
                { text: "Bitti", value: "3" },
            ],
        };
    },
};
</script>

<style>
</style>