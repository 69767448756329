<template>
    <div class='popup-model-content dp-flex-column justify-content-center items-center'>
        <div class='popup-blur '>
        </div>

        <div class='popup-model dp-flex-column'>
            <div class='popup-model-header dp-flex-row justify-content-between' v-bind:class="headerClass">
                <div class='popup-model-header-title dp-flex-row items-center width-inherit'>
                    <slot name="popup-header">

                    </slot>
                </div>
                <button class='btn-none popup-model-header-close-buton' @click="$emit('closePopup')">
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class='dp-flex-column justify-content-between items-center popup-model-body'>

                <slot name="popup-alert-icon">

                </slot>

                <slot name="popup-body-text">

                </slot>

                <slot name="popup-body">

                </slot>

                <slot name="popup-body-button">

                </slot>

            </div>
            <slot name="popup-body-footer">

            </slot>
        </div>
    </div>
</template>

<script>
import LabelWithInputWithButtonRow from "../Inputs/LabelWithInputWithButtonRow.vue";
export default {
    name: "TransactionPopup",
    components: { LabelWithInputWithButtonRow },
    props: ["headerClass"],
};
</script>

<style>
</style>