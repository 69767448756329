import { Currency, LoadFeaturesInfo, UnloadingLoadingAddress } from "@/Helpers/Models";

const moduleCreateLoad = {
    namespaced: true,
    state: {
        isComingFromDraft: false,
        loadTitle: "",
        loadNote: "",
        shipmentType: 0,
        vehicleType: 0,
        vehicleTypeName: "",
        vehicleInfos: {
            vehicleCount: 1,
            trailerType: 0,
            trailerTypeName: "",
            bodyFloorType: 0,
            bodyFloorTypeName: "",
            caseType: "",
        },
        loadFeaturesInfo: new LoadFeaturesInfo(),
        currency: new Currency(),
        loadingAddresses: [],
        unloadingAddresses: [],
        unloadingLoadingAddress: new UnloadingLoadingAddress(),

    },
    getters: {
        getShipmentType: (state) => {
            return state.shipmentType;
        },

        getVehicleType: (state) => {
            return { id: state.vehicleType, text: state.vehicleTypeName };
        },

        getVehicleInfos: (state) => {
            return state.vehicleInfos;
        },

        getDraftLoadingAddress: (state) => {
            return state.draftLoadingAddress;
        },

        getIsComingFromDraft: (state) => {
            return state.isComingFromDraft;
        },

        getLoadFeaturesInfo: (state) => {
            return state.loadFeaturesInfo;
        },

        getCurrency: (state) => {
            return state.currency;
        },

        getLoadTitleAndNote: (state) => {
            return { title: state.loadTitle, note: state.loadNote };
        },

        getLoadingAddresses: (state) => {
            return state.loadingAddresses;
        },

        getUnloadingAddresses: (state) => {
            return state.unloadingAddresses;
        },

        getUnloadingLoadingAddress: (state) => {
            return state.unloadingLoadingAddress;
        },
    },
    actions: {
        updateShipmentType: ({ commit }, [shipmentType]) => {
            commit('setShipmentType', [shipmentType]);
        },

        updateVehicleType: ({ commit }, [vehicleType, vehicleTypeName]) => {
            commit('setVehicleType', [vehicleType, vehicleTypeName]);
        },

        updateVehicleInfos: ({ commit }, [vehicleInfos]) => {
            commit('setVehicleInfos', [vehicleInfos]);
        },

        updateIsComingFromDraft: ({ commit }, [isComingFromDraft]) => {
            commit('setIsComingFromDraft', [isComingFromDraft]);
        },

        updateLoadFeaturesInfo: ({ commit }, [loadFeaturesInfo]) => {
            commit('setLoadFeaturesInfo', [loadFeaturesInfo]);
        },

        updateCurrency: ({ commit }, [currency]) => {
            commit('setCurrency', [currency]);
        },

        updateLoadTitleAndNote: ({ commit }, [loadTitle, loadNote]) => {
            commit('setLoadTitleAndNote', [loadTitle, loadNote]);
        },

        updateLoadingAddresses: ({ commit }, [loadingAddresses]) => {
            commit('setLoadingAddresses', [loadingAddresses]);
        },

        updateUnloadingAddresses: ({ commit }, [unloadingAddresses]) => {
            commit('setUnloadingAddresses', [unloadingAddresses]);
        },

        updateUnloadingLoadingAddress: ({ commit }, [unloadingLoadingAddress]) => {
            commit('setUnloadingLoadingAddress', [unloadingLoadingAddress]);
        },







        resetState({ commit }) {
            commit('resetState');
        },
    },
    mutations: {
        setShipmentType: (state, [shipmentType]) => {
            state.shipmentType = shipmentType;
        },

        setVehicleType: (state, [vehicleType, vehicleTypeName]) => {
            state.vehicleType = vehicleType;
            state.vehicleTypeName = vehicleTypeName;
        },

        setVehicleInfos: (state, [vehicleInfos]) => {
            state.vehicleInfos.trailerType = vehicleInfos.trailerType;
            state.vehicleInfos.trailerTypeName = vehicleInfos.trailerTypeName;
            state.vehicleInfos.bodyFloorType = vehicleInfos.bodyFloorType;
            state.vehicleInfos.bodyFloorTypeName = vehicleInfos.bodyFloorTypeName;
            state.vehicleInfos.vehicleCount = vehicleInfos.vehicleCount;
            state.vehicleInfos.caseType = vehicleInfos.caseType;
        },

        setIsComingFromDraft: (state, [isComingFromDraft]) => {
            state.isComingFromDraft = isComingFromDraft;
        },

        setLoadFeaturesInfo: (state, [loadFeaturesInfo]) => {
            state.loadFeaturesInfo = loadFeaturesInfo;
        },

        setCurrency: (state, [currency]) => {
            state.currency = currency;
        },

        setLoadTitleAndNote: (state, [loadTitle, loadNote]) => {
            state.loadTitle = loadTitle;
            state.loadNote = loadNote;
        },

        setLoadingAddresses: (state, [loadingAddresses]) => {
            state.loadingAddresses = loadingAddresses;
        },

        setUnloadingAddresses: (state, [unloadingAddresses]) => {
            state.unloadingAddresses = unloadingAddresses;
        },

        setUnloadingLoadingAddress: (state, [unloadingLoadingAddress]) => {
            state.unloadingLoadingAddress = unloadingLoadingAddress;
        },



        resetState: (state) => {
            console.log("RESET_STATE");
        }
    },
}

export default moduleCreateLoad;