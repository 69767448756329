<template>
    <div class="sidebar dp-flex-column">
        <div class="sidebar-top-content text-center">
            <figure>
                <img src="@/assets/daliportLogo.svg" alt="daliport-logo">
            </figure>
        </div>
        <div class="sidebar-menu-content dp-flex-column">

            <slot name="sidebar-menu-items">

            </slot>

            <router-link to="/customer" @click.native="changeToComponents('genelBakis')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == '/customer'}" class="sidebar-menu-item btn-none items-center dp-flex-row font-size-16">
                <i class="sidebar-menu-item-elements fa-solid fa-house"></i>
                {{$t("customerSideBar.Home")}}
            </router-link>

            <button @click="openCloseSideBarMenus('myAds')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == 'myAds'}" class="btn-none sidebar-menu-item items-center dp-flex-row">
                <i class="sidebar-menu-item-elements fa-solid fa-truck"></i>
                <p class="sidebar-menu-item-elements">
                    {{$t("customerSideBar.Ads")}}
                </p>
                <i v-if="!(selectedDropDown == 'myAds')" class="sidebar-menu-item-elements fa-solid fa-angle-right"></i>
                <i v-if="selectedDropDown == 'myAds'" class="sidebar-menu-item-elements fa-solid fa-angle-down"></i>
            </button>
            <div v-if="selectedDropDown == 'myAds'" class="dp-flex-column">
                <router-link to="/customer/new-ads" v-bind:class="{'sidebar-drop-down-item-active':this.$route.fullPath == '/customer/new-ads'}" class="items-center sidebar-drop-down-item btn-none">
                    <p class="sidebar-menu-item-elements">
                        {{$t("customerSideBar.Create_New_Ads")}}
                    </p>
                </router-link>

                <router-link to="/customer/my-ads" v-bind:class="{'sidebar-drop-down-item-active':this.$route.fullPath == '/customer/my-ads'}" class="items-center sidebar-drop-down-item btn-none">
                    <p class="sidebar-menu-item-elements btn-none">
                        {{$t("customerSideBar.Active_Ads")}}
                    </p>
                </router-link>
            </div>

            <router-link to="/customer/voyages" @click.native="changeToComponents('seferler')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == '/customer/voyages'}" class="sidebar-menu-item btn-none items-center dp-flex-row font-size-16">
                <i class="sidebar-menu-item-elements fa-solid fa-map-location-dot"></i>
                {{$t("customerSideBar.Voyages")}}
            </router-link>

            <router-link to="/customer/messages" @click.native="changeToComponents('seferler')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == '/customer/messages'}" class="sidebar-menu-item btn-none items-center dp-flex-row font-size-16">
                <i class="sidebar-menu-item-elements fa-solid fa-message"></i>
                {{$t("customerSideBar.Messages")}}
            </router-link>

            <router-link to="#" @click.native="changeToComponents('seferler')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == '#'}" class="sidebar-menu-item btn-none items-center dp-flex-row font-size-16">
                <i class="sidebar-menu-item-elements fa-solid fa-chart-line"></i>
                {{$t("customerSideBar.Statistics")}}
            </router-link>

            <router-link to="/customer/packages" @click.native="changeToComponents('packages')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == '/customer/packages'}" class="sidebar-menu-item btn-none items-center dp-flex-row font-size-16">
                <i class="sidebar-menu-item-elements fa-solid fa-box-open"></i>
                {{$t("customerSideBar.Packages")}}
            </router-link>

            <div @click="openCloseSideBarMenus('user-info')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == 'user-info'}" class="btn-none sidebar-menu-item items-center dp-flex-row">
                <i class="sidebar-menu-item-elements fa-solid fa-users"></i>
                <p class="sidebar-menu-item-elements">
                    {{$t("customerSideBar.Account")}}
                </p>
                <i v-if="!(selectedDropDown == 'user-info')" class="sidebar-menu-item-elements fa-solid fa-angle-right"></i>
                <i v-if="selectedDropDown == 'user-info'" class="sidebar-menu-item-elements fa-solid fa-angle-down"></i>
            </div>

            <div v-if="selectedDropDown == 'user-info'" class="dp-flex-column">
                <router-link to="/customer/profile" v-bind:class="{'sidebar-drop-down-item-active':this.$route.fullPath == '/customer/profile'}" class="items-center sidebar-drop-down-item btn-none">
                    <p class="sidebar-menu-item-elements">
                        {{$t("customerSideBar.My_Profile")}}
                    </p>
                </router-link>
                <router-link to="/customer/authorized-users" v-bind:class="{'sidebar-drop-down-item-active':this.$route.fullPath == '/customer/authorized-users'}" class="items-center sidebar-drop-down-item btn-none">
                    <p class="sidebar-menu-item-elements">
                        {{$t("customerSideBar.My_Authorized_Users")}}
                    </p>
                </router-link>
                <router-link to="/customer/business-profile" v-bind:class="{'sidebar-drop-down-item-active':this.$route.fullPath == '/customer/business-profile'}" class="items-center sidebar-drop-down-item btn-none">
                    <p class="sidebar-menu-item-elements">
                        {{$t("customerSideBar.Company_Profile")}}
                    </p>
                </router-link>
                <router-link to="/customer/billing-preferences" v-bind:class="{'sidebar-drop-down-item-active':this.$route.fullPath == '/customer/billing-preferences'}" class="items-center sidebar-drop-down-item btn-none">
                    <p class="sidebar-menu-item-elements">
                        {{$t("customerSideBar.My_Billing_Preferences")}}
                    </p>
                </router-link>
            </div>

            <div @click="changeToComponents('settings')" v-bind:class="{'customer-sidebar-menu-item-active':selectedItem == 'settings'}" class="sidebar-menu-item dp-flex-row">
                <i class="sidebar-menu-item-elements fa-solid fa-gear"></i>
                <p class="sidebar-menu-item-elements">
                    {{$t("customerSideBar.Settings")}}
                </p>
            </div>

        </div>
    </div>
</template>

<script>
import SidebarMenuItem from "./SidebarMenuItem.vue";
export default {
    name: "Sidebar",
    components: { SidebarMenuItem },
    computed: {
        classObject: function () {
            return {};
        },
    },
    data() {
        return {
            selectedItem: "",
            selectedDropDown: "",
        };
    },
    mounted() {
        this.selectedItem = this.$route.fullPath;

        if (this.selectedItem == "/customer/my-ads" || this.selectedItem == "/customer/new-ads") {
            this.selectedDropDown = "myAds";
            this.selectedItem = "myAds";
        }

        if (this.selectedItem == "/customer/profile" || this.selectedItem == "/customer/authorized-users" || this.selectedItem == "/customer/business-profile" || this.selectedItem == "/customer/billing-preferences") {
            this.selectedDropDown = "user-info";
            this.selectedItem = "user-info";
        }
    },
    methods: {
        changeToComponents(id) {
            this.selectedItem = this.$route.fullPath;
        },
        openCloseSideBarMenus(id) {
            if (this.selectedDropDown == id) {
                this.selectedDropDown = "";
            } else {
                this.selectedDropDown = id;
            }
            this.selectedItem = id;
        },
    },
};
</script>

<style>
</style>