import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class OfferService {
    resultModel = new ResultModel();

    errorResult = "";

    async getOffersByTransporterUserGuid(isAccept, isActive) {

        await apiHelper.get(APIUrls.GET_OFFERS_BY_TRANSPORTER_USER_GUID, {
            isAccept: isAccept,
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async addVehiclesToVoyageWithDrivers(voyageId, selectedVehicleIds) {
        await apiHelper.post(APIUrls.ADD_VEHICLES_TO_VOYAGE_WITH_DRIVERS, {
            voyageId: voyageId,
            vehicleIds: selectedVehicleIds,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async createOrUpdateOffer(id, loadId, amount) {

        await apiHelper.post(APIUrls.CREATE_OR_UPDATE_OFFER, {
            id: id,
            loadId: loadId,
            amount: amount,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }
}