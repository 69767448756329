<template>
    <div class='identity-information-form'>
        <form @submit="checkIdentityInfo" method="post">
            <div class='input-icons identity-information-input-icons'>
                <select class="identity-information-input" @change="selectCountry($event)">
                    <option class="identity-information-input" :key="0" :value="0">
                        Ülke Seçiniz
                    </option>
                    <option class="identity-information-input" :key="item.countryId" v-for="item in countries" :value="item.countryId">
                        {{item.countryName}}
                    </option>
                </select>
                <p class='font-size-13 text-color-red'>{{errors.country}}</p>
            </div>

            <div v-if="isShowIdentityNumber" class='input-icons identity-information-input-icons'>
                <i class="identity-information-icon icon"></i>
                <input class='btn-none identity-information-input' name='identityNumber' type="text" :placeholder="$t('complete-registration-page.IdentityInformationSurIdentityNumberInput')" v-model="identityNumber" />
                <p class='font-size-13 text-color-red'>{{errors.identityNumber}}</p>
            </div>

            <div class='input-icons identity-information-input-icons'>
                <i class="identity-information-icon icon"></i>
                <input class='btn-none identity-information-input' name='dateOfBirth' type="date" :placeholder="$t('complete-registration-page.IdentityInformationSurIdentityNumberInput')" v-model="dateOfBirth" :max="limitForDateOfBirth" />
                <p class='font-size-13 text-color-red'>{{errors.dateOfBirth}}</p>
            </div>

            <!-- <div class="dp-flex-row mt-y3">
                <div @click="$refs.licensePageFronFileInput.click()" class="dp-flex-column file-input justify-content-evenly btn-none items-center color-869094 p-y1">
                    <div class="dp-flex-row justify-content-evenly width-100 items-center">
                        <i class="fa-solid font-size-20 color-869094 fa-address-card"></i>
                        <p class="font-size-14">Ön yüzü <br> yükleyin</p>
                        <input ref="licensePageFronFileInput" @change="previewFiles(1,$event)" type="file" class="dp-none" value="Ön Yüzü Yükleyin" accept="image/gif, image/jpeg, image/png, image/jpg">
                    </div>
                    <img class="mt-y3 width-100" v-if="licensePageFrontUrl != ''" :src='licensePageFrontUrl'>
                </div>

                <div @click="$refs.licensePageBackFileInput.click()" class="dp-flex-column file-input justify-content-evenly btn-none items-center color-869094 ml-y1 p-y1">
                    <div class="dp-flex-row justify-content-evenly width-100 items-center">
                        <i class="fa-solid font-size-20 color-869094 fa-address-card"></i>
                        <p class="font-size-14">Arka yüzü <br> yükleyin</p>
                        <input ref="licensePageBackFileInput" @change="previewFiles(2,$event)" type="file" class="dp-none" value="Ön Yüzü Yükleyin" accept="image/gif, image/jpeg, image/png, image/jpg">
                    </div>
                    <img class="mt-y3 width-100" v-if="licensePageBackUrl != ''" :src='licensePageBackUrl'>
                </div>
            </div> -->

            <div class="why-we-want-this-info dp-flex-row justify-content-around">
                <i class="fa-solid fa-circle-info identity-information-input-icons-red"></i>
                <div class="font-size-13 why-we-want-this-info-text">{{$t("complete-registration-page.WhyWeWantThisInfo")}}</div>
            </div>

            <button class='btn-none mt-mb-25 identity-information-input-submit identity-information-input-submit login-input-submit' type='submit'>
                {{$t("complete-registration-page.ComplateRegistrationButton")}}
            </button>
        </form>
    </div>
</template>

<script>
// TODO: tc kimlik kısmına mask eklenecek.
// TODO: doğum tarihi seçilen kısım tıklama düzeltilecek.
// TODO: istenilen alanlara label'lar eklenecek.
// TODO: local storage'a basılan verilerde use vuex and complete registartion api edited

import { validateIdentityNumber, validateRequired } from "@/Helpers/FormValidateFunctions";
import { APIUrls } from "@/Helpers/APIUrls";
import moment from "moment";

export default {
    name: "IdentityInformation",
    components: {},
    data() {
        return {
            errors: {},
            resultModel: {
                code: 0,
                message: "",
                data: {},
            },
            countries: [],
            countryId: 0,
            dateOfBirth: "",
            identityNumber: "",
            userType: localStorage.getItem("userType"),
            licensePageFront: undefined,
            licensePageBack: undefined,
            licensePageFrontUrl: "",
            licensePageBackUrl: "",
            isShowIdentityNumber: false,
            limitForDateOfBirth: new Date(),
        };
    },
    methods: {
        validateIdentityNumber,
        validateRequired,

        previewFiles(whichFile, event) {
            // console.log(whichFile);
            // console.log(event.target.files);
            if (event.target.files.length > 0) {
                //console.log(event.target.files.length);
                switch (whichFile) {
                    case 1:
                        // console.log(event.target.files[0]);
                        this.licensePageFront = event.target.files[0];
                        this.licensePageFrontUrl = URL.createObjectURL(event.target.files[0]);

                        break;
                    case 2:
                        this.licensePageBack = event.target.files[0];
                        this.licensePageBackUrl = URL.createObjectURL(event.target.files[0]);
                        break;
                    case 3:
                        this.profilePhoto = event.target.files[0];
                        break;
                    case 4:
                        this.srcDocument = event.target.files[0];
                        break;
                    default:
                        break;
                }
            }
        },

        checkIdentityInfo(e) {
            e.preventDefault();

            let error = 0;

            if (!validateIdentityNumber(this.identityNumber).isValid && this.countryId == 216) {
                this.$set(this.errors, "Identity Number", validateIdentityNumber(this.identityNumber).message);

                error++;
            }
            if (!validateRequired("Date Of Birth", this.dateOfBirth).isValid) {
                this.$set(this.errors, "dateOfBirth", validateRequired("Date Of Birth", this.dateOfBirth).message);

                error++;
            }

            if (error == 0) {
                localStorage.setItem("countryId", this.countryId);
                localStorage.setItem("identityNumber", this.identityNumber);
                localStorage.setItem("dateOfBirth", this.dateOfBirth);
                this.$emit("changeTab");
            }
        },

        selectCountry(selectedCountry) {
            this.countryId = selectedCountry.target.value;
            this.isShowIdentityNumber = this.countryId == 216 ? true : false;
        },
    },

    mounted() {
        this.axios({
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            url: APIUrls.GET_COUNTRIES_CITIES_TAX_OFFICES,
        })
            .then((response) => {
                this.$set(this.resultModel, "code", response.data.code);
                this.$set(this.resultModel, "message", response.data.message);
                this.$set(this.resultModel, "data", response.data.data);

                this.resultModel.data.forEach((element) => {
                    let country = {
                        countryCode: "",
                        countryName: "",
                        countryId: "",
                        cities: [],
                    };

                    country.countryCode = element.code;
                    country.countryName = element.name;
                    country.countryId = element.id;
                    country.cities = element.cities;

                    this.countries.push(country);
                });
            })
            .catch(function (error) {
                console.log(error.data);
            });

        this.limitForDateOfBirth = new Date(this.limitForDateOfBirth.getUTCFullYear() - 18, this.limitForDateOfBirth.getMonth(), this.limitForDateOfBirth.getDate());

        this.limitForDateOfBirth = moment(this.limitForDateOfBirth).format("YYYY-MM-DD");
    },
};
</script>

<style>
.file-input {
    width: 50%;
    background-color: #eff1f2;
    border-radius: 5px;
    padding: 3%;
}

.file-input > input {
    opacity: 0;
    height: 100%;
}
</style>