<template>
    <div class="dp-flex-column width-75">
        <div class="panel-content-title">
            <div>
                <h2 class="panel-content-title">
                    {{$t("customer-dashboard-page.LastOffresContentTitle")}}
                </h2>
            </div>
        </div>
        <div class="panel-content dp-flex-column">
            <div class="last-offres-content-title">
                <p>
                    <!-- {{$t("customer-dashboard-page.LastOffresTitle")}} -->
                </p>
            </div>
            <div class="">
                <p class="font-size-15">

                </p>

                <div class="dp-flex-row">
                    <div v-if="lastOffers.length == 0">
                        {{$t("customer-dashboard-page.No_Last_Offers")}}                        
                    </div>
                    <OffersCard class="ml-mr-10 flex-wrap-23" v-for="lastOffer in lastOffers" :key="lastOffer.id" :id="lastOffer.id" :fulName="lastOffer.name + ' ' + lastOffer.surname" :company="lastOffer.companyTitle" :price="'tl ' + lastOffer.amount" :starRating="lastOffer.rating" :imgUrl="lastOffer.profilePhoto" :isAccept="lastOffer.isAccept">

                    </OffersCard>

                    <!-- <div class="last-offres-card dp-flex-column" v-for="lastOffer in lastOffers" :key="lastOffer.id">
                        <div class="dp-flex-row justify-content-between">
                            <div class="user-icon">
                                <img src="@/assets/images/customer-area/dashboard/ben.png">
                                <i class="fa-solid fa-face-smile"></i>
                            </div>
                            <div class="user-name-title dp-flex-column">
                                <p class="name text-color-last-offres-card font-size-15">
                                    {{lastOffer.name + ' ' + lastOffer.surname}}
                                </p>
                                <p class="title font-size-12">
                                    {{lastOffer.companyName}}
                                </p>
                            </div>
                            <div class="star-raiting dp-flex-column justify-content-between">
                                <i class="star fa-solid fa-star"></i>
                                <p class="raiting text-color-last-offres-card font-size-15 text-bold">
                                    4,8
                                </p>

                            </div>
                        </div>
                        <div class="dp-flex-column last-offres-card-middle">
                            <p class="middle-text font-style-italic font-size-15">
                                İlanına teklif verdi.
                            </p>
                            <p class="middle-text text-color-last-offres-card font-size-15 text-bold">
                                {{lastOffer.title}}
                            </p>
                        </div>
                        <div class="font-size-22 text-center last-offres-price">
                            tl {{lastOffer.amount}}
                        </div>
                        <button class="last-offres-send-message font-size-15 text-color-last-offres-card">
                            <i class="fa-solid fa-message font-size-15"></i>
                            Mesaj Gönder
                        </button>
                    </div> -->
                </div>
                <div class="dp-flex-row justify-content-end">
                    <router-link :to="'/customer/my-ads'" class="panel-dashboard-button btn-none font-size-14">
                        {{$t("customer-dashboard-page.LastOffresSeeAllOffresButton")}}
                    </router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { APIUrls } from "@/Helpers/APIUrls";
import OffersCard from "@/components/Widgets/CustomerPanel/OffersCard.vue";
export default {
    components: { OffersCard },
    name: "LastOffers",
    data() {
        return {
            lastOffers: [],
        };
    },
    mounted() {
        this.axios({
            url: APIUrls.GET_LAST_OFFERS_BY_CUSTOMER_GUID,
            method: "GET",
            params: {
                customerUserGuid: localStorage.getItem("guid"),
                take: 4,
                isActive: true,
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.code == 14) {
                        this.lastOffers = response.data.data;
                        //console.log(this.lastOffers);
                    } else {
                        this.$toast.warning("Son Teklifler Getirilirken Beklenmedik Bir Hata Oluştu !");
                        //console.log(response.data);
                    }
                } else {
                    //console.log(response);
                    this.$toast.warning("Son Teklifler Getirilirken Beklenmedik Bir Hata Oluştu !");
                }
            })
            .catch((error) => {
                //console.log(error);
                this.$toast.error("Son Teklifler Getirilirken Beklenmedik Bir Hata Oluştu !");
            });
    },
};
</script>

<style>
@import "@/assets/css/customer-area/customer-dashboard/last-offres.css";
</style>

// TODO: tasarımsal düzenleme yapılacak.