import Vue from 'vue';
import { APIUrls } from "@/Helpers/APIUrls";
import axios from "axios";
import i18n from "@/i18n.js";
import { FilterDto } from '@/Helpers/Models';


class OfficialUser {
    id = 0;
    name = "";
    surname = "";
    email = "";
    phone = "";
    title = "";
    createdAt = "";
    userType = 0;
}


export default class OfficialUsersService {
    async getOfficialUsersByOwnerId(filterDto = new FilterDto()) {

        let users = [];

        await axios({
            url: APIUrls.GET_OFFICIAL_USERS_BY_OWNER_ID,
            method: "GET",
            params: {
                IsLatest: filterDto.IsLatest,
                Page: filterDto.Page,
                Search: filterDto.Search,
                Take: filterDto.Take,
                IsActive: filterDto.IsActive,
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    if ((response.data.code = 14)) {
                        // console.log(response.data.data);
                        users = response.data.data;
                    } else {
                        Vue.$toast.warning("Beklenmedik Bir Hata Oluştu !");
                    }
                } else {
                    Vue.$toast.error("Beklenmedik Bir Hata Oluştu !");
                }
            })
            .catch((error) => {
                // console.log(error);
                Vue.$toast.error("Beklenmedik Bir Hata Oluştu! Lütfen Daha Sonra Tekrar Deneyiniz !");
            });

        return users;
    }

    deleteOrRestoreDeleteOfficialUser(id, isActive) {
        axios({
            url: APIUrls.DELETE_OR_RESTORE_DELETE_OFFICIAL_USER,
            method: "POST",
            params: {
                id: id,
                isActive: isActive,
            },
        })
            .then((response) => {
                //console.log(response);
                if (response.status == 200) {
                    if (response.data.code == 207) {
                        Vue.$toast.success(i18n.t("resultModel." + response.data.message));

                    } else {
                        Vue.$toast.error(i18n.t("resultModel.Transaction_Failed"));
                    }
                } else {
                    Vue.$toast.error(i18n.t("resultModel.Transaction_Failed"));
                }
            })
            .catch((error) => {
                //console.log(error);
                Vue.$toast.error(i18n.t("resultModel.Transaction_Failed"));
            });
    }

    async createOrUpdateOfficialUser(officialUser = new OfficialUser()) {
        await axios({
            url: APIUrls.CREATE_OR_UPDATE_OFFICIAL_USER,
            method: "POST",
            data: {
                id: officialUser.id,
                title: officialUser.title,
                name: officialUser.name,
                surname: officialUser.surname,
                email: officialUser.email,
                phone: officialUser.phone,
                userType: officialUser.userType,
            },
        })
            .then((response) => {
                // console.log(response);
                if (response.status == 200) {
                    if (response.data.code == 3) {
                        Vue.$toast.success(i18n.t("resultModel." + response.data.message));
                    }
                    if (response.data.code == 141 || response.data.code == 142) {
                        Vue.$toast.warning(i18n.t("resultModel." + response.data.message));
                    }
                    if (response.data.code == 206) {
                        Vue.$toast.success(i18n.t("resultModel." + response.data.message));
                    }
                }
            })
            .catch((error) => {
                // console.log(error);
                Vue.$toast.error(i18n.t("resultModel.Transaction_Failed"));
            });
    }
}