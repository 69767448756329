<template>
    <div class="dp-flex-row justify-content-start width-100 mt-mb-y25">
        <!-- <input id="chckbox_to_late_date_time" @change="toogleLateDate" type="checkbox" /> -->
        <label class="container">
            <input @change="toogleLateDate" id="chckbox_to_late_date_time" type="checkbox" @input="$emit('input', $event.target)">
            <span class="checkmark"></span>
        </label>
        <label for="chckbox_to_late_date_time" class="font-size-12">
            {{text}}
            <slot name="chekbox-label-text">

            </slot>
        </label>
    </div>

</template>

<script>
export default {
    props: ["text"],
    data: {
        isActive: false,
    },
    methods: {
        toogleLateDate() {
            this.isActive = !this.isActive;
            // console.log(this.isActive);
        },
    },
};
</script>

<style>
@import url("@/assets/css/widgets/customer-panel/yellow-checkbox.css");
</style>