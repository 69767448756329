<template>
    <div class="dp-flex-row width-100 four-ziro-four-content">
        <div class="width-50 dp-flex-column justify-content-center items-center">
            <img src="@/assets/images/widgets/error-pages/404Page/oops.png" alt="404_Oops" />
        </div>
        <div class="width-50 dp-flex-column">
            <div class="dp-flex-row justify-content-end not-found-transporter items-right">
                <img class="width-70" src="@/assets/images/widgets/error-pages/404Page/transporter2.png" alt="not-found-transporter">
            </div>
            <div class="dp-flex-column not-found-text-content">
                <img class="width-5" src="@/assets/images/widgets/error-pages/404Page/daliportOrangeLogo.svg" alt="daliport-logo">
                <h3 class="font-size-45 mt-mb-y25 text-semi-bold text-color-white">
                    {{$t("not-found.Title")}}
                </h3>
                <p class="font-size-30 text-medium text-color-white">
                    {{$t("not-found.Desc")}}
                </p>
                <div class="dp-flex-row width-70 justify-content-end">
                    <img src="@/assets/images/widgets/error-pages/404Page/Group 3828.png" alt="background-img">
                </div>
                <router-link to="/customer" class="btn-none font-size-13 not-found-return-back-button width-30">
                    <i class="fa-solid font-size-13 fa-angle-left m-y1"></i>
                    {{$t("button.ReturnBack")}}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
@import url("@/assets/css/widgets/error-pages/404.css");
</style>