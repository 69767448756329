<template>
    <div class="dp-flex-column">
        <Title :title="$t('messages.Title')"></Title>

        <div class="tab mt-mb-25">
            <div class="tab-button-header dp-flex-row justify-content-start">
                <button @click="selectTab(1)" v-bind:class="{'button-active':currentTab == 1, 'button-pasive':currentTab != 1}" class="font-size-15 text-capitalize btn-none color-667085">
                    {{$t("messages.ActiveExpeditions")}}
                </button>

                <button @click="selectTab(2)" v-bind:class="{'button-active':currentTab == 2, 'button-pasive':currentTab != 2}" class="font-size-15 text-capitalize btn-none color-667085">
                    {{$t("messages.PastMessages")}}
                </button>
            </div>
            <div class="tab-content dp-flex-column items-center">
                <AdsSearch :text="$t('messages.SearhcInAds')"></AdsSearch>
            </div>
            <div class="panel-content mt-mb-y1">
                <div class="dp-flex-row dp-flex-wrap messages-content justify-content-between">
                    <MessageRow v-on:click.native="goToIncomingMessages(voyage.id,voyage.loadingCity +' - ' + voyage.unloadingCity,currentTab == 1?true:false)" :key="voyage.id" v-for="voyage in voyages" :expeditionNumber="voyage.title + ' ' + $t('messages.Voyage_Title')" :time="voyage.status" :roadMap="voyage.loadingCity +' - ' + voyage.unloadingCity" :messageCount="voyage.count"></MessageRow>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { APIUrls } from "@/Helpers/APIUrls";
import AdsSearch from "../MyAds/AdsSearch.vue";
import MessageRow from "./MessageRow.vue";

export default {
    components: { AdsSearch, MessageRow },

    data() {
        return {
            currentTab: 1,
            voyages: {},
            status: true,
        };
    },

    mounted() {
        this.getMessagesWithVoyages();
    },

    methods: {
        selectTab(selectedTab) {
            this.currentTab = selectedTab;
            if (selectedTab == 1) {
                this.status = true;
            } else {
                this.status = false;
            }
            this.getMessagesWithVoyages();
        },

        getMessagesWithVoyages() {
            this.axios({
                url: APIUrls.GET_MESSAGES_WITH_VOYAGES,
                method: "GET",
                params: {
                    status: this.status,
                },
            })
                .then((response) => {
                    // console.log(response);
                    if (response.status == 200) {
                        if (response.data.code == 14) {
                            this.voyages = response.data.data;
                        } else {
                            this.$toast.warning($t("resultModel.Transaction_Failed"));
                        }
                    } else {
                        this.$toast.error($t("resultModel.Transaction_Failed"));
                    }
                })
                .catch((error) => {
                    // console.log(error);
                    this.$toast.error($t("resultModel.Transaction_Failed"));
                });
        },

        goToIncomingMessages(voyageId, voyageRoute, isActiveMessages) {
            // console.log(voyageId);
            // console.log(voyageRoute);
            // console.log(isActiveMessages);

            this.$router.push({
                name: "incoming-messages",
                params: {
                    voyageId,
                    voyageRoute,
                    isActiveMessages,
                },
            });
        },
    },
};
</script>

<style>
@import url("@/assets/css/customer-area/messages/messages.css");
</style>