<template>
    <div class="dp-flex-column">
        <Title :title="load.title + ' #' + voyage.title + ' ' + $t('messages.Voyage_Title')" :description="loadAddressInfo.city + ' - ' + unLoadAddressInfo.city"></Title>

        <div class="panel-content">
            <Map :vehicleLocations="vehicleLocations"></Map>
        </div>

        <div class="dp-flex-column mt-mb-y1 justify-content-between width-50 panel-content">
            <TitleWithIcon class="bottom-border" :title="$t('voyageDetail.List_Of_Vehicles_On_Voyage')"></TitleWithIcon>

            <div class="dp-flex-row dp-flex-wrap justify-content-start mt-y1">
                <p v-if="vehicleStatus.length == 0" class="font-size-14">
                    {{$t("messages.No_Active_Vehicles")}}
                </p>
                <div v-for="vehicle in vehicleStatus" :key="vehicle.vehicleId" class="voyage-info-card dp-flex-row mb-y1 width-20 mr-y1 justify-content-between items-center border-radius-5 p-y1">
                    <div class="dp-flex-column width-40">
                        <p class="font-size-15 mb-y3">
                            {{vehicle.licencePlate}}
                        </p>

                        <p :class="vehicle.status == 1 ? 'voyage-status-hold' : vehicle.status == 2 ? 'voyage-status-continue' : 'voyage-status-finished'" class="font-size-15 voyage-status-cards">
                            {{vehicle.status == 1 ? $t("voyageVehicleStatus.Waiting") : vehicle.status == 2 ? $t("voyageVehicleStatus.On_The_Way") : $t("voyageVehicleStatus.Finished")}}
                        </p>
                    </div>
                    <div class="dp-flex-column width-60 voyage-user-infos">
                        <div v-for="driver in vehicle.drivers" :key="driver.name" class="dp-flex-row justify-content-start items-center mb-y3">
                            <img class="width-20 mr-y5 voyage-user-img" :src="AssetUrls.USER_ASSETS_URL + driver.profilePhoto" @error="handleImageError" alt="driver-photo">
                            <p class="font-size-15">
                                {{driver.name}} {{driver.surname}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dp-flex-column mt-y3">
            <div class="dp-flex-row">
                <div class="dp-flex-column justify-content-between width-50 panel-content">
                    <TitleWithIcon :title="$t('ad-detail.Title')"></TitleWithIcon>
                    <div class="dp-flex-row justify-content-between mt-mb-10">
                        <AdDetailRow :text="load.shipmentType">
                            <template slot="image">
                                <img class="width-50" src="@/assets/images/customer-area/my-ads/icon1.png" />
                            </template>
                        </AdDetailRow>
                        <AdDetailRow class="ml-y1" :text="load.vehicleType">
                            <template slot="image">
                                <img class="width-50" src="@/assets/images/customer-area/my-ads/create-new-ads/truck2.png" />
                            </template>
                        </AdDetailRow>
                    </div>
                </div>

                <div class="dp-flex-column ml-y1 justify-content-between panel-content">

                    <TitleWithIcon :title="$t('vehicle-infos.Title')" :isButtonActive="false">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>

                    <div class="dp-flex-row dp-flex-wrap justify-content-between mt-mb-10">
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.VehicleCount')" :description="load.vehicleCount + ' Adet'"></BlueBox>
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.TrailerType')" :description="load.trailerType"></BlueBox>
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.CaseProperties')" :description="load.caseFeatures"></BlueBox>
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.Body_Floor_Type')" :description="load.bodyFloorType"></BlueBox>
                    </div>

                </div>
            </div>
        </div>

        <div class="dp-flex-column justify-content-between">
            <div class="dp-flex-row mt-mb-15 justify-content-between">
                <div class="panel-content width-50">

                    <TitleWithIcon :title="$t('ad-summary.LoadingPoint')" :actionName="'Düzenle'">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>

                    <div class="dp-flex-column mt-mb-y1">
                        <div class="dp-flex-column mt-mb-y25">
                            <div class="dp-flex-row">
                                <div class="dp-flex-column width-100">
                                    <h3 class="font-size-16 text-semi-bold">
                                        {{load.loadingAddress.title}}
                                    </h3>
                                    <h5 class="font-size-16">
                                        {{load.loadingAddress.country + "-" + load.loadingAddress.city + "-" + load.loadingAddress.district}}
                                    </h5>
                                </div>
                            </div>
                            <p class="font-size-14 mt-mb-y1">
                                {{load.loadingAddress.direction}}
                            </p>
                            <div class="dp-flex-column width-100 mt-mb-y1">
                                <h3 class="font-size-16 text-semi-bold">
                                    {{$t('ad-summary.EstimatedDeliveryTime')}}
                                </h3>

                                <div class="dp-flex-row font-size-14 width-100">
                                    <div class="dp-flex-row items-center">
                                        <i class="fa-solid color-1A5EF7 font-size-16 fa-clock"></i>
                                        <p class="ml-10 font-size-14">
                                            {{load.loadingAddress.earliestDate.replace('T',' ')}}
                                        </p>
                                    </div>
                                    <div class="ml-y1 dp-flex-row items-center">
                                        <i class="fa-solid color-1A5EF7 font-size-16 fa-hourglass-end"></i>
                                        <p class="ml-10 font-size-14">
                                            {{load.loadingAddress.latestDate.replace('T', ' ')}}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-content width-50 ml-y1">
                    <TitleWithIcon :title="$t('ad-summary.DischargePoints')" :actionName="'Düzenle'">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>
                    <div class="dp-flex-row">
                        <button @click="selectUnloadingAddress(unloadingAddress.id,unloadingAddress.order)" v-for="unloadingAddress in load.unLoadingAddresses" :key="unloadingAddress.id" :class="unloadingAddress.order == selectedUnloadingAddressOrder ? 'btn-first-unloading-point' : 'btn-other-unloading-point'" class="btn-none btn-first-unloading-point">{{unloadingAddress.order == selectedUnloadingAddressOrder ? + (unloadingAddress.order) + '. ' + $t('new-address.Unloading_Point_Title') : unloadingAddress.order + '.'}}</button>
                    </div>
                    <template v-for="(unloadingAddress, index) in load.unLoadingAddresses">
                        <div :key="index" v-if="selectedUnloadingAddressId == unloadingAddress.id" class="dp-flex-column mt-mb-y1">
                            <div class="dp-flex-column mt-mb-y25">
                                <div class="dp-flex-row">
                                    <div class="dp-flex-column width-100">
                                        <h3 class="font-size-16 text-semi-bold">
                                            {{unloadingAddress.title}}
                                        </h3>
                                        <h5 class="font-size-16">
                                            {{unloadingAddress.country + "-" + unloadingAddress.city + "-" + unloadingAddress.district}}
                                        </h5>
                                    </div>
                                </div>
                                <p class="font-size-14 mt-mb-y1">
                                    {{unloadingAddress.direction}}
                                </p>
                                <div class="dp-flex-column width-100 mt-mb-y1">
                                    <h3 class="font-size-16 text-semi-bold">
                                        {{$t('ad-summary.EstimatedDeliveryTime')}}
                                    </h3>

                                    <div class="dp-flex-row font-size-14 width-100">
                                        <div class="dp-flex-row items-center">
                                            <i class="fa-solid color-1A5EF7 font-size-16 fa-clock"></i>
                                            <p class="ml-10 font-size-14">
                                                {{unloadingAddress.earliestDate.replace('T', ' ')}}
                                            </p>
                                        </div>
                                        <div class="ml-y1 dp-flex-row items-center">
                                            <i class="fa-solid color-1A5EF7 font-size-16 fa-hourglass-end"></i>
                                            <p class="ml-10 font-size-14">
                                                {{unloadingAddress.latestDate.replace('T', ' ')}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="dp-flex-column mt-mb-15 panel-content justify-content-between">
                <TitleWithIcon :title="$t('ad-summary.LoadInfo')" :actionName="'Düzenle'">
                    <template v-slot:action-icon>
                        <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                    </template>
                </TitleWithIcon>

                <div class="dp-flex-row dp-flex-wrap justify-content-between mt-mb-10">
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.CargoType')" :description="load.loadItemType"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LoadType')" :description="load.loadType"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Volume')" :description="load.volume + ' m³'"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Desi')" :description="load.decimeter"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LDM')" :description="load.ldmLoadingMeter"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.TonnageRange')" :description="load.range"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LoadingShape')" :description="load.loadingMethod"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.CostValue')" :description="load.currencyCode + ' ' + load.value"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Title')" :description="(load.isFlammable ? ($t('draftAd.Is_Flammable') + ', ') : '') + (load.isStacked ? ($t('draftAd.Is_Stacked') + ', ') : '') + (load.isHamal ? $t('draftAd.Is_Hamal') : '')"></BlueBox>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import TitleWithIcon from "@/components/Widgets/CustomerPanel/TitleWithIcon.vue";
import BlueBox from "@/components/Widgets/CustomerPanel/BlueBox.vue";
import AdDetailRow from "../MyAds/AdSummary/AdDetailRow.vue";
import Map from "@/components/Widgets/Map/Map.vue";
import VoyageService from "@/services/VoyageService";
import MQTTHelper from "@/Helpers/MQTTHelper";
import { AssetUrls } from "@/Helpers/AssetUrls";

export default {
    name: "CustomerVoyageDetail",

    components: {
        Title,
        TitleWithIcon,
        BlueBox,
        Map,
        AdDetailRow,
    },

    data() {
        return {
            selectedUnloadingAddressId: 0,
            selectedUnloadingAddressOrder: 1,
            vehicleUsers: [],

            voyageService: new VoyageService(),
            voyageId: 0,
            voyage: {},
            load: {},
            vehicleStatus: {},
            loadAddressInfo: {},
            unLoadAddressInfo: {},

            lastLocation: [],

            vehicleLocations: [],

            voyageMarker: {
                vehicleId: 0,
                plateNumber: "",
                position: {
                    lat: 0,
                    lng: 0,
                },
            },

            AssetUrls: AssetUrls,
            mqttHelper: new MQTTHelper(),
        };
    },

    beforeDestroy() {
        this.mqttHelper.destroyConnection();
    },

    async mounted() {
        if (this.$route.params.voyageId != 0 && this.$route.params.voyageId != null) {
            this.voyageId = this.$route.params.voyageId;

            await this.getVoyageDetail();

            this.getVehicleLocations();
        } else {
            this.$router.push("/customer/voyages");
        }
    },

    methods: {
        getImgPath(imgName) {
            try {
                return require(AssetUrls.USER_ASSETS_URL + "/" + imgName);
            } catch (error) {
                return require("@/assets/images/transporter-area/dirvers/driver.png");
            }
        },

        handleImageError(event) {
            event.target.src = require(`@/assets/images/img-not-found.avif`);
        },

        async getVoyageDetail() {
            await this.voyageService.getVoyageDetailForCustomerUserGuid(this.voyageId);

            if (this.voyageService.resultModel.code == 14) {
                this.voyage = this.voyageService.resultModel.data;

                console.log(this.voyage);

                this.load = this.voyage.loads;
                this.vehicleStatus = this.voyage.vehicleUsers;

                this.loadAddressInfo = this.voyage.loads.loadingAddress;
                this.unLoadAddressInfo = this.voyage.loads.unLoadingAddresses[this.voyage.loads.unLoadingAddresses.length - 1];

                this.selectedUnloadingAddressId = this.voyage.loads.unLoadingAddresses[0].id;
                this.selectedUnloadingAddressOrder = this.voyage.loads.unLoadingAddresses[0].order;
            } else {
                this.$toast.success(this.$t("resultModel." + this.voyageService.resultModel.message));
            }

            if (this.voyageService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        selectUnloadingAddress(id, order) {
            //console.log(id, order);
            let data = this.load.unLoadingAddresses;
            this.selectedUnloadingAddressId = id;
            this.selectedUnloadingAddressOrder = order;
        },

        getVehicleLocations() {
            this.mqttHelper.subscription.topic = "voyages/" + this.voyageId + "/#";

            this.mqttHelper.createConnection();

            this.mqttHelper.doSubscribe();

            this.mqttHelper.getMqttMessage((topic, newMessage) => {
                // console.log("Topic => " + topic);
                // console.log("Gelen Mesaj => " + newMessage);

                let messageArray = newMessage.toString().split("#");

                let indexOfVehicleLocation = this.vehicleLocations.findIndex((x) => x.vehicleId == parseInt(messageArray[8]));

                if (indexOfVehicleLocation == -1) {
                    let vehicleLocation = {
                        vehicleId: 0,
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    };

                    vehicleLocation.vehicleId = parseInt(messageArray[8]);
                    vehicleLocation.plateNumber = messageArray[0];
                    vehicleLocation.position.lat = parseFloat(messageArray[2]);
                    vehicleLocation.position.lng = parseFloat(messageArray[3]);

                    this.vehicleLocations.push(vehicleLocation);
                } else {
                    let vehicleLocation = {
                        vehicleId: 0,
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    };

                    vehicleLocation.vehicleId = parseInt(messageArray[8]);
                    vehicleLocation.plateNumber = messageArray[0];
                    vehicleLocation.position.lat = parseFloat(messageArray[2]);
                    vehicleLocation.position.lng = parseFloat(messageArray[3]);

                    this.$set(this.vehicleLocations, indexOfVehicleLocation, vehicleLocation);
                }
            });
        },
    },
};
</script>

<style>
.selected-voyage-vehicle {
    border: 1px solid #26b198;
}
</style>