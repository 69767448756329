import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class VoyageService {
    resultModel = new ResultModel();

    errorResult = "";

    async getVoyagesByTransporterUserGuid(voyageStatus) {

        await apiHelper.get(APIUrls.GET_VOYAGES_BY_TRANSPORTER_USER_GUID_WITH_STATUS, {
            status: voyageStatus,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getVoyageVehicleUsersWithVoyageId(voyageId) {
        await apiHelper.get(APIUrls.GET_VOYAGE_VEHICLE_USERS_WITH_VOYAGE_ID, {
            voyageId: voyageId,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getVoyageDetailForTransporter(voyageId) {

        await apiHelper.get(APIUrls.GET_VOYAGE_DETAIL_FOR_TRANSPORTER_USER, {
            voyageId: voyageId,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getVoyagesByCustomerUserGuidWithStatus(voyageStatus) {

        await apiHelper.get(APIUrls.GET_VOYAGES_BY_CUSTOMER_USER_GUID_WITH_STATUS, {
            status: voyageStatus,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getVoyageDetailForCustomerUserGuid(voyageId) {
        await apiHelper.get(APIUrls.GET_VOYAGE_DETAIL_FOR_CUSTOMER_USER, {
            voyageId: voyageId,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };
}