export class ResultModel {
    code = 0;
    message = "";
    data = {};
}

export class FilterDto {
    IsLatest = false;
    Page = 0;
    Search = "";
    Take = 0;
    IsActive = true;
}

export class LoadFeaturesInfo {
    decimeter = 0;
    isFlammable = false;
    isHamal = false;
    isStacked = false;
    ldmLoadingMeter = 0;
    loadItemTypeId = 0;
    loadItemTypeName = "";
    loadTonnageRange = "";
    loadTonnageRangeId = 0;
    loadTypeId = 0;
    loadTypeName = "";
    loadingMethodId = 0;
    loadingMethodName = "";
    value = 0;
    volume = 0;
}

export class Currency {
    id = 0;
    name = "";
    code = "";
}

export class UnloadingLoadingAddress {
    id = 0;
    apartmentNumber = 0;
    buildingNumber = 0;
    cityName = "";
    countryName = "";
    deliveredPersonEmail = "";
    deliveredPersonName = "";
    deliveredPersonPhone = "";
    description = "";
    direction = "";
    districtName = "";
    earliestDate = "";
    floorNumber = 0;
    isDraft = true;
    latestDate = "";
    note = "";
    postalCode = "";
    sequence = 0;
    title = "";
}

export class CreateOrUpdateVehicleModel {
    id = 0;
    brandName = "";
    licencePlate = "";
    modelYear = "";
    vehicleTypeName = "";
    vehicleTypeId = 0;
    vehicleBodyTypeName = "";
    vehicleBodyTypeId = 0;
    vehicleBoydFloorTypeName = "";
    bodyFloorTypeId = 0;
    netWight = 0;
    isAdr = true;
    caseFeatures = "";
    insuranceExpiryDate = "";
    licensePageFront = undefined;
    licensePageBack = undefined;
    insurancePolicy = undefined;
    kDocument = undefined;    
    workingStatus = true;
}