<template>
    <div class="dp-flex-column justify-content-between panel-content mr-y1 mb-y1 flex-wrap-24">
        <div class="dp-flex-row justify-content-start items-center width-100">
            <img :src="AssetUrls.COMPANY_ASSETS_URL + load.companyLogo" @error="handleImageError" alt="company-profile-photo" style="width: 50px; height: 50px; border-radius: 50%;">

            <div class="font-size-20 text-bold ml-y3">
                {{ load.companyTitle }}
            </div>
        </div>

        <div class="dp-flex-row justify-content-between mt-y3">
            <div class="dp-flex-column justify-content-start">
                <div v-if="load.title.length < 25" class="font-size-20 color-0D3CA5">
                    {{ load.title.substring(0, 25) }}
                </div>

                <div v-else class="font-size-20 color-0D3CA5">
                    {{ load.title.substring(0, 25) + '...' }}
                </div>
            </div>
        </div>

        <div class="dp-flex-row justify-content-between items-center mt-y3">
            <div class="font-size-14 color-869094">
                {{ $t('transporterLoads.Avarage_Price') }}
            </div>
            <div class="font-size-20">
                {{ load.averagePrice != null ? load.averagePrice : 0 }} {{ load.currency }}
            </div>
        </div>

        <div class="loads-item-location dp-flex-column justify-content-between mt-mb-y25">
            <div class="dp-flex-row justify-content-between width-100">
                <div class="dp-flex-column">
                    <div class="font-size-14 text-medium">
                        {{ load.loadAddressInfo.cityName }}
                    </div>

                    <div class="font-size-12 color-869094">
                        {{ load.loadAddressInfo.distrcitName }}
                    </div>
                </div>
                <div class="dp-flex-column justify-content-center items-center">
                    <!-- <div class="font-size-12">{{cityDistance}} km</div> -->
                    <div class="dp-flex-row justify-content-center">
                        <i class="fa-solid fa-arrow-right"></i>
                    </div>
                </div>
                <div class="dp-flex-column">
                    <div class="font-size-14 text-medium">
                        {{ load.unLoadAddressInfo[load.unLoadAddressInfo.length - 1].cityName }}
                    </div>

                    <div class="font-size-12 color-869094">
                        {{ load.unLoadAddressInfo[load.unLoadAddressInfo.length - 1].distrcitName }}
                    </div>
                </div>
            </div>
            <div class="dp-flex-row justify-content-start mt-mb-5 width-100">
                <h4 class="font-size-13 mr-y1">
                    {{ load.loadAddressInfo.cityName }}
                </h4>

                <h4 :key="index" v-for="(item, index) in load.unLoadAddressInfo" class="font-size-13 mr-y1">
                    - {{ item.cityName }}
                </h4>
            </div>
        </div>

        <div class="dp-flex-row justify-content-start mt-y5">
            <div class="dp-flex-row width-50 justify-content-start">
                <p class="font-size-12">
                    {{ $t('transporterLoads.Weight') }}: {{ load.range }}
                </p>
            </div>
            <!-- <div class="dp-flex-row width-50 justify-content-start">
                <p class="font-size-12">
                    Yüke Uzaklık: {{loadDistance}} Km
                </p>
            </div> -->
        </div>
    </div>
</template>

<script>
import { AssetUrls } from "@/Helpers/AssetUrls";

export default {
    name: "LoadItem",

    props: ["load"],

    data() {
        return {
            AssetUrls: AssetUrls,
        };
    },

    mounted() { },

    methods: {
        handleImageError(event) {
            event.target.src = require(`@/assets/images/img-not-found.avif`);
        },
    },
};
</script>

<style></style>