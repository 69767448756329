<template>
    <div class='dp-flex-row'>
        <div class='bg-login-register-pages items-right dp-flex-column'>
            <div class="blur complate-registration-layout-blur">

            </div>

            <img src='@/assets/images/introduction-area/complete-registration/container.png' alt='Mask Group 19.png' />

            <div class='complete-registration-layout-icon-left dp-flex-column items-right'>

                <figure>
                    <img src='@/assets/images/introduction-area/login/Group 1385.svg' alt="loginPageIconSvg" />
                </figure>
                <!-- <h2>
					{{$t("layout-register-login-page.Title")}}
				</h2>
				<p>{{$t("layout-register-login-page.Desc")}}</p> -->
            </div>
        </div>

        <slot />

    </div>
</template>

<script>
export default {
    name: "LayoutCompleteRegistration",
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
@import "@/assets/css/introduction-area/complate-registration.css";
</style>