<template>
    <label v-bind:class="{'text-bold':isActive }" @change="toggleCheckbox" class="blue-checkbox-container dp-flex-row items-end tex text-underline font-size-12 justify-content-start">
        <input type="checkbox" name="radio">
        <span class="blue-checkbox-checkmark mr-15"></span>
        İstiflenebilir
    </label>
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
        };
    },
    methods: {
        toggleCheckbox() {
            this.isActive = !this.isActive;
            console.log(this.isActive);
        },
    },
};
</script>

<style>
@import url("@/assets/css/widgets/customer-panel/blue-checkbox.css");
</style>