<template>
    <div class="">
        <div class="dp-flex-column statistics-content">
            <div class="panel-content-title">
                <h2>
                    {{$t('customer-dashboard-page.Statistic_Title')}}
                </h2>
            </div>
            <div class="dp-flex-row">
                <YourAdds>

                </YourAdds>
                <PaymentInfo>

                </PaymentInfo>
                <DistancePerMonth>

                </DistancePerMonth>
            </div>
            <div class="dp-flex-row mt-mb-15">
                <StatisticsInfo :title="'Teslimatlar'" :value="monthlyVoyagesCount" :month="currentMonthName"></StatisticsInfo>
                <StatisticsInfo :title="'Araç Talep'" :value="monthlyLoadVehiclesCount" :month="currentMonthName"></StatisticsInfo>
            </div>
        </div>
    </div>
</template>

<script>
import YourAdds from "./Statistics/YourAdds.vue";
import PaymentInfo from "./Statistics/PaymentInfo.vue";
import DistancePerMonth from "./Statistics/DistancePerMonth.vue";
import StatisticsInfo from "./Statistics/StatisticsInfo.vue";
import StatisticsInfo1 from "./Statistics/StatisticsInfo.vue";
import { getMonthName } from "@/Helpers/DateTimeHelper";
import StatisticService from "@/services/StatisticService";

export default {
    components: {
        YourAdds,
        PaymentInfo,
        DistancePerMonth,
        StatisticsInfo,
        StatisticsInfo1,
    },

    data() {
        return {
            monthlyVoyagesCount: 0,
            monthlyLoadVehiclesCount: 0,
            currentMonthName: "",
            statisticService: new StatisticService(),
        };
    },

    async mounted() {
        await this.getMonthlyVoyagesCount();
        await this.getMonthlLoadVehiclesCount();
        this.currentMonthName = getMonthName(new Date().getMonth() + 1);
    },

    methods: {
        getMonthName,

        async getMonthlyVoyagesCount() {
            await this.statisticService.getMonthlyVoyagesCount(new Date().getMonth() + 1, 4, true);

            if (this.statisticService.resultModel.code == 14) {
                this.monthlyVoyagesCount = this.statisticService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.statisticService.resultModel.message));
            }

            if (this.statisticService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getMonthlLoadVehiclesCount() {
            await this.statisticService.getMonthlyLoadVehiclesCount(new Date().getMonth() + 1, true);

            if (this.statisticService.resultModel.code == 14) {
                this.monthlyLoadVehiclesCount = this.statisticService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.statisticService.resultModel.message));
            }

            if (this.statisticService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/customer-dashboard/statistics.css";
</style>