<template>
  <div class="dp-flex-column">
    <Title :title="$t('profile.Title')"></Title>

    <div class="dp-flex-row justify-content-start">
      <div class="dp-flex-column justify-content-between profile-content width-25">
        <Title class="profile-title" :title="$t('profile.Profile_Edit_Title')">
          <template slot="title-icon">
            <i class="fa-solid fa-user font-size-16 color-667085 mr-y5"></i>
          </template>
        </Title>
        <div class="dp-flex-column items-center edit-profile-user-info">
          <img v-if="user.profilePhoto != null" class="width-30" :src="AssetUrls.USER_ASSETS_URL + user.profilePhoto" alt="profile-picture" />
          <div class="dp-flex-column mt-y3 items-center ml-y1">
            <h3 class="font-size-20">
              {{ user.name }}
            </h3>
            <button class="font-size-12 color-6098FB btn-none text-semi-bold">
              {{ $t("profile.ChangeProfilePhoto") }}
            </button>
          </div>
        </div>
        <div class="dp-flex-column justify-content-between">
          <AddressLabelWithInput v-model="user.name" class="mt-y3" :label="$t('profile.Name')" :name="'name'" :placeHolder="user.name" :type="'text'"></AddressLabelWithInput>
          <AddressLabelWithInput v-model="user.surname" class="mt-y3" :label="$t('profile.SurName')" :name="'name'" :placeHolder="user.surname" :type="'text'"></AddressLabelWithInput>
          <AddressLabelWithInput v-model="user.email" class="mt-y3" :label="$t('profile.Email')" :name="'name'" :placeHolder="user.email" :type="'text'"></AddressLabelWithInput>
          <AddressLabelWithInput v-model="user.phone" class="mt-y3" :label="$t('profile.MobilePhone')" :name="'name'" :placeHolder="user.phone" :type="'text'"></AddressLabelWithInput>
        </div>
        <div class="dp-flex-row justify-content-center mt-y5 items-center">
          <button @click="updateCustomerUserProfile()" class="btn-none width-60 p-y5 continue-btn font-size-14 items-center">
            {{ $t("button.Save_To_Changes") }}
          </button>
        </div>
      </div>

      <div class="dp-flex-column justify-content-start profile-content width-25">
        <Title class="profile-title" :title="$t('profile.Change_Password_Title')">
          <template slot="title-icon">
            <i class="fa-solid fa-lock font-size-16 color-667085 mr-y5"></i>
          </template>
        </Title>

        <AddressLabelWithInput v-model="oldPassword" :label="$t('profile.Password')" :name="'password'" :placeHolder="$t('profile.Password')" :type="'password'"></AddressLabelWithInput>

        <div class="dp-flex-row mt-mb-15 justify-content-end">
          <p class="font-size-14 color-D01438">
            {{ $t("profile.Forgot_Password") }}
          </p>
        </div>

        <AddressLabelWithInput v-model="newPassword" class="mt-y3" :label="$t('profile.Password_Label')" :name="'password'" :placeHolder="$t('profile.NewPassword')" :type="'password'"></AddressLabelWithInput>

        <AddressLabelWithInput v-model="newPasswordAgain" class="mt-y3" :label="$t('profile.Password_Again_Label')" :name="'password'" :placeHolder="$t('profile.NewPassword')" :type="'password'"></AddressLabelWithInput>

        <div class="dp-flex-row justify-content-center mt-y5 items-center">
          <button @click="changePassword()" class="btn-none width-60 p-y5 continue-btn font-size-14 items-center">
            {{ $t("button.Save_To_Changes") }}
          </button>
        </div>
      </div>

      <div class="dp-flex-column justify-content-start profile-content width-25">
        <Title class="profile-title" :title="$t('profile.Notification_Settings')">
          <template slot="title-icon">
            <i class="fa-solid fa-bell font-size-16 color-667085 mr-y5"></i>
          </template>
        </Title>
        <p class="font-size-14 text-bold">Uygulama</p>
        <div class="dp-flex-column mt-y3 justify-content-start profile-notif-settings-content">
          <GreenCheckbox v-model="notificationSettings.isAppNotification" class="width-100 profile-notif-settings-item justify-content-between">
            <template slot="label">
              <label for="checkbox_green" class="font-size-14 mr-y1">
                Uygulama Bildirimleri
              </label>
            </template>
          </GreenCheckbox>

          <GreenCheckbox v-model="notificationSettings.isVoyageNotification" class="width-100 profile-notif-settings-item justify-content-between">
            <template slot="label">
              <label for="checkbox_green" class="font-size-14 mr-y1">
                Sefer Bildirimleri
              </label>
            </template>
          </GreenCheckbox>

          <GreenCheckbox v-model="notificationSettings.isOfferNotification" class="width-100 profile-notif-settings-item justify-content-between">
            <template slot="label">
              <label for="checkbox_green" class="font-size-14 mr-y1">
                Teklif Bildirimleri
              </label>
            </template>
          </GreenCheckbox>

          <GreenCheckbox v-model="notificationSettings.isMessageNotification" class="width-100 profile-notif-settings-item justify-content-between">
            <template slot="label">
              <label for="checkbox_green" class="font-size-14 mr-y1">
                Mesaj Bildirimleri
              </label>
            </template>
          </GreenCheckbox>
        </div>
        <div class="dp-flex-row justify-content-center mt-y5 items-center">
          <button @click="saveNotificationSettings()" class="btn-none width-60 p-y5 continue-btn font-size-14 items-center">
            {{ $t("button.Save_To_Changes") }}
          </button>
        </div>
      </div>

      <div class="dp-flex-column justify-content-start profile-content width-25">
        <Title class="profile-title" :title="'Uygulama Dili'">
          <template slot="title-icon">
            <i class="fa-solid fa-language font-size-16 color-667085 mr-y5"></i>
          </template>
        </Title>
      </div>
    </div>
  </div>
</template>

<script>
// TODO: uygulama dili kısmı eklenecek.
// TODO: profil fotoğrafı seçme ve getirme ve güncelleme işlemleri eklenecek.
// TODO: api işlemlerine loading atılacak.

import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import AddressLabelWithInput from "../Addresses/LoadingPoint/AddressLabelWithInput.vue";
import LabelWithInputWithButtonRow from "@/components/Widgets/Inputs/LabelWithInputWithButtonRow.vue";
import GreenCheckbox from "@/components/Widgets/CustomerPanel/GreenCheckbox.vue";
import { AssetUrls } from "@/Helpers/AssetUrls";
import ProfileService from "@/services/ProfileService";

export default {
  components: {
    Title,
    AddressLabelWithInput,
    LabelWithInputWithButtonRow,
    GreenCheckbox,
  },

  data() {
    return {
      user: {},
      AssetUrls: AssetUrls,
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: "",
      notificationSettings: {},
      profileService: new ProfileService(),
    };
  },

  async mounted() {
    this.user = await this.profileService.getTransporterUserByGuid();

    this.notificationSettings =
      await this.profileService.getNotificationSettingsByUserId();
  },

  methods: {
    saveNotificationSettings() {
      this.profileService.createOrUpdateNotificationSetting(
        this.notificationSettings
      );
    },

    updateCustomerUserProfile() {
      this.profileService.updateCustomerUserProfile(this.user);
    },

    changePassword() {
      if (
        this.oldPassword != "" &&
        this.newPassword != "" &&
        this.newPasswordAgain != ""
      ) {
        if (this.newPassword != this.newPasswordAgain) {
          this.$toast.warning(
            this.$t("profile.Password_Password_Again_Not_Same")
          );
        } else if (this.newPassword.length < 6) {
          this.$toast.warning(
            this.$t("profile.Password_Must_Be_Longer_Then_Six_Character")
          );
        } else {
          this.profileService.changePassword(
            this.oldPassword,
            this.newPassword
          );
        }
      } else {
        this.$toast.warning(this.$t("resultModel.Model_Invalid"));
      }
    },
  },
};
</script>

<style>
@import url("@/assets/css/customer-area/settings-components/profile.css");
</style>