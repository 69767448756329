<template>
    <div class="dp-flex-row justify-content-end">
        <div class="dp-flex-column width-100 items-end">
            <label v-if="isTimeActive" class="font-size-12 color-667085">
                {{time}}
            </label>
            <p v-if="text != null || text != 'null'" v-bind:class="{'border-radius-bottom-right-none':whichMessage == 'first', 'border-radius-top-right-none':whichMessage == 'last', 'border-radius-bottom-right-none border-radius-top-right-none':whichMessage == 'middle'}" class="sending-message-box message-box font-size-16">
                {{text}}
            </p>
            <img class="message-img" v-if="fileUrl != null" :src="fileUrl" />
        </div>
    </div>
</template>

<script>
export default {
    name: "SendingMessageBox",

    props: ["time", "text", "isTimeActive", "whichMessage", "fileUrl"],

    mounted() {
        //console.log(this.text);
        // console.log(this.fileUrl);
    },
};
</script>

<style>
.message-img{
    width: 50%;
}
</style>