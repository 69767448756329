<template>
    <div class="dp-flex-row justify-content-start">
        <div class="dp-flex-column width-100 items-left">
            <label v-if="isTimeActive" class="font-size-12 color-667085">
                {{time}}
            </label>
            <p v-if="text != null" v-bind:class="{'border-radius-bottom-left-none':whichMessage == 'first', 'border-radius-top-left-none':whichMessage == 'last', 'border-radius-bottom-left-none border-radius-top-left-none':whichMessage == 'middle'}" class="incoming-message-box message-box font-size-16">
                {{text}}
            </p>
            <img class="message-img mt-mb-y1" v-if="fileUrl != null" :src="fileUrl" />
        </div>
    </div>
</template>

<script>
export default {
    name: "IncomingMessageBox",
    props: ["time", "text", "isTimeActive", "whichMessage", "fileUrl"],
    mounted(){
        console.log(this.fileUrl);
    },
};
</script>

<style>
.message-img {
    width: 50%;
}
</style>