<template>
    <div class="dp-flex-column">
        <Title :title="$t('packages.Title')"></Title>

        <div class="dp-flex-row width-100">
            <div class="dp-flex-column justify-content-between items-center p-y5 border-radius-5 jus packages-info-content width-30">
                <p class="font-size-20 text-center">
                    {{$t("packages.Desc")}}
                </p>
                <p class="font-size-30 text-center text-color-white">
                    <!-- {{$t("packages.EndDate")}} -->
                    Paket Bitiş Tarihi
                    <br>
                    <span class="font-size-30 text-color-white text-semi-bold"> 30.05.2022 </span>
                </p>
                <div class="packages-info-date-time width-60 dp-flex-column border-radius-10 justify-content-center ">
                    <p class="font-size-70 text-center text-bold">
                        28
                    </p>
                    <p class="font-size-20 text-center">
                        Gün
                    </p>
                </div>
            </div>
            <div class="width-70 ml-y1 dp-flex-column items-center packages-select-content">
                <h3 class="font-size-30 text-center mt-y5 text-color-white">
                    {{$t("packages.ViewPackages")}}
                </h3>
                <div class="dp-flex-row dp-flex-wrap width-80 mt-y3 justify-content-center packages-select-items">
                    <div class="dp-flex-column packages-select-item flex-wrap-31">
                        <div class="packages-item-count">
                            <h3 class="font-size-16 text-semi-bold">
                                Paket-2
                            </h3>
                            <h3 class="font-size-25 text-bold font-style-italic">
                                2 {{$t("packages.Transportation")}}
                            </h3>
                        </div>
                        <div class="packages-item-price">
                            <p class="font-size-20 text-center text-bold">
                                30
                                <span>TL</span>
                                <span class="font-size-9"> 
                                    {{$t("packages.TaxIncluded")}}
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="dp-flex-column packages-select-item ml-y1 flex-wrap-31">
                        <div class="packages-item-count">
                            <h3 class="font-size-16 text-semi-bold">
                                Paket-2
                            </h3>
                            <h3 class="font-size-25 text-bold font-style-italic">
                                2 Taşıma
                            </h3>
                        </div>
                        <div class="packages-item-price">
                            <p class="font-size-20 text-center text-bold">
                                30
                                <span>TL</span>
                                <span class="font-size-9"> KDV Dahil</span>
                            </p>
                        </div>
                    </div>
                    <div class="dp-flex-column packages-select-item ml-y1 flex-wrap-31">
                        <div class="packages-item-count">
                            <h3 class="font-size-16 text-semi-bold">
                                Paket-2
                            </h3>
                            <h3 class="font-size-25 text-bold font-style-italic">
                                2 Taşıma
                            </h3>
                        </div>
                        <div class="packages-item-price">
                            <p class="font-size-20 text-center text-bold">
                                30
                                <span>TL</span>
                                <span class="font-size-9"> KDV Dahil</span>
                            </p>
                        </div>
                    </div>
                    <div class="dp-flex-column packages-select-item ml-y1 flex-wrap-31">
                        <div class="packages-item-count">
                            <h3 class="font-size-16 text-semi-bold">
                                Paket-2
                            </h3>
                            <h3 class="font-size-25 text-bold font-style-italic">
                                2 Taşıma
                            </h3>
                        </div>
                        <div class="packages-item-price">
                            <p class="font-size-20 text-center text-bold">
                                30
                                <span>TL</span>
                                <span class="font-size-9"> KDV Dahil</span>
                            </p>
                        </div>
                    </div>
                    <div class="dp-flex-column packages-select-item ml-y1 flex-wrap-31">
                        <div class="packages-item-count">
                            <h3 class="font-size-16 text-semi-bold">
                                Paket-2
                            </h3>
                            <h3 class="font-size-25 text-bold font-style-italic">
                                2 Taşıma
                            </h3>
                        </div>
                        <div class="packages-item-price">
                            <p class="font-size-20 text-center text-bold">
                                30
                                <span>TL</span>
                                <span class="font-size-9"> KDV Dahil</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dp-flex-row justify-content-center mt-y5 items-center">
            <button class="font-size-14 text-color-white border-radius-5 btn-none packages-go-details-button">
                {{$t("packages.GoPackageDetails")}}
            </button>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
@import url("@/assets/css/customer-area/packages/packages.css");
</style>