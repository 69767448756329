<template>
    <header class="width-100">

        <slot name="banner-block"></slot>

        <div class="panel-header-menu dp-flex-row justify-content-end">

            <slot name="header-menu-items">

            </slot>

            <div class="customer-panel-menu-item">
                <button @click="isOpenSendBugPopup = true" class="btn-none header-menu-icon fa-solid fa-bug"></button>
            </div>

            <!-- <div class="customer-panel-menu-item header-search-bar-content">
                <i class="header-menu-icon fa-solid fa-magnifying-glass"></i>
                <div class="header-search-bar-hover-content p-y1 position-absolute border-radius-5 dp-flex-row justify-content-between items-center">
                    <i class="fa-solid color-929DA7 position-absolute font-size-15 fa-magnifying-glass"></i>
                    <input class="width-100 font-size-15 header-search-bar-input" placeholder="Ara..." type="text">
                </div>
            </div> -->

            <div class="customer-panel-menu-item notification-content">
                <i class="header-menu-icon fa-regular fa-bell"></i>
                <div class="notification-hover-content dp-flex-column position-absolute border-radius-5 width-20">
                    <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                    <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                    <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                    <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                </div>
            </div>

            <div class="customer-panel-menu-item header-language-content">
                <i class="header-menu-icon fa-solid fa-globe"></i>
                <div class="header-language-hover-content position-absolute border-radius-5 p-y1">
                    <div class="dp-flex-column">
                        <div class="dp-flex-row dp-flex-wrap justify-content-start">
                            <button class="flex-wrap-33 color-929DA7 p-y3 font-size-13 btn-none" v-bind:class="{'text-bold': activeLanguage == 'tr'}" @click="changeLanguage('tr')">
                                Türkçe
                            </button>
                            <button class="flex-wrap-33 color-929DA7 p-y3 font-size-13 btn-none" v-bind:class="{'text-bold': activeLanguage == 'en'}" @click="changeLanguage('en')">
                                İngilizce
                            </button>
                            <!-- <button class="flex-wrap-33 color-929DA7 p-y3 font-size-13 btn-none" v-bind:class="{'text-bold': activeLanguage == 'en'}" @click="changeLanguage('en')">
                                almanca
                            </button> -->
                            <button class="flex-wrap-33 color-929DA7 p-y3 font-size-13 btn-none" v-bind:class="{'text-bold': activeLanguage == 'ro'}" @click="changeLanguage('ro')">
                                Romence
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="customer-panel-menu-item header-user-content">
                <i class="header-menu-icon header-user-hover-icon fa-regular fa-circle-user"></i>
                <div class="header-user-hover-content position-absolute border-radius-5">
                    <div class="dp-flex-column">
                        <div @click="goToProfilePage()" class="dp-flex-row header-user-item btn-none items-center">
                            <i class="fa-solid font-size-15 color-929DA7 fa-user"></i>
                            <p class="font-size-15 ml-15">{{nameSurname}}</p>
                        </div>
                        <div class="dp-flex-row header-user-item items-center">
                            <i class="fa-solid font-size-15 color-929DA7  fa-arrow-right-from-bracket"></i>
                            <p class="font-size-15 ml-y4" @click="logout()">
                                {{$t('complete-registration-page.CompleteRegistrationLogoutButton')}}                                
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <TransactionPopup v-if="isOpenSendBugPopup" @closePopup="isOpenSendBugPopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center font-size-20 text-color-white">
                    HATA BİLDİR
                </p>
            </template>

            <template slot="popup-body">
                <div style="width:35vw!important" class="dp-flex-column justify-content-between items-center">
                    <p class="text-center font-size-14 mt-mb-y25 color-667085">
                        Bu alanda uygulama üzerinde gördüğünüz hata ve sorunları, resmi ve açıklaması ile bizlere iletebilirsiniz. Sorununuzu en kısa zamanda çözeceğimize emin olabilirsiniz.
                    </p>

                    <div class="dp-flex-row justify-content-start items-center width-100">
                        <label class="font-size-15 text-bold" :class="bugImage == undefined ? 'text-color-red' : ''">
                            Hatanın Resmini Yükle *
                        </label>

                        <input @change="previewFiles($event)" class="ml-y4" type="file">
                    </div>

                    <div class="dp-flex-column justify-content-between mt-y3 width-100">
                        <label class="font-size-15 text-bold" :class="bugDescription == '' ? 'text-color-red' : ''">
                            Hatanın Açıklaması *
                        </label>
                        <textarea v-model="bugDescription" class="width-100" rows="4" cols="10"></textarea>
                    </div>
                </div>
            </template>

            <template slot="popup-body-button">
                <button @click="sendBug($event)" class="dp-flex-row btn-none mt-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                    Hata Bildir
                </button>
            </template>
        </TransactionPopup>

        <NotificationPopup v-if="isActiveNotif" :title="notifTitle" :description="notifDesc" :type="notifType"></NotificationPopup>
    </header>
</template>

<script>
import NotificationRow from "@/components/Widgets/Header/NotificationRow.vue";
import NotificationPopup from "./NotificationPopup.vue";
import { APIUrls } from "@/Helpers/APIUrls";
import BugService from "@/services/BugService";
import TransactionPopup from "@/components/Widgets/ModelPopup/TransactionPopup.vue";

export default {
    name: "CustomerPanelHeader",

    components: {
        NotificationPopup,
        NotificationRow,
        TransactionPopup,
    },

    data() {
        return {
            isActive: "",
            activeLanguage: localStorage.getItem("lng"),
            isActiveNotif: false,
            notifTitle: "Ahmetin Paketi Bitmek Üzere",
            notifDesc: "Detaylı Bilgi İçin Dokunun",
            notifType: "danger",
            nameSurname: "",
            isOpenSendBugPopup: false,
            bugImage: undefined,
            bugDescription: "",
            bugService: new BugService(),
        };
    },

    async beforeCreate() {
        await this.axios({
            url: APIUrls.CUSTOMER_GET_USER_PROFILE,
            methods: "GET",
        })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.code == 14) {
                        // console.log(response.data);
                        this.nameSurname = response.data.data.name + " " + response.data.data.surname;
                    } else {
                        this.$toast.warning("Kullanıcı Bilgileri Çekilemedi !");
                    }
                } else {
                    this.$toast.warning("Kullanıcı Bilgileri Çekilemedi !");
                }
            })
            .catch((error) => {
                this.$toast.error("Kullanıcı Bilgileri Çekilemedi !");
            });
    },

    async mounted() {},

    methods: {
        changeLanguage(language) {
            this.activeLanguage = language;
            localStorage.setItem("lng", language);
            window.location.reload();
        },

        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("guid");
            localStorage.removeItem("userType");

            this.$router.push({ name: "Home" });

            // TODO: logout api'sine istek atılacak.
        },

        goToProfilePage() {
            this.$router.push("/customer/profile");
        },

        previewFiles(event) {
            this.bugImage = event.target.files[0];
        },

        async sendBug(e) {
            if (this.bugImage != undefined && this.bugDescription != "") {
                await this.bugService.createOrUpdateBug(0, this.bugDescription, this.bugImage);

                e.target.classList.remove("button--loading");

                if (this.bugService.resultModel.code == 221 || this.bugService.resultModel.code == 222) {
                    this.$toast.success(this.$t("resultModel." + this.bugService.resultModel.message));
                    this.isOpenSendBugPopup = false;
                } else {
                    this.$toast.error(this.$t("resultModel." + this.bugService.resultModel.message));
                }

                if (this.bugService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            }
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/_layout-customer-panel.css";
</style>