<template>
    <div class="dp-flex-column">
        <Title :title="addressTypeId == 2 ? $t('new-address.Unloading_Point_Title') : $t('new-address.Title')" :description="addressTypeId == 2 ? $t('Unloading_Point_Description') : $t('new-address.Desc')"> </Title>

        <div class="dp-flex-column">
            <div class="registered-address-content">
                <h4 class="font-size-14 text-medium">
                    {{ $t("new-address.RegisteredAddressesInfo") }}
                </h4>
            </div>
        </div>

        <div class="panel-content mt-mb-y25">
            <ValidationObserver ref="form">
                <form class="dp-flex-column" method="post">
                    <div class="dp-flex-column">
                        <p class="font-size-16 color-667085">
                            {{ $t("new-address.AddressInfo") }}
                        </p>
                        <div class="dp-flex-row width-100">
                            <div class="dp-flex-column width-50">
                                <!-- <AddresssLabelWithInput v-model="addressTypeId" :type="'select'" :placeHolder="'Adres Tipini Seçin'" :selectItems="getAddressTypeSelectItems()">
                                </AddresssLabelWithInput> -->

                                <label class="font-size-14 mt-mb-5 text-bold"></label>
                                <!-- <ValidationProvider class="vee-validate-container" rules="required" v-slot="{ errors }"> -->
                                <!-- @change="selectAddressType" -->
                                <select v-model="addressTypeId" class="new-addres-input" disabled>
                                    <option class="color-667085" value="0">Adres Tipini Seçin</option>
                                    <option class="color-667085" value="1">
                                        {{ $t("new-address.Title") }}
                                    </option>
                                    <option class="color-667085" value="2">
                                        {{ $t("new-address.Unloading_Point_Title") }}
                                    </option>
                                </select>
                                <!-- <span class="vee-validate-error-message">{{ errors[0] }}</span>
                                </ValidationProvider> -->
                            </div>

                            <AddresssLabelWithInput v-model="title" :rules="'required'" :name="'Address Title'" class="width-50 ml-y1" :type="'text'" :placeHolder="$t('new-address.AddressTitle')"> </AddresssLabelWithInput>
                        </div>
                        <div v-if="!getIsComingFromDraft" class="dp-flex-row justify-content-end p-y1 items-center">
                            <p class="font-size-13 text-semi-bold m-y1">
                                {{ $t("new-address.SaveAddressAsDraft") }}
                            </p>
                            <GreenCheckbox @input="greenCheckboxEvent"></GreenCheckbox>
                        </div>
                    </div>
                    <div class="dp-flex-column mt-y3">
                        <p class="font-size-16 color-667085">
                            {{ $t("new-address.AddressDetails") }}
                        </p>
                        <div class="dp-flex-row">
                            <AddresssLabelWithInput v-model="direction" :readonly="true" :name="$t('new-address.Address')" class="width-50" :type="'text'" :label="$t('new-address.Address')" :placeHolder="$t('new-address.AddressPlaceHolder')"> </AddresssLabelWithInput>

                            <AddresssLabelWithInput v-model="description" :rules="'required'" :name="$t('new-address.AddressDefination')" class="width-50 ml-y1" :type="'text'" :label="$t('new-address.AddressDefination')" :placeHolder="$t('new-address.AddressDefinationPlaceHolder')"> </AddresssLabelWithInput>
                        </div>
                        <div class="dp-flex-row mt-mb-5">
                            <div class="dp-flex-row width-50">
                                <div class="dp-flex-column width-35">
                                    <label class="font-size-14 mt-mb-5 text-bold">
                                        {{ $t("new-address.Country") }}
                                    </label>

                                    <select v-model="mutableCountry" @change="countrySelectChange()" class="new-addres-input" :class="mutableId == null ? 'red-border' : ''" :disabled="mutableId == null ? false : true">
                                        <option v-if="mutableId != null" :value="mutableCountry" selected>
                                            {{ mutableCountry }}
                                        </option>

                                        <option :key="country.id" v-for="country in countries" :value="{ id: country.id, text: country.name }">
                                            {{ country.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="dp-flex-column width-35 ml-y1">
                                    <label class="font-size-14 mt-mb-5 text-bold">
                                        {{ $t("new-address.City") }}
                                    </label>

                                    <select v-model="mutableCity" @change="citySelectChange()" class="new-addres-input" :class="mutableId == null ? 'red-border' : ''" :disabled="mutableId == null ? false : true">
                                        <option v-if="mutableId != null" :value="mutableCity" selected>
                                            {{ mutableCity }}
                                        </option>

                                        <option :key="city.id" v-for="city in cities" :value="{ id: city.id, text: city.name }">
                                            {{ city.name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="dp-flex-column width-35 ml-y1">
                                    <label class="font-size-14 mt-mb-5 text-bold">
                                        {{ $t("new-address.District") }}
                                    </label>

                                    <select v-model="mutableDistrict" @change="citySelectChange()" class="new-addres-input" :class="mutableId == null ? 'red-border' : ''" :disabled="mutableId == null ? false : true">
                                        <option v-if="mutableId != null" :value="mutableDistrict" selected>
                                            {{ mutableDistrict }}
                                        </option>

                                        <option :key="district.id" v-for="district in districts" :value="{ id: district.id, text: district.name }">
                                            {{ district.name }}
                                        </option>
                                    </select>
                                </div>

                                <!-- <select @change="showValue()" class="width-35" v-model="mutableCountry">
                                    <option :key="country.id" v-for="country in countries" :value="{ id: country.id, text: country.name }">
                                        {{ country.name }}
                                    </option>
                                </select> -->

                                <!-- <AddresssLabelWithInput v-model="mutableCountry" :readonly="true" :name="$t('new-address.Country')" class="width-35" :type="'text'" :label="$t('new-address.Country')"></AddresssLabelWithInput> -->

                                <!-- <AddresssLabelWithInput v-model="mutableCity" :readonly="true" :name="$t('new-address.City')" class="width-35 ml-y1" :type="'text'" :label="$t('new-address.City')"> </AddresssLabelWithInput> -->

                                <!-- <AddresssLabelWithInput v-model="mutableDistrict" :readonly="true" :name="$t('new-address.District')" class="width-35 ml-y1" :type="'text'" :label="$t('new-address.District')"> </AddresssLabelWithInput> -->
                            </div>

                            <div class="dp-flex-row width-50 ml-y1 justify-content-between">
                                <AddresssLabelWithInput v-model="buildingNumber" :rules="'required'" :name="$t('new-address.BuildingNo')" class="width-35" :type="'number'" :label="$t('new-address.BuildingNo')"> </AddresssLabelWithInput>

                                <AddresssLabelWithInput v-model="floorNumber" :rules="'required'" :name="$t('new-address.Floor')" class="width-35 ml-y1" :type="'number'" :label="$t('new-address.Floor')"> </AddresssLabelWithInput>

                                <AddresssLabelWithInput v-model="apartmentNumber" :rules="'required'" :name="$t('new-address.ApartmentNumber')" class="width-35 ml-y1" :type="'number'" :label="$t('new-address.ApartmentNumber')"> </AddresssLabelWithInput>
                            </div>
                        </div>
                    </div>

                    <div class="dp-flex-column mt-mb-y25">
                        <p class="font-size-16 color-667085">
                            {{ $t("new-address.UploadDate") }}
                        </p>
                        <div class="dp-flex-row width-50">
                            <AddresssLabelWithInput v-model="earliesDate" class="width-50" :type="'datetime-local'" :label="$t('new-address.EarliestDate')"> </AddresssLabelWithInput>

                            <AddresssLabelWithInput v-model="latestDate" class="width-50 ml-y1" :type="'datetime-local'" :label="$t('new-address.LatestDate')" :placeHolder="$t('new-address.LatestDatePlaceHolder')"> </AddresssLabelWithInput>

                            <!-- <div class="dp-flex-column ml-y1 width-50">
                                <label class="font-size-14 mt-mb-5 text-bold">
                                    {{$t("new-address.SelectTimeRange")}}
                                </label>

                                <select class="new-addres-input">
                                    <option class="color-667085" value="text">1-2</option>
                                    <option class="color-667085" value="text">2-3 </option>
                                    <option class="color-667085" value="text">4-5</option>
                                    <option class="color-667085" value="text">vb</option>
                                </select>
                            </div> -->
                        </div>
                        <!-- <div class="dp-flex-row justify-content-start width-100 mt-mb-y25">
                            <label class="container">
                                <input @change="toogleLateDate" id="chckbox_to_late_date_time" type="checkbox">
                                <span class="checkmark"></span>
                            </label>
                            <label for="chckbox_to_late_date_time" class="font-size-12 text-semi-bold">
                                {{$t("new-address.ChooseLatestDate")}}
                            </label>
                        </div>
                        <div v-if="isActiveLateDate" class="dp-flex-row width-50">

                            <AddresssLabelWithInput class="width-50" :type="'date'" :label="$t('new-address.LatestDate')" :placeHolder="$t('new-address.LatestDatePlaceHolder')"></AddresssLabelWithInput>

                            <div class="dp-flex-column ml-y1 width-50">
                                <label class="font-size-14 mt-mb-5 text-bold">
                                    {{$t("new-address.SelectTimeRange")}}
                                </label>

                                <select class="new-addres-input">
                                    <option class="color-667085" value="text">1-2</option>
                                    <option class="color-667085" value="text">2-3 </option>
                                    <option class="color-667085" value="text">4-5</option>
                                    <option class="color-667085" value="text">vb</option>
                                </select>
                            </div>
                        </div> -->
                    </div>

                    <div class="dp-flex-column">
                        <p class="font-size-16 color-667085">
                            {{ $t("new-address.DeliveredPerson") }}
                        </p>
                        <div class="dp-flex-row mt-mb-5">
                            <div class="dp-flex-row width-100 justify-content-between">
                                <AddresssLabelWithInput v-model="deliveredPersonName" :rules="'required'" :name="$t('new-address.DeliveredPersonName')" class="width-100" :type="'text'" :label="$t('new-address.DeliveredPersonName')" :placeHolder="$t('new-address.DeliveredPersonNamePlaceHolder')"></AddresssLabelWithInput>

                                <!-- <AddresssLabelWithInput v-model="deliveredPersonSurname" :rules="'required'" :name="$t('new-address.DeliveredPersonSurName')" class="width-100 ml-y1" :type="'text'" :label="$t('new-address.DeliveredPersonSurName')" :placeHolder="$t('new-address.DeliveredPersonSurNamePlaceHolder')"></AddresssLabelWithInput> -->

                                <AddresssLabelWithInput v-model="deliveredPersonEmail" :rules="'required'" :name="$t('new-address.DeliveredPersonEPosta')" class="width-100 ml-y1" :type="'text'" :label="$t('new-address.DeliveredPersonEPosta')" :placeHolder="$t('new-address.DeliveredPersonEPostaPlaceHolder')"></AddresssLabelWithInput>

                                <AddresssLabelWithInput v-model="deliveredPersonPhone" :rules="'required'" :name="$t('new-address.DeliveredPersonPhone')" class="width-100 ml-y1" :type="'text'" :label="$t('new-address.DeliveredPersonPhone')" :placeHolder="$t('new-address.DeliveredPersonPhone')"> </AddresssLabelWithInput>
                            </div>
                        </div>
                        <!-- <div class="dp-flex-row mt-mb-5">
                            <div class="dp-flex-row width-100 justify-content-between">
                                <div class="dp-flex-column width-100">                                    
                                    <label class="font-size-14 mt-mb-5 text-bold">
                                        {{$t("new-address.DeliveredPersonNote")}}
                                    </label>
                                    <textarea v-model="note" class="new-addres-input width-50" rows="4" maxlength="250" :placeholder="$t('new-address.DeliveredPersonNotePlaceHolder')">
                                    </textarea>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </form>
            </ValidationObserver>
            <div class="dp-flex-row justify-content-end mt-mb-y25">
                <router-link :to="addressTypeId == 1 ? '/customer/registered-addresses' : '/customer/registered-unloading-points'" class="btn-none continue-btn pl-pr-y1-4 previous-btn font-size-14 items-center">
                    {{ $t("button.Back") }}
                </router-link>
                <button @click="postUnloadingLoadingAddress($event)" class="btn-none continue-btn pl-pr-y1-4 ml-y1 font-size-14 items-center button-loading">
                    <span class="button-text font-size-14">{{ $t("button.Continue") }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import GreenCheckbox from "@/components/Widgets/CustomerPanel/GreenCheckbox.vue";
import AddresssLabelWithInput from "@/components/CustomerArea/Addresses/LoadingPoint/AddressLabelWithInput.vue";
import { APIUrls } from "@/Helpers/APIUrls.js";
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages as validationMessages } from "vee-validate/dist/locale/en.json";
import { mapActions, mapGetters } from "vuex";
import { UnloadingLoadingAddress } from "@/Helpers/Models";
import CountryService from "@/services/CountryService";
import CityService from "@/services/CityService";
import DistrictService from "@/services/DistrictService";

Object.keys(rules).forEach((rule) => {
    extend(rule, {
        ...rules[rule],
        message: validationMessages[rule],
    });
});

export default {
    props: {
        id: "",
        selectedAddressTypeId: 1,
        country: "",
        city: "",
        district: "",
        postalCode: "",
        formatedAddress: "",
        latitude: "",
        longitude: "",
    },

    components: {
        Title,
        GreenCheckbox,
        AddresssLabelWithInput,
    },

    data() {
        return {
            denemeList: [],
            unloadingLoadingAdress: {},
            isActiveLateDate: false,
            mutableId: this.id,
            addressTypeId: this.selectedAddressTypeId,
            title: "",
            isDraft: true,
            description: "",
            direction: this.formatedAddress != null ? this.formatedAddress : "",
            mutableCountry: this.country,
            // this.country.toLowerCase().trim(),
            mutableCity: this.city,
            mutableDistrict: this.district,
            mutablePostalCode: this.postalCode,
            mutableLatitude: this.latitude,
            mutableLongitude: this.longitude,
            buildingNumber: 0,
            floorNumber: 0,
            apartmentNumber: 0,
            earliesDate: "",
            latestDate: "",
            deliveredPersonName: "",
            deliveredPersonSurname: "",
            deliveredPersonEmail: "",
            deliveredPersonPhone: "",
            note: "",
            countries: [],
            cities: [],
            districts: [],
            countryService: new CountryService(),
            cityService: new CityService(),
            districtService: new DistrictService(),
        };
    },

    computed: {
        ...mapGetters("createLoad", ["getIsComingFromDraft", "getLoadingAddresses", "getUnloadingAddresses", "getUnloadingLoadingAddress"]),
    },

    async mounted() {
        await this.getCountries();

        this.mutableId = this.getIsComingFromDraft ? this.getUnloadingLoadingAddress.id : this.id;

        this.addressTypeId = this.getIsComingFromDraft ? this.getUnloadingLoadingAddress.addressTypeId : this.selectedAddressTypeId;

        if (this.mutableId != null) {
            this.axios({
                url: APIUrls.GET_UNLOADING_LOADING_ADDRESS_WITH_DETAILS,
                method: "GET",
                params: { id: this.mutableId },
            })
                .then((response) => {
                    if (response.data.code == 14) {
                        this.unloadingLoadingAdress = response.data.data;
                        this.title = response.data.data.title;
                        this.addressTypeId = this.unloadingLoadingAdress.addressTypeId;
                        this.direction = this.unloadingLoadingAdress.direction;
                        this.description = this.unloadingLoadingAdress.description;
                        this.mutableCountry = this.unloadingLoadingAdress.countryName;
                        this.mutableDistrict = this.unloadingLoadingAdress.districtName;
                        this.mutableCity = this.unloadingLoadingAdress.cityName;
                        this.mutablePostalCode = this.unloadingLoadingAdress.postalCode;
                        this.mutableLatitude = this.unloadingLoadingAdress.latitude;
                        this.mutableLongitude = this.unloadingLoadingAdress.longitude;
                        this.buildingNumber = this.unloadingLoadingAdress.buildingNumber;
                        this.floorNumber = this.unloadingLoadingAdress.floorNumber;
                        this.apartmentNumber = this.unloadingLoadingAdress.apartmentNumber;
                        this.deliveredPersonName = this.unloadingLoadingAdress.deliveredPerson;
                        // this.deliveredPersonName = this.unloadingLoadingAdress.deliveredPerson.split(" ")[0] + " " + this.unloadingLoadingAdress.deliveredPerson.split(" ")[1];
                        // this.deliveredPersonSurname = this.unloadingLoadingAdress.deliveredPerson.split(" ")[this.unloadingLoadingAdress.deliveredPerson.split(" ").length - 1];
                        this.deliveredPersonEmail = this.unloadingLoadingAdress.deliveredPersonEmail;
                        this.deliveredPersonPhone = this.unloadingLoadingAdress.deliveredPersonPhone;
                        this.note = this.unloadingLoadingAdress.note;
                    } else {
                        this.$toast.warning("Beklenmedik Bir Hata Oluştu !");
                    }
                })
                .catch((error) => {
                    this.$toast.error("Beklenmedik Bir Hata Oluştu !");
                });
        }
    },

    methods: {
        ...mapActions("createLoad", ["updateLoadingAddresses", "updateUnloadingAddresses", "updateUnloadingLoadingAddress"]),

        toogleLateDate() {
            this.isActiveLateDate = !this.isActiveLateDate;
        },

        selectAddressType(event) {
            this.addressTypeId = event.target.value;
        },

        greenCheckboxEvent(event) {
            this.isDraft = event.checked;
        },

        postUnloadingLoadingAddress(e) {
            console.log(this.addressTypeId);

            this.$refs.form.validate().then((valid) => {
                if (valid && this.earliesDate != "" && this.latestDate != "") {
                    e.target.classList.add("button--loading");
                    console.log(this.mutableCountry);
                    this.axios
                        .post(APIUrls.CREATE_OR_UPDATE_UNLOADING_LOADING_ADDRESS, {
                            customerUserGuid: localStorage.getItem("guid"),
                            id: this.mutableId ?? 0,
                            addressTypeId: this.addressTypeId,
                            order: 1,
                            title: this.title,
                            country: this.mutableCountry.text,
                            city: this.mutableCity.text,
                            district: this.mutableDistrict.text,
                            street: "",
                            buildingNumber: this.buildingNumber,
                            floorNumber: this.floorNumber,
                            apartmentNumber: this.apartmentNumber,
                            direction: this.direction,
                            description: this.description,
                            postalCode: this.mutablePostalCode,
                            deliveredPersonNameSurname: this.deliveredPersonName,
                            // deliveredPersonNameSurname: `${this.deliveredPersonName} ${this.deliveredPersonSurname}`,
                            deliveredPersonEmail: this.deliveredPersonEmail,
                            deliveredPersonPhone: this.deliveredPersonPhone,
                            note: this.note,
                            latitude: this.mutableLatitude,
                            longitude: this.mutableLongitude,
                            isDraft: this.isDraft,
                            earliestDate: this.earliesDate,
                            latestDate: this.latestDate,
                        })
                        .then((response) => {
                            console.log(response);
                            e.target.classList.remove("button--loading");
                            if (this.getIsComingFromDraft) {
                                let unloadingLoadingAddress = new UnloadingLoadingAddress();

                                unloadingLoadingAddress.id = response.data.data.id;
                                unloadingLoadingAddress.apartmentNumber = this.apartmentNumber;
                                unloadingLoadingAddress.buildingNumber = this.buildingNumber;
                                unloadingLoadingAddress.earliestDate = this.earliesDate;
                                unloadingLoadingAddress.floorNumber = this.floorNumber;
                                unloadingLoadingAddress.isDraft = false;
                                unloadingLoadingAddress.latestDate = this.latestDate;
                                unloadingLoadingAddress.note = this.note;
                                unloadingLoadingAddress.postalCode = this.mutablePostalCode;
                                unloadingLoadingAddress.sequence = this.getUnloadingLoadingAddress.sequence;
                                unloadingLoadingAddress.title = this.title;
                                unloadingLoadingAddress.cityName = this.mutableCity.text;
                                unloadingLoadingAddress.countryName = this.mutableCountry.text;
                                unloadingLoadingAddress.deliveredPersonEmail = this.deliveredPersonEmail;
                                unloadingLoadingAddress.deliveredPersonName = this.deliveredPersonName;
                                // unloadingLoadingAddress.deliveredPersonName = `${this.deliveredPersonName} ${this.deliveredPersonSurname}`;
                                unloadingLoadingAddress.deliveredPersonPhone = this.deliveredPersonPhone;
                                unloadingLoadingAddress.description = this.description;
                                unloadingLoadingAddress.direction = this.direction;
                                unloadingLoadingAddress.districtName = this.mutableDistrict.text;

                                let unloadingLoadingAddresses = [];

                                unloadingLoadingAddresses = this.addressTypeId == 1 ? this.getLoadingAddresses : this.getUnloadingAddresses;

                                let indexOfUnloadingLoadingAddress = unloadingLoadingAddresses.findIndex((x) => x.id === this.mutableId);

                                unloadingLoadingAddresses[indexOfUnloadingLoadingAddress] = unloadingLoadingAddress;

                                if (this.addressTypeId == 1) {
                                    this.updateLoadingAddresses([unloadingLoadingAddresses]);
                                } else {
                                    this.updateUnloadingAddresses([unloadingLoadingAddresses]);
                                }

                                this.$router.push("/customer/unloading-points");
                            } else {
                                let unloadingLoadingAddress = new UnloadingLoadingAddress();

                                unloadingLoadingAddress.id = response.data.data.id;
                                unloadingLoadingAddress.apartmentNumber = this.apartmentNumber;
                                unloadingLoadingAddress.buildingNumber = this.buildingNumber;
                                unloadingLoadingAddress.earliestDate = this.earliesDate;
                                unloadingLoadingAddress.floorNumber = this.floorNumber;
                                unloadingLoadingAddress.isDraft = false;
                                unloadingLoadingAddress.latestDate = this.latestDate;
                                unloadingLoadingAddress.note = this.note;
                                unloadingLoadingAddress.postalCode = this.mutablePostalCode;
                                unloadingLoadingAddress.sequence = this.getUnloadingLoadingAddress.sequence;
                                unloadingLoadingAddress.title = this.title;
                                unloadingLoadingAddress.cityName = this.mutableCity.text;
                                unloadingLoadingAddress.countryName = this.mutableCountry.text;
                                unloadingLoadingAddress.deliveredPersonEmail = this.deliveredPersonEmail;
                                unloadingLoadingAddress.deliveredPersonName = this.deliveredPersonName;
                                // unloadingLoadingAddress.deliveredPersonName = `${this.deliveredPersonName} ${this.deliveredPersonSurname}`;
                                unloadingLoadingAddress.deliveredPersonPhone = this.deliveredPersonPhone;
                                unloadingLoadingAddress.description = this.description;
                                unloadingLoadingAddress.direction = this.direction;
                                unloadingLoadingAddress.districtName = this.mutableDistrict.text;

                                if (response.data.code == 137 || response.data.code == 138) {
                                    if (this.addressTypeId == 1) {
                                        let unloadingLoadingAddresses = [];
                                        unloadingLoadingAddresses.push(unloadingLoadingAddress);
                                        this.updateLoadingAddresses([unloadingLoadingAddresses]);

                                        this.$router.push("/customer/registered-unloading-points");
                                    } else if (this.addressTypeId == 2) {
                                        let unloadingLoadingAddresses = [];
                                        unloadingLoadingAddresses = this.getUnloadingAddresses;
                                        unloadingLoadingAddresses.push(unloadingLoadingAddress);
                                        this.updateUnloadingAddresses([unloadingLoadingAddresses]);

                                        this.$router.push("/customer/unloading-points");
                                    }
                                    // } else if (response.data.code == 138) {
                                    //     if (this.addressTypeId == 1) {
                                    //         this.updateLoadingAddress([response.data.data]);
                                    //         //this.$router.push("/customer/registered-unloading-points");
                                    //     } else if (this.addressTypeId == 2) {
                                    //         this.updateUnloadingAddresses([response.data.data]);
                                    //         //this.$router.push("/customer/unloading-points");
                                    //     }
                                } else {
                                    //console.log(response.data);
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.$toast.error("Beklenmedik Bir Hata Oluştu !");
                        });
                } else {
                    this.$toast.warning("Tarihleri Doldurunuz !");
                }
            });
        },

        async getCountries() {
            await this.countryService.getCountries();

            if (this.countryService.resultModel.code == 14) {
                this.countries = this.countryService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.countryService.resultModel.message));
            }

            if (this.countryService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async countrySelectChange() {
            await this.getCitiesByCountryId(this.mutableCountry.id);
        },

        async getCitiesByCountryId(countryId) {
            if (countryId > 0) {
                await this.cityService.getCitiesByCountryId(countryId);

                if (this.cityService.resultModel.code == 14) {
                    this.cities = this.cityService.resultModel.data;
                } else {
                    this.$toast.error(this.$t("resultModel." + this.cityService.resultModel.message));
                }

                if (this.cityService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            } else {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async citySelectChange() {
            await this.getDistrictsByCityId(this.mutableCity.id);
        },

        async getDistrictsByCityId(cityId) {
            if (cityId > 0) {
                await this.districtService.getDistrictsByCityId(cityId);

                if (this.districtService.resultModel.code == 14) {
                    this.districts = this.districtService.resultModel.data;
                } else {
                    this.$toast.error(this.$t("resultModel." + this.districtService.resultModel.message));
                }

                if (this.districtService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            } else {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/my-ads/create-new-ads/new-address.css";
</style>
