<template>
    <LayoutUserLoginRegister>

        <div class="dp-flex-column items-center justify-content-center login-left">
            <div class='login-form'>
                <h1 class='font-size-35 text-bold mt-mb-15'>{{$t("register-page.Title")}}</h1>

                <form @submit="postRegisterInfoToAPI">
                    <div class='input-icons'>
                        <i class='register-icon icon'></i>
                        <input class='btn-none register-form-input login-input' name='name' type="text" :placeholder="$t('register-page.NameInput')" v-model="userName" />
                        <p class='font-size-13 text-color-red'>{{errors.userName}}</p>
                    </div>

                    <div class='input-icons'>
                        <i class="register-icon icon"></i>
                        <input class='btn-none register-form-input login-input' name='surName' type="text" :placeholder="$t('register-page.SurNameInput')" v-model="surName" />
                        <p class='font-size-13 text-color-red'>{{errors.surName}}</p>
                    </div>
                    <div class='input-icons'>
                        <i class='register-icon icon'></i>
                        <input class='btn-none register-form-input login-input' name='phoneNumber' type="text" :placeholder="$t('register-page.PhoneNumberInput')" v-model="phoneNumber" />
                        <p class='font-size-13 text-color-red'>{{errors.phoneNumber}}</p>
                    </div>
                    <div class='input-icons'>
                        <i class='register-icon icon'></i>
                        <input class='btn-none register-form-input login-input' name='email' type="email" :placeholder="$t('register-page.EmailInput')" v-model="email" />
                        <p class='font-size-13 text-color-red'>{{errors.email}}</p>
                    </div>
                    <div class='input-icons'>
                        <i class='register-icon icon'></i>
                        <input class='btn-none register-form-input login-input' name='password' type="password" :placeholder="$t('register-page.PasswordInput')" v-model="password" />
                        <p class='font-size-13 text-color-red'>{{errors.password}}</p>
                    </div>
                    <div class='input-icons'>
                        <i class='register-icon icon'></i>
                        <input class='btn-none register-form-input login-input' name='passwordAgain' type="password" :placeholder="$t('register-page.PasswordAgainInput')" v-model="passwordAgain" />
                        <p class='font-size-13 text-color-red'>{{errors.passwordAgain}}</p>
                    </div>
                    <div class='input-icons'>
                        <i class='register-icon icon'></i>
                        <input class='btn-none register-form-input login-input' name='referenceCode' type="text" :placeholder="$t('register-page.ReferenceCodeInput')" v-model="referenceCode" />
                        <p class='font-size-13 text-color-red'>{{errors.referenceCode}}</p>
                    </div>

                    <div class='dp-flex-column justify-content-between items-left login-remember-and-forget-password'>
                        <div class=''>
                            <input id='chckTanitim' type='checkbox' />
                            <label class='font-size-13' for='chckTanitim'>{{$t("register-page.TanitimCheckBoxLabel")}}</label>
                        </div>
                        <div class=''>
                            <input id='chckUyelikSoz' type='checkbox' defaultChecked />
                            <label class='font-size-13' for='chckUyelikSoz'>
                                <router-link class='font-size-13 text-color-red' to='/login'>{{$t("register-page.UyelikSozlesmesiCheckBoxLabel")}}</router-link> {{$t("register-page.IAgreeCheckBoxLabel")}}
                            </label>
                        </div>
                        <div class=''>
                            <input id='chckKVKK' type='checkbox' defaultChecked />
                            <label class='font-size-13' for='chckKVKK'>
                                <router-link class='font-size-13 text-color-red' to='/login'>{{$t("register-page.KVKKCheckBoxLabel")}} </router-link>{{$t("register-page.IAgreeCheckBoxLabel")}}
                            </label>
                        </div>
                    </div>

                    <button class='btn-none mt-mb-25 login-input login-input-submit-disable' type='submit'>{{$t("register-page.SubmitButton")}}</button>

                </form>

                <div class='login-register-div text-center mb-20 font-size-15'>
                    <p>{{$t("register-page.LoginTitle")}}</p>
                    <router-link :to="{name: 'Login', params: {userType: userType}}">{{$t("register-page.LoginDesc")}}</router-link>
                </div>

                <div class='login-re-captcha-kvkk'>
                    <p class='font-size-13'>
                        ReCAPTCHA tarafından korunmaktadır ve
                        <br />
                        Google
                        <a href='#' class='text-color-blue font-size-13 btn-none'> Gizlilik Politikası </a>
                        ve
                        <a href='#' class='font-size-13 text-color-blue btn-none'> Hizmet Şartları</a>
                        ’na tabidir.
                    </p>
                </div>
            </div>
        </div>

        <Popup v-if="isOpenPopup" @closePopup="isOpenPopup = false">
            <template slot="popup-alert-icon" class="width-65">
                <div>
                    <img src="@/assets/images/widgets/pop-up/Group 1381.svg" />
                </div>
            </template>
            <template slot="popup-body-text">
                <div class="dp-flex-row justify-content-center text-center items-center font-size-15 width-50 mt-mb-y25">
                    {{resultModel.message}}
                </div>
            </template>
            <template slot="popup-body-button">
                <div class="dp-flex-row justify-content-center items-center mt-mb-25">
                    <button class='popup-model-body-button font-size-15 text-medium btn-none' @click="closePopup">Tamam</button>
                </div>
            </template>
        </Popup>
    </LayoutUserLoginRegister>
</template>

<script>
// TODO: inputlara mask eklenecek.
// TODO: checkbox'lara kontrol eklenecek.
// TODO: tasarımsal düzenlemeler yapılacak.
// TODO: kayıt ol dendiğinde loading eklenecek.

import LayoutUserLoginRegister from "./_LayoutUserLoginRegister.vue";
import {
    validateEmail,
    validatePassword,
    validateRequired,
} from "@/Helpers/FormValidateFunctions";
import { APIUrls } from "@/Helpers/APIUrls";
import Popup from "@/components/Widgets/ModelPopup/Popup.vue";

export default {
    name: "Register",
    components: {
        LayoutUserLoginRegister,
        Popup,
    },

    data() {
        return {
            isOpenPopup: false,
            userName: "",
            surName: "",
            phoneNumber: "",
            email: "",
            password: "",
            userType: localStorage.getItem("userType"),
            passwordAgain: "",
            referenceCode: "",
            resultModel: {
                code: 0,
                message: "",
                data: {},
            },
            errors: {},
        };
    },
    methods: {
        validateEmail,
        validatePassword,
        validateRequired,
        validateForm(e) {
            e.preventDefault();
            let error = 0;

            if (!validateRequired("Name", this.userName).isValid) {
                this.$set(
                    this.errors,
                    "userName",
                    validateRequired("Name", this.userName).message
                );
                error++;
            }
            if (!validateRequired("SurName", this.surName).isValid) {
                this.$set(
                    this.errors,
                    "surName",
                    validateRequired("SurName", this.surName).message
                );
                error++;
            }
            if (!validateRequired("PhoneNumber", this.phoneNumber).isValid) {
                this.$set(
                    this.errors,
                    "phoneNumber",
                    validateRequired("PhoneNumber", this.phoneNumber).message
                );
                error++;
            }
            if (!validateRequired("Email", this.email).isValid) {
                this.$set(
                    this.errors,
                    "email",
                    validateRequired("Email", this.email).message
                );
                error++;
            } else if (!validateEmail(this.email).isValid) {
                this.$set(
                    this.errors,
                    "email",
                    validateEmail(this.email).message
                );
                error++;
            }
            if (!validateRequired("Password", this.password).isValid) {
                this.$set(
                    this.errors,
                    "password",
                    validateRequired("Password", this.password).message
                );
                error++;
            } else if (
                !validatePassword(this.password, this.password).isValid
            ) {
                this.$set(
                    this.errors,
                    "password",
                    validatePassword(this.password).message
                );
                error++;
            }
            if (
                !validateRequired("PasswordAgain", this.passwordAgain).isValid
            ) {
                this.$set(
                    this.errors,
                    "passwordAgain",
                    validateRequired("PasswordAgain", this.passwordAgain)
                        .message
                );
                error++;
            } else if (
                !validatePassword(this.password, this.passwordAgain).isValid
            ) {
                this.$set(
                    this.errors,
                    "passwordAgain",
                    validatePassword(this.password, this.passwordAgain).message
                );
                error++;
            }

            return error;
        },
        postRegisterInfoToAPI(e) {
            e.preventDefault();

            let error = this.validateForm(e);

            //2 nakliyeci 3 müşteri

            if (error == 0) {
                this.axios({
                    method: "post",
                    url: this.userType == 3 ? APIUrls.CUSTOMER_REGISTER_URL : APIUrls.TRANSPORTER_REGISTER_URL,
                    data: {
                        name: this.userName,
                        surname: this.surName,
                        phone: this.phoneNumber,
                        eMail: this.email,
                        password: this.password,
                        referenceCode: this.referenceCode,
                    },
                })
                    .then((response) => {
                        console.log(response);
                        this.$set(this.resultModel, "code", response.data.code);

                        this.$set(
                            this.resultModel,
                            "message",
                            response.data.message
                        );

                        this.$set(this.resultModel, "data", response.data.data);

                        if (this.resultModel.code == 3) {
                            localStorage.setItem(
                                "guid",
                                this.resultModel.data.guid
                            );

                            localStorage.setItem("name", this.resultModel.data.name);
                            localStorage.setItem("surname", this.resultModel.data.surname);
                            
                            this.$router.push({path: '/complete-registration'});
                        } else if (this.resultModel.code == 4) {
                            this.resultModel.message =
                                "Kullanıcı Kaydı Tamamlanamadı!";
                            this.isOpenPopup = true;
                        } else if (this.resultModel.code == 141) {
                            this.resultModel.message =
                                "Bu Mail Adresi Kullanılıyor!";
                            this.isOpenPopup = true;
                        } else if (this.resultModel.code == 142) {
                            this.resultModel.message =
                                "Bu Telefon Numarası Kullanılıyor!";
                            this.isOpenPopup = true;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                //this.$router.push('/complete-registration');
            }
        },
        openPopup() {
            this.isOpenPopup = true;
        },
        closePopup() {
            this.isOpenPopup = false;
        },
    },
};
</script>

<style>
</style>