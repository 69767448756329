<template>
    <div class="dp-flex-row justify-content-between massage-content width-100">
        <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

            <img v-if="imgUrl == null" v-bind:src="require('@/assets/images/customer-area/dashboard/ben.png')" alt="user-icon">

            <img v-if="imgUrl != null" v-bind:src="imgUrl" @error="catchImgError()" alt="user-icon" class="receiver-profile-photo">

        </div>
        <div class="dp-flex-column width-80">
            <div class="dp-flex-row justify-content-between">
                <p class="font-size-16 text-medium">
                    {{name}}
                </p>
                <p class="font-size-12 color-667085 text-medium">
                    {{time}}
                </p>
            </div>
            <div class="dp-flex-row justify-content-between">
                <p class="font-size-13">
                    {{message}}
                </p>
                <p class="border-radius-5 text-color-white font-size-14 message-count">
                    {{messageCount}}
                </p>
            </div>
            <div class="dp-flex-row justify-content-between">
                <p v-if="status" class="font-size-13 color-26B198 text-semi-bold">
                    Çevrim İçi
                </p>

                <p v-if="!status" class="font-size-13 color-D01438 text-semi-bold">
                    Çevrim Dışı
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "IncomingMessagesItem",
    props: ["imgUrl", "name", "price", "message", "messageCount", "time","status"],

    mounted(){
        console.log(this.imgUrl);
    },

    data() {
        return {
            mutatedImgUrl: "",
        };
    },

    methods: {
        catchImgError() {
            // console.log("hata alındı");
            this.mutatedImgUrl = "/assets/images/customer-area/dashboard/ben.png";
        },
    },
};
</script>

<style>
.receiver-profile-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
</style>