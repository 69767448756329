import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';


export class CreateOrUpdateDriverModel {
    id = 0;
    name = "";
    surname = "";
    identificationNumber = "";
    email = "";
    phone = "";
    birthDate = "";
    licenseFrontPhoto = undefined;
    licenseBackPhoto = undefined;
    profilePhoto = undefined;
    sRCDocument = undefined;
}

const apiHelper = new ApiHelper();


export default class DriversService {
    resultModel = new ResultModel();

    errorResult = "";

    async getDriversByTransporterId(filterDto = new FilterDto()) {
        await apiHelper.get(APIUrls.GET_DRIVERS_BY_TRANSPORTER_GUID, filterDto)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }

    async updateDriverStatus(driverId, workingStatus) {
        await apiHelper.postQuery(APIUrls.UPDATE_DRIVER_STATUS, {
            driverId: driverId,
            status: workingStatus,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            })
    }

    async deleteOrRestoreDeleteDriver(driverId, isActive) {
        await apiHelper.postQuery(APIUrls.TRANSPORTER_USE_DELETE_OR_RESTORE_DELETE_URL, {
            id: driverId,
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }

    async createOrUpdateDriver(createOrUpdateDriverModel = new CreateOrUpdateDriverModel()) {
        await apiHelper.post(APIUrls.CREATE_OR_UPDATE_DRIVER, {
            Id: createOrUpdateDriverModel.id,
            Name: createOrUpdateDriverModel.name,
            Surname: createOrUpdateDriverModel.surname,
            IdentificationNumber: createOrUpdateDriverModel.identificationNumber,
            Email: createOrUpdateDriverModel.email,
            Phone: createOrUpdateDriverModel.phone,
            BirthDate: createOrUpdateDriverModel.birthDate,
            LicenseFrontPhoto: createOrUpdateDriverModel.licenseFrontPhoto,
            LicenseBackPhoto: createOrUpdateDriverModel.licenseBackPhoto,
            ProfilePhoto: createOrUpdateDriverModel.profilePhoto,
            SRCDocument: createOrUpdateDriverModel.sRCDocument,
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }
}