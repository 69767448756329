import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router';
import Routes from './routes.js';
import axios from "axios";
import VueAxios from "vue-axios";

import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";

import i18n from './i18n';

import VueGeolocation from "vue-browser-geolocation";

import * as VueGoogleMaps from "vue2-google-maps";

import store from './store';


// import mqtt from "mqtt/dist/mqtt";
// import { VueMqttClientProvider } from 'vue-mqtt-client';

// const mqttClient = mqtt.connect("ws://171.22.187.222:8083/mqtt", {
//     username: "daliport",
//     password: "123456",
//     clinetId: "vuejsRiza",
// });

// Vue.use(VueMqttClientProvider, {
//     client: mqttClient,
// });

import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA2MklQe6mKVeIWfiXWigh7fvqzPHnl1Fg', //  , AIzaSyBUqMGK8sOLExaz8QJ19kdKvOSub-WZ8D0
        libraries: 'places,drawing',
        language: 'en',
    },

});

const options = {
    // You can set your default options here
};

const lang = localStorage.getItem('lng') || 'en';

Vue.use(Toast, options);

Vue.use(VueRouter);

axios.defaults.baseURL = 'https://test.daliport.com/api';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers['Accept-Language'] = lang;


Vue.use(VueAxios, axios);

Vue.use(VueGeolocation);


const router = new VueRouter({
    routes: Routes,
    mode: 'history'
})

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
    let language = to.params.lang;
    if (!language) {
        language = lang;
    }

    i18n.locale = language;

    // Todo: burasi guncellenecek local storage'da token varsa kontrolu yapilmayacak, token gecerlilik kontrolu yapilacak
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('token')) {
            if (to.matched.some(record => record.meta.isCustomer)) {
                localStorage.setItem('userType', 3);
            } else if (to.matched.some(record => record.meta.isTransporter)) {
                localStorage.setItem('userType', 2);
            }
            next({
                name: "Login"
            });
        } else {
            // user type kontrolu yapmak icin kod ornegi, su anda token 'da userType yok, userType eklenince bu kod calisacak
            // const token = localStorage.getItem('token');
            // const base64 = token.split('.')[1];
            // const userType = JSON.parse(window.atob(base64)).userType;

            // if (to.matched.some(record => record.meta.isCustomer)) {
            //     if (userType == 3) {
            //         next({
            //             name: "AccessDenied"
            //         });
            //     } else {
            //         next({
            //             name: "Login"
            //         });
            //     }
            // } else if (to.matched.some(record => record.meta.isTransporter)) {
            //     if (userType == 2) {
            //         next({
            //             name: "AccessDenied"
            //         });
            //     } else {
            //         next({
            //             name: "Login"
            //         });
            //     }
            // } else {
            next();
            // }
        }
    } else if (to.matched.some(record => record.meta.isExistUserData)) {

        if (!localStorage.getItem('whichPage') && !localStorage.getItem('userType')) {
            if (to.matched.some(record => record.name === 'Login')) {
                localStorage.setItem('whichPage', 'Login');
            } else if (to.matched.some(record => record.name === 'Register')) {
                localStorage.setItem('whichPage', 'Register');
            }
            next({
                name: "ChooseOne"
            });
        } else {
            next();
        }

    } else {
        next();
    }
})


new Vue({
    i18n,
    router: router,
    render: h => h(App),
    store,
}).$mount('#app')