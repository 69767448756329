import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';


const apiHelper = new ApiHelper();


export default class CompanyService {
    resultModel = new ResultModel();

    errorResult = "";

    async getCompanyProfile() {
        await apiHelper.get(APIUrls.GET_COMPANY_PROFILE)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            })
    }


}