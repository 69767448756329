<template>
    <div class="dp-flex-column flex-wrap-24 mb-y1 mr-y1 justify-content-between panel-content">
        <div class="dp-flex-row justify-content-between">
            <div class="dp-flex-column justify-content-between">
                <div class="dp-flex-column justify-content-start">
                    <h3 class="font-size-20">{{ driver.name + " " + driver.surname }} </h3>
                    <span class="font-size-14 color-667085">{{ driver.identificationNumber }}</span>
                </div>
            </div>

            <img v-if="driver.profilePhoto != null" class="" style="width:93px;height:93px;border-radius: 50%;" :src="AssetUrls.USER_ASSETS_URL + driver.profilePhoto" @error="handleImageError" alt="">
        </div>

        <div class="dp-flex-column mt-mb-15 justify-content-start">
            <div class="dp-flex-column">
                <h3 class="font-size-16 text-medium">
                    {{ $t('transporterDriver.Phone_Number') }}
                </h3>
                <p class="font-size-14 color-667085r">{{ driver.phone }}</p>
            </div>

            <div class="dp-flex-column">
                <h3 class="font-size-16 text-medium">
                    {{ $t('transporterDriver.Email') }}
                </h3>
                <p class="font-size-14 color-667085r">{{ driver.email }}</p>
            </div>
        </div>

        <div class="dp-flex-row width-100 mb-y5">
            <div @click="updateDriverStatus(true)" :class="changedWorkingStatus ? 'vehicle-working-status-active' : ''" class="dp-flex-column p-y2 justify-content-center width-50 vehicle-working-status-left text-center">
                <p class="font-size-14">
                    {{ $t('button.Active') }}
                </p>
            </div>

            <div @click="updateDriverStatus(false)" :class="changedWorkingStatus == false ? 'vehicle-working-status-active' : ''" class="dp-flex-column p-y2 justify-content-center width-50 vehicle-working-status-right text-center">
                <p class="font-size-14">
                    {{ $t('button.Passive') }}
                </p>
            </div>
        </div>

        <div class="dp-flex-row justify-content-between">
            <button @click="isDriverDocumentPopupOpen = true" class="width-40 p-y3 btn-1">
                {{ $t('transporterDriver.Driver_Document') }}
            </button>

            <button @click="updateDriver()" class="width-25 p-y3 btn-2 user-update-button">
                {{ $t('button.Update') }}
            </button>

            <button @click="isOpenDeletePopup = true" class="btn-2 p-y3 width-25">
                {{ $t('button.Delete') }}
            </button>
        </div>

        <Popup v-if="isDriverDocumentPopupOpen" @closePopup="isDriverDocumentPopupOpen = false">
            <template slot="popup-header">
                <h3>
                    {{ $t('transporterDriver.Driver_Document') }}
                </h3>
            </template>

            <template slot="popup-body">
                <div class="dp-flex-column width-100">
                    <div class="dp-flex-row width-100">
                        <div class="dp-flex-column width-50">
                            <h3 class="vehicle-document-title">
                                {{ $t('transporterDriver.Driving_Liccence_Front') }}
                            </h3>
                            <img class="vehicle-document-img" :src="AssetUrls.USER_ASSETS_URL + driver.licenseFrontPhoto" @error="handleImageError" alt="">
                        </div>

                        <div class="dp-flex-column width-50">
                            <h3 class="vehicle-document-title">
                                {{ $t('transporterDriver.Driving_Liccence_Back') }}
                            </h3>
                            <img class="vehicle-document-img" :src="AssetUrls.USER_ASSETS_URL + driver.licenseBackPhoto" @error="handleImageError" alt="">
                        </div>
                    </div>

                    <div class="dp-flex-row justify-content-center width-100">
                        <div class="dp-flex-column width-50">
                            <h3 class="vehicle-document-title">
                                {{ $t('transporterDriver.SRC_Document') }}
                            </h3>
                            <img class="vehicle-document-img" :src="AssetUrls.USER_ASSETS_URL + driver.srcdocument" @error="handleImageError" alt="">
                        </div>
                    </div>
                </div>
            </template>
        </Popup>

        <TransactionPopup v-if="isOpenDeletePopup" @closePopup="isOpenDeletePopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center">
                    {{ $t('transporterDriver.Delete_Driver') }}
                </p>
            </template>

            <template slot="popup-body">
                <div style="width:20vw!important" class="width-100 dp-flex-column">
                    <div class="dp-flex-row justify-content-center text-center items-center mt-y3">
                        <p class="font-size-16">
                            {{ $t('transporterDriver.Delete_Driver_Description') }}
                        </p>
                    </div>
                </div>
            </template>

            <template slot="popup-body-button">
                <div class="dp-flex-row justify-content-center width-100 mt-y5 items-center">
                    <button @click="deleteDriver(driver.id)" class="dp-flex-row btn-none delte-user-button mr-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20" v-bind:class="{ 'button--loading': isButtonLoading }">
                        {{ $t('button.Delete') }}
                    </button>

                    <button @click="isOpenDeletePopup = false" class="dp-flex-row btn-none border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{ $t('button.Cancel') }}
                    </button>
                </div>
            </template>
        </TransactionPopup>
    </div>
</template>

<script>

import Popup from "@/components/Widgets/ModelPopup/Popup.vue";
import DriversService from "@/services/DriversService";
import { AssetUrls } from "@/Helpers/AssetUrls";
import TransactionPopup from "@/components/Widgets/ModelPopup/TransactionPopup.vue";
import { mapActions } from "vuex";

export default {
    name: "DriversItem",

    props: ["driver"],

    components: {
        Popup,
        TransactionPopup,
    },

    data() {
        return {
            changedWorkingStatus: this.driver.workingStatus,
            isDriverDocumentPopupOpen: false,
            driversService: new DriversService(),
            isActive: false,
            AssetUrls: AssetUrls,
            isOpenDeletePopup: false,
            isButtonLoading: false,
        };
    },

    methods: {
        ...mapActions("moduleCreateOrUpdateDriver", ["updateCreateOrUpdateDriver"]),

        handleImageError(event) {
            event.target.src = require(`@/assets/images/img-not-found.avif`);
        },

        async deleteDriver(driverId) {
            this.isButtonLoading = true;

            await this.driversService.deleteOrRestoreDeleteDriver(driverId, this.isActive);

            if (this.driversService.resultModel.code === 166) {
                this.$toast.success(this.$t("resultModel.Driver_Deleted"));
            } else {
                this.$toast.error(this.$t("resultModel." + this.driversService.resultModel.message));
            }

            if (this.driversService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isButtonLoading = false;

            this.$emit("deletedDriverId", driverId);
        },

        async updateDriverStatus(workingStatus) {
            this.changedWorkingStatus = workingStatus;

            if (this.driver.id > 0 && workingStatus != null) {
                await this.driversService.updateDriverStatus(this.driver.id, workingStatus);

                if (this.driversService.resultModel.code === 14) {
                    if (workingStatus) {
                        this.$toast.success(this.$t("resultModel.Driver_Status_Active"));
                    } else {
                        this.$toast.success(this.$t("resultModel.Driver_Status_DeActive"));
                    }
                } else {
                    this.$toast.error(this.$t("resultModel." + this.driversService.resultModel.message));
                }

                if (this.driversService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            }
        },

        updateDriver() {
            this.updateCreateOrUpdateDriver([this.driver]);

            this.$router.push("create-driver");
        },
    },
};
</script>

<style>
.identity {
    border: 1px solid #0d3ca5;
    border-radius: 6px;
}

.identity-text {
    color: #0d3ca5;
    padding: 9px 22px;
    border: 1px solid #0d3ca5;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
}

.identity-code {
    color: #fff;
    background-color: #0d3ca5;
    padding: 17px 7px 4px 7px;
    border: 1px solid #0f58fb;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
    font-weight: bold;
}

.btn-1 {
    color: #26b198;
    background-color: #fff;
    border: 1px solid #26b198;
    border-radius: 6px;
    cursor: pointer;
}

.btn-2 {
    color: #fff;
    background-color: #d01438;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}

.vehicle-working-status-left {
    border: 1px solid black;
    border-radius: 25px 0 0 25px;
    cursor: pointer;
}

.vehicle-working-status-right {
    border: 1px solid black;
    border-radius: 0 25px 25px 0;
    cursor: pointer;
}

.vehicle-working-status-active {
    background-color: green;
    border: 1px solid green;
    color: white;
}

.mb-y5 {
    margin-bottom: 5%;
}

.user-update-button {
    padding: 3%;
    /* border: 1px solid black; */
    background-color: orange;
    color: white;
}
</style>