<template>
    <div class="dp-flex-column unloading-points-content mt-mb-y1">
        <h3 class="font-size-16 text-semi-bold color-1A5EF7">{{title}}</h3>
        <div class="dp-flex-column mt-mb-y1 panel-content">
            <div class="dp-flex-row">
                <div class="dp-flex-column width-100">
                    <h3 class="font-size-16 text-semi-bold">{{addressTitle}}</h3>
                    <h5 class="font-size-16">{{underTitle}}</h5>
                </div>
                <button class="dp-flex-row items-center btn-none border-radius-5 btn-drop-address">
                    <i class="fa-regular color-D01438 font-size-16 fa-trash-can"></i>
                    <p class="font-size-14 text-medium ml-10">
                        {{$t("button.Remove")}}
                    </p>
                </button>
            </div>
            <p class="font-size-14 mt-mb-y1">
                {{address}}
            </p>
            <div class="dp-flex-column width-100 mt-mb-y1">
                <h3 class="font-size-16 text-semi-bold">
                    {{$t("unloading-points-row.EstimatedDeliveryTime")}}
                </h3>

                <div class="dp-flex-row font-size-14 width-100">
                    <div class="dp-flex-row items-center">
                        <i class="fa-solid color-1A5EF7 font-size-16 fa-clock"></i>
                        <p class="ml-10 font-size-14">
                            {{loadDate != null ? loadDate : 'En Erken Tarihi Giriniz !'}}
                        </p>
                        <p class="ml-15 font-size-14">
                            {{loadTime}}
                        </p>
                    </div>
                    <div class="ml-y1 dp-flex-row items-center">
                        <i class="fa-solid color-1A5EF7 font-size-16 fa-hourglass-end"></i>
                        <p class="ml-10 font-size-14">
                            {{unloadDate != null ? unloadDate : 'En Geç Tarih Giriniz !'}}
                        </p>
                        <p class="ml-15 font-size-14">
                            {{unloadTime}}
                        </p>
                    </div>

                </div>
            </div>
            <div class="dp-flex-row justify-content-end mt-y3">
                <button v-if="this.getIsComingFromDraft" @click="addDateToDraftAddress()" class="dp-flex-row btn-none border-radius-5 btn-edit-address btn-edit-address items-center mr-y1">
                    <i class="fa-solid fa-pen font-size-16"></i>
                    <p class="ml-mr-5 font-size-14 text-semi-bold">
                        {{$t("unloading-points-row.Add_Date")}}
                    </p>
                </button>

                <button v-if="!this.getIsComingFromDraft" class="dp-flex-row btn-none border-radius-5 btn-edit-address btn-edit-address items-center">
                    <i class="fa-solid fa-pen font-size-16"></i>
                    <p class="ml-mr-5 font-size-14 text-semi-bold">
                        {{$t("unloading-points-row.EditAddress")}}
                    </p>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { UnloadingLoadingAddress } from '@/Helpers/Models';
import { mapActions, mapGetters } from "vuex";
export default {
    name: "UnloadPointsRow",

    props: ["title", "addressTitle", "underTitle", "address", "loadDate", "loadTime", "unloadDate", "unloadTime", "draftUnloadingAddress"],

    computed: {
        ...mapGetters("createLoad", ["getIsComingFromDraft"]),
    },

    mounted() {
        // console.log("HEPSİNİN GELDİĞİ YER !");
        // console.log(this.draftUnloadingAddress);
    },

    methods: {
        ...mapActions("createLoad", ["updateUnloadingLoadingAddress"]),

        addDateToDraftAddress() {
            let unloadingLoadingAddress = new UnloadingLoadingAddress()

            unloadingLoadingAddress = this.draftUnloadingAddress;

            //console.log(this.draftUnloadingAddress);

            unloadingLoadingAddress.earliestDate = undefined;
            unloadingLoadingAddress.latestDate = undefined;

            this.updateUnloadingLoadingAddress([unloadingLoadingAddress]);

            this.$router.push("/customer/new-address");
        },
    },
};
</script>

<style>
</style>