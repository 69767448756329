import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class StatisticService {
    resultModel = new ResultModel();

    errorResult = "";

    async getMonthlyVoyagesCount(month, status, isActive) {

        await apiHelper.get(APIUrls.GET_MONTHLY_VOYAGES_COUNT, {
            month: month,
            status: status,
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getMonthlyLoadVehiclesCount(month, isActive) {
        await apiHelper.get(APIUrls.GET_MONTHLY_LOAD_VEHICLES_COUNT, {
            month: month,
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getMonthlyLoads(month, isActive) {
        await apiHelper.get(APIUrls.GET_MONTHLY_LOADS_COUNT, {
            month: month,
            isActive: isActive,
        })
            .then((response) => {                
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }
}