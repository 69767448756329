<template>
    <div id="app">
        <router-view>            
        </router-view>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
};
</script>

<style>
@import "./assets/css/general.css";
</style>

<style>
</style>
