<template>
    <div class="dp-flex-column justify-content-center items-center payment-info-content panel-content">
        <i class="fa-solid fa-check"></i>
        <div class="mt-mb-15">
            <p class="font-size-15 text-color-white text-center">
                {{$t('customer-dashboard-page.Payment_Desc1')}}
            </p>
            <p class="font-size-20 text-color-white text-center">
                {{$t('customer-dashboard-page.Payment_Desc2')}}
            </p>
        </div>

        <p class="font-size-25 text-semi-bold text-color-white text-center">
            tl 153.906
        </p>
    </div>
</template>

<script>
export default {
    name: "PaymentInfo",

    data() {
        return {
            price: 0,
        };
    },

    mounted() {
        
    },

    methods: {},
};
</script>

<style>
</style>