<template>
    <div>
        <Loading v-if="isLoading"></Loading>

        <div class="dp-flex-row dp-flex-wrap justify-content-start">
            <AdsSearch v-if="isLoading == false" v-model="searchPlateNumber" :placeholder="$t('transporterVehicle.Plate_Number')" @input="searchVehicle" class="vehicle-search mt-mb-y1 width-25"></AdsSearch>

            <VehicleItem v-for="vehicle in vehicles" :vehicle="vehicle" :key="vehicle.id" class="flex-wrap-32 mb-y2 mr-y1"></VehicleItem>
        </div>

        <EmptyAds v-if="isLoading == false && vehicles.length <= 0" :title="$t('emptyAds.Empty_Vehicles_Title')" :description="$t('emptyAds.Empty_Vehicles_Description')">

        </EmptyAds>
    </div>
</template>

<script>

import VehicleItem from "./VehicleItem.vue";
import { mapGetters } from "vuex";
import VehiclesService from "@/services/VehiclesService";
import { FilterDto } from "@/Helpers/Models";
import AdsSearch from "@/components/CustomerArea/MyAds/AdsSearch.vue";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import EmptyAds from "@/components/CustomerArea/MyAds/EmptyAds.vue";

export default {
    components: {
        VehicleItem,
        AdsSearch,
        Loading,
        EmptyAds,
    },

    data() {
        return {
            vehicles: [],
            vehiclesService: new VehiclesService(),
            filterDto: new FilterDto(),
            searchPlateNumber: "",
            isLoading: true,
        };
    },

    computed: {
        ...mapGetters("createOrUpdateVehicle", ["getCreateOrUpdateVehicle"]),
    },

    async mounted() {
        this.filterDto.IsActive = true;
        this.filterDto.Page = 0;
        this.filterDto.Take = 9;

        this.getVehiclesByTransportId(this.filterDto);

        window.addEventListener("scroll", this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },

    methods: {
        async getVehiclesByTransportId(filterDto = new FilterDto()) {
            await this.vehiclesService.getVehiclesByTransportId(filterDto);

            if (this.vehiclesService.resultModel.code == 14) {
                if (filterDto.Page > 0) {
                    this.vehicles.push(...this.vehiclesService.resultModel.data);
                } else if (filterDto.Search != "") {
                    this.vehicles = this.vehiclesService.resultModel.data;
                } else {
                    this.vehicles = this.vehiclesService.resultModel.data;
                }
                this.isLoading = false;
            } else {
                this.$toast.error(this.$t("resultModel." + this.vehiclesService.resultModel.message));
            }

            if (this.vehiclesService.errorResult != "") {
                console.log(this.vehiclesService.errorResult);
                this.$toast.error(this.vehiclesService.errorResult);
            }
        },

        getDeletedVehicleId(deletedVehicleId) {
            let deletedVehicle = this.vehicles.map((x) => x.id).indexOf(deletedVehicleId);

            this.vehicles.splice(deletedVehicle, 1);
        },

        searchVehicle() {
            this.filterDto.IsActive = true;
            this.filterDto.Page = 0;
            this.filterDto.Take = 9;

            this.filterDto.Search = this.searchPlateNumber;

            this.getVehiclesByTransportId(this.filterDto);
        },

        async handleScroll(event) {
            console.log(event);
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (documentHeight - (scrollTop + windowHeight) < 1) {
                this.filterDto.Page += 1;
                await this.getVehiclesByTransportId(this.filterDto);
            }
        },
    },
};
</script>

<style>
.vehicle-search {
    padding: 0 !important;
}
</style>