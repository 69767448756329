<template>
    <div class="dp-flex-column">
        <Title :title="$t('ad-detail.Title')" :description="load.title"></Title>

        <div class="panel-content">
            <Map></Map>
        </div>

        <div class="panel-content mt-y3 dp-flex-column">
            <h3 class="mb-y1 panel-content-inside-title">
                {{$t('ad-details.Offers')}}
            </h3>

            <div v-if="offers.length > 0" class="dp-flex-row width-100 dp-flex-wrap justify-content-between">
                <button class="btn-none slider-arrows" @click="changeSlide(false)">
                    <i class="fa-solid fa-arrow-left font-size-14"></i>
                </button>
                <div class="dp-flex-row justify-content-start width-90">
                    <OffersCard @offerAccept="forceComponent()" class="ml-mr-10 flex-wrap-23" v-for="offer in offers.slice(showOfferStart,showOfferEnd)" :key="offer.id" :id="offer.id" :fulName="offer.name + ' ' + offer.surname" :company="offer.companyTitle" :price="'tl ' + offer.amount" :starRating="offer.rating" :imgUrl="offer.profilePhoto" :isAccept="offer.isAccept"></OffersCard>
                </div>
                <button class="btn-none slider-arrows" @click="changeSlide(true)">
                    <i class="fa-solid fa-arrow-right font-size-14"></i>
                </button>
                <!-- <OffersCard class="ml-y1" :fulName="'Sevim ERGÜL'" :compnay="'Dalisto Logistics'" :price="'tl 25.000'" :starRating="'4.5'" imgUrl="ben.png"></OffersCard>
                <OffersCard class="ml-y1" :fulName="'Rıza Turancan YILMAZ'" :compnay="'Dalisto Logistics'" :price="'tl 15.000'" :starRating="'4.5'" imgUrl="ben.png"></OffersCard>
                <OffersCard class="ml-y1" :fulName="'Rıza Turancan YILMAZ'" :compnay="'Dalisto Logistics'" :price="'tl 15.000'" :starRating="'4.5'" imgUrl="ben.png"></OffersCard> -->
            </div>
            <div v-if="offers.length == 0" class="dp-flex-row width-100 justify-content-between">
                <p class="font-size-16">
                    {{$t('ad-details.No_Offers')}}
                </p>
                <!-- <button class="btn-none slider-arrows" @click="changeSlide(false)">
                    <i class="fa-solid fa-arrow-left font-size-14"></i>
                </button>
                <OffersCard @offerAccept="forceComponent()" class="ml-mr-10" v-for="offer in offers.slice(showOfferStart,showOfferEnd)" :key="offer.id" :id="offer.id" :fulName="offer.name + ' ' + offer.surname" :compnay="offer.companyName" :price="'tl ' + offer.amount" :starRating="'4.5'" imgUrl="ben.png" :isAccept="offer.isAccept"></OffersCard>
                <button class="btn-none slider-arrows" @click="changeSlide(true)">
                    <i class="fa-solid fa-arrow-right font-size-14"></i>
                </button> -->
                <!-- <OffersCard class="ml-y1" :fulName="'Sevim ERGÜL'" :compnay="'Dalisto Logistics'" :price="'tl 25.000'" :starRating="'4.5'" imgUrl="ben.png"></OffersCard>
                <OffersCard class="ml-y1" :fulName="'Rıza Turancan YILMAZ'" :compnay="'Dalisto Logistics'" :price="'tl 15.000'" :starRating="'4.5'" imgUrl="ben.png"></OffersCard>
                <OffersCard class="ml-y1" :fulName="'Rıza Turancan YILMAZ'" :compnay="'Dalisto Logistics'" :price="'tl 15.000'" :starRating="'4.5'" imgUrl="ben.png"></OffersCard> -->
            </div>
        </div>

        <div class="dp-flex-column mt-y3">
            <div class="dp-flex-row">
                <div class="dp-flex-column justify-content-between width-50 panel-content">
                    <TitleWithIcon :title="$t('ad-detail.Title')"></TitleWithIcon>

                    <div class="dp-flex-row justify-content-between mt-mb-10">
                        <AdDetailRow :text="load.shipmentType">
                            <template slot="image">
                                <img class="width-50" src="@/assets/images/customer-area/my-ads/icon1.png" />
                            </template>
                        </AdDetailRow>

                        <AdDetailRow class="ml-y1" :text="load.vehicleType">
                            <template slot="image">
                                <img class="width-50" src="@/assets/images/customer-area/my-ads/create-new-ads/truck2.png" />
                            </template>
                        </AdDetailRow>
                    </div>
                </div>

                <div class="dp-flex-column ml-y1 justify-content-between panel-content">

                    <TitleWithIcon :title="$t('vehicle-infos.Title')" :actionName="'Düzenle'">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>

                    <div class="dp-flex-row dp-flex-wrap justify-content-between mt-mb-10">
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.VehicleCount')" :description="load.vehicleCount"></BlueBox>
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.TrailerType')" :description="load.trailerType"></BlueBox>
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.CaseProperties')" :description="load.caseFeatures"></BlueBox>
                        <BlueBox class="vehicle-infos-content-2 flex-wrap-48" :title="$t('vehicle-infos.Body_Floor_Type')" :description="load.bodyFloorType"></BlueBox>
                    </div>

                </div>
            </div>
        </div>

        <div class="dp-flex-column justify-content-between">
            <div class="dp-flex-row mt-mb-15 justify-content-between">
                <div class="panel-content width-50">
                    <TitleWithIcon :title="$t('ad-summary.LoadingPoint')" :actionName="'Düzenle'">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>
                    <div class="dp-flex-column mt-mb-y1">
                        <div class="dp-flex-column mt-mb-y25">
                            <div class="dp-flex-row">
                                <div class="dp-flex-column width-100">
                                    <h3 class="font-size-16 text-semi-bold">
                                        {{load.loadingAddress.title}}
                                    </h3>
                                    <h5 class="font-size-16">
                                        {{load.loadingAddress.country + "-" + load.loadingAddress.city + "-" + load.loadingAddress.district}}
                                    </h5>
                                </div>
                            </div>
                            <p class="font-size-14 mt-mb-y1">
                                {{load.loadingAddress.direction}}
                            </p>
                            <div class="dp-flex-column width-100 mt-mb-y1">
                                <h3 class="font-size-16 text-semi-bold">
                                    {{$t('ad-summary.EstimatedDeliveryTime')}}
                                </h3>

                                <div class="dp-flex-row font-size-14 width-100">
                                    <div class="dp-flex-row items-center">
                                        <i class="fa-solid color-1A5EF7 font-size-16 fa-clock"></i>
                                        <p class="ml-10 font-size-14">
                                            {{load.loadingAddress.earliestDate.replace('T',' ')}}
                                        </p>
                                    </div>
                                    <div class="ml-y1 dp-flex-row items-center">
                                        <i class="fa-solid color-1A5EF7 font-size-16 fa-hourglass-end"></i>
                                        <p class="ml-10 font-size-14">
                                            {{load.loadingAddress.latestDate.replace('T', ' ')}}
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-content width-50 ml-y1">
                    <TitleWithIcon :title="$t('ad-summary.DischargePoints')" :actionName="'Düzenle'">
                        <template v-slot:action-icon>
                            <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                        </template>
                    </TitleWithIcon>
                    <div class="dp-flex-row">
                        <button @click="selectUnloadingAddress(unloadingAddress.id,unloadingAddress.order)" v-for="unloadingAddress in load.unLoadingAddresses" :key="unloadingAddress.id" :class="unloadingAddress.order == selectedUnloadingAddressOrder ? 'btn-first-unloading-point' : 'btn-other-unloading-point'" class="btn-none btn-first-unloading-point">{{unloadingAddress.order == selectedUnloadingAddressOrder ? + unloadingAddress.order + '. ' + $t('new-address.Unloading_Point_Title') : unloadingAddress.order + '.'}}</button>
                    </div>
                    <template v-for="(unloadingAddress, index) in load.unLoadingAddresses">
                        <div :key="index" v-if="selectedUnloadingAddressId == unloadingAddress.id" class="dp-flex-column mt-mb-y1">
                            <div class="dp-flex-column mt-mb-y25">
                                <div class="dp-flex-row">
                                    <div class="dp-flex-column width-100">
                                        <h3 class="font-size-16 text-semi-bold">
                                            {{unloadingAddress.title}}
                                        </h3>
                                        <h5 class="font-size-16">
                                            {{unloadingAddress.country + "-" + unloadingAddress.city + "-" + unloadingAddress.district}}
                                        </h5>
                                    </div>
                                </div>
                                <p class="font-size-14 mt-mb-y1">
                                    {{unloadingAddress.direction}}
                                </p>
                                <div class="dp-flex-column width-100 mt-mb-y1">
                                    <h3 class="font-size-16 text-semi-bold">
                                        {{$t('ad-summary.EstimatedDeliveryTime')}}
                                    </h3>

                                    <div class="dp-flex-row font-size-14 width-100">
                                        <div class="dp-flex-row items-center">
                                            <i class="fa-solid color-1A5EF7 font-size-16 fa-clock"></i>
                                            <p class="ml-10 font-size-14">
                                                {{unloadingAddress.earliestDate.replace('T', ' ')}}
                                            </p>
                                            <!-- <p class="ml-15 font-size-14">
                                            09.00 / 12.00
                                        </p> -->
                                        </div>
                                        <div class="ml-y1 dp-flex-row items-center">
                                            <i class="fa-solid color-1A5EF7 font-size-16 fa-hourglass-end"></i>
                                            <p class="ml-10 font-size-14">
                                                {{unloadingAddress.latestDate.replace('T', ' ')}}
                                            </p>
                                            <!-- <p class="ml-15 font-size-14">
                                            09.00 / 12.00
                                        </p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="dp-flex-column mt-mb-15 panel-content justify-content-between">
                <TitleWithIcon :title="$t('load-types.Title')" :actionName="'Düzenle'">
                    <template v-slot:action-icon>
                        <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
                    </template>
                </TitleWithIcon>

                <div class="dp-flex-row dp-flex-wrap justify-content-between mt-mb-10">
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.CargoType')" :description="load.loadItemType"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LoadType')" :description="load.loadType"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Volume')" :description="load.volume + ' m³'"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Desi')" :description="load.decimeter"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LDM')" :description="load.ldmLoadingMeter"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.TonnageRange')" :description="load.range"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.LoadingShape')" :description="load.loadingMethod"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.CostValue')" :description="load.currencyCode + ' ' + load.value"></BlueBox>
                    <BlueBox class="flex-wrap-31 m-5" :title="$t('load-types.Title')" :description="(load.isFlammable ? ($t('draftAd.Is_Flammable') + ', ') : '') + (load.isStacked ? ($t('draftAd.Is_Stacked') + ', ') : '') + (load.isHamal ? $t('draftAd.Is_Hamal') : '')"></BlueBox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import TitleWithIcon from "@/components/Widgets/CustomerPanel/TitleWithIcon.vue";
import AdDetailRow from "./AdDetailRow.vue";
import VehicleInfos from "./VehicleInfos.vue";
import BlueBox from "@/components/Widgets/CustomerPanel/BlueBox.vue";
import OffersCard from "@/components/Widgets/CustomerPanel/OffersCard.vue";
import Map from "@/components/Widgets/Map/Map.vue";
import { APIUrls } from "@/Helpers/APIUrls";
export default {
    components: {
        Title,
        TitleWithIcon,
        AdDetailRow,
        VehicleInfos,
        BlueBox,
        OffersCard,
        Map,
    },
    data() {
        return {
            loadId: 0,
            load: {},
            offers: [],
            selectedUnloadingAddressId: 0,
            selectedUnloadingAddressOrder: 1,
            showOfferStart: 0,
            showOfferEnd: 4,
        };
    },
    mounted() {
        if (this.$route.params.id != 0 && this.$route.params.id != null) {
            //console.log(this.$route.params.id);

            this.loadId = this.$route.params.id;

            // get-load-detail
            this.axios({
                url: APIUrls.GET_LOAD_WITH_DETAIL,
                method: "GET",
                params: {
                    id: this.loadId,
                },
            })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.code == 14) {
                            this.load = response.data.data;
                            this.selectedUnloadingAddressId = this.load.unLoadingAddresses[0].id;
                            //console.log(this.load);
                        } else {
                            console.log(response.data);
                        }
                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // get-offers
            this.axios({
                url: APIUrls.GET_OFFERS_BY_LOAD_ID,
                method: "GET",
                params: {
                    loadId: this.loadId,
                },
            })
                .then((response) => {
                    if (response.status == 200) {
                        console.log(response.data);
                        if (response.data.code == 14) {
                            this.offers = response.data.data;
                            // console.log(this.offers);
                        } else {
                            console.log(response.data);
                        }
                    } else {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            this.$router.push("/customer/my-ads");
        }
    },

    methods: {
        forceComponent() {
            console.log("burası");
            this.$forceUpdate();
        },

        selectUnloadingAddress(id, order) {
            console.log(id, order);
            let data = this.load.unLoadingAddresses;
            this.selectedUnloadingAddressId = id;
            this.selectedUnloadingAddressOrder = order;
        },

        changeSlide(isLeft) {
            if (isLeft) {
                if (this.showOfferEnd < this.offers.length) {
                    this.showOfferStart += 4;
                    this.showOfferEnd += 4;
                } else {
                    console.log(this.showOfferStart);
                    console.log(this.showOfferEnd);
                    // this.showOfferStart = this.offers.length;
                    // this.showOfferEnd = this.offers.length - 4;
                }
            } else {
                if (this.showOfferStart > 0) {
                    this.showOfferStart -= 4;
                    this.showOfferEnd -= 4;
                }
            }
        },
    },
};
</script>        
<style>
.slider-arrows {
    margin: 0;
    padding: 0;
}
</style>

// TODO: vehicleType ve shipmentType resimleri dinamik yapılacak.
// TODO: map üzerinde yükleme ve boşaltma noktaları arasındaki yollar çizdirilecek.
// TODO: gelene id mevzusunu state managament ile yapmak gerek adam her sayfayı yenilediğnde bir önceki sayfaya gidiyor.