import { CreateOrUpdateDriverModel } from "@/services/DriversService";


const moduleCreateOrUpdateDriver = {
    namespaced: true,
    
    state: {
        createOrUpdateDriver: new CreateOrUpdateDriverModel(),
    },

    getters: {
        getCreateOrUpdateDriver: (state) => {
            return state.createOrUpdateDriver;
        },
    },

    actions: {
        updateCreateOrUpdateDriver: ({ commit }, [createOrUpdateDriver]) => {        
            commit('setCreateOrUpdateDriver', [createOrUpdateDriver]);
        },
    },

    mutations: {
        setCreateOrUpdateDriver: (state, [createOrUpdateDriver]) => {            
            state.createOrUpdateDriver = createOrUpdateDriver;
        },
    },
}

export default moduleCreateOrUpdateDriver;