import Home from './components/IntroductionArea/Home.vue';
import Login from './components/IntroductionArea/UserLoginRegister/Login';
import Register from './components/IntroductionArea/UserLoginRegister/Register';
import ChooseOne from './components/IntroductionArea/UserLoginRegister/ChooseOne.vue';
import ForgetPassword from './components/IntroductionArea/UserLoginRegister/ForgetPassword.vue';
import PopUp from './components/Widgets/ModelPopup/Popup.vue';
import CompleteRegistrationTab from './components/IntroductionArea/CompleteRegistration/CompleteRegistartionTab.vue';
import CompleteRegistration from './components/IntroductionArea/CompleteRegistration/CompleteRegistration.vue';
import LayoutCustomerPanel from './components/CustomerArea/CustomerPanelLayout/_LayoutCustomerPanel.vue';
import CustomerPanelDashboard from './components/CustomerArea/CustomerDashboard/Dashboard.vue';
import MyAdsTab from './components/CustomerArea/MyAds/MyAdsTab.vue';
import ShipmentType from './components/CustomerArea/MyAds/CreateNewAds/ShipmentType.vue';
import VehicleType from './components/CustomerArea/MyAds/CreateNewAds/VehicleType.vue';
import RegisteredAddresses from './components/CustomerArea/Addresses/LoadingPoint/RegisteredAddresses';
import NewAddress from './components/CustomerArea/Addresses/LoadingPoint/NewAddress';
import UnloadingPoints from './components/CustomerArea/Addresses/UnloadingPoint/UnloadingPoints';
import RegisteredUnloadingPoints from './components/CustomerArea/Addresses/UnloadingPoint/RegisteredUnloadingPoints';
import LoadTypes from './components/CustomerArea/MyAds/CreateNewAds/LoadTypes';
import AdSummary from './components/CustomerArea/MyAds/AdSummary/AdSummary';
import SaveAd from './components/CustomerArea/MyAds/AdSummary/SaveAd';
import AdDetails from './components/CustomerArea/MyAds/AdSummary/AdDetails';
import Messages from './components/CustomerArea/Messages/Messages';
import IncomingMessages from './components/CustomerArea/Messages/IncomingMessages';
import OnlineMessage from './components/Widgets/CustomerPanel/OnlineMessage';
import OnlineMessageText from './components/Widgets/CustomerPanel/OnlineMessageText';
import NotFound from './components/Widgets/ErrorPages/404.vue';
import Packages from './components/CustomerArea/Packages/Packages';
import Profile from './components/CustomerArea/SettingsComponents/Profile';
import LayoutTransporterPanel from './components/TransporterArea/TransporterPanelLayout/_LayoutTransporterPanel';
import AuthorizedUsers from './components/CustomerArea/Users/AuthorizedUsers';
// import DataTable from './components/Widgets/Datatable/DataTable';
import BusinessProfile from './components/CustomerArea/Users/BusinessProfile';
import BillingPreferences from './components/CustomerArea/Users/BillingPreferences';
import TransactionPopup from './components/Widgets/ModelPopup/TransactionPopup';
import ResetPassword from './components/IntroductionArea/UserLoginRegister/ResetPassword';
import Loads from './components/TransporterArea/Loads/Loads';
import MyVehicles from './components/TransporterArea/Vehicles/MyVehicles';
import LoadDetail from './components/TransporterArea/Loads/LoadDetail';
import CreateVehicle from './components/TransporterArea/Vehicles/CreateVehicle';
import MyDrivers from './components/TransporterArea/Drivers/MyDrivers';
import EmptyAds from './components/CustomerArea/MyAds/EmptyAds.vue';
import Offers from './components/TransporterArea/Offers/Offers.vue';
import Voyages from './components/TransporterArea/Voyages/Voyages.vue';
import VoyageDetail from './components/TransporterArea/Voyages/VoyageDetail.vue';
import CreateDriver from './components/TransporterArea/Drivers/CreateDriver.vue';
import CustomerVoyages from './components/CustomerArea/Voyages/Voyages.vue';
import CustomerVoyageDetail from './components/CustomerArea/Voyages/VoyageDetail.vue';
import TransporterUserProfile from './components/TransporterArea/Profile/Profile.vue';
import TransporterMessges from './components/TransporterArea/Messages/Messages.vue';
import TransporterIncomingMessages from './components/TransporterArea/Messages/IncomingMessages.vue';
import TransporterAuthorizedUsers from './components/TransporterArea/Users/AuthorizedUsers.vue';
import CompanyProfile from './components/TransporterArea/Company/CompanyProfile.vue';

export default [
    { name: 'Home', path: '/', component: Home },
    { path: '*', component: NotFound },
    { name: 'Login', path: '/login', component: Login, meta: { isExistUserData: true }, props: true },
    { name: 'Register', path: '/register', component: Register, meta: { isExistUserData: true }, props: true },
    { name: 'ChooseOne', path: '/choose-one', component: ChooseOne, props: true },
    { path: '/forget-password', component: ForgetPassword },
    { path: '/reset-password', component: ResetPassword },
    { path: '/pop-up', component: PopUp },
    { path: '/identity-information', component: CompleteRegistrationTab },
    { path: '/complete-registration', component: CompleteRegistration },
    // { path: '/datatable', component: DataTable },
    { path: '/deneme-popup', component: TransactionPopup },
    {
        name: 'Customer',
        path: '/customer',
        component: LayoutCustomerPanel,
        meta: { requiresAuth: true, isCustomer: true },
        children: [
            { path: '/', component: CustomerPanelDashboard },
            { path: 'my-ads', component: MyAdsTab },
            { path: 'empty-ads', component: EmptyAds },
            { path: 'new-ads', component: ShipmentType },
            { path: 'vehicle-type', component: VehicleType },
            { path: 'registered-addresses', component: RegisteredAddresses },
            { name: 'new-address', path: 'new-address', component: NewAddress, props: true },
            { path: 'unloading-points', component: UnloadingPoints },
            { path: 'registered-unloading-points', component: RegisteredUnloadingPoints },
            { path: 'load-types', component: LoadTypes },
            { name: 'ad-summary', path: 'ad-summary', component: AdSummary },
            { path: 'save-ad', component: SaveAd },
            { name: 'ad-details', path: 'ad-details', component: AdDetails },
            { path: 'messages', component: Messages },
            { name: 'incoming-messages', path: 'incoming-messages', component: IncomingMessages },
            { path: 'online-message', component: OnlineMessage },
            { path: 'online-message-text', component: OnlineMessageText },
            { path: 'packages', component: Packages },
            { path: 'profile', component: Profile },
            { path: 'authorized-users', component: AuthorizedUsers },
            { path: 'business-profile', component: BusinessProfile },
            { path: 'billing-preferences', component: BillingPreferences },
            { path: 'voyages', component: CustomerVoyages },
            { name: 'customer-voyage-detail', path: 'voyage-detail', component: CustomerVoyageDetail },
        ]
    },
    {
        name: 'Transporter',
        path: '/transporter',
        component: LayoutTransporterPanel,
        meta: { requiresAuth: true, isTransporter: true },
        children: [
            { path: '/', component: Loads },
            { path: 'my-vehicles', component: MyVehicles },
            { name: 'load-detail', path: 'load-detail', component: LoadDetail },
            { path: 'create-vehilce', component: CreateVehicle },
            { path: 'my-drivers', component: MyDrivers },
            { path: 'create-driver', component: CreateDriver },
            { name: 'offers', path: "offers", component: Offers },
            { name: "voyages", path: "voyages", component: Voyages },
            { name: "voyage-detail", path: "voyage-detail", component: VoyageDetail },
            { path: "profile", component: TransporterUserProfile },
            { name: "transporter-messages", path: "transporter-messages", component: TransporterMessges },
            { name: "transporter-incoming-messages", path: "incoming-messages", component: TransporterIncomingMessages },
            { path: 'authorized-users', component: TransporterAuthorizedUsers },
            { path: 'company-profile', component: CompanyProfile },
        ]
    }

]