<template>
    <div class="dp-flex-column">

        <Title :title="$t('customer-vehicle-info-page.Title')" :description="$t('customer-vehicle-info-page.Desc')"></Title>

        <span v-if="this.vehicleCount == 0 || (this.trailerType.id == 0 && this.bodyFloorType.id == 0)" class="vee-validate-error-message">
            {{$t('inputValidation.Make_Choice')}}
        </span>

        <div class="panel-content vehicle-info-content">
            <form class="dp-flex-row" method="get">
                <div class="dp-flex-column vehicle-info-content-item">
                    <p class="font-size-14 color-667085">
                        {{$t("customer-vehicle-info-page.VehicleCount")}}
                    </p>
                    <input v-model="vehicleCount" @change="setSelectedVehicleInfoValues()" class="inp-vehicle-info color-667085 " type="number" min="1" max="" value="1">
                </div>
                <div class="dp-flex-column vehicle-info-content-item">
                    <p class="font-size-14 color-667085">
                        {{$t("customer-vehicle-info-page.BodyType")}}
                    </p>
                    <select @change="setSelectedVehicleInfoValues()" v-model="trailerType" class="inp-vehicle-info color-667085 ">
                        <option class="vehicle-info-option color-667085" :key="0" :value="{id: 0, text: ''}" selected>
                            {{$t('inputValidation.Make_Choice')}}
                        </option>
                        <option class="vehicle-info-option color-667085" :key="bodyType.id" v-for="bodyType in vehicleBodyTypes" :value="{id: bodyType.id, text: bodyType.name}">
                            {{bodyType.name}}
                        </option>
                    </select>
                </div>

                <div class="dp-flex-column vehicle-info-content-item">
                    <p class="font-size-14 color-667085">
                        {{$t("customer-vehicle-info-page.CaseFeatures")}}
                    </p>
                    <input v-model="caseType" @change="setSelectedVehicleInfoValues()" class="inp-vehicle-info color-667085 " type="text">
                </div>

                <div class="dp-flex-column vehicle-info-content-item mr-y1">
                    <p class="font-size-14 color-667085">
                        {{$t("customer-vehicle-info-page.BodyFloorType")}}
                    </p>
                    <select @change="setSelectedVehicleInfoValues()" v-model="bodyFloorType" class="inp-vehicle-info color-667085 ">
                        <option class="vehicle-info-option color-667085" :key="0" :value="{id: 0, text: ''}" selected>
                            {{$t('inputValidation.Make_Choice')}}
                        </option>
                        <option class="vehicle-info-option color-667085" :key="bodyFloorType.id" v-for="bodyFloorType in bodyFloorTypes" :value="{id:bodyFloorType.id, text:bodyFloorType.name}">
                            {{bodyFloorType.name}}
                        </option>
                    </select>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
import LayoutCustomerPanel from "../../CustomerPanelLayout/_LayoutCustomerPanel.vue";
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import LoadService from "@/services/LoadService";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        LayoutCustomerPanel,
        Title,
    },

    data() {
        return {
            vehicleCount: 1,
            trailerType: {
                id: 0,
                text: "",
            },
            bodyFloorType: {
                id: 0,
                text: "",
            },
            caseType: "",

            vehicleBodyTypes: [],
            loadTonnageRanges: [],
            bodyFloorTypes: [],
            resultModel: [],

            loadService: new LoadService(),
            isActive: true,
        };
    },

    computed: {
        ...mapGetters("createLoad", ["getVehicleInfos"]),
    },

    async mounted() {
        await this.getVehicleBodyTypes();

        await this.getBodyFloorTypes();

        this.vehicleCount = this.getVehicleInfos.vehicleCount;
        this.trailerType.id = this.getVehicleInfos.trailerType;
        this.trailerType.text = this.getVehicleInfos.trailerTypeName;
        this.caseType = this.getVehicleInfos.caseType;
        this.bodyFloorType.id = this.getVehicleInfos.bodyFloorType;
        this.bodyFloorType.text = this.getVehicleInfos.bodyFloorTypeName;
    },

    methods: {
        ...mapActions("createLoad", ["updateVehicleInfos"]),

        // parent component'e veri gönderdik.
        setSelectedVehicleInfoValues() {
            //console.log(this.bodyFloorType.text);

            this.$emit("updateMultiple", {
                vehicleCount: parseInt(this.vehicleCount),
                trailerType: this.trailerType.id,
                trailerTypeName: this.trailerType.text,
                bodyFloorType: this.bodyFloorType.id,
                bodyFloorTypeName: this.bodyFloorType.text,
                caseType: this.caseType,
            });
        },

        async getVehicleBodyTypes() {
            await this.loadService.getVehicleBodyTypes(this.isActive);

            if (this.loadService.resultModel.code == 14) {
                this.vehicleBodyTypes = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getBodyFloorTypes() {
            await this.loadService.getBodyFloorTypes(this.isActive);

            if (this.loadService.resultModel.code == 14) {
                this.bodyFloorTypes = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/my-ads/create-new-ads/vehicle-info.css";
</style>
