import mqtt from "mqtt/dist/mqtt";
import { ResultModel } from '@/Helpers/Models';


export default class MQTTHelper {
    resultModel = new ResultModel();
    errorResult = "";
    mqttResult = "";

    connection = {
        protocol: "ws",
        host: "171.22.187.222",
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: "/mqtt",
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId: "vuejs-client",
        // auth
        username: "dalisto",
        password: "dalisto123",
    };
    subscription = {
        topic: "",
        qos: 0,
    };
    publish = {
        topic: "",
        qos: 0,
        payload: "",
    };
    receiveNews = "";
    qosList = [0, 1, 2];
    client = {
        connected: false,
    };
    subscribeSuccess = false;
    connecting = false;
    retryTimes = 0;

    subscribeTopic() {
        const topic = this.subscription.topic;
        this.client.subscribe(topic);
        console.log(`MQTT ${topic} TOPIC SUBSCRIBED.`);
    };

    initData() {
        this.client = {
            connected: false,
        };
        this.retryTimes = 0;
        this.connecting = false;
        this.subscribeSuccess = false;
    };

    handleOnReConnect() {
        this.retryTimes += 1;
        if (this.retryTimes > 5) {
            try {
                this.client.end();
                this.initData();
                // this.$message.error("Connection maxReconnectTimes limit, stop retry");
                console.log("Connection maxReconnectTimes limit, stop retry");
            } catch (error) {
                // this.$message.error(error.toString());
                console.log(error.toString());
            }
        }
    };

    createConnection() {
        try {
            this.connecting = true;

            const { protocol, host, port, endpoint, ...options } = this.connection;
            const connectUrl = `${protocol}://${host}:${port}${endpoint}`;

            this.client = mqtt.connect(connectUrl, options);

            if (this.client.on) {

                // connect durumu
                this.client.on("connect", () => {
                    this.connecting = false;
                    console.log("CONNECTION SUCCEEDED !");
                });

                // reconnect durumu
                this.client.on("reconnect", this.handleOnReConnect);

                // error durumu
                this.client.on("error", (error) => {
                    console.log("CONNECTION FAILED !", error);
                });

                // // gelen mesajı dinlediğimiz yer
                // this.client.on("message", (topic, message) => {
                //     // Gelen mesajı konsola yaz
                //     console.log(`Gelen mesaj - Topic: ${topic}, Mesaj: ${message.toString()}`);
                //     this.mqttResult = message.toString();
                // });
            }
        } catch (error) {
            this.connecting = false;
            console.log("mqtt.connect error", error);
        }
    };

    getMqttMessage(callback) {
        this.client.on("message", (topic, message) => {
            callback(topic, message);
        });
    };

    doSubscribe() {
        const { topic, qos } = this.subscription;

        this.client.subscribe(topic, { qos }, (error, res) => {
            if (error) {
                console.log("SUBSCRIBE TO TOPICS ERROR", error);
                return;
            }
            this.subscribeSuccess = true;

            console.log("SUBSCRIBE TO TOPIC RESULT => ", res);
        });
    };

    doUnSubscribe() {
        const { topic } = this.subscription;

        this.client.unsubscribe(topic, (error) => {
            if (error) {
                console.log("UNSUBSCRIBE ERROR => ", error);
            }
        });
    };

    destroyConnection() {
        if (this.client.connected) {
            try {
                this.client.end(false, () => {
                    this.initData();
                    console.log("SUCCESSFULLY DISCONNETED !");
                });
            } catch (error) {
                console.log("DISCONNECT FAILED ! ", error.toString());
            }
        }
        else {
            console.log("CLIENT NOT CONNECTED !");
        }
    };

}

// TODO: mqtt kullanılan yerlerde helper'dan çekilecek.
// TODO: bağlanma ve hata durumlarında toast mesaj gösterilebilir.


// NOTE: hepsinde genel sorun protocol'deymiş 2 protocol var ws wss iksinin de sabit portları var bu ikisini kullanıp sabit portlar kullanılmalı yoksa bağlanmıyor. ws 8083 wss 8084
// NOTE: github respository linki => https://github.com/mqttjs/MQTT.js
// NOTE: npm package linki => https://www.npmjs.com/package/mqtt