import axios from "axios";


export default class ApiHelper {

    get(url = "", params = {}) {
        return axios.get(url, { params: params });
    }

    postQuery(url = "", params = {}) {
        return axios.post(url, null, { params: params });
    }

    post(url = "", data, config = {}) {
        return axios.post(url, data, config);
    }

}