<template>
    <div class="dp-flex-column">
        <Title :title="$t('transporterSideBar.My_Authorized_Users')"></Title>

        <div class="dp-flex-row items-center">
            <button @click="openPopup()" class="dp-flex-row p-y1 font-size-14 authorized-users-add-user-button mt-mb-y1 items-center color-667085 btn-none">
                <i class="fa-solid color-26B198 font-size-14 fa-circle-plus mr-y5"></i>
                {{ $t('button.Add_User') }}
            </button>
        </div>

        <div class="dp-flex-row justify-content-between items-center p-y1 authorized-users-search-bar">

            <div class="width-50 dp-flex-row items-center">
                <i class="fa-solid color-929DA7 search-icon fa-magnifying-glass font-size-15"></i>
                <input v-model="searchText" @input="searchUser()" class="search-input search-button" type="text" :placeholder="$t('authorizedUser.Search_Bar')" />
            </div>

            <LabelWithInput class="width-40 ml-mr-10" :isLabelActive="false" :type="'select'" :selectItems="selectBoxItems"></LabelWithInput>

            <button class="btn-none border-radius-5 font-size-14 width-10 authorized-users-search-button">
                {{ $t('button.Search') }}
            </button>
        </div>

        <Loading v-if="isLoading"></Loading>

        <div v-if="!isLoading" class="panel-content mt-y1">
            <table class="vue-table panel-content">
                <tr>
                    <th class="vue-table-header vue-table-border font-size-14 color-797979 text-left p-y1">
                        {{ $t('authorizedUser.Name_Surname') }}
                    </th>
                    <th class="vue-table-header vue-table-border font-size-14 color-797979 text-left p-y1">
                        {{ $t('authorizedUser.Duty') }}
                    </th>
                    <th class="vue-table-header vue-table-border font-size-14 color-797979 text-left p-y1">
                        {{ $t('authorizedUser.Email') }}
                    </th>
                    <th class="vue-table-header vue-table-border font-size-14 color-797979 text-left p-y1">
                        {{ $t('authorizedUser.Membership_Date') }}
                    </th>
                    <th class="vue-table-header vue-table-border font-size-14 color-797979 text-left p-y1">
                        {{ $t('authorizedUser.Phone') }}
                    </th>
                    <th class="vue-table-header vue-table-border font-size-14 color-797979 text-left p-y1">
                        {{ $t('authorizedUser.Action') }}
                    </th>
                </tr>

                <tr :key="user.id" v-for="user in users">
                    <td class="vue-table-border font-size-14 p-y1">
                        {{ user.name }} {{ user.surname }}
                    </td>
                    <td class="vue-table-border font-size-14 p-y1">
                        {{ user.title == null ? 'Belirtilmemiş' : user.title }}
                    </td>
                    <td class="vue-table-border font-size-14 p-y1">
                        {{ user.email }}
                    </td>
                    <td class="vue-table-border font-size-14 p-y1">
                        {{ user.createdAt }}
                    </td>
                    <td class="vue-table-border font-size-14 p-y1">
                        {{ user.phone }}
                    </td>
                    <td class="vue-table-border font-size-14 p-y1">
                        <button @click="isOpenPopup = true, officialUser.id = user.id, officialUser.name = user.name, officialUser.surname = user.surname, officialUser.email = user.email, officialUser.phone = user.phone, officialUser.title = user.title" class="btn-none vue-table-action-button">
                            <i class="fa-solid font-size-20 color-797979 fa-pen"></i>
                        </button>
                        <button @click="isOpenDeletePopup = true, deletedUserId = user.id" class="btn-none vue-table-action-button ml-10">
                            <i class="fa-solid font-size-20 color-797979 fa-trash-can"></i>
                        </button>
                    </td>
                </tr>
            </table>

            <div class="dp-flex-row justify-content-end mt-y1">
                <select class="paginate-button width-5">
                    <option value="1">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
                <button class="btn-none ml-mr-5 paginate-button">
                    10 / 13
                </button>
                <button class="btn-none paginate-button-disable ml-mr-5 paginate-button-size paginate-button">
                    <i class="fa-solid font-size-13 fa-angle-left"></i>
                </button>
                <button class="btn-none paginate-button-active ml-mr-5 text-color-white font-size-14 paginate-button-size paginate-button">
                    1
                </button>
                <button class="btn-none font-size-14 paginate-button ml-mr-5 paginate-button-size">
                    2
                </button>
                <button class="btn-none paginate-button ml-mr-5 paginate-button-size">
                    <i class="fa-solid font-size-13 fa-angle-right"></i>
                </button>
            </div>
        </div>

        <TransactionPopup v-if="isOpenPopup" @closePopup="isOpenPopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center">
                    {{ officialUser.id == 0 ? 'Yeni Kullanıcı Ekle' : 'Kullanıcıyı Güncelle' }}
                </p>
            </template>
            <template slot="popup-body">
                <div style="width:50vw!important" class="width-100 dp-flex-column">
                    <div class="width-100 mt-y3 dp-flex-row">
                        <LabelWithInputWithButtonRow v-model="officialUser.name" :type="'text'" :isLabelActive="true" :label="$t('profile.Name')" :isButtonActive="false"></LabelWithInputWithButtonRow>
                        <LabelWithInputWithButtonRow v-model="officialUser.surname" :type="'text'" class="ml-y4" :isLabelActive="true" :label="$t('profile.SurName')" :isButtonActive="false"></LabelWithInputWithButtonRow>
                    </div>
                    <div class="width-100 mt-y3 dp-flex-row">
                        <LabelWithInputWithButtonRow v-model="officialUser.email" :type="'text'" :isLabelActive="true" :label="$t('profile.Email')" :isButtonActive="false"></LabelWithInputWithButtonRow>
                        <LabelWithInputWithButtonRow v-model="officialUser.phone" :type="'text'" class="ml-y4" :isLabelActive="true" :label="$t('profile.MobilePhone')" :isButtonActive="false"></LabelWithInputWithButtonRow>
                    </div>
                    <div class="width-100 mt-y3 dp-flex-row justify-content-start">
                        <LabelWithInputWithButtonRow v-model="officialUser.title" class="width-50" :type="'text'" :isLabelActive="true" :label="$t('authorizedUser.Duty')" :isButtonActive="false"></LabelWithInputWithButtonRow>
                    </div>
                </div>
            </template>
            <template slot="popup-body-button">
                <button @click="addUser()" class="dp-flex-row btn-none mt-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                    {{ officialUser.id == 0 ? $t('authorizedUser.Add_New_User') : $t('authorizedUser.Update_User') }}
                </button>
            </template>
        </TransactionPopup>

        <TransactionPopup v-if="isOpenDeletePopup" @closePopup="isOpenDeletePopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center">
                    {{ $t('authorizedUser.Delete_User') }}
                </p>
            </template>
            <template slot="popup-body">
                <div style="width:20vw!important" class="width-100 dp-flex-column">
                    <div class="dp-flex-row justify-content-center text-center items-center mt-y3">
                        <p class="font-size-16">
                            {{ $t('authorizedUser.Delete_User_Description') }}
                        </p>
                    </div>
                </div>
            </template>
            <template slot="popup-body-button">
                <div class="dp-flex-row justify-content-center width-100 mt-y5 items-center">
                    <button @click="deleteUser()" class="dp-flex-row btn-none delte-user-button mr-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{ $t('button.Delete') }}
                    </button>
                    <button @click="isOpenDeletePopup = false" class="dp-flex-row btn-none border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{ $t('button.Cancel') }}
                    </button>
                </div>
            </template>
        </TransactionPopup>
    </div>
</template>

<script>

import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import LabelWithInput from "@/components/Widgets/Inputs/LabelWithInput.vue";
import TransactionPopup from "@/components/Widgets/ModelPopup/TransactionPopup.vue";
import LabelWithInputWithButtonRow from "@/components/Widgets/Inputs/LabelWithInputWithButtonRow.vue";
import OfficialUsersService from "@/services/OfficialUsersService";
import Loading from '@/components/Widgets/Loadings/Loading.vue';

export default {
    components: {
        Title,
        LabelWithInput,
        TransactionPopup,
        LabelWithInputWithButtonRow,
        Loading,
    },

    data() {
        return {
            searchText: "",
            isOpenPopup: false,
            isOpenPupUpAlert: false,

            isOpenDeletePopup: false,

            deletedUserId: 0,

            selectBoxItems: [
                { text: "Tüm Personeller", value: 1 },
                { text: "Müşteri Personeli", value: 2 },
                { text: "Müşteri Yöneticisi", value: 3 },
            ],

            officialUsersService: new OfficialUsersService(),
            users: [],
            filterDto: {
                IsLatest: false,
                Page: 0,
                Search: "",
                Take: 10,
                IsActive: true,
            },
            officialUser: {
                id: 0,
                name: "",
                surname: "",
                email: "",
                phone: "",
                title: "",
                createdAt: "",
                userType: 2,
            },
            isLoading: false,
        };
    },

    async mounted() {
        this.isLoading = true;

        this.users = await this.officialUsersService.getOfficialUsersByOwnerId(this.filterDto);

        this.isLoading = false;
    },

    methods: {
        async searchUser() {
            this.isLoading = true;

            this.filterDto.Search = this.searchText;

            this.users = await this.officialUsersService.getOfficialUsersByOwnerId(this.filterDto);

            this.isLoading = false;
        },

        openPopup() {
            this.isOpenPopup = true;
            this.officialUser.name = "";
            this.officialUser.surname = "";
            this.officialUser.email = "";
            this.officialUser.phone = "";
            this.officialUser.title = "";
            this.officialUser.id = 0;
        },

        closePopup() {
            this.isOpenPopup = false;
            this.isOpenPupUpAlert = false;
        },

        async addUser() {
            if (this.officialUser.name != "" && this.officialUser.surname != "" && this.officialUser.title != "" && this.officialUser.email != "" && this.officialUser.phone != "") {
                await this.officialUsersService.createOrUpdateOfficialUser(this.officialUser);
                this.users = await this.officialUsersService.getOfficialUsersByOwnerId(this.filterDto);
                this.isOpenPopup = false;
            } else {
                this.$toast.warning(this.$t('resultModel.Model_Invalid'));
            }
        },

        deleteUser() {
            if (this.deletedUserId > 0) {
                this.officialUsersService.deleteOrRestoreDeleteOfficialUser(this.deletedUserId, false);

                let deletedUser = this.users.map((x) => x.id).indexOf(this.deletedUserId);

                this.users.splice(deletedUser, 1);

                this.isOpenDeletePopup = false;
            }
        },
    },
};
</script>

<style>
@import url("@/assets/css/customer-area/users/authorized-users.css");

.delte-user-button:hover {
    background-color: #e3384d;
    border-color: #e3384d;
    color: white;
}
</style>