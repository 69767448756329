<template>
    <div class="dp-flex-column">
        <div class="my-ads-title mt-mb-35">
            <h1 class="color-667085 font-size-25">
                {{$t("my-ads-tab.Title")}}
            </h1>
            <p class="color-667085 font-size-15 text-semi-bold">
                {{$t("my-ads-tab.Desc")}}
            </p>
        </div>
        <div class="tab mt-mb-25">
            <div class="tab-button-header dp-flex-row justify-content-start">
                <button @click="selectTab(1)" v-bind:class="{'button-active':currentTab == 1, 'button-pasive':currentTab != 1}" class="font-size-15 text-capitalize btn-none color-667085">
                    {{$t("my-ads-tab.ActiveAds")}}
                </button>

                <button @click="selectTab(2)" v-bind:class="{'button-active':currentTab == 2, 'button-pasive':currentTab != 2}" class="font-size-15 text-capitalize btn-none color-667085">
                    {{$t("my-ads-tab.PastAds")}}
                </button>

                <button @click="selectTab(3)" v-bind:class="{'button-active':currentTab == 3, 'button-pasive':currentTab != 3}" class="font-size-15 text-capitalize btn-none color-667085">
                    {{$t("my-ads-tab.Deleted_Ads")}}
                </button>
            </div>
            <div class="tab-content dp-flex-column items-center">
                <AdsSearch></AdsSearch>
            </div>
            <ActiveAds v-if="currentTab == 1"></ActiveAds>
            <PastAds v-if="currentTab == 2"></PastAds>
            <DeletedAds v-if="currentTab == 3"></DeletedAds>
        </div>
    </div>
</template>

<script>
import ActiveAds from "./ActiveAds.vue";
import PastAds from "./PastAds.vue";
import AdsSearch from "./AdsSearch.vue";
import DeletedAds from "./DeletedAds.vue";

export default {
    name: "MyAdsTab",
    components: {
        ActiveAds,
        PastAds,
        AdsSearch,
        DeletedAds,
    },
    data() {
        return {
            currentTab: 1,
            isActiveActiveAds: true,
            isActivePastAds: false,
        };
    },
    methods: {
        selectTab(selectedTab) {
            this.currentTab = selectedTab;
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/my-ads/my-ads-tab.css";
</style>