<template>
    <div class="dp-flex-column">
        <Loading v-if="isLoading">

        </Loading>

        <div v-if="!isLoading" class="dp-flex-column justify-content-between">
            <div class="dp-flex-row justify-content-center font-size-20 text-color-white items-center load-price border-radius-5 fiyat">
                {{ voyage.title }} {{ $t('messages.Voyage_Title') }}
            </div>

            <div class="panel-content mt-mb-y1">
                <Map :vehicleLocations="vehicleLocations" :loadingUnloadingPoints="loadingUnloadingPoints">

                </Map>
            </div>

            <div class="dp-flex-column mt-mb-y1 panel-content">
                <div class="dp-flex-row font-size-20 justify-content-start items-center bottom-border">
                    {{ $t('transporterVoyage.Vehicle_List') }}
                </div>

                <div class="dp-flex-row dp-flex-wrap justify-content-start mt-y1">
                    <div v-for="vehicle in vehicleStatus" :key="vehicle.vehicleId" class="voyage-info-card dp-flex-row mb-y1 width-20 mr-y1 justify-content-between items-center border-radius-5 p-y1">
                        <div class="dp-flex-column width-40">
                            <p class="font-size-15 mb-y3">
                                {{ vehicle.licencePlate }}
                            </p>

                            <p :class="vehicle.status == 1 ? 'voyage-status-hold' : vehicle.status == 2 ? 'voyage-status-continue' : 'voyage-status-finished'" class="font-size-15 voyage-status-cards">
                                {{ vehicle.status == 1 ? $t('voyageVehicleStatus.Waiting') : vehicle.status == 2 ? $t('voyageVehicleStatus.On_The_Way') : $t('voyageVehicleStatus.Finished') }}
                            </p>
                        </div>

                        <div class="dp-flex-column width-60 voyage-user-infos">
                            <div v-for="driver in vehicle.drivers" :key="driver.name" class="dp-flex-row justify-content-start items-center mb-y3">
                                <img class="width-20 mr-y5 voyage-user-img" :src="AssetUrls.USER_ASSETS_URL + driver.profilePhoto" @error="handleImageError" alt="driver-profile-photo">

                                <p class="font-size-15">
                                    {{ driver.name }} {{ driver.surname }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dp-flex-row justify-content-between width-100">
                <div class="dp-flex-column panel-content justify-content-start">
                    <div class="dp-flex-row justify-content-start items-center bottom-border">
                        {{ $t('transporterLoadDetail.Loading_Unloading_Addresses') }}
                    </div>

                    <div v-if="load.loadAddressInfo" class="dp-flex-row justify-content-start width-100" style="overflow-x: scroll; height:100%;padding: 2% 0;">
                        <div style="min-width: 50%;" class="dp-flex-column width-100 justify-content-around konum">
                            <div class="dp-flex-column justify-content-between items-center">
                                <p class="font-size-20 text-medium">
                                    {{ load.loadAddressInfo.cityName }}
                                </p>

                                <p class="font-size-15 color-869094">
                                    {{ load.loadAddressInfo.districtName }}
                                </p>
                            </div>

                            <div class="dp-flex-row justify-content-center items-center">
                                <i class="fa-solid fa-calendar-week font-size-25 color-667085 mr-y5"></i>

                                <div class="dp-flex-column justify-content-center items-center">
                                    <p class="font-size-16 text-medium">
                                        {{ $t('transporterLoadDetail.Loading_Date') }}
                                    </p>

                                    <p class="font-size-13">
                                        {{ load.loadAddressInfo.loadDate.replace('T', ' ') }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div :key="index" v-for="(item, index) in load.unLoadAddressInfo" style="min-width: 50%;" class="dp-flex-row width-100 justify-content-between">
                            <div class="icon-arrow dp-flex-column items-center justify-content-center ml-mr-10">
                                <i class="fa-solid fa-arrow-right"></i>
                            </div>

                            <div style="padding:5%;" class="dp-flex-column width-100 justify-content-around konum">
                                <div class="dp-flex-column justify-content-between items-center">
                                    <p class="font-size-20 text-medium">
                                        {{ item.cityName }}
                                    </p>

                                    <p class="font-size-15 color-869094">
                                        {{ item.districtName }}
                                    </p>
                                </div>

                                <div class="dp-flex-row justify-content-center items-center">
                                    <i class="fa-solid fa-calendar-week font-size-25 color-667085 mr-y5"></i>

                                    <div class="dp-flex-column justify-content-center items-center">
                                        <p class="font-size-16 text-medium">
                                            {{ $t('transporterLoadDetail.Unloading_Date') }}
                                        </p>

                                        <p class="font-size-13">
                                            {{ item.unLoadDate.replace('T', ' ') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dp-flex-column panel-content ml-y1">
                    <div class="dp-flex-row justify-content-start items-center bottom-border">
                        {{ $t('transporterLoadDetail.Load_Fatures') }}
                    </div>

                    <div class="dp-flex-row width-100 justify-content-start">
                        <div class="dp-flex-column width-50 justify-content-start">
                            <!-- <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    Mesafe:
                                </h3>
                                <p class="font-size-14 ml-y2 color-667085">                                    
                                    420 km
                                </p>
                            </div> -->

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Vehicle_Type') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.vehicleType }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Trailer_Type') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.trailerType }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Tonnage_Range') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.range }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Volume') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.volume }} m3
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Desi') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.decimeter }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.LDM') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.ldmLoadingMeter }}
                                </p>
                            </div>
                        </div>

                        <div class="dp-flex-column width-50 justify-content-start">
                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Load_Item_Type') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.loadItemType }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Load_Type') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.loadType }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Is_Flammable') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.isFlammable ? $t('transporterLoadDetail.Yes') : $t('transporterLoadDetail.No') }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Is_Hamal') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.isHamal ? $t('transporterLoadDetail.Yes') : $t('transporterLoadDetail.No') }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Is_Stacked') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.isStacked ? $t('transporterLoadDetail.Yes') : $t('transporterLoadDetail.No') }}
                                </p>
                            </div>

                            <div class="dp-flex-row mt-mb-y25 justify-content-start">
                                <h3 class="font-size-14 text-medium">
                                    {{ $t('transporterLoadDetail.Value') }}
                                </h3>

                                <p class="font-size-14 ml-y2 color-667085">
                                    {{ load.value }} {{ load.currencyCode }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dp-flex-column mt-mb-y1 panel-content">
                <div class="dp-flex-row font-size-20 justify-content-start items-center bottom-border">
                    {{ $t('transporterLoadDetail.Load_Note') }}
                </div>
                <p class="font-size-13">
                    {{ load.note != "" ? load.note : $t('transporterLoadDetail.Unspecified') }}
                </p>
            </div>
        </div>

    </div>

</template>

// TODO: Voyages kısmından push'lanan id'yi ileride state magament'e basıp oradan çekebiliriz.
// NOTE: driver'dan gelen mqtt messajının sıralaması plate, datetime, lat, long, 0,0,0 voygeId, vehicleId,

<script>

import VoyageService from "@/services/VoyageService";
import MQTTHelper from "@/Helpers/MQTTHelper";
import { AssetUrls } from "@/Helpers/AssetUrls";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import Map from "@/components/Widgets/Map/Map.vue";

export default {
    name: "TransporterVoyageDetail",

    components: {
        Loading,
        Map
    },

    data() {
        return {
            id: 0,
            voyage: {},
            load: {},
            vehicleStatus: {},

            loadAddressInfo: {},
            unLoadAddressInfo: {},
            topicVehicleId: 0,
            voyageService: new VoyageService(),
            mqttHelper: new MQTTHelper(),

            lastLocation: [],

            vehicleLocations: [],

            voyageMarker: {
                vehicleId: 0,
                plateNumber: "",
                position: {
                    lat: 0,
                    lng: 0,
                },
            },

            loadingUnloadingPoints: [],

            isLoading: false,

            AssetUrls: AssetUrls,
        };
    },

    beforeDestroy() {
        this.mqttHelper.destroyConnection();
    },

    async mounted() {
        this.isLoading = true

        if (this.$route.params.id > 0) {
            this.id = this.$route.params.id;

            await this.getVoyageDetail();

            this.getVehicleLocations();

            this.isLoading = false;
        } else {
            this.$router.push("/transporter/voyages");
        }
    },

    methods: {
        handleImageError(event) {
            event.target.src = require(`@/assets/images/img-not-found.avif`);
        },

        async getVoyageDetail() {
            await this.voyageService.getVoyageDetailForTransporter(this.id);

            if (this.voyageService.resultModel.code == 14) {

                this.voyage = this.voyageService.resultModel.data;
                this.load = this.voyage.loads;
                this.vehicleStatus = this.voyage.vehicleUsers;

                let marker = {
                    markerType: 1,
                    title: "1. Yükleme Nokatası",
                    position: {
                        lat: 0,
                        lng: 0,
                    },
                }

                marker.position.lat = this.load.loadAddressInfo.latitude;
                marker.position.lng = this.load.loadAddressInfo.longitude;

                this.loadingUnloadingPoints.push(marker);

                let order = 1;
                this.load.unLoadAddressInfo.forEach(element => {
                    let marker2 = {
                        markerType: 2,
                        title: "",
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    }

                    marker2.markerType = 2;
                    marker2.title = order + ".Boşaltma Noktası";
                    marker2.position.lat = element.latitude;
                    marker2.position.lng = element.longitude;

                    this.loadingUnloadingPoints.push(marker2);
                    order++;
                });


                this.vehicleStatus.forEach((element) => {
                    let vehicleLocation = {
                        vehicleId: 0,
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    };

                    vehicleLocation.vehicleId = element.vehicleId;
                    vehicleLocation.plateNumber = element.licencePlate;
                    vehicleLocation.position.lat = element.lastLocation.latitude;
                    vehicleLocation.position.lng = element.lastLocation.longitude;

                    this.vehicleLocations.push(vehicleLocation);
                });

                this.loadAddressInfo = this.voyage.loads.loadAddressInfo;
                this.unLoadAddressInfo = this.voyage.loads.unLoadAddressInfo[this.voyage.loads.unLoadAddressInfo.length - 1];
            } else {
                this.$toast.success(this.$t("resultModel." + this.voyageService.resultModel.message));
            }

            if (this.voyageService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        getVehicleLocations() {
            this.mqttHelper.subscription.topic = "voyages/" + this.id + "/#";

            this.mqttHelper.createConnection();

            this.mqttHelper.doSubscribe();

            this.mqttHelper.getMqttMessage((topic, newMessage) => {
                //console.log("Topic => " + topic);
                //console.log("Gelen Mesaj => " + newMessage);

                let messageArray = newMessage.toString().split("#");

                let indexOfVehicleLocation = this.vehicleLocations.findIndex((x) => x.vehicleId == parseInt(messageArray[8]));

                if (indexOfVehicleLocation == -1) {
                    let vehicleLocation = {
                        vehicleId: 0,
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    };

                    vehicleLocation.vehicleId = parseInt(messageArray[8]);
                    vehicleLocation.plateNumber = messageArray[0];
                    vehicleLocation.position.lat = parseFloat(messageArray[2]);
                    vehicleLocation.position.lng = parseFloat(messageArray[3]);

                    this.vehicleLocations.push(vehicleLocation);
                } else {
                    let vehicleLocation = {
                        vehicleId: 0,
                        position: {
                            lat: 0,
                            lng: 0,
                        },
                    };

                    vehicleLocation.vehicleId = parseInt(messageArray[8]);
                    vehicleLocation.plateNumber = messageArray[0];
                    vehicleLocation.position.lat = parseFloat(messageArray[2]);
                    vehicleLocation.position.lng = parseFloat(messageArray[3]);

                    this.$set(this.vehicleLocations, indexOfVehicleLocation, vehicleLocation);
                }
            });
        },
    },
};
</script>

<style>
.voyage-vehicle-info-text {
    background-color: #d7dddf;
    padding: 5px 2%;
    cursor: pointer;
}
</style>