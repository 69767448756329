let LOCAL_DOMAIN = "https://localhost:44342/";
let TEST_DOMAIN = "https://backend-test.daliport.com/";
let MAIN_DOMAIN = "https://backend.daliport.com/";
let MAIN_IP = "http://172.16.64.4:3011/";

let MAIN_URL = TEST_DOMAIN;

export const AssetUrls = {

    USER_ASSETS_URL: MAIN_URL + "users/",
    VEHICLE_DOCUMENTS_URL: MAIN_URL + "vehicle-documents/",
    VEHICLE_TYPES_URL: MAIN_URL + "vehicle-types/",
    MESSAGE_FILES_URL: MAIN_URL + "messages/",
    SHIPMENT_TYPES_URL: MAIN_URL + "shipment-types/",
    DRIVERS_URL: MAIN_URL + "drivers/",
    COMPANY_ASSETS_URL: MAIN_URL + "companies/"
}

// TODO: projedeki bütün img url'leri buradan çekilecek şekilde baştan düzenlenecek.