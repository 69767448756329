<template>
    <LayoutCompleteRegistration>
        <div class="dp-flex-column items-center justify-content-center login-left">

            <div class="dp-flex-column items-center">

                <div class='complete-registartion-form'>
                    <div class="identitiy-information-title">
                        <h2 class='font-size-30'>{{$t("complete-registration-page.CompleteRegistrationTitleName")}}, {{name}}</h2>
                        <h1 class='font-size-30'>{{$t("complete-registration-page.CompleteRegistrationTitle")}}</h1>
                    </div>
                    <div class="">
                        <figure class="text-center">
                            <img src="@/assets/images/introduction-area/complete-registration/Group 1384.svg" />
                        </figure>
                    </div>

                    <div class="font-size-15 text-center mt-mb-25">
                        {{$t("complete-registration-page.CompleteRegistrationDesc")}}
                    </div>

                    <router-link class='btn-none mt-mb-15 identity-information-input-submit identity-information-input-submit dp-flex-row justify-content-center items-center login-input-submit' to="/identity-information">
                        {{$t("complete-registration-page.ComplateRegistrationButton")}}
                    </router-link>

                    <!-- <button class='btn-none mt-mb-15 identity-information-input-submit identity-information-input-submit login-input-submit' type='submit'>{{$t("complete-registration-page.ComplateRegistrationButton")}}</button> -->

                    <div class='text-center'>
                        <router-link class="font-size-15 text-medium text-color-green2" to="/">
                            {{$t("complete-registration-page.CompleteRegistrationLogoutButton")}}
                        </router-link>
                    </div>

                    <div class='login-re-captcha-kvkk'>
                        <p class='font-size-13 mt-mb-15'>

                            {{$t("complete-registration-page.CompleteRegistrationPolicyTxt")}}

                            <a href='#' class='text-color-blue font-size-13 btn-none'>
                                {{$t("complete-registration-page.CompleteRegistrationPolicyFirst")}}
                            </a>
                            &
                            <a href='#' class='font-size-13 text-color-blue btn-none'>
                                {{$t("complete-registration-page.CompleteRegistrationPolicySecond")}}
                            </a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </LayoutCompleteRegistration>
</template>

<script>
import LayoutCompleteRegistration from "./_LayoutCompleteRegistration.vue";

export default {
    name: "CompleteRegistration",
    components: {
        LayoutCompleteRegistration,
    },
    data() {
        return {
            name: "",
        };
    },
    methods: {},
    mounted() {
        this.name = localStorage.getItem("name");
    },
};
</script>

<style>
</style>