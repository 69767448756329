<template>
    <div>
        <Header />
        <main>
            <slot />
        </main>
        <Footer />
    </div>
</template>

<script>
import Header from "./header.vue";
import Footer from "./footer.vue";

export default {
    name: "Layout",
    components: {
        Header,
        Footer,
    },
};
</script>
<style>
@import "@/assets/css/introduction-area/_layout.css";
</style>