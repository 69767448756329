<template>
    <div class="dp-flex-column">
        <NotificationPopup class="mb-y1" :title="$t('notificationPopup.Account_Accept_Title')" :description="$t('notificationPopup.Account_Accept_Description')" :type="'success'"></NotificationPopup>
        
        <NotificationPopup :title="$t('notificationPopup.Package_Finished_Title')" :description="$t('notificationPopup.Package_Finished_Description')" :type="'danger'"></NotificationPopup>
        
        <Map></Map>
        
        <div class="dp-flex-row mt-mb-15 justify-content-between">
            <LastOffres></LastOffres>
            <Packages class="ml-y2"></Packages>
        </div>
        
        <div class="dp-flex-column mt-mb-15">
            <Statistics></Statistics>
        </div>
    </div>
</template>

<script>
// TODO: Notifiacation popup dinamik olacak. api'den paket bilgisi hesap durumu ve yük bilgileri gelecek.
// TODO: Seferin durumu sefer yoksa gözükmeyecek.
// TODO: Son teklifler teklif yokken kötü gözüküyor düzeltilecek.
// TODO: Paket düzeltilecek.

import Map from "./Map.vue";
import LastOffres from "./LastOffres.vue";
import Packages from "./Packages.vue";
import Statistics from "./Statistics.vue";
import NotificationPopup from "../CustomerPanelLayout/NotificationPopup.vue";

export default {
    name: "CustomerPanelDashboard",
    components: {
        Map,
        LastOffres,
        Packages,
        Statistics,
        NotificationPopup,
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
</style>