<template>
    <header class="width-100">

        <slot name="banner-block"></slot>

        <div class="panel-header-menu dp-flex-row justify-content-end">

            <slot name="header-menu-items">
                <div v-if="getWhichPage == 'isVehicle'" class="dp-flex-row justify-content-start width-100">
                    <router-link to="/transporter/my-vehicles" class="dp-flex-row p-y1 font-size-16 btn-none justify-content-center items-center">
                        <i class="fa-solid font-size-16 color-6098FB mr-15 fa-truck-moving"></i>
                        {{$t('transporterSideBar.My_Vehicles')}}
                    </router-link>
                    <!-- <button class="dp-flex-row font-size-13 btn-atanmis-araclar pl-pr-y2 justify-content-center btn-none items-center">
                        {{$t('transporterSideBar.Assigned_Vehicles')}}
                    </button> -->
                    <button @click="goToAddNewVehiclePage()" class="dp-flex-row font-size-14 items-center pl-pr-y2 add-new-vehicle-btn btn-none">
                        <i class="fa-solid font-size-14 color-26B198 mr-y5 fa-circle-plus"></i>
                        {{$t('transporterSideBar.Add_New_Vehicle')}}
                    </button>
                </div>

                <div v-if="getWhichPage == 'isDriver'" class="dp-flex-row justify-content-start width-100">
                    <router-link to="/transporter/my-drivers" class="dp-flex-row p-y1 font-size-16 btn-none justify-content-center items-center">
                        <i class="fa-solid font-size-16 color-6098FB mr-15 fa-truck-moving"></i>
                        {{$t('transporterSideBar.My_Drivers')}}
                    </router-link>
                    <button @click="goToAddNewDriverPage()" class="dp-flex-row font-size-14 items-center pl-pr-y2 add-new-vehicle-btn btn-none">
                        <i class="fa-solid font-size-14 color-26B198 mr-y5 fa-circle-plus"></i>
                        {{$t('transporterSideBar.Add_New_Driver')}}
                    </button>
                </div>

                <div v-if="getWhichPage == 'isOffers'" class="dp-flex-row justify-content-start width-100">
                    <button @click="offersTabPush(true)" class="dp-flex-row p-y1 font-size-16 btn-none justify-content-center items-center">
                        <!-- <i class="fa-solid font-size-16 color-6098FB mr-15 fa-truck-moving"></i> -->
                        <i class="sidebar-menu-item-elements fa-solid fa-handshake"></i>
                        {{$t('transporterSideBar.Accepted_Offers')}}
                    </button>
                    <button @click="offersTabPush(null)" class="dp-flex-row font-size-13 btn-atanmis-araclar pl-pr-y2 justify-content-center btn-none items-center">
                        {{$t('transporterSideBar.Pending_Offers')}}
                    </button>
                    <button @click="offersTabPush(false)" class="dp-flex-row font-size-14 items-center pl-pr-y2 add-new-vehicle-btn btn-none">
                        {{$t('transporterSideBar.Rejected_Offers')}}
                    </button>
                </div>

                <div v-if="getWhichPage == 'isVoyage'" class="dp-flex-row justify-content-start width-100">
                    <button @click="voyageTabPush(1)" :to="{name: 'voyages'}" class="dp-flex-row p-y1 font-size-16 btn-none justify-content-center items-center">
                        {{$t('transporterSideBar.Voyage_Pending_Vehicle_Assignment')}}
                    </button>
                    <button @click="voyageTabPush(2)" :to="{name: 'voyages'}" class="dp-flex-row font-size-13 btn-atanmis-araclar pl-pr-y2 justify-content-center btn-none items-center">
                        {{$t('transporterSideBar.Not_Started_Voyages')}}
                    </button>
                    <button @click="voyageTabPush(3)" :to="{name: 'voyages'}" class="dp-flex-row font-size-13 btn-atanmis-araclar pl-pr-y2 justify-content-center btn-none items-center">
                        {{$t('transporterSideBar.Ongoing_Voyages')}}
                    </button>
                    <button @click="voyageTabPush(4)" :to="{name: 'voyages' }" class="dp-flex-row font-size-14 items-center pl-pr-y2 add-new-vehicle-btn btn-none">
                        {{$t('transporterSideBar.Completed_Voyages')}}
                    </button>
                </div>

                <div v-if="getWhichPage == 'isLoad'" class="dp-flex-row justify-content-start width-100">
                    <router-link to="/transporter" class="dp-flex-row p-y1 font-size-16 btn-none justify-content-center items-center">
                        {{$t('transporterSideBar.Loads')}}
                    </router-link>
                </div>

                <div v-if="getWhichPage == 'isMessages'" class="dp-flex-row justify-content-start width-100">
                    <button @click="messagesTabPush(true)" class="dp-flex-row p-y1 font-size-16 btn-none justify-content-center items-center">
                        <i class="sidebar-menu-item-elements fa-solid fa-handshake"></i>
                        {{$t('transporterSideBar.Active_Voyages')}}
                    </button>
                    <button @click="messagesTabPush(false)" class="dp-flex-row font-size-13 btn-atanmis-araclar pl-pr-y2 justify-content-center btn-none items-center">
                        {{$t('transporterSideBar.Past_Messages')}}
                    </button>
                </div>
            </slot>

            <div class="dp-flex-row justify-content-end items-center">
                <div class="panel-header-menu-item">
                    <button @click="isOpenSendBugPopup = true" class="btn-none header-menu-icon fa-solid fa-bug"></button>
                </div>

                <!-- <div class="panel-header-menu-item header-search-bar-content">
                    <i class="header-menu-icon fa-solid fa-magnifying-glass"></i>
                    <div class="header-search-bar-hover-content p-y1 position-absolute border-radius-5 dp-flex-row justify-content-between items-center">
                        <i class="fa-solid color-929DA7 position-absolute font-size-15 fa-magnifying-glass"></i>
                        <input class="width-100 font-size-15 header-search-bar-input" placeholder="Ara..." type="text">
                    </div>
                </div> -->

                <div class="panel-header-menu-item notification-content">
                    <i class="header-menu-icon fa-regular fa-bell"></i>
                    <div class="notification-hover-content dp-flex-column position-absolute border-radius-5 width-20">
                        <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                        <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                        <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                        <NotificationRow :text="'Yükünüz şu anda belirtilen adrese ulaşmıştır.'" :time="'3 dakika önce'" :from="'Nazmi İslambay'" :imgUrl="'notif1.png'"></NotificationRow>
                    </div>
                </div>

                <div class="panel-header-menu-item header-language-content">
                    <i class="header-menu-icon fa-solid fa-globe"></i>
                    <div class="header-language-hover-content position-absolute border-radius-5 p-y1">
                        <div class="dp-flex-column">
                            <div class="dp-flex-row dp-flex-wrap justify-content-start">
                                <button class="flex-wrap-33 color-929DA7 p-y3 font-size-13 btn-none" v-bind:class="{'text-bold': activeLanguage == 'tr'}" @click="changeLanguage('tr')">
                                    Türkçe
                                </button>
                                <button class="flex-wrap-33 color-929DA7 p-y3 font-size-13 btn-none" v-bind:class="{'text-bold': activeLanguage == 'en'}" @click="changeLanguage('en')">
                                    İngilizce
                                </button>
                                <button class="flex-wrap-33 color-929DA7 p-y3 font-size-13 btn-none" v-bind:class="{'text-bold': activeLanguage == 'ro'}" @click="changeLanguage('ro')">
                                    Romence
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="panel-header-menu-item header-user-content">
                    <i class="header-menu-icon header-user-hover-icon fa-regular fa-circle-user"></i>
                    <div class="header-user-hover-content position-absolute border-radius-5">
                        <div class="dp-flex-column">
                            <div @click="goToProfilePage()" class="dp-flex-row header-user-item items-center">
                                <i class="fa-solid font-size-15 color-929DA7 fa-user"></i>
                                <p class="font-size-15 ml-y4">{{nameSurname}}</p>
                            </div>
                            <div class="dp-flex-row header-user-item items-center">
                                <i class="fa-solid font-size-15 color-929DA7  fa-arrow-right-from-bracket"></i>
                                <p class="font-size-15 ml-y4" @click="logout()">
                                    {{$t('transporterSideBar.Logout')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <TransactionPopup v-if="isOpenSendBugPopup" @closePopup="isOpenSendBugPopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center font-size-20 text-color-white">
                    {{$t('bugReport.Title')}}
                </p>
            </template>

            <template slot="popup-body">
                <div style="width:35vw!important" class="dp-flex-column justify-content-between items-center">
                    <p class="text-center font-size-14 mt-mb-y25 color-667085">
                        {{$t('bugReport.Description')}}
                    </p>

                    <div class="dp-flex-row justify-content-start items-center width-100">
                        <label class="font-size-15 text-bold" :class="bugImage == undefined ? 'text-color-red' : ''">
                            {{$t('bugReport.Upload_Image')}}
                        </label>

                        <input @change="previewFiles($event)" class="ml-y4" type="file">
                    </div>

                    <div class="dp-flex-column justify-content-between mt-y3 width-100">
                        <label class="font-size-15 text-bold" :class="bugDescription == '' ? 'text-color-red' : ''">
                            {{$t('bugReport.Bug_Description')}}
                        </label>
                        <textarea v-model="bugDescription" class="width-100" rows="4" cols="10"></textarea>
                    </div>
                </div>
            </template>

            <template slot="popup-body-button">
                <button @click="sendBug($event)" class="dp-flex-row btn-none mt-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                    {{$t('bugReport.Button')}}
                </button>
            </template>
        </TransactionPopup>

        <NotificationPopup v-if="isActiveNotif" :title="notifTitle" :description="notifDesc" :type="notifType"></NotificationPopup>
    </header>
</template>

<script>
import NotificationRow from "@/components/Widgets/Header/NotificationRow.vue";
import NotificationPopup from "@/components/CustomerArea/CustomerPanelLayout/NotificationPopup.vue";
import { mapGetters, mapActions } from "vuex";
import { APIUrls } from "@/Helpers/APIUrls";
import { CreateOrUpdateVehicleModel } from "@/Helpers/Models";
import { CreateOrUpdateDriverModel } from "@/services/DriversService";
import TransactionPopup from "../ModelPopup/TransactionPopup.vue";
import BugService from "@/services/BugService";

export default {
    name: "PanelHeader",

    components: {
        NotificationRow,
        NotificationPopup,
        TransactionPopup,
    },

    data() {
        return {
            isActive: "",
            activeLanguage: localStorage.getItem("lng"),
            isActiveNotif: false,
            notifTitle: "Ahmetin Paketi Bitmek Üzere",
            notifDesc: "Detaylı Bilgi İçin Dokunun",
            notifType: "danger",
            nameSurname: "",
            isOpenSendBugPopup: false,
            bugImage: undefined,
            bugDescription: "",
            bugService: new BugService(),
        };
    },

    beforeCreate() {
        this.axios({
            url: APIUrls.GET_TRANSPORTER_USER_BY_GUID,
            methods: "GET",
        })
            .then((response) => {
                if (response.status == 200) {
                    if (response.data.code == 14) {
                        this.nameSurname = response.data.data.name + " " + response.data.data.surname;
                    } else {
                        this.$toast.warning("Kullanıcı Bilgileri Çekilemedi !");
                    }
                } else {
                    this.$toast.warning("Kullanıcı Bilgileri Çekilemedi !");
                }
            })
            .catch((error) => {
                console.log(error);
                this.$toast.error("Kullanıcı Bilgileri Çekilemedi !");
            });
    },

    computed: {
        ...mapGetters("header", ["getWhichPage"]),
    },

    async mounted() {},

    methods: {
        ...mapActions("createOrUpdateVehicle", ["updateCreateOrUpdateVehicle"]),

        ...mapActions("moduleCreateOrUpdateDriver", ["updateCreateOrUpdateDriver"]),

        previewFiles(event) {
            this.bugImage = event.target.files[0];
        },

        async sendBug(e) {
            if (this.bugImage != undefined && this.bugDescription != "") {
                await this.bugService.createOrUpdateBug(0, this.bugDescription, this.bugImage);

                e.target.classList.remove("button--loading");

                console.log(this.bugService.resultModel);

                if (this.bugService.resultModel.code == 221 || this.bugService.resultModel.code == 222) {
                    this.$toast.success(this.$t("resultModel." + this.bugService.resultModel.message));
                    this.isOpenSendBugPopup = false;
                } else {
                    this.$toast.error(this.$t("resultModel." + this.bugService.resultModel.message));
                }

                if (this.bugService.errorResult != "") {
                    this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                }
            }
        },

        goToAddNewVehiclePage() {
            this.updateCreateOrUpdateVehicle([new CreateOrUpdateVehicleModel()]);

            this.$router.push("create-vehilce");
        },

        goToAddNewDriverPage() {
            this.updateCreateOrUpdateDriver([new CreateOrUpdateDriverModel()]);

            this.$router.push("create-driver");
        },

        changeLanguage(language) {
            this.activeLanguage = language;
            localStorage.setItem("lng", language);
            window.location.reload();
        },

        voyageTabPush(voyageStatus) {
            //console.log(voyageStatus);
            this.$router.push({
                name: "voyages",
                // params: {
                //     voyageStatus,
                // },
                query: {
                    voyageStatus: voyageStatus,
                },
            });
        },

        offersTabPush(offerStatus) {
            this.$router.push({
                name: "offers",
                query: {
                    offerStatus: offerStatus,
                },
            });
        },

        messagesTabPush(voyageMessageStatus) {
            this.$router.push({
                name: "transporter-messages",
            });
        },

        goToProfilePage() {
            this.$router.push("/transporter/profile");
        },

        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("guid");
            localStorage.removeItem("userType");

            this.$router.push({ name: "Home" });

            window.location.reload();

            // TODO: logout api'sine istek atılacak.
        },
    },
};
</script>

<style>
@import url("@/assets/css/widgets/header/header.css");

.btn-atanmis-araclar {
    border-left: 1px solid #929da7;
    border-right: 1px solid #929da7;
}

.add-new-vehicle-btn {
    width: 15vw;
}
</style>