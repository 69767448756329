<template>
    <router-link class="sidebar-menu-item items-center btn-none dp-flex-row font-size-16" :to="link">
        <slot name="item-icon">

        </slot>

        {{title}}

    </router-link>
</template>

<script>
export default {
    name: "SidebarMenuItem",
    
    props: ["title", "link"],
};
</script>

<style>
</style>