<template>
    <div class="dp-flex-column justify-content-between panel-content">
        <TitleWithIcon :title="$t('save-ad.Title')"></TitleWithIcon>

        <AddressLabelWithInput v-model="title" :type="'text'" :placeHolder="$t('save-ad.DraftTitle')"></AddressLabelWithInput>
        <AddressLabelWithInput v-model="note" class="mt-mb-10" :type="'text'" :placeHolder="$t('save-ad.AdNotePlaceHolder')" :label="$t('save-ad.AdNote')"></AddressLabelWithInput>

        <div v-if="!getIsComingFromDraft" class="dp-flex-row green-chechkbox-content justify-content-end items-center">
            <p class="font-size-12 text-semi-bold mr-y1">
                {{$t("save-ad.SaveToDraft")}}
            </p>
            <GreenCheckbox @input="greenCheckboxEvent"></GreenCheckbox>
        </div>
    </div>
</template>

<script>
import GreenCheckbox from "@/components/Widgets/CustomerPanel/GreenCheckbox.vue";
import AddressLabelWithInput from "../../Addresses/LoadingPoint/AddressLabelWithInput.vue";
import TitleWithIcon from "@/components/Widgets/CustomerPanel/TitleWithIcon.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        GreenCheckbox,
        TitleWithIcon,
        AddressLabelWithInput,
    },

    data() {
        return {
            title: "",
            note: "",
            isDraft: false,
        };
    },
    computed: {
        ...mapGetters("createLoad", ["getLoadTitleAndNote","getIsComingFromDraft"]),
    },

    mounted() {
        this.title = this.getLoadTitleAndNote.title;
        this.note = this.getLoadTitleAndNote.note;
    },

    methods: {
        greenCheckboxEvent(event) {            
            this.isDraft = event;
            this.$emit("setSaveAdDetails", {
                title: this.title,
                note: this.note,
                isDraft: this.isDraft,
            });
        },
    },

    updated() {
        this.$emit("setSaveAdDetails", {
            title: this.title,
            note: this.note,
            isDraft: this.isDraft,
        });
    },
};
</script>

<style>
</style>