<template>
    <div class="dp-flex-column justify-content-between panel-content">
        <TitleWithIcon :title="$t('ad-detail.Title')"></TitleWithIcon>

        <div class="dp-flex-row justify-content-between mt-mb-10">
            <AdDetailRow :text="this.getShipmentType == 1 ? $t('ad-detail.FTL') : $t('ad-detail.FTL') ">
                <template slot="image">
                    <img class="width-70" :src="shipmentTypeImageUrl" />
                </template>
            </AdDetailRow>

            <AdDetailRow class="ml-y2" :text=" this.getVehicleType.id == 1 ? 'Kamyon' : this.getVehicleType.id == 2 ? 'Tır' : 'Diğer'">
                <template slot="image">
                    <img class="width-70" :src="vehicleTypeImageUrl" />
                </template>
            </AdDetailRow>
        </div>
    </div>
</template>

<script>
import TitleWithIcon from "@/components/Widgets/CustomerPanel/TitleWithIcon.vue";
import AdDetailRow from "./AdDetailRow.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        TitleWithIcon,
        AdDetailRow,
    },

    data() {
        return {
            shipmentTypeImageUrl: "",
            vehicleTypeImageUrl: "",
        };
    },

    computed: {
        ...mapGetters("createLoad", ["getShipmentType", "getVehicleType"]),
    },

    mounted() {
        // TODO: PARSİYEL İÇİN RESİM EKLENECEK.
        this.shipmentTypeImageUrl = this.getShipmentType == 1 ? require("@/assets/images/customer-area/my-ads/icon1.png") : require("@/assets/images/customer-area/my-ads/icon1.png");
        this.vehicleTypeImageUrl = this.getVehicleType.id == 1 ? require("@/assets/images/customer-area/my-ads/create-new-ads/truck.png") : this.getVehicleType.id == 2 ? require("@/assets/images/customer-area/my-ads/create-new-ads/truck2.png") : require("@/assets/images/customer-area/my-ads/create-new-ads/other1.png");
    },
};
</script>

<style>
</style>