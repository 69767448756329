<template>
    <div class="dp-flex-column">
        <Title :title="$t('messages.Title')"></Title>

        <div class="tab mt-mb-25">
            <div class="tab-button-header dp-flex-row justify-content-start">
                <button @click="selectTab(1,1)" v-bind:class="{'button-active':currentTab == 1, 'button-pasive':currentTab != 1}" class="font-size-15 text-capitalize btn-none color-667085">
                    {{$t("messages.ActiveExpeditions")}}
                </button>

                <button @click="selectTab(2,4)" v-bind:class="{'button-active':currentTab == 2, 'button-pasive':currentTab != 2}" class="font-size-15 text-capitalize btn-none color-667085">
                    {{$t("messages.Completed_Voyages")}}
                </button>
            </div>

            <div class="tab-content dp-flex-column items-center">
                <AdsSearch v-model="searchText" @input="searchToVoyage()"></AdsSearch>
            </div>

            <Loading v-if="isLoading"></Loading>

            <EmptyAds v-if="!isLoading && voyages.length == 0" :title=" currentTab == 1 ? $t('emptyAds.Empty_Active_Voyage_Title') : $t('emptyAds.Empty_Completed_Voyage_Title')" :description="$t('emptyAds.Empty_Active_Completed_Voyage_Description')">

            </EmptyAds>

            <div v-if="currentTab == 1 && !isLoading && voyages.length != 0" class="panel-content mt-mb-y1">
                <div class="dp-flex-row dp-flex-wrap messages-content justify-content-between">
                    <MessageRow v-on:click.native="goToCustomerVoyageDetail(voyage.id)" v-for="voyage in searchList" :key="voyage.id" :expeditionNumber="voyage.title + ' ' + $t('messages.Voyage_Title')" :roadMap="voyage.loadingCity + ' - ' + voyage.unloadingCity"></MessageRow>
                </div>
            </div>

            <div v-if="currentTab == 2 && !isLoading && voyages.length !=0" class="panel-content mt-mb-y1">
                <div class="dp-flex-row dp-flex-wrap messages-content justify-content-between">
                    <MessageRow v-on:click.native="goToCustomerVoyageDetail(voyage.id)" v-for="voyage in searchList" :key="voyage.id" :expeditionNumber="voyage.title + ' ' + $t('messages.Voyage_Title')" :roadMap="voyage.loadingCity + ' - ' + voyage.unloadingCity"></MessageRow>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// TODO: goToCustomerVoyageDetail kısmında voyageId vuex'e basılabilir.

import MessageRow from "../Messages/MessageRow.vue";
import AdsSearch from "../MyAds/AdsSearch.vue";
import VoyageService from "@/services/VoyageService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import EmptyAds from "../MyAds/EmptyAds.vue";

export default {
    name: "CustomerVoyages",

    components: {
        AdsSearch,
        MessageRow,
        Loading,
        EmptyAds,
    },

    data() {
        return {
            currentTab: 1,
            voyages: [],
            voyageStatus: 1,
            searchText: "",
            searchList: [],
            voyageService: new VoyageService(),
            isLoading: false,
        };
    },

    async mounted() {
        await this.getVoyages();
    },

    methods: {
        searchToVoyage() {
            if (this.searchText != "") {
                this.searchList = [];

                this.searchList = this.voyages.filter((x) => {
                    return x.title.toLowerCase().includes(this.searchText);
                });
            } else {
                this.searchList = this.voyages;
            }
        },

        selectTab(selectedTab, voyageStatus) {
            this.currentTab = selectedTab;
            this.voyageStatus = voyageStatus;
            this.getVoyages();
        },

        goToCustomerVoyageDetail(voyageId) {
            console.log(voyageId);
            this.$router.push({
                name: "customer-voyage-detail",
                params: {
                    voyageId,
                },
            });
        },

        async getVoyages() {
            this.isLoading = true;

            await this.voyageService.getVoyagesByCustomerUserGuidWithStatus(this.voyageStatus);

            if (this.voyageService.resultModel.code == 14) {
                this.voyages = this.voyageService.resultModel.data;
                this.searchList = this.voyages;
            } else {
                this.$toast.error(this.$t("resultModel." + this.voyageService.resultModel.message));
            }

            if (this.voyageService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },
    },
};
</script>

<style>
@import url("@/assets/css/customer-area/messages/messages.css");
</style>