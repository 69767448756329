<template>
    <div class="search-content dp-flex-row items-center">
        <i class="fa-solid color-929DA7 search-icon fa-magnifying-glass font-size-15"></i>
        <input class="search-button" type="text" :value="text" :placeholder="placeholder" @input="$emit('input', $event.target.value)"/>
    </div>
</template>

<script>
// TODO: adı değişecek. genel search tarzında olacak.
// TODO: tasarımı değişebilir olacak.

export default {
    name: "SearchComponent",
    props: ["text","placeholder"],
};
</script>

<style>
</style>