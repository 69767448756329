<template>
    <div>
        <Loading v-if="isLoading"></Loading>

        <div v-if="!isLoading" class="dp-flex-column">
            <div class="mt-mb-35">
                <h1 class="color-667085 font-size-25">{{$t("customer-shipment-type-page.ShipmentTypeTitle")}}</h1>
                <p class="color-667085 font-size-15 text-semi-bold">
                    {{$t("customer-shipment-type-page.ShipmentTypeDec")}}
                </p>
            </div>
            <div class="panel-content dp-flex-row shipment-types justify-content-between">
                <div class="dp-flex-column shipment-type justify-content-between items-left" :key="shipmentType.id" v-for="shipmentType in shipmentTypes">
                    <p class="font-size-14 color">{{shipmentType.name}}</p>
                    <div @click="getSelectedShipmentType(shipmentType.id)">
                        <img :src="AssetUrls.SHIPMENT_TYPES_URL + shipmentType.imgName" class="shipment-type-img" />
                    </div>
                    <p class="font-size-14 color-667085">{{shipmentType.description}}</p>
                </div>
            </div>
        </div>
        <div v-if="!isLoading" class="dp-flex-column">
            <div class="mt-mb-35">
                <h1 class="color-667085 font-size-25">
                    {{$t("customer-shipment-type-page.Draft_Ads")}}
                </h1>
                <p class="color-667085 font-size-15 text-semi-bold">
                    {{$t("customer-shipment-type-page.ShipmentTypeDec")}}
                </p>
                <EmptyAds v-if="isLoading == false && draftLoads.length == 0" :title="$t('emptyAds.Empty_Draft_Ads_Title')" :description="$t('emptyAds.Empty_Draft_Ads_Description')"></EmptyAds>
            </div>
            <div>
                <DraftAd :key="draftLoad.id" v-for="draftLoad in draftLoads" :draftLoad="draftLoad"></DraftAd>
            </div>
        </div>
    </div>
</template>

<script>

import { APIUrls } from "@/Helpers/APIUrls";
import DraftAd from "@/components/CustomerArea/MyAds/CreateNewAds/DraftAd.vue";
import { mapActions, mapGetters } from "vuex";
import { AssetUrls } from "@/Helpers/AssetUrls";
import LoadService from "@/services/LoadService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import EmptyAds from "../EmptyAds.vue";
import { Currency, LoadFeaturesInfo } from "@/Helpers/Models";

export default {
    components: {
        DraftAd,
        Loading,
        EmptyAds,
    },

    data() {
        return {
            shipmentTypes: [],
            selectedShipmentType: 0,
            AssetUrls: AssetUrls,
            loadService: new LoadService(),
            isActive: true,
            isLoading: false,
            draftLoads: [],
        };
    },

    async mounted() {
        this.$store.resetState();

        await this.getShipmentTypes();

        await this.getDraftLoads();

        this.resetVuex();
    },

    computed: {
        ...mapGetters("createLoad", ["getShipmentType", "getLoadingAddresses", "getUnloadingAddresses"]),
    },

    methods: {
        ...mapActions("createLoad", ["updateShipmentType", "updateVehicleType", "updateVehicleInfos", "updateIsComingFromDraft", "updateLoadFeaturesInfo", "updateCurrency", "updateLoadTitleAndNote", "updateLoadingAddresses", "updateUnloadingAddresses"]),

        resetVuex() {
            let vehicleInfos = {
                bodyFloorType: 0,
                bodyFloorTypeName: "",
                caseType: "",
                trailerType: 0,
                trailerTypeName: "",
                vehicleCount: 1,
            };

            this.updateShipmentType([0]);

            this.updateIsComingFromDraft([false]);

            this.updateLoadTitleAndNote(["", ""]);

            this.updateVehicleType([0, ""]);

            this.updateVehicleInfos([vehicleInfos]);            

            this.updateLoadFeaturesInfo([new LoadFeaturesInfo()]);

            this.updateCurrency([new Currency()]);

            this.updateLoadingAddresses([[]]);

            this.updateUnloadingAddresses([[]]);
        },

        getImgPath(imgName) {
            try {
                return require(APIUrls.MAIN_URL + "shipment-types/" + imgName);
            } catch (error) {
                return require(`@/assets/images/customer-area/my-ads/create-new-ads/default-shipment-type.png`);
            }
        },

        async getShipmentTypes() {
            this.isLoading = true;

            await this.loadService.getShipmentTypes(this.isActive);

            if (this.loadService.resultModel.code == 14) {
                this.shipmentTypes = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async getDraftLoads() {
            await this.loadService.getDraftLoadsByCustomerUserGudi();

            if (this.loadService.resultModel.code == 14) {
                this.draftLoads = this.loadService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.loadService.resultModel.message));
            }

            if (this.loadService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },

        getSelectedShipmentType(id) {
            this.updateShipmentType([id]);

            this.$router.push("/customer/vehicle-type");
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/my-ads/create-new-ads/shipment-type.css";
</style>