<template>
    <div class="dp-flex-row p-y5 notification-hover-item justify-content-between">
        <div class="dp-flex-row items-baseline justify-content-start">
            <div class="point-notification"></div>
            <div class="dp-flex-column ml-y4">
                <p class="font-size-13">{{text}}</p>
                <p class="font-size-14 mt-y3 color-667085">{{from}}</p>
                <p class="font-size-10">{{time}}</p>
            </div>
        </div>
        <div class="dp-flex-column justify-content-center items-center">
            <img v-bind:src="require('@/assets/images/customer-area/customer-panel/header/'+ imgUrl)"  alt="notification1">
        </div>
    </div>
</template>

<script>

export default {
    props:["text", "from", "time", "imgUrl"]
};
</script>

<style>

</style>