const moduleCompleteRegistration = {
    namespaced: true,

    state: {
        countryId: 0,
        identityNumber: "",
        dateOfBirth: "",
        licensePageFront: {},
        licensePageBack: {},
    },

    getters: {
        getIdentityInformation: (state) => {
            return {
                countryId: state.countryId,
                identityNumber: state.identityNumber,
                dateOfBirth: state.dateOfBirth,
                licensePageFront: state.licensePageFront,
                licensePageBack: state.licensePageBack,
            };
        },
    },

    actions: {
        updateIdentityInformation: ({ commit }, [countryId, identityNumber, dateOfBirth, licensePageFront, licensePageBack]) => {
            commit('setIdentityInformation', [countryId, identityNumber, dateOfBirth, licensePageFront, licensePageBack]);
        },
    },

    mutations: {
        setIdentityInformation: (state, [countryId, identityNumber, dateOfBirth, licensePageFront, licensePageBack]) => {
            state.countryId = countryId;
            state.identityNumber = identityNumber;
            state.dateOfBirth = dateOfBirth;
            state.licensePageFront = licensePageFront;
            state.licensePageBack = licensePageBack;
        },
    },
}

export default moduleCompleteRegistration;