import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';


export class VehicleModel {
    id = 0;
    brandName = "";
    insurancePolicy = "";
    kDocument = "";
    licencePlate = "";
    licensePageBack = "";
    licensePageFront = "";
    modelYear = "";
    netWight = 0;
    vehicleBodyTypeName = "";
    vehicleBoydFloorTypeName = "";
    vehicleDrivers = {};
    vehicleTypeName = "";
    workingStatus = false;
}

export class VehicleCreateOrUpdateModel {
    id = 0;
    vehicleBrandId = 0;
    insurancePolicy = undefined;
    kDocument = undefined;
    licencePlate = "";
    licensePageBack = undefined;
    licensePageFront = undefined;
    modelYear = "";
    netWeight = 0;
    vehicleBodyTypeId = 0;
    bodyFloorTypeId = 0;
    vehicleTypeId = 0;
    isAdr = false;
    caseFeatures = "";
    insuranceExpiryDate = "";
}


const apiHelper = new ApiHelper();

export default class VehiclesService {

    resultModel = new ResultModel();

    errorResult = "";

    async getVehiclesByTransportId(filterDto = new FilterDto()) {
        await apiHelper.get(APIUrls.GET_VEHICLES_BY_TRANSPORTER_USER_GUID, filterDto)
            .then((response) => {
                this.resultModel = response.data;
            }).catch((error) => {
                this.errorResult = error;
            });
    }

    async updateVehicleStatus(vehicleId, workingStatus) {
        await apiHelper.postQuery(APIUrls.UPDATE_VEHICLE_STATUS, {
            vehicleId: vehicleId,
            status: workingStatus,
        })
            .then((response) => {
                // console.log(response);
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }

    async deleteOrRestoreDeleteVehicle(vehicleId, isActive) {

        await apiHelper.postQuery(APIUrls.DELETE_OR_RESTORE_DELETE_VEHICLE, {
            id: vehicleId,
            isActive: isActive
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }

    async createOrUpdateVehicle(vehicleCreateOrUpdateModel = new VehicleCreateOrUpdateModel) {
        await apiHelper.post(APIUrls.CREATE_OR_UPDATE_VEHICLE, {
            id: vehicleCreateOrUpdateModel.id,
            TransporterUserGuid: localStorage.getItem("guid"),
            LicencePlate: vehicleCreateOrUpdateModel.licencePlate,
            VehicleBrandId: vehicleCreateOrUpdateModel.vehicleBrandId,
            ModelYear: vehicleCreateOrUpdateModel.modelYear,
            NetWeight: vehicleCreateOrUpdateModel.netWeight,
            IsAdr: vehicleCreateOrUpdateModel.isAdr,
            VehicleTypeId: vehicleCreateOrUpdateModel.vehicleTypeId,
            VehicleBodyTypeId: vehicleCreateOrUpdateModel.vehicleBodyTypeId,
            CaseFeatures: vehicleCreateOrUpdateModel.caseFeatures,
            BodyFloorTypeId: vehicleCreateOrUpdateModel.bodyFloorTypeId,
            InsuranceExpiryDate: vehicleCreateOrUpdateModel.insuranceExpiryDate,
            LicensePageFront: vehicleCreateOrUpdateModel.licensePageFront,
            LicensePageBack: vehicleCreateOrUpdateModel.licensePageBack,
            InsurancePolicy: vehicleCreateOrUpdateModel.insurancePolicy,
            KDocument: vehicleCreateOrUpdateModel.kDocument,
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }

    async getAssignableVehiclesByTransporterGuid(vehicleTypeId) {

        await apiHelper.get(APIUrls.GET_ASSIGNABLE_VEHICLES_BY_TRANSPORTER_GUID, {
            vehicleTypeId: vehicleTypeId,
        })
            .then((response) => {
                this.resultModel = response.data;
            }).catch((error) => {
                this.errorResult = error;
            });
    }
}