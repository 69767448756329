<template>
    <div class="dp-flex-column">
        <div class="dp-flex-row justify-content-end items-center m-y1 map-autocomplete-content">
            <GmapAutocomplete class="map-autocomplete" @place_changed="getLoadAddress"></GmapAutocomplete>
            <i class="fa-solid icon-map-autocomplete fa-magnifying-glass"></i>
        </div>

        <GmapMap :center="{ lat: currentPlace.lat, lng: currentPlace.lng }" :options="{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false, disableDefaultUi: false }" :zoom="17" map-type-id="roadmap" class="new-ads-map">
            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.place" @click="center = m.place"> </GmapMarker>
        </GmapMap>

        <div class="dp-flex-row justify-content-end popup-map-subtitle items-center">
            <p class="font-size-13">
                {{ $t("new-address-map.Desc") }}
            </p>
            <router-link :to="{ name: 'new-address', params: { selectedAddressTypeId: adressTypeId, country: country, city: city, district: district, postalCode: postalCode, formatedAddress: formatedAddress, latitude: loadAddress.lat, longitude: loadAddress.lng } }" class="btn-none continue-btn popup-map-button vehicle-info-continue-btn font-size-14 items-center">
                {{ $t("button.Continue") }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        adressTypeId: 0,
    },
    data() {
        return {
            country: "",
            city: "",
            district: "",
            postalCode: "",
            formatedAddress: "",
            currentPlace: {
                lat: 0,
                lng: 0,
            },
            loadAddress: {
                lat: 0,
                lng: 0,
            },
            markers: [],
        };
    },
    methods: {
        getCurrentLocation() {
            this.$getLocation({
                enableHighAccuracy: true,
                timeout: Infinity,
                maximumAge: 1,
            }).then((coordinates) => {
                this.currentPlace.lat = coordinates.lat;
                this.currentPlace.lng = coordinates.lng;
            });

            this.addMarkerToPosition(this.currentPlace);
        },

        addMarkerToPosition(place) {
            if (place) {
                this.markers.push({ place });
            }

            this.currentPlace = place;
        },
        getLoadAddress(place) {
            console.log(place);
            place.address_components.forEach((element) => {
                // console.log(element);
                element.types.forEach((type) => {
                    if (type == "administrative_area_level_1") {
                        this.city = element.long_name;
                    }

                    if (type == "country") {
                        this.country = element.long_name;
                    }

                    if (type == "postal_code") {
                        this.postalCode = element.long_name;
                    }

                    if (type == "administrative_area_level_2") {
                        this.district = element.long_name;
                    }
                });
            });

            this.formatedAddress = place.formatted_address;

            this.loadAddress.lat = place.geometry.location.lat();
            this.loadAddress.lng = place.geometry.location.lng();

            this.addMarkerToPosition(this.loadAddress);
        },
    },
    created() {
        this.getCurrentLocation();
    },
};
</script>

<style>
</style>