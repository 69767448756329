<template>
    <LayoutUserLoginRegister>
        <div class="dp-flex-column items-center justify-content-center login-left">
            <div class='choose-one-middle justify-content-between'>
                <div class='dp-flex-column choose-one-middle-content middle-content-customer'>
                    <router-link v-if="whichPageT" :to="{name: this.whichPageT}">
                        <div v-bind:style="{opacity: chooseOneCustomerOpacity}" class='choose-one-middle-content-blur'></div>
                        <div id='chooseOneMusteri' class='dp-flex-column choose-one-middle-content-text' @click="saveUserTypeInfo('3')" @mouseover="mouseOverEvent('chooseOneMusteri')" @mouseleave="mouseLeaveEvent('chooseOneMusteri')">
                            <div>
                                <p class='font-size-25'>{{$t("choose-one-page.Vehicle")}}</p>
                                <p class='font-size-25'>{{$t("choose-one-page.Searching")}}</p>
                            </div>
                            <div>
                                <p class='font-size-15'>{{$t("choose-one-page.CustomerInfoText")}}</p>
                                <p class='font-size-15'>{{$t("choose-one-page.CustomerInfoText2")}}</p>
                            </div>
                        </div>
                    </router-link>
                    <router-link v-else :to="{name: this.whichPage}">
                        <div v-bind:style="{opacity: chooseOneCustomerOpacity}" class='choose-one-middle-content-blur'></div>
                        <div id='chooseOneMusteri' class='dp-flex-column choose-one-middle-content-text' @click="saveUserTypeInfo('3')" @mouseover="mouseOverEvent('chooseOneMusteri')" @mouseleave="mouseLeaveEvent('chooseOneMusteri')">
                            <div>
                                <p class='font-size-25'>{{$t("choose-one-page.Vehicle")}}</p>
                                <p class='font-size-25'>{{$t("choose-one-page.Searching")}}</p>
                            </div>
                            <div>
                                <p class='font-size-15'>{{$t("choose-one-page.CustomerInfoText")}}</p>
                                <p class='font-size-15'>{{$t("choose-one-page.CustomerInfoText2")}}</p>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class='dp-flex-column choose-one-middle-content middle-content-nakliyeci'>
                    <router-link v-if="whichPageT" :to="{name: whichPageT}">
                        <div v-bind:style="{opacity: chooseOneDeliveryOpacity}" class='choose-one-middle-content-blur'></div>
                        <div id='chooseOneNakliyeci' class='dp-flex-column choose-one-middle-content-text' @click="saveUserTypeInfo('2')" @mouseover="mouseOverEvent('chooseOneNakliyeci')" @mouseleave="mouseLeaveEvent('chooseOneNakliyeci')">
                            <div>
                                <p class='font-size-25'>{{$t("choose-one-page.Load")}}</p>
                                <p class='font-size-25'>{{$t("choose-one-page.Searching")}}</p>
                            </div>

                            <div>
                                <p class='font-size-15'>{{$t("choose-one-page.CarrierInfoText")}}</p>
                                <p class='font-size-15'>{{$t("choose-one-page.CarrierInfoText2")}}</p>
                            </div>
                        </div>
                    </router-link>
                    <!-- {name: whichPage} -->
                    <router-link v-else to="#">
                        <div v-bind:style="{opacity: chooseOneDeliveryOpacity}" class='choose-one-middle-content-blur'></div>
                        <div id='chooseOneNakliyeci' class='dp-flex-column choose-one-middle-content-text' @click="saveUserTypeInfo('2')" @mouseover="mouseOverEvent('chooseOneNakliyeci')" @mouseleave="mouseLeaveEvent('chooseOneNakliyeci')">
                            <div>
                                <p class='font-size-25'>{{$t("choose-one-page.Load")}}</p>
                                <p class='font-size-25'>{{$t("choose-one-page.Searching")}}</p>
                            </div>

                            <div>
                                <p class='font-size-15'>{{$t("choose-one-page.CarrierInfoText")}}</p>
                                <p class='font-size-15'>{{$t("choose-one-page.CarrierInfoText2")}}</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class='choose-one-middle-subtitle text-center mt-mb-70 font-size-15'>
                <p class='text-bold font-size-20'>{{$t("choose-one-page.Title")}}</p>
                <p class='font-size-15 mt-mb-15'>{{$t("choose-one-page.Desc")}}</p>
            </div>
        </div>
    </LayoutUserLoginRegister>

</template>
<script>
import LayoutUserLoginRegister from "./_LayoutUserLoginRegister.vue";
export default {
    name: "ChooseOne",
    props: ["whichPageT"],
    data() {
        return {
            chooseOneCustomerOpacity: 0.65,
            chooseOneDeliveryOpacity: 0.65,
            whichPage: localStorage.getItem("whichPage"),
        };
    },
    components: {
        LayoutUserLoginRegister,
    },
    methods: {
        mouseOverEvent(id) {
            let opacity = "0.20";

            if (id == "chooseOneMusteri") {
                this.chooseOneCustomerOpacity = opacity;
            } else if (id == "chooseOneNakliyeci") {
                this.chooseOneDeliveryOpacity = opacity;
            }
        },
        mouseLeaveEvent(id) {
            let opacity = "0.65";

            if (id == "chooseOneMusteri") {
                this.chooseOneCustomerOpacity = opacity;
            } else if (id == "chooseOneNakliyeci") {
                this.chooseOneDeliveryOpacity = opacity;
            }
        },
        saveUserTypeInfo(userType) {
            localStorage.setItem("userType", userType);
        },
    },
};
</script>

<style>
@import "@/assets/css/introduction-area/choose-one.css";
</style>