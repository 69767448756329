<template>
    <div class="dp-flex-column">
        <div class="panel-content-title">
            <h2 class="map-content-title">
                {{$t("customer-dashboard-page.MapContentTitle")}}
            </h2>
        </div>
        <div class="panel-content dp-flex-column">
            <div class="">
                <GmapMap :center="{lat:coordinates.lat, lng: coordinates.lng}" :options="{streetViewControl:false, mapTypeControl:false, fullscreenControl:false, disableDefaultUi:false, minZoom:4}" :zoom="6" map-type-id="roadmap" class="map">
                    <GmapMarker :key="index" v-for="(m, index) in markers" :position="m" @click="center=m" :icon="{url: require('@/assets/images/customer-area/dashboard/maps/ref-point.svg')}"></GmapMarker>
                    <!-- <GmapMarker ref="myMarker" :position="new google.maps.LatLng(1.38, 103.8)" />
                    <GmapMarker :key="index" :position="markers.position" :clickable="true" :draggable="true" @click="center=m.position" /> -->
                </GmapMap>
            </div>

            <div class="map-subtitle-info">
                <p class="font-size-15">
                    {{$t("customer-dashboard-page.MapContentDesc")}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Map",
    components: {},
    data() {
        return {
            coordinates: {
                lat: 0,
                lng: 0,
            },
            center: {
                lat: 0,
                lng: 0,
            },
            markers: [],
            currentPlace: null,
        };
    },
    mounted() {
        this.geolocate();
    },
    created() {
        this.$getLocation({}).then((coordinates) => {
            this.coordinates = coordinates;
            console.log(this.coordinates);
            //37.8890164
            // 32.4945477
        });
    },
    methods: {
        setPlace(place) {
            this.currentPlace = place;
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                //console.log(this.center);
                this.setPlace(this.center);
            });
            this.markers = [
                {
                    lat: 37.88879600333804,
                    lng: 32.49466911972377,
                    label: "Caner",
                },
            ];
        },
    },
};
</script>

<style>
@import "@/assets/css/customer-area/customer-dashboard/map.css";
</style>