<template>
    <div class="dp-flex-column justify-content-between panel-content">

        <TitleWithIcon :title="$t('vehicle-infos.Title')" :actionName="$t('button.Edit')">
            <template v-slot:action-icon>
                <i class="fa-solid font-size-16 ml-mr-10 fa-pen"></i>
            </template>
        </TitleWithIcon>

        <div class="dp-flex-row dp-flex-wrap justify-content-between mt-mb-10">
            <BlueBox class="vehicle-infos-content flex-wrap-48" :title="$t('vehicle-infos.VehicleCount')" :description="getVehicleInfos.vehicleCount"></BlueBox>
            <!-- $t('vehicle-infos.TrailerTypePlaceHolder') dil dosyasından çekilen desc -->
            <BlueBox class="vehicle-infos-content flex-wrap-48" :title="$t('vehicle-infos.TrailerType')" :description="getVehicleInfos.trailerTypeName"></BlueBox>
            <!-- :description="$t('vehicle-infos.CasePropertiesPlaceHolder')" -->
            <BlueBox class="vehicle-infos-content flex-wrap-48" :title="$t('vehicle-infos.CaseProperties')" :description="getVehicleInfos.caseType != '' ? getVehicleInfos.caseType : $t('vehicle-infos.None')"></BlueBox>
            <BlueBox class="vehicle-infos-content flex-wrap-48" :title="$t('vehicle-infos.Body_Floor_Type')" :description="getVehicleInfos.bodyFloorTypeName"></BlueBox>
        </div>

    </div>
</template>

<script>
import TitleWithIcon from "@/components/Widgets/CustomerPanel/TitleWithIcon.vue";
import BlueBox from "@/components/Widgets/CustomerPanel/BlueBox.vue";
import { mapGetters } from "vuex";
export default {
    components: { TitleWithIcon, BlueBox },

    mounted() {
        //console.log(this.getVehicleInfos);
    },

    computed: {
        ...mapGetters("createLoad", ["getVehicleInfos"]),
    },
};
</script>

<style>
</style>