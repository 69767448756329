<template>
    <div class="online-message dp-flex-column">
        <div class="online-message-header-content justify-content-between items-center dp-flex-row">
            <div>
                <p class="font-size-14 text-semi-bold">
                    D2200056 No’lu Sefer
                </p>
                <p class="font-size-12 text-medium">
                    İstanbul - Konya
                </p>
            </div>
            <i class="fa-solid fa-ellipsis-vertical"></i>
        </div>
        <div class="dp-flex-column background-color-F4F6F8 height-80 width-100">
            <div class="last-messages-content p-y5 width-100">
                <div class="dp-flex-row items-center">
                    <i class="fa-solid fa-magnifying-glass position-absolute font-size-14 ml-y1 color-929DA7 search"></i>
                    <input type="text" class="width-100 txt" :placeholder="$t('incoming-messages.SearchPerson')">
                </div>
            </div>
            <div class="p-y5 dp-flex-column incoming-messages-list width-100">

                
                <IncomingMessagesItem class="massage-active" :imgUrl="'ben.png'" :message="'Tamamdır abi teklifi onaylayın...'" :messageCount="'2'" :name="'Rıza Turancan'" :price="'tl 15.0000'" :time="'11:26'"></IncomingMessagesItem>

                <IncomingMessagesItem :imgUrl="'ben.png'" :message="'Tamamdır abi teklifi onaylayın...'" :messageCount="'2'" :name="'Rıza Turancan'" :price="'tl 15.0000'" :time="'11:26'"></IncomingMessagesItem>

                <IncomingMessagesItem :imgUrl="'ben.png'" :message="'Tamamdır abi teklifi onaylayın...'" :messageCount="'2'" :name="'Rıza Turancan'" :price="'tl 15.0000'" :time="'11:26'"></IncomingMessagesItem>
                
                <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                    <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                        <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                    </div>
                    <div class="dp-flex-column width-80">
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13 color-F07C31 text-semi-bold">
                                Teklif
                            </p>
                            <p class="font-size-16 text-medium">
                                tl 15.000
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-16 text-medium">
                                Rıza Turancan
                            </p>
                            <p class="font-size-12 color-667085 text-medium">
                                11:26
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13">
                                Tamamdır abi teklifi onaylayın...
                            </p>
                        </div>
                    </div>
                </div>

                <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                    <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                        <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                    </div>
                    <div class="dp-flex-column width-80">
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13 color-F07C31 text-semi-bold">
                                Teklif
                            </p>
                            <p class="font-size-16 text-medium">
                                tl 15.000
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-16 text-medium">
                                Rıza Turancan
                            </p>
                            <p class="font-size-12 color-667085 text-medium">
                                11:26
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13">
                                Tamamdır abi teklifi onaylayın...
                            </p>
                        </div>
                    </div>
                </div>

                <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                    <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                        <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                    </div>
                    <div class="dp-flex-column width-80">
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13 color-F07C31 text-semi-bold">
                                Teklif
                            </p>
                            <p class="font-size-16 text-medium">
                                tl 15.000
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-16 text-medium">
                                Rıza Turancan
                            </p>
                            <p class="font-size-12 color-667085 text-medium">
                                11:26
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13">
                                Tamamdır abi teklifi onaylayın...
                            </p>
                        </div>
                    </div>
                </div>

                <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                    <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                        <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                    </div>
                    <div class="dp-flex-column width-80">
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13 color-F07C31 text-semi-bold">
                                Teklif
                            </p>
                            <p class="font-size-16 text-medium">
                                tl 15.000"
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-16 text-medium">
                                Rıza Turancan
                            </p>
                            <p class="font-size-12 color-667085 text-medium">
                                11:26
                            </p>
                        </div>
                        <div class="dp-flex-row justify-content-between">
                            <p class="font-size-13">
                                Tamamdır abi teklifi onaylayın...
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="dp-flex-row justify-content-center items-center">
            <button class="font-size-13 btn-none p-y4 online-message-footer-button border-radius-5 width-80 mt-mb-y25 text-color-white ">
                {{$t("messages.AllMessages")}}
            </button>
        </div>
    </div>
</template>

<script>
import IncomingMessagesItem from "@/components/CustomerArea/Messages/IncomingMessagesItem.vue";
export default {
    components: { IncomingMessagesItem },
};
</script>

<style>
@import url("@/assets/css/widgets/customer-panel/online-message.css");
</style>