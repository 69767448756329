<template>
    <LayoutUserLoginRegister>
        <div class="dp-flex-column items-center justify-content-center login-left">
            <div class='login-form'>
                <h1 class='font-size-35 text-bold'>{{$t("login-page.Title")}}</h1>

                <h3 class='font-size-15 mb-20'>
                    {{$t("login-page.Desc")}}
                    <br />
                    {{$t("login-page.Desc2")}}
                </h3>

                <h3 v-if="resultModel.code == 101" class="text-bold">
                    Giriş Bilgileriniz Hatalı
                </h3>

                <form @submit="postEmailAndPasswordToAPI" method="post">
                    <div class='input-icons'>
                        <i class='fas fa-xs fa-user icon'></i>
                        <input class='btn-none login-input' name="email" type="text" placeholder="Email Girini" v-model="email" />
                        <p class='font-size-13 text-color-red'>{{errors.email}}</p>
                    </div>

                    <div class='input-icons'>
                        <i class="fa-solid fa-xs fa-lock icon"></i>
                        <input class='btn-none login-input' name='password' type="password" placeholder="Şifre Giriniz" v-model="password" />
                        <p class='font-size-13 text-color-red'>{{errors.password}}</p>
                    </div>

                    <div class='dp-flex-row justify-content-between items-center login-remember-and-forget-password'>
                        <div class=''>
                            <input v-model="rememberMe" id='chckRememberMe' type='checkbox' />
                            <label class='font-size-13' for='chckRememberMe'>{{$t("login-page.RememberMe")}}</label>
                        </div>
                        <router-link class='font-size-13 btn-none' to="/forget-password">{{$t("login-page.ForgetPassword")}}</router-link>
                    </div>

                    <button id="login-btn" class='btn-none mt-mb-25 login-input login-input-submit button-loading' type='submit'>
                        {{$t("login-page.SubmitButton")}}
                    </button>

                </form>

                <div class='login-register-div text-center mt-mb-25 font-size-15'>
                    <p>Henüz bir hesabınız yok mu?</p>
                    <router-link :to="{name: 'Register', params: {userType: userType}}"> Hesap Oluştur</router-link>
                </div>

                <div class='login-re-captcha-kvkk'>
                    <p class='font-size-13'>
                        ReCAPTCHA tarafından korunmaktadır ve
                        <br />
                        Google
                        <a href='#' class='text-color-blue font-size-13 btn-none'> Gizlilik Politikası </a>
                        ve
                        <a href='#' class='font-size-13 text-color-blue btn-none'> Hizmet Şartları</a>
                        ’na tabidir.
                    </p>
                </div>
            </div>

        </div>

        <Popup v-if="isOpenPopup" @closePopup="isOpenPopup = false">
            <template slot="popup-alert-icon" class="width-65">
                <div>
                    <img src="@/assets/images/widgets/pop-up/Group 1381.svg" />
                </div>
            </template>
            <template slot="popup-body-text">
                <div class="dp-flex-row justify-content-center text-center items-center font-size-15 width-50 mt-mb-y25">
                    {{popupMessage}}
                </div>
            </template>
            <template slot="popup-body-button">
                <div class="dp-flex-row justify-content-center items-center mt-mb-25">
                    <button class='popup-model-body-button font-size-15 text-medium btn-none' @click="closePopup">Tamam</button>
                </div>
            </template>
        </Popup>
    </LayoutUserLoginRegister>
</template>

<script>
// TODO- popup açıldığında çıkan ikonu result model'e göre değiştir (alert - success - warning - gibi)
// TODO: GİRİŞ YAP'a basıldıktan sonra kalan loading kalkacak.

import LayoutUserLoginRegister from "./_LayoutUserLoginRegister.vue";
import { APIUrls } from "@/Helpers/APIUrls";
import { validateRequired, validateEmail, validatePassword } from "@/Helpers/FormValidateFunctions.js";
import Popup from "@/components/Widgets/ModelPopup/Popup.vue";

export default {
    name: "Login",
    components: {
        LayoutUserLoginRegister,
        Popup,
    },
    data() {
        return {
            isOpenPopup: false,
            email: localStorage.getItem("email"),
            password: localStorage.getItem("password"),
            resultModel: {
                code: 0,
                message: "",
                data: {},
            },

            popupMessage: "",
            errors: {},
            userType: localStorage.getItem("userType"),
            rememberMe: localStorage.getItem("rememberMe") == true ? true : false,
        };
    },
    methods: {
        validateRequired,
        validateEmail,
        validatePassword,
        postEmailAndPasswordToAPI(e) {
            e.preventDefault();

            let error = 0;

            if (!validateRequired("Email", this.email).isValid) {
                this.$set(this.errors, "email", validateRequired("Email", this.email).message);
                error++;
            } else if (!validateEmail(this.email).isValid) {
                this.$set(this.errors, "email", validateEmail(this.email).message);
                error++;
            }
            if (!validateRequired("Password", this.password).isValid) {
                this.$set(this.errors, "password", validateRequired("Password", this.password).message);
                error++;
            } else if (!validatePassword(this.password, this.password).isValid) {
                this.$set(this.errors, "password", validatePassword(this.password, this.password).message);
                error++;
            }

            if (error == 0) {
                if (localStorage.getItem("userType") == 3) {
                    //this.$router.push("/customer");
                } else {
                    //this.$router.push("/transporter");
                }

                if (this.rememberMe) {
                    localStorage.setItem("email", this.email);
                    localStorage.setItem("password", this.password);
                    localStorage.setItem("rememberMe", this.rememberMe);
                }

                document.querySelector("#login-btn").classList.add("button--loading");

                this.axios({
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    url: this.userType == 3 ? APIUrls.CUSTOMER_LOGIN_URL : APIUrls.TRANSPORTER_LOGIN_URL,
                    data: {
                        value: this.email,
                        password: this.password,
                        firebaseToken: null,
                    },
                })
                    .then((response) => {
                        console.log(response.data);

                        this.$set(this.resultModel, "code", response.data.code);

                        this.$set(this.resultModel, "message", response.data.message);

                        this.$set(this.resultModel, "data", response.data.data);

                        if (this.resultModel.code == 1) {
                            localStorage.setItem("guid", this.resultModel.data.guid);
                            localStorage.setItem("token", this.resultModel.data.token);
                            localStorage.setItem("isOwner", this.resultModel.data.isOwner);
                            localStorage.setItem("id", this.resultModel.data.id);

                            this.axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");

                            // TODO: customer ve transporter kısmında karışıklık olmamsı için loacalStorage'a basılan veriler kontorl edilmeli.

                            if (this.resultModel.data.statusTypeId == 1) {
                                this.userType == 3 ? this.$router.push("/customer") : this.$router.push("/transporter");
                            } else if (this.resultModel.data.statusTypeId == 2) {
                                this.popupMessage = "Kullanıcı Kaydınız İnceleniyor. Lütfen Daha Sonra Tekrar Deneyinz !";
                                this.isOpenPopup = true;
                            } else if (this.resultModel.data.statusTypeId == 3) {
                                this.popupMessage = "Kullanıcı Kaydınız Reddedildi. Bizimle İletişime Geçiniz !";
                                this.isOpenPopup = true;
                            } else if (this.resultModel.data.statusTypeId == 4) {
                                this.$router.push("/complete-registration");
                            }
                        } else if (this.resultModel.code == 2) {
                            this.popupMessage = "Kullanıcı Adı veya Şifreniz Hatalıdır. Lütfen Bilgileri Eksiksiz ve Doğru Giriniz.";
                            this.isOpenPopup = true;
                        } else if (this.resultModel.code == 7) {
                            this.popupMessage = "Kullanıcı Bulunamadı";
                            this.isOpenPopup = true;
                        } else if (this.resultModel.code == 8) {
                            this.popupMessage = "Bu Kullanıcı Bir Müşteri Heabı Değildir. Lütfen Nakliyeci kısmından giriş yapınız !";
                            this.isOpenPopup = true;
                        } else if (this.resultModel.code == 9) {
                            this.popupMessage = "Bu Kullanıcı Bir Nakliyeci Heabı Değildir. Lütfen Müşteri Kısmından Giriş Yapınız !";
                            this.isOpenPopup = true;
                        }
                    })
                    .catch(function (error) {
                        console.log(error.data);
                        this.popupMessage = "Beklenmedik Bir Hata Oluştu! Lütfen Daha Sonra Tekrar Deneyiniz";
                        this.isOpenPopup = true;
                    });

                document.querySelector("#login-btn").classList.remove("button--loading");
            }
        },
        openPopup() {
            this.isOpenPopup = true;
        },
        closePopup() {
            this.isOpenPopup = false;
        },
    },
};
</script>