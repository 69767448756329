<template>
    <div class="dp-flex-row">
        <Sidebar class="customer-panel-sidebar"></Sidebar>
        <div class="dp-flex-column" style="width:80%; background-color:#F0F1F4;">

            <Header>
                <template slot="banner-block">
                    <div class="header-banner-block-s">
                    </div>
                </template>
            </Header>
            <main class="panel-main">
                <router-view></router-view>
            </main>
        </div>

        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Sidebar from "./Sidebar.vue";
import mqtt from "mqtt/dist/mqtt";

export default {
    name: "LayoutCustomerPanel",
    components: {
        Header,
        Footer,
        Sidebar,
    },
    data() {
        return {
            // MQTT VARIABLES
            connection: {
                protocol: "ws",
                host: "171.22.187.222",
                // ws: 8083; wss: 8084
                port: 8083,
                endpoint: "/mqtt",
                // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
                clean: true,
                connectTimeout: 30 * 1000, // ms
                reconnectPeriod: 4000, // ms
                clientId: "vuesjRiza",
                // auth
                username: "daliport",
                password: "123456",
            },
            subscription: {
                topic: "messages/chat/",
                // + this.id + "/" + this.topicVehicleId
                qos: 0,
            },
            publish: {
                topic: "messages/chat/",
                qos: 0,
                payload: "",
            },
            receiveNews: "",
            qosList: [0, 1, 2],
            client: {
                connected: false,
            },
            subscribeSuccess: false,
            connecting: false,
            retryTimes: 0,
        };
    },
    methods: {
        initData() {
            this.client = {
                connected: false,
            };
            this.retryTimes = 0;
            this.connecting = false;
            this.subscribeSuccess = false;
        },

        handleOnReConnect() {
            this.retryTimes += 1;
            // if (this.retryTimes > 5) {
            try {
                this.client.end();
                this.initData();
                this.$message.error("Connection maxReconnectTimes limit, stop retry");
            } catch (error) {
                this.$message.error(error.toString());
            }
            // }
        },

        createConnection() {
            try {
                this.connecting = true;
                const { protocol, host, port, endpoint, ...options } = this.connection;
                const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
                this.client = mqtt.connect(connectUrl, options);
                if (this.client.on) {
                    this.client.on("connect", () => {
                        this.connecting = false;
                        console.log("Connection succeeded!");
                    });
                    this.client.on("reconnect", this.handleOnReConnect);
                    this.client.on("error", (error) => {
                        console.log("Connection failed", error);
                    });
                    this.client.on("message", (topic, message) => {
                        this.receiveNews = this.receiveNews.concat(message);

                        console.log(`Received message ${message} from topic ${topic}`);

                        // let arrays = message.toString().split("#");

                        // let messageObject = {
                        //     id: 0,
                        //     isSeen: false,
                        //     isSender: false,
                        //     readAt: null,
                        //     sentAt: arrays[3],
                        //     text: arrays[2],
                        //     voyageId: 2,
                        // };

                        // this.messages.push(messageObject);
                    });
                }
            } catch (error) {
                this.connecting = false;
                console.log("mqtt.connect error", error);
            }
        },

        doSubscribe() {
            const { topic, qos } = this.subscription;
            this.client.subscribe(topic, { qos }, (error, res) => {
                if (error) {
                    console.log("Subscribe to topics error", error);
                    return;
                }
                this.subscribeSuccess = true;
                console.log("Subscribe to topics res", res);
            });
        },

        doUnSubscribe() {
            const { topic } = this.subscription;
            this.client.unsubscribe(topic, (error) => {
                if (error) {
                    console.log("Unsubscribe error", error);
                }
            });
        },

        doPublish() {
            const { topic, qos, payload } = this.publish;
            this.client.publish(topic, payload, { qos }, (error) => {
                if (error) {
                    console.log("Publish error", error);
                }
            });
        },

        destroyConnection() {
            if (this.client.connected) {
                try {
                    this.client.end(false, () => {
                        this.initData();
                        console.log("Successfully disconnected!");
                    });
                } catch (error) {
                    console.log("Disconnect failed", error.toString());
                }
            }
        },
    },
    mounted() {
        // this.createConnection();

        // this.publish.topic = this.publish.topic + localStorage.getItem("id");        

        // this.publish.payload = "true";
        // this.doPublish();
    },
    beforeDestroy() {
        // this.publish.payload = "false";
        // this.doPublish();

        // this.destroyConnection();
    },
};
</script>

<style>
@import "@/assets/css/customer-area/customer-panel-side-bar.css";
@import "@/assets/css/customer-area/customer-panel.css";
</style>