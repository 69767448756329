<template>
    <LayoutCompleteRegistration>
        <div class="dp-flex-column items-center justify-content-center login-left">
            <div class="identitiy-information-title">
                <h1 class='font-size-30'>{{$t("complete-registration-page.CompleteRegistrationTitle")}}</h1>
            </div>
            <div class="tab complete-registartion-tab-padding">
                <div class="tab-button-header dp-flex-row justify-content-around">
                    <button @click="selectTab(1)" v-bind:class="{'tab-button-item':isActiveIdentity}" class="font-size-15 text-capitalize btn-none">
                        <span class="font-bold font-size-20">1 </span> {{$t("complete-registration-page.CompleteRegistrationTabsButton1")}}
                    </button>

                    <button @click="selectTab(2)" v-bind:class="{'tab-button-item':isActiveCompanyInformation}" class="font-size-15 text-capitalize btn-none">
                        <span class="font-bold font-size-20">2 </span> {{$t("complete-registration-page.CompleteRegistrationTabsButton2")}}
                    </button>

                    <button @click="selectTab(3)" v-bind:class="{'tab-button-item':isActiveConfirmationProcess}" class="font-size-15 text-capitalize btn-none">
                        <span class="font-bold font-size-20">3 </span> {{$t("complete-registration-page.CompleteRegistrationTabsButton3")}}
                    </button>
                </div>
                <div class="tab-content dp-flex-column items-center">

                    <IdentityInformation v-if="currnetTab == 1" @changeTab="selectTab(2)"></IdentityInformation>

                    <CompanyInformation v-if="currnetTab == 2" @changeTab="selectTab(3)"></CompanyInformation>

                    <ConfirmationProcess v-if="currnetTab == 3"></ConfirmationProcess>

                </div>
            </div>
        </div>
    </LayoutCompleteRegistration>
</template>

<script>

// TODO: tablardaki inputlara mask atılacak
// TODO: tablardaki inputlara validation atılacak.

import LayoutCompleteRegistration from "./_LayoutCompleteRegistration.vue";
import IdentityInformation from "./IdentityInformation.vue";
import CompanyInformation from "./CompanyInformation.vue";
import ConfirmationProcess from "./ConfirmationProcess.vue";

export default {
    name: "CompleteRegistrationTab",
    components: {
        LayoutCompleteRegistration,
        IdentityInformation,
        CompanyInformation,
        ConfirmationProcess,
    },
    data() {
        return {
            currnetTab: 1,
            isActiveIdentity: true,
            isActiveCompanyInformation: false,
            isActiveConfirmationProcess: false,
        };
    },
    methods: {
        selectTab(selectedTab) {
            this.currnetTab = selectedTab;
            this.findActiveTab(selectedTab);
        },
        
        findActiveTab(id) {
            if (id == 1) {
                this.isActiveIdentity = true;
                this.isActiveCompanyInformation = false;
                this.isActiveConfirmationProcess = false;
            } else if (id == 2) {
                this.isActiveIdentity = false;
                this.isActiveCompanyInformation = true;
                this.isActiveConfirmationProcess = false;
            } else if (id == 3) {
                this.isActiveIdentity = false;
                this.isActiveCompanyInformation = false;
                this.isActiveConfirmationProcess = true;
            }
        },
    },
};
</script>

<style>
</style>