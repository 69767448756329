import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();

// TODO: getCurrencies kısmı belki başka servise alınabilir.

export default class LoadService {
    resultModel = new ResultModel();

    errorResult = "";

    async getShipmentTypes(isActive) {
        await apiHelper.get(APIUrls.GET_SHIPMENT_TYPES, {
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getDraftLoadsByCustomerUserGudi() {
        await apiHelper.get(APIUrls.GET_DRAFT_LOADS_BY_CUSTOMER_USER_GUID)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getVehicleTypes(isActive) {
        await apiHelper.get(APIUrls.GET_VEHICLE_TYPES, {
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getVehicleBodyTypes(isActive) {
        await apiHelper.get(APIUrls.GET_VEHICLE_BODY_TYPES, {
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getBodyFloorTypes(isActive) {
        await apiHelper.get(APIUrls.GET_BODY_FLOOR_TYPES, {
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getLoadItemTypes() {
        await apiHelper.get(APIUrls.GET_LOAD_ITEM_TYPES)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getLoadTypes() {
        await apiHelper.get(APIUrls.GET_LOAD_TYPES)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getLoadingMethods() {
        await apiHelper.get(APIUrls.GET_LOADING_METHODS)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getLoadTonnageRange() {
        await apiHelper.get(APIUrls.GET_LOAD_TONNAGE_RANGES)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getLoadsByCustomerUserGuid(isPastLoad, isActive) {
        await apiHelper.get(APIUrls.GET_LOADS_BY_CUSTOMER_USER_GUID, {
            isPastLoad: isPastLoad,
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async deleteOrRestoreDeleteLoad(loadId, isActive) {
        await apiHelper.postQuery(APIUrls.DELETE_OR_RESTORE_DELETE_LOAD, {
            id: loadId,
            isActive: isActive
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getCurrencies(isActive) {
        await apiHelper.get(APIUrls.GET_CURRENCIES, {
            isActive: isActive,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getLoadsForTransporter() {
        await apiHelper.get(APIUrls.GET_LOADS_FOR_TRANSPORTER, {
            isActive: true,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getLoadDetailForTransporter(id) {
        await apiHelper.get(APIUrls.GET_LOAD_DETAIL_FOR_TRANSPORTER, {
            id: id,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };
}