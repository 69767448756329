<template>
    <Layout>
        <div class='home-page-section'>
            <div class='home-tasit-map-content mt-mb-50 dp-flex-row'>
                <div class='tasit-map'>
                    <div class='blur'></div>
                    <img class='tasit-map-images' src='@/assets/images/introduction-area/home-page/bg-map2x.png' alt='tasit-image' />
                </div>
                <div class='tasit-map'>
                    <div class='blur'></div>
                    <img class='tasit-map-images' src='@/assets/images/introduction-area/home-page/bg-tir2x.png' alt='tasit-image' />
                </div>
                <div class='tasit-map-middle-content dp-flex-column justify-content-center'>
                    <div class='dp-flex-column justify-content-center items-center'>
                        <div class='tasit-map-middle-title mt-mb-25'>
                            <span class='text-upper'>{{$t("home-page.Title")}}</span> ile
                            <br />
                            <span class='text-capitalize'>{{$t("home-page.TitleDesc")}}</span>
                        </div>
                        <!-- <div v-if="userToken == null" class="header-menu-item" @click="saveWhichPageInfo('Login')">
                            <a class="header-menu-a" href="#">paketler</a>
                            <router-link class="header-menu-a" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">Paketler</router-link>
                        </div> -->
                        <router-link class="tasit-map-middle-button mt-mb-25 text-upper" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">{{$t("home-page.MapUpButton")}}</router-link>
                        <!-- <a class='tasit-map-middle-button mt-mb-25 text-upper' href="#">{{$t("home-page.MapUpButton")}}</a> -->
                    </div>
                </div>
            </div>

            <div class='yenilik-content mt-mb-50'>
                <h2 class='yenilik-title text-normal font-size-45'>
                    {{$t("home-page.InovationContentTitle")}}
                    <span class='font-size-45'>
                        <img class='yenilik-title-img' src='@/assets/images/introduction-area/home-page/bg-yellow.svg' />
                        {{$t("home-page.InovationContentTitleBold")}}
                    </span>
                    <br />
                    <span class='font-size-45'>{{$t("home-page.InovationContentTitleBold2")}}</span>
                </h2>
                <div class='yenilik-image'>
                    <img src='@/assets/images/introduction-area/home-page/Image 18.png' />
                </div>
                <div class='yenilik-subtitle-blok'>
                </div>
            </div>

            <!-- <div class='tara-aninda-tasi-section items-center dp-flex-column mt-mb-50 tex'>
                <h2 class='tara-aninda-tasi-title text-center'>
                    {{$t("home-page.MoveInstantlyTitle")}} <span>{{$t("home-page.DaliportTitle")}}</span> {{$t("home-page.MoveInstantlyTitle2")}}
                    <br />
                    {{$t("home-page.MoveInstantlyTitle3")}}
                </h2>
                <p class='tara-aninda-tasi-text mt-mb-25'>
                    {{$t("home-page.MoveInstantlyTitleDesc")}}
                </p>
                <div class='dp-flex-row'>
                    <div class='row-50 text-right'>
                        <img src='@/assets/images/introduction-area/home-page/Image 7.png' />
                    </div>
                    <div class='row-50 dp-flex-column justify-content-end pb-50 pl-50'>
                        <div class='tara-aninda-right-info'>
                            <img src='@/assets/images/introduction-area/home-page/Group 872.png' />
                            <p class='font-size-20'>{{$t("home-page.MoveInstantlyDescTitle")}}</p>
                        </div>

                        <p class='tara-anidan-tasi-right-text font-size-15 mt-mb-25'>
                            {{$t("home-page.MoveInstantlyDesc")}}
                            <br /> <br />
                            {{$t("home-page.MoveInstantlyDesc2")}}
                        </p>
                        <a class='tara-aninda-tasi-details-btn text-upper font-size-13' href="#">{{$t("home-page.MoveInstantlyButton")}}</a>
                    </div>
                </div>
            </div> -->

            <div class='tara-arac-bulmak dp-flex-row mt-mb-50'>
                <div class='arac-bulmak-left'>
                    <div class='arac-bulmak-left-info dp-flex-column'>
                        <h1 class='rac-bulmak-left-title'>
                            <span class="font-size-45"> Daliport </span> {{$t("home-page.FindVehicleTitle")}}
                        </h1>
                        <p class='arac-bulmak-left-text'>
                            {{$t("home-page.FindVehicleTitleDesc")}}
                        </p>
                    </div>
                </div>
                <div class='arac-bulmak-right'>
                    <div class='arac-bulmak-right-info dp-flex-column'>
                        <div class='arac-bulmak-services dp-flex-row services-row text-capitalize'>
                            <div class='services-item dp-flex-column'>
                                <img class='services-image' src='@/assets/images/introduction-area/home-page/fast-delivery.png' />
                                <p class='services-text'>{{$t("home-page.FindVehicleStepByStep")}}</p>
                            </div>
                            <div class='services-item dp-flex-column'>
                                <img class='services-image' src='@/assets/images/introduction-area/home-page/handshake.png' />
                                <p class='services-text'>{{$t("home-page.FindVehicleStepByStep2")}}</p>
                            </div>
                            <div class='services-item dp-flex-column'>
                                <img class='services-image' src='@/assets/images/introduction-area/home-page/delivery-time.png' />
                                <p class='services-text'>{{$t("home-page.FindVehicleStepByStep3")}}</p>
                            </div>
                        </div>
                        <div class='arac-bulmak-services dp-flex-row services-row mb-50 text-capitalize'>
                            <div class='services-item dp-flex-column'>
                                <img class='services-image' src='@/assets/images/introduction-area/home-page/map.png' />
                                <p class='services-text'>{{$t("home-page.FindVehicleStepByStep4")}}</p>
                            </div>
                            <div class='services-item dp-flex-column'>
                                <img class='services-image' src='@/assets/images/introduction-area/home-page/on-time.png' />
                                <p class='services-text'>{{$t("home-page.FindVehicleStepByStep5")}}</p>
                            </div>
                            <div class='services-item dp-flex-column'>
                                <img class='services-image' src='@/assets/images/introduction-area/home-page/analytics.png' />
                                <p class='services-text'>{{$t("home-page.FindVehicleStepByStep6")}}</p>
                            </div>
                        </div>

                        <p class='arac-bulmak-right-text'>
                            {{$t("home-page.FindVehicleDesc")}}
                        </p>
                        <p class='arac-bulmak-right-text'>
                            {{$t("home-page.FindVehicleDesc2")}}
                        </p>
                        <a href="#" class='btn-arac-bulmak-tasit-ara font-size-13 text-upper'>{{$t("home-page.FindVehicleButton")}}</a>
                    </div>
                </div>
            </div>

            <div class='tara-ile-yuk-tasimak mt-mb-50 dp-flex-row'>
                <div class='yuk-tasima-left dp-flex-column div-left-rigth-center'>
                    <h1 class='yuk-tasima-left-title font-size-45'>
                        <span class="font-size-45"> Daliport </span> {{$t("home-page.LoadCarrying")}}
                    </h1>

                    <p class='yuk-tasima-left-text font-size-15'>
                        {{$t("home-page.LoadCarryingDesc")}}
                    </p>
                    <p class='yuk-tasima-left-text font-size-15'>
                        {{$t("home-page.LoadCarryingDesc2")}}
                    </p>

                    <div class='dp-flex-row yuk-tasima-services services-row text-capitalize'>
                        <div class='services-item dp-flex-column'>
                            <img class='services-image yuk-tasima-services-image' src='@/assets/images/introduction-area/home-page/Path 1996.png' />
                            <p class='services-text'>{{$t("home-page.LoadCarryingStepByStep")}}</p>
                        </div>
                        <div class='services-item dp-flex-column'>
                            <img class='services-image yuk-tasima-services-image' src='@/assets/images/introduction-area/home-page/Group 367.png' />
                            <p class='services-text'>{{$t("home-page.LoadCarryingStepByStep2")}}</p>
                        </div>
                        <div class='services-item dp-flex-column'>
                            <img class='services-image' src='@/assets/images/introduction-area/home-page/Group 385.png' />
                            <p class='services-text'>{{$t("home-page.LoadCarryingStepByStep3")}}</p>
                        </div>
                    </div>
                    <div class='dp-flex-row yuk-tasima-services services-row mb-50 text-capitalize'>
                        <div class='services-item dp-flex-column'>
                            <img class='services-image' src='@/assets/images/introduction-area/home-page/Group 386.png' />
                            <p class='services-text'>{{$t("home-page.LoadCarryingStepByStep4")}}</p>
                        </div>
                        <div class='services-item dp-flex-column'>
                            <img class='services-image' src='@/assets/images/introduction-area/home-page/Path 2020.png' />
                            <p class='services-text'>{{$t("home-page.LoadCarryingStepByStep5")}}</p>
                        </div>
                        <div class='services-item dp-flex-column'>
                            <img class='services-image' src='@/assets/images/introduction-area/home-page/Group 397.png' />
                            <p class='services-text'>{{$t("home-page.LoadCarryingStepByStep6")}}</p>
                        </div>
                    </div>
                </div>
                <div class='yuk-tasima-right'>
                    <img src='@/assets/images/introduction-area/home-page/young-man-working-warehouse-with-boxes (1).png' />
                </div>
            </div>

            <div class='tara-avantaj-content justify-content-center dp-flex-row mt-mb-50'>
                <div class='tara-avantaj-img'>
                    <img src='@/assets/images/introduction-area/home-page/Image 9.png' alt="" />

                </div>
                <div class='dp-flex-column justify-content-center items-left'>
                    <div class='tara-avantaj-title'>
                        <h3 class='text-upper'>{{$t("home-page.DaliportMobileAppTitle")}}</h3>
                        <h2>
                            {{$t("home-page.DaliportMobileAppTitleBold")}}
                            <br />
                            {{$t("home-page.DaliportMobileAppTitleBold2")}}
                        </h2>
                    </div>

                    <div class='tara-avantaj-list list'>
                        <h2 class='list-title'> {{$t("home-page.DaliportMobileAppListTitle")}}</h2>
                        <div class='list-item'>
                            <div class='point'></div>
                            <p class='list-item-text'>
                                {{$t("home-page.DaliportMobileAppList")}}
                            </p>
                        </div>
                        <div class='list-item'>
                            <div class='point'></div>
                            <p class='list-item-text'>
                                {{$t("home-page.DaliportMobileAppList2")}}
                            </p>
                        </div>
                        <div class='list-item'>
                            <div class='point'></div>
                            <p class='list-item-text'>
                                {{$t("home-page.DaliportMobileAppList3")}}
                            </p>
                        </div>
                        <div class='list-item'>
                            <div class='point'></div>
                            <p class='list-item-text'>
                                {{$t("home-page.DaliportMobileAppList4")}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class='home-page-steb steb-by-steb-content dp-flex-row justify-content-center items-center mt-mb-50'>
                <div class='home-page-steb-cart step-by-step-cart text-center dp-flex-column items-center justify-content-center font-size-13'>
                    <img src='@/assets/images/introduction-area/home-page/Group 880.png' class='step-by-step-cart-image' />
                    <p class='step-by-step-cart-text'>
                        {{$t("home-page.DaliportMobileAppStepByStep")}}
                    </p>
                </div>
                <div class='steb-by-step-arrow'>
                    <img src='@/assets/images/introduction-area/home-page/Path 3454.png' />
                </div>
                <div class='home-page-steb-cart step-by-step-cart text-center dp-flex-column items-center justify-content-center font-size-13'>
                    <img src='@/assets/images/introduction-area/home-page/arrow-right-to-bracket.png' class='step-by-step-cart-image' />
                    <p class='step-by-step-cart-text'>
                        {{$t("home-page.DaliportMobileAppStepByStep2")}}
                    </p>
                </div>
                <div class='steb-by-step-arrow'>
                    <img src='@/assets/images/introduction-area/home-page/Path 3454.png' />
                </div>
                <div class='home-page-steb-cart step-by-step-cart text-center dp-flex-column items-center justify-content-center font-size-13'>
                    <img src='@/assets/images/introduction-area/home-page/Group 879.png' class='step-by-step-cart-image' />
                    <p class='step-by-step-cart-text'>
                        {{$t("home-page.DaliportMobileAppStepByStep3")}}
                    </p>
                </div>
                <div class='steb-by-step-arrow'>
                    <img src='@/assets/images/introduction-area/home-page/Path 3454.png' />
                </div>
                <div class='home-page-steb-cart step-by-step-cart text-center dp-flex-column items-center justify-content-center font-size-13'>
                    <img src='@/assets/images/introduction-area/home-page/list.png' class='step-by-step-cart-image' />
                    <p class='step-by-step-cart-text'>
                        {{$t("home-page.DaliportMobileAppStepByStep4")}}
                    </p>
                </div>
                <div class='steb-by-step-arrow'>
                    <img src='@/assets/images/introduction-area/home-page/Path 3454.png' />
                </div>
                <div class='home-page-steb-cart step-by-step-cart text-center dp-flex-column items-center justify-content-center font-size-13'>
                    <img src='@/assets/images/introduction-area/home-page/handshake-angle.png' class='step-by-step-cart-image' />
                    <p class='step-by-step-cart-text'>
                        {{$t("home-page.DaliportMobileAppStepByStep5")}}
                    </p>
                </div>
            </div>

            <div class='home-page-app-download-icons dp-flex-row justify-content-center items-center mt-mb-50'>
                <a href="#"><img src='@/assets/images/introduction-area/home-page/Group 878.png' alt="" /></a>
                <a href="#" class='ml-10'><img src='@/assets/images/introduction-area/home-page/Group 877.png' alt="" /></a>
            </div>

            <!-- <div class='tara-customer-content justify-content-center dp-flex-row mt-mb-50'>
                <div class='tara-customer-info dp-flex-row'>
                    <div class='tara-customer-info-image'>
                        <img src='@/assets/images/introduction-area/home-page/Mask Group 9.png' />
                    </div>
                    <div class='dp-flex-column tara-customer-info-desc justify-content-center'>
                        <h1 class='tara-customer-info-title text-capitalize'>
                            barış gökçe
                        </h1>
                        <p class='tara-customer-info-statu font-size-20'>
                            ikonel elektronik genel müdürü
                        </p>
                        <p class='tara-customer-info-text font-size-15'>
                            TARA 2.0 teknolojisini kullanan kamyonlara anında erişebiliyoruz.
                            Yükümüzü teslim edip hedefe kadar izliyoruz.
                            Hizmet kalitesini arttıran kamyoncu sayısı giderek artmakta buda bizleri daha güvende
                            hissettirmektedir.
                        </p>
                    </div>
                </div>
                <div class='tara-customer-info dp-flex-row'>
                    <div class='tara-customer-info-image'>
                        <img src='@/assets/images/introduction-area/home-page/Mask Group 9.png' />
                    </div>
                    <div class='dp-flex-column tara-customer-info-desc justify-content-center'>
                        <h1 class='tara-customer-info-title text-capitalize'>
                            barış gökçe
                        </h1>
                        <p class='tara-customer-info-statu font-size-20'>
                            ikonel elektronik genel müdürü
                        </p>
                        <p class='tara-customer-info-text font-size-15'>
                            TARA 2.0 teknolojisini kullanan kamyonlara anında erişebiliyoruz.
                            Yükümüzü teslim edip hedefe kadar izliyoruz.
                            Hizmet kalitesini arttıran kamyoncu sayısı giderek artmakta buda bizleri daha güvende
                            hissettirmektedir.
                        </p>
                    </div>
                </div>
            </div> -->

            <div class='tara-find-transport mt-mb-50 dp-flex-column'>
                <h2 class='find-transport-text text-capitalize'>
                    {{$t("home-page.FindTransport")}}
                </h2>
                <router-link class="find-transport-button text-upper" :to="{name: 'ChooseOne', params: {whichPageT: 'Login'}}">
                    {{$t("home-page.FindTransportButton")}}
                </router-link>
                <!-- <a href="#" class='find-transport-button text-upper'>
                    {{$t("home-page.FindTransportButton")}}
                </a> -->
            </div>

        </div>
    </Layout>
</template>

<script>
import Layout from "@/components/IntroductionArea/_Layout/_Layout.vue";

export default {
    name: "Home",
    components: {
        Layout,
    },
    data() {
        return {
            patates: "patates",
        };
    },
    methods: {
        bas() {
            alert("adasda");
        },
    },
};
</script>


<style>
@import "@/assets/css/introduction-area/home-page.css";
</style>


