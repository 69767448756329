<template>
    <div class="dp-flex-column blue-box-content border-radius-5 justify-content-start">
        <p class="font-size-13">
            {{title}}
        </p>
        <p class="font-size-16 text-semi-bold">
            {{description}}
        </p>
    </div>
</template>

<script>
export default {
    props:["title", "description"]
};
</script>

<style>
@import url("@/assets/css/widgets/customer-panel/blue-box.css");
</style>