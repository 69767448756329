<template>
    <div class="dp-flex-column">
        <Title :title="$t('messages.Title')"></Title>

        <div class="dp-flex-row items-center">
            <i class="color-869094 font-size-20 mr-y1 fa-solid fa-message"></i>
            <p class="font-size-20 text-semi-bold">
                {{$t("incoming-messages.Title")}}
            </p>
        </div>
        <div class="sefer dp-flex-column border-radius-5 p-y1 mt-mb-y1">
            <p class="font-size-20 text-semi-bold">D{{voyageId}} {{$t("messages.Voyage_Title")}}</p>
            <p class="font-size-16">{{voyageRoute}}</p>
        </div>
        <div class="incoming-messages-content dp-flex-row width-100">
            <div class="dp-flex-column background-color-F4F6F8 width-30">
                <div class="last-messages-content p-y5 width-100">
                    <div class="dp-flex-row items-center">
                        <i class="fa-solid fa-magnifying-glass position-absolute font-size-14 ml-y1 color-929DA7 search"></i>
                        <input type="text" class="width-100 txt" :placeholder="$t('incoming-messages.SearchPerson')">
                    </div>
                </div>
                <div class="p-y5 dp-flex-column incoming-messages-list width-100">

                    <IncomingMessagesItem v-on:click.native="getPastMessages(usersMessages.id)" class="massage-active" :imgUrl="usersMessages.profilePhoto" :message="usersMessages.lastsMessage.substring(0,25) + '...'" :messageCount="usersMessages.unSeenMessageCount" :name="usersMessages.name + ' ' + usersMessages.surname" :time="usersMessages.lastMessageDate" :status="userChatStatus"></IncomingMessagesItem>

                    <!-- <IncomingMessagesItem :imgUrl="'ben.png'" :message="'Tamamdır abi teklifi onaylayın...'" :messageCount="'2'" :name="'Rıza Turancan'" :price="'tl 15.0000'" :time="'11:26'"></IncomingMessagesItem>

                    <IncomingMessagesItem :imgUrl="'ben.png'" :message="'Tamamdır abi teklifi onaylayın...'" :messageCount="'2'" :name="'Rıza Turancan'" :price="'tl 15.0000'" :time="'11:26'"></IncomingMessagesItem> -->

                    <!-- <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                        <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                            <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                        </div>
                        <div class="dp-flex-column width-80">
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13 color-F07C31 text-semi-bold">
                                    Teklif
                                </p>
                                <p class="font-size-16 text-medium">
                                    tl 15.000
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-16 text-medium">
                                    Rıza Turancan
                                </p>
                                <p class="font-size-12 color-667085 text-medium">
                                    11:26
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13">
                                    Tamamdır abi teklifi onaylayın...
                                </p>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                        <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                            <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                        </div>
                        <div class="dp-flex-column width-80">
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13 color-F07C31 text-semi-bold">
                                    Teklif
                                </p>
                                <p class="font-size-16 text-medium">
                                    tl 15.000
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-16 text-medium">
                                    Rıza Turancan
                                </p>
                                <p class="font-size-12 color-667085 text-medium">
                                    11:26
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13">
                                    Tamamdır abi teklifi onaylayın...
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                        <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                            <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                        </div>
                        <div class="dp-flex-column width-80">
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13 color-F07C31 text-semi-bold">
                                    Teklif
                                </p>
                                <p class="font-size-16 text-medium">
                                    tl 15.000
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-16 text-medium">
                                    Rıza Turancan
                                </p>
                                <p class="font-size-12 color-667085 text-medium">
                                    11:26
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13">
                                    Tamamdır abi teklifi onaylayın...
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="dp-flex-row justify-content-between mt-mb-y25 massage-content message-pasive width-100">
                        <div class="massage-user-icon dp-flex-column justify-content-center items-center width-20">

                            <img src="@/assets/images/customer-area/dashboard/ben.png" alt="user-icon">

                        </div>
                        <div class="dp-flex-column width-80">
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13 color-F07C31 text-semi-bold">
                                    Teklif
                                </p>
                                <p class="font-size-16 text-medium">
                                    tl 15.000"
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-16 text-medium">
                                    Rıza Turancan
                                </p>
                                <p class="font-size-12 color-667085 text-medium">
                                    11:26
                                </p>
                            </div>
                            <div class="dp-flex-row justify-content-between">
                                <p class="font-size-13">
                                    Tamamdır abi teklifi onaylayın...
                                </p>
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>

            <div class="width-70 dp-flex-column justify-content-between incoming-messages-content-text">
                <div class="width-100 dp-flex-column incoming-messages-box height-80">
                    <template v-for="(message,index) in messages">
                        <p v-if="index - 1 > 0 && new Date(message.sentAt).toLocaleDateString() != new Date(messages[index - 1].sentAt).toLocaleDateString()" :key="index+100" class="message-date-between-day font-size-13">
                            {{new Date(message.sentAt).toLocaleDateString()}}
                        </p>
                        <SendingMessageBox v-if="message.isSender" :key="index" :isTimeActive="true" :text="message.text" :time="new Date(message.sentAt).toLocaleTimeString()" :whichMessage="'first'" :fileUrl="message.fileUrl != null ? fileUrl + message.fileUrl : null"></SendingMessageBox>
                        <div v-if="index - 1 >= 0 && message.isSender != true && messages[index-1].isSender == true" :key="index+100" class="dp-flex-row width-100 mt-mb-y1">
                            <div>
                                <i class="fa-solid fa-face-smile"></i>
                            </div>
                            <div class="dp-flex-column ml-y1 justify-content-between">
                                <p class="font-size-14">
                                    {{usersMessages.name}}
                                </p>
                                <label class="font-size-12 color-667085">
                                    {{new Date(message.sentAt).toLocaleTimeString()}}
                                </label>
                            </div>
                        </div>
                        <IncomingMessageBox v-if="message.isSender != true" :key="index" :isTimeActive="false" :text="message.text" :whichMessage="'first'" :fileUrl="message.fileUrl != 'null' ? fileUrl + message.fileUrl : null"></IncomingMessageBox>
                    </template>

                    <!-- <SendingMessageBox :isTimeActive="true" :text="'Merhaba Hocam,'" :time="'Bu gün, 13.12'" :whichMessage="'first'"></SendingMessageBox>

                    <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>
                    <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>
                    <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>
                    <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>

                    <SendingMessageBox :isTimeActive="false" :text="'Anlaştığımız gibi yapalım teslimatları hocam. Önce Konya’dan başla sen.'" :whichMessage="'last'"></SendingMessageBox> -->

                    <!-- <IncomingMessageBox :isTimeActive="false" :text="'Merhaba abi,'" :whichMessage="'first'"></IncomingMessageBox>
                    <IncomingMessageBox :isTimeActive="false" :text="'Nasılsın?'" :whichMessage="'middle'"></IncomingMessageBox>
                    <IncomingMessageBox :isTimeActive="false" :text="'Nasılsın?'" :whichMessage="'middle'"></IncomingMessageBox>
                    <IncomingMessageBox :isTimeActive="false" :text="'Nasılsın?'" :whichMessage="'middle'"></IncomingMessageBox>
                    <IncomingMessageBox :isTimeActive="false" :text="'Yükünüzü 5 saate teslim edeceğim abi bilgin olsun. Ancak kime teslim etmeliyim?'" :whichMessage="'last'"></IncomingMessageBox> -->

                </div>

                <div v-if="messageFileUrl != ''" class="dp-flex-row message-img-preview justify-content-start width-70 p-y1">
                    <img class="width-25" v-if="messageFileUrl != ''" :src='messageFileUrl'>
                </div>

                <div v-if="isActiveMessages" class="dp-flex-row justify-content-between">
                    <input v-model="message" class="width-70 border-radius-10 new-addres-input" type="text" :placeholder="$t('incoming-messages.WriteMessage')" />

                    <button @click="$refs.messageFileInput.click()" class="dp-flex-row justify-content-center btn-none items-center">
                        <i class="fa-solid fa-file-circle-plus font-size-30 color-6098FB"></i>
                        <input ref="messageFileInput" @change="previewFiles($event)" class="dp-none" type="file" value="Belge Ekle" accept="image/png, image/gif, image/jpeg" />
                    </button>

                    <button @click="sendMessage()" class="btn-none send-message-button width-15 border-radius-10">
                        <i class="fa-solid fa-paper-plane"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// TODO: make mqtt service
// TODO: tasarımsal düzenlemeler yapılacak.
// TODO: mqtt türkçe dil desteklemiyor.

import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import IncomingMessagesItem from "@/components/CustomerArea/Messages/IncomingMessagesItem.vue";
import SendingMessageBox from "./SendingMessageBox.vue";
import IncomingMessageBox from "./IncomingMessageBox.vue";
import { APIUrls } from "@/Helpers/APIUrls";
import { AssetUrls } from "@/Helpers/AssetUrls";
import mqtt from "mqtt/dist/mqtt";
import moment from "moment";

export default {
    components: {
        Title,
        IncomingMessagesItem,
        SendingMessageBox,
        IncomingMessageBox,
    },

    data() {
        return {
            voyageId: 0,
            voyageRoute: "",
            isActiveMessages: this.$route.params.isActiveMessages,
            usersMessages: {
                id: 0,
                lastMessageDate: "",
                lastsMessage: "",
                name: "",
                surname: "",
                profilePhoto: "",
                unSeenMessageCount: "",
            },
            messages: [],
            message: "",
            receiverId: 0,

            userChatStatus: false,

            fileUrl: AssetUrls.MESSAGE_FILES_URL,
            messageFile: undefined,
            messageFileUrl: "",

            take: 10,
            skip: 1,

            oldScrollHeight: 0,

            // MQTT VARIABLES
            connection: {
                protocol: "ws",
                host: "172.16.64.8",
                // host: "176.16.64.8",
                // ws: 8083; wss: 8084
                port: 8083,
                endpoint: "/mqtt",
                // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
                clean: true,
                connectTimeout: 30 * 1000, // ms
                reconnectPeriod: 4000, // ms
                clientId: "vuesj-client",
                // auth
                username: "dalisto",
                password: "dalisto123",
            },
            subscription: {
                topic: "messages/",
                // + this.id + "/" + this.topicVehicleId
                qos: 0,
            },
            publish: {
                topic: "messages/",
                qos: 0,
                payload: "",
            },
            receiveNews: "",
            qosList: [0, 1, 2],
            client: {
                connected: false,
            },
            subscribeSuccess: false,
            connecting: false,
            retryTimes: 0,
        };
    },

    beforeDestroy() {
        this.destroyConnection();
    },

    mounted() {
        var scroll = this.$el.querySelector(".incoming-messages-box");

        scroll.addEventListener("scroll", this.scrollChange);

        scroll.scrollTop = scroll.scrollHeight;

        this.createConnection();

        if (this.$route.params.voyageId != 0 && this.$route.params.voyageId != null) {
            //console.log(this.$route.params.voyageId);

            this.voyageId = this.$route.params.voyageId;
            this.voyageRoute = this.$route.params.voyageRoute;

            this.fileUrl = this.fileUrl + this.voyageId + "/";

            this.axios({
                url: APIUrls.GET_USERS_BY_SEND_MESSAGES,
                method: "GET",
                params: {
                    voyageId: this.voyageId,
                },
            })
                .then((response) => {
                    //console.log(response);
                    if (response.status == 200) {
                        if (response.data.code == 14) {
                            this.usersMessages = response.data.data;
                            this.usersMessages.profilePhoto = this.usersMessages.profilePhoto != null ? AssetUrls.USER_ASSETS_URL + this.usersMessages.profilePhoto : null;
                            //console.log(this.usersMessages);
                        } else {
                            this.$toast.warning("Beklenmedik Bir Hata Oluştu !");
                        }
                    } else {
                        this.$toast.error("Beklenmedik Bir Hata Oluştu !");
                    }
                })
                .catch((error) => {
                    //console.log(error);
                    this.$toast.error("Beklenmedik Bir Hata Oluştu! Sonra Deneyiniz !");
                });
        } else {
            this.$router.push("/customer/messages");
        }
    },

    updated() {
        if (this.skip <= 1) {
            var scroll = this.$el.querySelector(".incoming-messages-box");
            scroll.scrollTop = scroll.scrollHeight;
        } else if (this.skip > 1 && this.message != null) {
            var scroll = this.$el.querySelector(".incoming-messages-box");
            //scroll.scrollTop = scroll.scrollHeight;
            scroll.scrollTop = scroll.scrollHeight - this.oldScrollHeight;
        }
    },

    methods: {
        scrollChange(e) {
            //console.log(e.target.scrollTop);
            if (e.target.scrollTop == 0) {
                console.log("yeni kayıtları çek");

                this.skip += 1;

                this.getPastMessages(this.receiverId);
            }
        },

        previewFiles(event) {
            //console.log(whichFile);
            //console.log(event.target.files);
            if (event.target.files.length > 0) {
                //console.log(event.target.files.length);

                this.messageFile = event.target.files[0];
                this.messageFileUrl = URL.createObjectURL(event.target.files[0]);
            }
        },

        getPastMessages(userId) {
            this.receiverId = userId;

            this.axios({
                url: APIUrls.GET_MESSAGES_BY_RECEIVER_AND_VOYAGE_IDS,
                method: "GET",
                params: {
                    userId: userId,
                    voyageId: this.voyageId,
                    take: this.take,
                    skip: this.skip,
                },
            })
                .then((response) => {
                    // console.log(response);
                    if (response.status == 200) {
                        if (response.data.code == 14) {
                            if (this.skip > 1) {
                                let oldMessages = this.messages; // 11 12 13 14 ...
                                let newMessages = response.data.data.reverse(); // 1 2 3 4 ...

                                this.messages = [];

                                this.messages.push(...newMessages);
                                this.messages.push(...oldMessages);

                                // this.messages.push(...response.data.data.reverse());
                                // this.messages = this.messages.reverse();

                                //console.log(this.messages);
                            } else {
                                this.messages = response.data.data.reverse();
                            }
                        } else {
                            this.$toast.warning("Beklenmedik Bir Hata Oluştu !");
                        }
                    } else {
                        this.$toast.error("Beklenmedik Bir Hata Oluştu !");
                    }
                })
                .finally(() => {
                    if (this.skip <= 1) {
                        var scroll = this.$el.querySelector(".incoming-messages-box");
                        scroll.scrollTop = scroll.scrollHeight;
                        this.oldScrollHeight = scroll.scrollHeight;
                    } else {
                        var scroll = this.$el.querySelector(".incoming-messages-box");
                        this.oldScrollHeight = scroll.scrollHeight;
                    }
                })
                .catch((error) => {
                    //console.log(error);
                    this.$toast.error("Beklenmedik Bir Hata Oluştu! Lütfen Daha Sonra Tekrar Deneyiniz !");
                });

            this.subscription.topic = "messages/" + this.voyageId + "/" + userId;
            //this.subscription.topic = "messages/chat/" + userId;
            this.doSubscribe();

            this.publish.topic = "messages/" + this.voyageId + "/" + localStorage.getItem("id");
        },

        sendMessage() {
            //console.log(this.message);

            let messageObject = {
                id: 0,
                isSeen: false,
                isSender: true,
                readAt: null,
                sentAt: "",
                text: "",
                voyageId: 0,
                fileUrl: null,
            };

            if (this.message != "" || this.messageFile != undefined) {
                messageObject.text = this.message;
                messageObject.voyageId = this.voyageId;
                messageObject.sentAt = moment(new Date()).format("YYYY/MM/DD hh:mm");

                //console.log(messageObject);

                if (this.messageFile == undefined) {
                    this.messages.push(messageObject);

                    var scroll = this.$el.querySelector(".incoming-messages-box");
                    scroll.scrollTop = scroll.scrollHeight;
                } else {
                    this.axios({
                        url: APIUrls.CREATE_OR_UPDATE_MESSAGE,
                        method: "POST",
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        data: {
                            id: 0,
                            message: this.message,
                            receiverId: this.receiverId,
                            voyageId: this.voyageId,
                            file: this.messageFile,
                        },
                    })
                        .then((response) => {
                            //console.log(response);
                            if (response.status == 200) {
                                if (response.data.code == 199) {
                                    messageObject.id = response.data.data.id;
                                    messageObject.sentAt = response.data.data.sentAt;
                                    messageObject.fileUrl = response.data.data.fileUrl;
                                    this.messages.push(messageObject);
                                    // TODO: push atmak için api result beklerken loading atılacak.
                                    this.messageFileUrl = "";
                                } else {
                                    this.$toast.warning("Beklenmedik Bir Hata Oluştu !");
                                }
                            } else {
                                this.$toast.warning("Beklenmedik Bir Hata Oluştu !");
                            }
                        })
                        .catch((error) => {
                            //console.log(error);
                            this.$toast.warning("Beklenmedik Bir Hata Oluştu !");
                        });
                }

                let payload = 0 + "#" + messageObject.voyageId + "#" + messageObject.text + "#" + messageObject.sentAt + "#" + false + "#" + null + "#" + false + "#" + localStorage.getItem("id") + "#" + this.receiverId + "#" + messageObject.fileUrl;

                this.publish.payload = payload;
                this.doPublish();
            }
        },

        initData() {
            this.client = {
                connected: false,
            };
            this.retryTimes = 0;
            this.connecting = false;
            this.subscribeSuccess = false;
        },

        handleOnReConnect() {
            this.retryTimes += 1;
            // if (this.retryTimes > 5) {
            try {
                this.client.end();
                this.initData();
                this.$message.error("Connection maxReconnectTimes limit, stop retry");
            } catch (error) {
                this.$message.error(error.toString());
            }
            // }
        },

        createConnection() {
            try {
                this.connecting = true;
                const { protocol, host, port, endpoint, ...options } = this.connection;
                const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
                this.client = mqtt.connect(connectUrl, options);
                if (this.client.on) {
                    this.client.on("connect", () => {
                        this.connecting = false;
                        console.log("Connection succeeded!");
                    });
                    this.client.on("reconnect", this.handleOnReConnect);
                    this.client.on("error", (error) => {
                        console.log("Connection failed", error);
                    });
                    this.client.on("message", (topic, message) => {
                        this.receiveNews = this.receiveNews.concat(message);

                        console.log(`Received message ${message} from topic ${topic}`);

                        let arrays = message.toString().split("#");

                        let messageObject = {
                            id: 0,
                            isSeen: false,
                            isSender: false,
                            readAt: null,
                            sentAt: arrays[3],
                            text: arrays[2],
                            voyageId: 2,
                            fileUrl: arrays[arrays.length - 1],
                        };

                        this.messages.push(messageObject);
                    });
                }
            } catch (error) {
                this.connecting = false;
                console.log("mqtt.connect error", error);
            }
        },

        doSubscribe() {
            const { topic, qos } = this.subscription;
            this.client.subscribe(topic, { qos }, (error, res) => {
                if (error) {
                    console.log("Subscribe to topics error", error);
                    return;
                }
                this.subscribeSuccess = true;
                console.log("Subscribe to topics res", res);
            });
        },

        doUnSubscribe() {
            const { topic } = this.subscription;
            this.client.unsubscribe(topic, (error) => {
                if (error) {
                    console.log("Unsubscribe error", error);
                }
            });
        },

        doPublish() {
            const { topic, qos, payload } = this.publish;
            this.client.publish(topic, payload, { qos }, (error) => {
                if (error) {
                    console.log("Publish error", error);
                }
            });
        },

        destroyConnection() {
            if (this.client.connected) {
                try {
                    this.client.end(false, () => {
                        this.initData();
                        console.log("Successfully disconnected!");
                    });
                } catch (error) {
                    console.log("Disconnect failed", error.toString());
                }
            }
        },
    },
};
</script>

<style>
.message-date-between-day {
    margin: 2% auto;
}
.message-img-preview {
    background-color: white;
    margin: 1% 0 0 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #d7dddf;
    border-bottom: none;
}
</style>        