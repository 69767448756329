import { CreateOrUpdateVehicleModel } from "@/Helpers/Models";


const moduleCreateOrUpdateVehicle = {
    namespaced: true,
    
    state: {
        createOrUpdateVehicle: new CreateOrUpdateVehicleModel(),
    },

    getters: {
        getCreateOrUpdateVehicle: (state) => {
            return state.createOrUpdateVehicle;
        },
    },

    actions: {
        updateCreateOrUpdateVehicle: ({ commit }, [createOrUpdateVehicle]) => {        
            commit('setCreateOrUpdateVehicle', [createOrUpdateVehicle]);
        },
    },

    mutations: {
        setCreateOrUpdateVehicle: (state, [createOrUpdateVehicle]) => {            
            state.createOrUpdateVehicle = createOrUpdateVehicle;
        },
    },
}

export default moduleCreateOrUpdateVehicle;