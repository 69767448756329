lear<template>
    <div class="dp-flex-column">
        <Title :title="$t('unloading-points.Title')" :description="$t('unloading-points.Desc')">
            <template v-slot:title-icon>
                <i class="fa-solid mr-y1 color-667085 fa-truck-ramp-box"></i>
            </template>
        </Title>

        <router-link v-if="!this.getIsComingFromDraft" to="registered-unloading-points" class="dp-flex-row border-radius-5 text-center add-new-loading-point-button btn-none items-center justify-content-between text-color-white">
            <i class="fa-solid font-size-16 fa-plus"></i>
            <p class="font-size-14 ml-y1 text-semi-bold">
                {{$t("unloading-points.AddNewUnloadingPoints")}}                
            </p>
        </router-link>

        <div class="panel-content dp-flex-column justify-content-between mt-mb-y25">

            <div class="dp-flex-wrap justify-content-between dp-flex-row">

                <UnloadPointsRow :title="index + 1 + '. ' + $t('unloading-points.Title')" :addressTitle="unloadingAddress.title" :underTitle="unloadingAddress.countryName + '-' + unloadingAddress.cityName + '-' +unloadingAddress.districtName " :address="unloadingAddress.direction" :loadDate="unloadingAddress.earliestDate" :unloadDate="unloadingAddress.latestDate" :draftUnloadingAddress="unloadingAddress" :key="unloadingAddress.id" v-for="(unloadingAddress, index) in unloadingAddresses">

                </UnloadPointsRow>
            </div>

            <div class="dp-flex-row justify-content-end mt-mb-y25">
                <router-link to="/customer/registered-addresses" class="btn-none continue-btn pl-pr-y1-4 previous-btn font-size-14 items-center">
                    {{$t("button.Back")}}
                </router-link>

                <button @click="goToLoadTypes()" class="btn-none continue-btn pl-pr-y1-4 ml-y1 font-size-14 items-center">
                    {{$t("button.Continue")}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import UnloadPointsRow from "@/components/CustomerArea/Addresses/UnloadingPoint/UnloadPointsRow.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        Title,
        UnloadPointsRow,
    },

    data() {
        return {
            unloadingAddresses: [],
            isDateValid: true,
        };
    },

    computed: {
        ...mapGetters("createLoad", ["getUnloadingAddresses", "getIsComingFromDraft"]),
    },

    mounted() {
        this.unloadingAddresses = this.getUnloadingAddresses;
    },

    methods: {
        goToLoadTypes() {
            this.checkUnloadingAddressesEarliestAndLatestDate();

            if (this.isDateValid) {
                //this.$toast.success("Valid !");
                this.$router.push("/customer/load-types");
            } else {
                this.$toast.warning("Tarihleri Doldurunuz !");
            }
        },

        checkUnloadingAddressesEarliestAndLatestDate() {
            this.unloadingAddresses.forEach((element) => {
                if (element.earliestDate == undefined || element.latestDate == undefined) {
                    this.isDateValid = false;
                }
            });
        },
    },
};
</script>

<style>
</style>