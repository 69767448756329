import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class MessageService {
    resultModel = new ResultModel();

    errorResult = "";

    async getMessagesWithVoyages(status) {
        await apiHelper.get(APIUrls.GET_MESSAGES_WITH_VOYAGES, {
            status: status,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getUsersBySendMessages(voyageId) {
        await apiHelper.get(APIUrls.GET_USERS_BY_SEND_MESSAGES, {
            voyageId: voyageId,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };

    async getMessagesByReceiverAndVoyageIds(userId, voyageId, take, skip) {
        await apiHelper.get(APIUrls.GET_MESSAGES_BY_RECEIVER_AND_VOYAGE_IDS, {
            userId: userId,
            voyageId: voyageId,
            take: take,
            skip: skip,
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    };


}