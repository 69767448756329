<template>
    <div class='identity-information-form'>
        <div class="">
            <figure class="text-center">
                <img src="@/assets/images/introduction-area/complete-registration/step 3 image.svg" />
            </figure>
        </div>

        <div class="font-size-13 text-center mt-mb-50">
            {{$t("complete-registration-page.ConfirmationProcessDesc")}}
        </div>

        <router-link to="/login/" class='btn-none dp-flex-row justify-content-center items-center font-size-15 mt-mb-35 identity-information-input-submit identity-information-input-submit login-input-submit'>
            {{$t("complete-registration-page.ComplateRegistrationButton")}}
        </router-link>

        <!-- <button class='btn-none mt-mb-35 identity-information-input-submit identity-information-input-submit login-input-submit' type='submit'>{{$t("complete-registration-page.ComplateRegistrationButton")}}</button> -->
    </div>
</template>

<script>
export default {
    name: "ConfirmationProcess",
    components: {},
    data() {
        return {
            errors: {},
            resultModel: {},
        };
    },
    methods: {},
};
</script>

<style>
</style>