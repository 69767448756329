<template>
    <div>
        <div class="dp-flex-column blue-white-content">
            <div class="dp-flex-row blue-header">
                <h3 class="color-205985 font-size-16">
                    {{title}}
                </h3>
            </div>
            <div class="white-body">
                <slot name="white-body">
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlueHeaderWhiteBodyContent",
    props: ["title"],
};
</script>

<style>
</style>