export function getMonthName(month) {

    // let now = new Date();
    // now.setMonth(now.getMonth() - 2);
    // console.log(new Date(now.toJSON().slice(0, 10)));
    // return new Date(now.toJSON().slice(0, 10));

    // let month = new Date().getMonth() + 1;
    let monthName = "";

    switch (month) {
        case 1:
            monthName = "Ocak";
            break;

        case 2:
            monthName = "Şubat";
            break;

        case 3:
            monthName = "Mart";
            break;

        case 4:
            monthName = "Nisan";
            break;

        case 5:
            monthName = "Mayıs";
            break;

        case 6:
            monthName = "Haziran";
            break;

        case 7:
            monthName = "Temmuz";
            break;

        case 8:
            monthName = "Ağustos";
            break;

        case 9:
            monthName = "Eylül";
            break;

        case 10:
            monthName = "Ekim";
            break;

        case 11:
            monthName = "Kasım";
            break;

        case 12:
            monthName = "Aralık";
            break;

        default:
            monthName = "Tarih Bulunamadı";
            break;
    }

    return monthName;
}