import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { FilterDto, ResultModel } from '@/Helpers/Models';




const apiHelper = new ApiHelper();



export default class BugService {
    resultModel = new ResultModel();

    errorResult = "";

    async createOrUpdateBug(id, text, image) {
        await apiHelper.post(APIUrls.CREATE_OR_UPDATE_BUG, {
            Id: id,
            Text: text,
            Image: image,
        }, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }
}