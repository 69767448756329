<template>
    <div class="dp-flex-row">
        <TransporterSidebar :whichSidebar="'transporter-sidebar-menu-item-active'" class="transporter-panel-sidebar">

        </TransporterSidebar>

        <div class="dp-flex-column width-85 transporter-layout-content">

            <Header>

            </Header>

            <main class="panel-main">
                <router-view></router-view>
            </main>
        </div>

        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import Header from "@/components/Widgets/Header/Header.vue";
import TransporterSidebar from "./TransporterSidebar.vue";
import mqtt from "mqtt/dist/mqtt";

export default {
    name: "LayoutTransporterPanel",
    components: {
        Header,
        TransporterSidebar,
    },
    data() {
        return {
            // MQTT VARIABLES
            connection: {
                protocol: "ws",
                host: "171.22.187.222",
                // ws: 8083; wss: 8084
                port: 8083,
                endpoint: "/mqtt",
                // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
                clean: true,
                connectTimeout: 30 * 1000, // ms
                reconnectPeriod: 4000, // ms
                clientId: "vuesjRiza",
                // auth
                username: "dalisto",
                password: "dalisto123",
            },
            subscription: {
                topic: "voyages/#",
                // + this.id + "/" + this.topicVehicleId
                qos: 0,
            },
            publish: {
                topic: "messages/chat/",
                qos: 0,
                payload: "",
            },
            receiveNews: "",
            qosList: [0, 1, 2],
            client: {
                connected: false,
            },
            subscribeSuccess: false,
            connecting: false,
            retryTimes: 0,
        };
    },
    methods: {
        initData() {
            this.client = {
                connected: false,
            };
            this.retryTimes = 0;
            this.connecting = false;
            this.subscribeSuccess = false;
        },

        handleOnReConnect() {
            this.retryTimes += 1;
            // if (this.retryTimes > 5) {
            try {
                this.client.end();
                this.initData();
                this.$message.error("Connection maxReconnectTimes limit, stop retry");
            } catch (error) {
                this.$message.error(error.toString());
            }
            // }
        },

        createConnection() {
            try {
                this.connecting = true;
                const { protocol, host, port, endpoint, ...options } = this.connection;
                const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
                this.client = mqtt.connect(connectUrl, options);
                if (this.client.on) {
                    this.client.on("connect", () => {
                        this.connecting = false;
                        console.log("Connection succeeded!");
                    });

                    this.client.on("reconnect", this.handleOnReConnect);

                    this.client.on("error", (error) => {
                        console.log("Connection failed", error);
                    });

                    this.client.on("voyages/#", (topic, message) => {
                        // this.receiveNews = this.receiveNews.concat(message);
                        console.log("geliyor bişiler");

                        console.log(`Received message ${message} from topic ${topic}`);

                        // let arrays = message.toString().split("#");

                        // let messageObject = {
                        //     id: 0,
                        //     isSeen: false,
                        //     isSender: false,
                        //     readAt: null,
                        //     sentAt: arrays[3],
                        //     text: arrays[2],
                        //     voyageId: 2,
                        // };

                        // this.messages.push(messageObject);
                    });

                    this.client.on("message", (topic, message) => {
                        // Gelen mesajı konsola yaz
                        console.log(`Gelen mesaj - Topic: ${topic}, Mesaj: ${message.toString()}`);
                    });
                }
            } catch (error) {
                this.connecting = false;
                console.log("mqtt.connect error", error);
            }
        },

        doSubscribe() {
            const { topic, qos } = this.subscription;
            this.client.subscribe(topic, { qos }, (error, res) => {
                if (error) {
                    console.log("Subscribe to topics error", error);
                    return;
                }
                this.subscribeSuccess = true;
                console.log("Subscribe to topics res", res);
            });
        },

        doUnSubscribe() {
            const { topic } = this.subscription;
            this.client.unsubscribe(topic, (error) => {
                if (error) {
                    console.log("Unsubscribe error", error);
                }
            });
        },

        doPublish() {
            const { topic, qos, payload } = this.publish;
            this.client.publish(topic, payload, { qos }, (error) => {
                if (error) {
                    console.log("Publish error", error);
                }
            });
        },

        destroyConnection() {
            if (this.client.connected) {
                try {
                    this.client.end(false, () => {
                        this.initData();
                        console.log("Successfully disconnected!");
                    });
                } catch (error) {
                    console.log("Disconnect failed", error.toString());
                }
            }
        },
    },

    mounted() {
        // this.createConnection();
        // this.doSubscribe();

        this.publish.topic = this.publish.topic + localStorage.getItem("id");

        this.publish.payload = "true";
        //this.doPublish();
    },
    beforeDestroy() {
        // this.publish.payload = "false";
        // this.doPublish();

        this.destroyConnection();
    },
};
</script>

<style>
@import url("@/assets/css/transporter-area/_layout-transporter-panel.css");
</style>