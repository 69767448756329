import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class CountryService {
    resultModel = new ResultModel();

    errorResult = "";

    async getCountries() {
        await apiHelper.get(APIUrls.GET_COUNTRIES)
            .then((response) => {
                this.resultModel = response.data;
            })
            .catch((error) => {
                this.errorResult = error;
            });
    }
    

}