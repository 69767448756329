<template>
    <div class="online-message dp-flex-column">
        <div class="online-message-header-content justify-content-between items-center dp-flex-row">
            <div>
                <p class="font-size-14 text-semi-bold">
                    D2200056 No’lu Sefer
                </p>
                <p class="font-size-12 text-medium">
                    İstanbul - Konya
                </p>
            </div>
            <i class="fa-solid fa-ellipsis-vertical"></i>
        </div>

        <div class="dp-flex-row online-message-text-header-info justify-content-between height-10">
            <div class="dp-flex-row justify-content-start items-center width-80 p-y2">
                <div class="dp-flex-column justify-content-center items-center">
                    <i class="fa-solid fa-face-smile"></i>
                </div>
                <div class="dp-flex-column ml-y2">
                    <p class="font-size-14 text-semi-bold">
                        Rıza Turancan
                    </p>
                    <p class="font-size-12">
                        En son 1 saat önce görüldü
                    </p>
                </div>
            </div>
            <div class="dp-flex-column justify-content-center p-y5 items-center">
                <i class="fa-solid fa-face-smile"></i>
            </div>
        </div>

        <div class="width-100 dp-flex-column justify-content-between height-80 incoming-messages-content-text p-y5">
            <div class="width-100 dp-flex-column incoming-messages-box height-80">

                <SendingMessageBox :isTimeActive="true" :text="'Merhaba Hocam,'" :time="'Bu gün, 13.12'" :whichMessage="'first'"></SendingMessageBox>

                <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>
                <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>
                <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>
                <SendingMessageBox :isTimeActive="false" :text="'Nasılsın,'" :whichMessage="'middle'"></SendingMessageBox>

                <SendingMessageBox :isTimeActive="false" :text="'Anlaştığımız gibi yapalım teslimatları hocam. Önce Konya’dan başla sen.'" :whichMessage="'last'"></SendingMessageBox>

                <div class="dp-flex-row width-100 mt-mb-y1">
                    <div>
                        <i class="fa-solid fa-face-smile"></i>
                    </div>
                    <div class="dp-flex-column ml-y1 justify-content-between">
                        <p class="font-size-14">
                            Rıza
                        </p>
                        <label class="font-size-12 color-667085">
                            Bu gün, 13.12
                        </label>
                    </div>
                </div>

                <IncomingMessageBox :isTimeActive="false" :text="'Merhaba abi,'" :whichMessage="'first'"></IncomingMessageBox>
                <IncomingMessageBox :isTimeActive="false" :text="'Nasılsın?'" :whichMessage="'middle'"></IncomingMessageBox>
                <IncomingMessageBox :isTimeActive="false" :text="'Nasılsın?'" :whichMessage="'middle'"></IncomingMessageBox>
                <IncomingMessageBox :isTimeActive="false" :text="'Nasılsın?'" :whichMessage="'middle'"></IncomingMessageBox>
                <IncomingMessageBox :isTimeActive="false" :text="'Yükünüzü 5 saate teslim edeceğim abi bilgin olsun. Ancak kime teslim etmeliyim?'" :whichMessage="'last'"></IncomingMessageBox>

            </div>
            <div class="dp-flex-row justify-content-between">
                <input class="width-80 border-radius-10 new-addres-input" type="text" placeholder="Mesaj yaz..." />

                <button class="btn-none send-message-button width-15 border-radius-10">
                    <i class="fa-solid fa-paper-plane"></i>
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import IncomingMessageBox from "@/components/CustomerArea/Messages/IncomingMessageBox.vue";
import SendingMessageBox from "@/components/CustomerArea/Messages/SendingMessageBox.vue";

export default {
    components: {
        SendingMessageBox,
        IncomingMessageBox,
    },
};
</script>

<style>
</style>