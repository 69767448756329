<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <Loading v-if="isLoading"></Loading>

        <EmptyAds v-if="isLoading == false && offers.length == 0" :title="$t('emptyAds.Empty_Offers_Title')" :description="$t('emptyAds.Empty_Offers_Description')"></EmptyAds>

        <div v-if="!isLoading" class="dp-flex-row dp-flex-wrap justify-content-start">
            <div v-for="offer in offers" :key="offer.id" class="dp-flex-column flex-wrap-24 mb-y1 mr-y1 justify-content-between panel-content">
                <div class="dp-flex-row justify-content-between">
                    <div class="dp-flex-column justify-content-between">
                        <h3 class="font-size-20 text-bold">
                            {{ offer.title }}
                        </h3>

                        <p class="font-size-16">
                            {{ offer.amount }}
                        </p>
                    </div>

                    <div class="dp-flex-column items-center justify-content-center">
                        <p class="font-size-12 text-right">
                            {{ moment(offer.sentAt).format("DD/MM/YYYY") }}
                            <br>
                            {{ moment(offer.sentAt).format("hh:mm") }}
                        </p>
                    </div>
                </div>

                <button v-if="offer.isAccept == true && offer.status == 1" @click="addVehicleToVoyage(offer.id, offer.voyageId, offer.vehicleTypeId)" class="add-vehicle-to-voyage-button">
                    {{ $t('transporterOffer.Add_Vehicles_To_Voyage') }}
                </button>

                <button v-if="offer.isAccept == true && offer.status != 1" class="add-vehicle-to-voyage-button">
                    {{ $t('transporterOffer.Vehicles_Added') }}
                </button>

                <button v-if="offer.isAccept == null" class="add-vehicle-to-voyage-button">
                    {{ $t('transporterOffer.Update_Offer') }}
                </button>

                <!-- <button v-if="offer.isAccept == null" @click="isOpenPopupUpdateOffer = true, offerId = offer.id, loadId = offer.loadId" class="add-vehicle-to-voyage-button">
                    {{ $t('transporterOffer.Update_Offer') }}
                </button> -->

                <!-- <button v-if="offer.isAccept == false && offer.isUpdatable == true" @click="isOpenPopupUpdateOffer = true, offerId = offer.id, loadId = offer.loadId" class="add-vehicle-to-voyage-button">
                    {{ $t('transporterOffer.Vehicles_Added') }}
                </button> -->
            </div>

            <Popup v-if="isOpenPopup" @closePopup="isOpenPopup = false">
                <template slot="popup-header">
                    <p>
                        {{ $t('transporterOffer.Add_Vehicles_To_Voyage') }}
                    </p>
                </template>

                <template slot="popup-body">
                    <div class="dp-flex-column width-100 justify-content-start">
                        <div v-for="vehicle in vehicles" :key="vehicle.id" class="dp-flex-row justify-content-between items-center">
                            <i @click="openVehicleInfo(vehicle.id)" class="font-size-20 fa-solid fa-circle-info"></i>

                            <div class="dp-flex-column justify-content-between items-center">
                                <p class="font-size-20">
                                    {{ vehicle.licencePlate.toUpperCase() }} ({{ vehicle.vehicleTypeName.toUpperCase() }})
                                </p>

                                <span v-if="vehicle.vehicleDrivers == null" class="text-color-red font-size-10">
                                    {{ $t('inputValidation.Vehicle_Cannot_Be_Assigned') }}
                                </span>
                            </div>

                            <input v-if="vehicle.vehicleDrivers != null" @change="changeCheckbox($event, vehicle.id)" type="checkbox" />

                            <i v-else class="fa-solid font-size-13 fa-circle-xmark"></i>
                        </div>

                        <div class="dp-flex-row justify-content-center items-center">
                            <button @click="addVehiclesToVoyageWithDrivers()" class="btn-none mt-y5 p-y3 border-radius-5 width-80 font-size-16 text-color-white color" style="background-color:orange;">
                                {{ $t('transporterOffer.Add_Vehicles_To_Voyage') }}
                            </button>
                        </div>
                        <!-- <div class="dp-flex-row justify-content-between items-center">
                        <i class="font-size-20 fa-solid fa-circle-info"></i>
                        <p class="font-size-20">
                            42 AGR 394 (TIR)
                        </p>

                        <input type="checkbox" />
                    </div> -->
                    </div>
                </template>
            </Popup>

            <Popup v-if="isOpenPopupVehicleInfo" @closePopup="isOpenPopupVehicleInfo = false">
                <template slot="popup-header">
                    <p class="font-size-22 text-bold">
                        {{ selectedVehicle.licencePlate }}
                    </p>
                </template>

                <template slot="popup-body">
                    <div class="dp-flex-row width-100 dp-flex-wrap width-100 justify-content-start">
                        <div class="dp-flex-row width-33 items-center">
                            <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>

                            <p class="font-size-20">
                                {{ selectedVehicle.brandName }}
                            </p>
                        </div>

                        <div class="dp-flex-row width-33 items-center">
                            <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>

                            <p class="font-size-20">
                                {{ selectedVehicle.modelYear }}
                            </p>
                        </div>

                        <div class="dp-flex-row width-33 items-center">
                            <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>

                            <p class="font-size-20">
                                {{ selectedVehicle.vehicleTypeName }}
                            </p>
                        </div>

                        <div class="dp-flex-row width-33 items-center">
                            <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>

                            <p class="font-size-20">
                                {{ selectedVehicle.vehicleBodyTypeName }}
                            </p>
                        </div>

                        <div class="dp-flex-row width-33 items-center">
                            <i class="font-size-14 mr-y5 fa-solid fa-circle"></i>

                            <p class="font-size-20">
                                {{ selectedVehicle.vehicleBoydFloorTypeName }}
                            </p>
                        </div>

                        <div class="dp-flex-column width-100 justify-content-start items-left">
                            <p class="font-size-16 text-bold mt-y3">
                                {{ $t('transporterOffer.Driver') }}
                            </p>

                            <div v-if="selectedVehicle.vehicleDrivers != null">
                                <p v-for="driver in selectedVehicle.vehicleDrivers" :key="driver.id" class="font-size-16">
                                    {{ driver.name + ' ' + driver.surname }}
                                </p>
                            </div>

                            <p v-if="selectedVehicle.vehicleDrivers == null" class="font-size-16">
                                {{ $t('transporterOffer.There_Is_No_Driver') }}
                            </p>
                        </div>
                    </div>
                </template>
            </Popup>

            <Popup v-if="isOpenPopupUpdateOffer" @closePopup="isOpenPopupUpdateOffer = false">
                <template slot="popup-header">
                    <p class="font-size-22 text-bold">
                        TEKLİFİNİZİ GÜNCELLEYİN
                    </p>
                </template>

                <template slot="popup-body-text">
                    <div class="dp-flex-row justify-content-center items-center width-100">
                        <p class="font-size-16">
                            <!-- {{load.averagePrice == null ? 'İlk teklif veren siz olun !' : 'Bu ilana verilen en iyi teklif: ' + load.averagePrice + ' tl, siz yeni teklif verin'}} -->
                        </p>
                    </div>
                </template>

                <template slot="popup-body">
                    <div class="dp-flex-row justify-content-center items-center width-100">
                        <!-- <input type="text" placeholder="Fiyat Giriniz"/> -->

                        <AddressLabelWithInput class="width-60" v-model="amount" :placeHolder="'Teklifiniz Giriniz'" :type="'text'" :name="'offer'">

                        </AddressLabelWithInput>
                    </div>
                </template>

                <template slot="popup-body-footer">
                    <div class="dp-flex-row justify-content-center items-center width-100 mt-mb-10">
                        <button @click="updateOffer()" class="btn-none font-size-13 width-50 border-radius-5 ml-y1 btn-post-ad give-offer-input">
                            Teklifi Güncelle
                        </button>
                    </div>
                </template>
            </Popup>
        </div>
    </div>

</template>

<script>
// TODO: selected tab colorful
// TODO: component'lere ayır
// TODO: reload offers component. araç atama kaldırılabilir.

import Popup from "@/components/Widgets/ModelPopup/Popup.vue";
import OfferService from "@/services/OfferService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import EmptyAds from "@/components/CustomerArea/MyAds/EmptyAds.vue";
import VehiclesService from "@/services/VehiclesService";
import AddressLabelWithInput from "@/components/CustomerArea/Addresses/LoadingPoint/AddressLabelWithInput.vue";
import moment from "moment";

export default {
    components: {
        Popup,
        Loading,
        EmptyAds,
        AddressLabelWithInput,
    },

    data() {
        return {
            isAccept: true,
            isActive: true,
            offers: [],
            isOpenPopup: false,
            isOpenPopupVehicleInfo: false,
            isOpenPopupUpdateOffer: false,
            vehicles: [],
            selectedVehicle: {},
            selectedVehicleIds: [],
            voyageId: 0,
            offerService: new OfferService(),
            vehicleService: new VehiclesService(),
            isLoading: true,
            offerId: 0,
            amount: 0,
            loadId: 0,
            moment: moment,
        };
    },

    async mounted() {
        this.$watch(
            () => this.$route.query,
            //toParams, previousParams
            async () => {
                this.isAccept = this.$route.query.offerStatus != undefined ? this.$route.query.offerStatus : null;

                await this.getOffersByTransporterUserGuid();
            }
        );

        await this.getOffersByTransporterUserGuid();
    },

    methods: {
        async getOffersByTransporterUserGuid() {
            this.isLoading = true;

            await this.offerService.getOffersByTransporterUserGuid(this.isAccept, this.isActive);

            if (this.offerService.resultModel.code == 14) {
                this.offers = this.offerService.resultModel.data;                
            } else {
                this.$toast.error(this.$t("resultModel." + this.offerService.resultModel.message));
            }

            if (this.offerService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },

        async addVehicleToVoyage(offerId, voyageId, vehicleTypeId) {
            this.voyageId = voyageId;

            await this.vehicleService.getAssignableVehiclesByTransporterGuid(vehicleTypeId);

            if (this.vehicleService.resultModel.code == 14) {
                this.vehicles = this.vehicleService.resultModel.data;
            } else {
                this.$toast.error(this.$t("resultModel." + this.vehicleService.resultModel.message));
            }

            if (this.vehicleService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isOpenPopup = true;
        },

        openVehicleInfo(vehicleId) {
            this.selectedVehicle = this.vehicles.find((element) => {
                return element.id == vehicleId;
            });

            this.isOpenPopupVehicleInfo = true;
        },

        changeCheckbox(event, vehicleId) {
            if (event.target.checked) {
                this.selectedVehicleIds.push(vehicleId);
            } else {
                this.selectedVehicleIds.pop(vehicleId);
            }
        },

        async addVehiclesToVoyageWithDrivers() {
            await this.offerService.addVehiclesToVoyageWithDrivers(this.voyageId, this.selectedVehicleIds);

            if (this.offerService.resultModel.code == 179) {
                this.$toast.success(this.$t("resultModel." + this.offerService.resultModel.message));
                this.isOpenPopup = false;
            } else {
                this.$toast.warning(this.$t("resultModel." + this.offerService.resultModel.message));
            }

            if (this.offerService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }
        },

        async updateOffer() {
            await this.offerService.createOrUpdateOffer(this.offerId, this.loadId, this.amount);

            if (this.offerService.resultModel.code == 154 || this.offerService.resultModel.code == 155) {
                this.$toast.success(this.$t("resultModel." + this.offerService.resultModel.message));
                this.isOpenPopupUpdateOffer = false;
            } else {
                this.$toast.error(this.$t("resultModel." + this.offerService.resultModel.message));
                this.isOpenPopupUpdateOffer = false;
            }

            if (this.offerService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
                this.isOpenPopupUpdateOffer = false;
            }
        },
    },
};
</script>

<style>
.add-vehicle-to-voyage-button {
    border: none;
    cursor: pointer;
    background-color: green;
    padding: 4%;
    color: white;
    margin: 5% 0 0 0;
    border-radius: 6px;
}
</style>