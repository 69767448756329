import { APIUrls } from "@/Helpers/APIUrls";
import ApiHelper from '@/Helpers/ApiHelper';
import { ResultModel } from '@/Helpers/Models';



const apiHelper = new ApiHelper();


export default class CityService {
    resultModel = new ResultModel();

    errorResult = "";

    async getCitiesByCountryId(countryId) {
        await apiHelper.get(APIUrls.GET_CITIES_BY_COUNTRY_ID,
            {
                countryId: countryId
            }
        ).then((response) => {
            this.resultModel = response.data;
        })
            .catch((error) => {
                this.errorResult = error;
            });
    }


}