<template>
    <div class="dp-flex-row items-center green-chechkbox-content">
        <slot name="label">

        </slot>
        <label class="green-chechkbox-switch">
            <input :id="'checkbox_green_' + id" type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)">
            <span class="slider round"></span>
        </label>
    </div>

</template>

<script>
export default {
    name: "GreenCheckbox",
    props: ["id", "value"],
};
</script>

<style>
@import url("@/assets/css/widgets/customer-panel/green-checkbox.css");
</style>