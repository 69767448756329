<template>
    <div class='dp-flex-column'>
        <LayoutUserLoginRegister>
            <div class="dp-flex-column items-center justify-content-center login-left">
                <div class='login-form'>
                    <h1 class='font-size-35 text-bold'>{{$t("forget-password-page.Title")}}</h1>

                    <h3 class='font-size-15 mb-20'>
                        {{$t("forget-password-page.Desc")}}
                    </h3>

                    <h3 class='font-size-15 mb-20'>
                        {{$t("forget-password-page.Desc2")}}
                    </h3>

                    <form @submit="postForgetPasswordInfoToAPI">
                        <div class='input-icons'>
                            <i class='fa-solid fa-envelope icon icon-forget-password' v-bind:style="{color: color, 'border-color': borderColor}"></i>
                            <input class='btn-none login-input forget-password-input' name='email' type="text" @focus="changeFormInputIconColor" :placeholder="$t('forget-password-page.EmailInput')" v-model="email" />
                            <p class='font-size-13 text-color-red'>{{errors.email}}</p>
                        </div>
                        <button class='btn-none mt-mb-25 login-input forget-password-input-submit login-input-submit' type="submit">{{$t("forget-password-page.SubmitButton")}}</button>
                    </form>

                    <div class='login-register-div text-center mt-mb-25 font-size-15'>
                        <p class='dp-inline-block font-size-13'>{{$t("forget-password-page.RememberPassword")}} </p>
                        <router-link to='/login' class='font-size-13 text-color-green2'>{{$t("forget-password-page.LoginLink")}}</router-link>
                    </div>

                    <div class='login-register-div text-center mt-mb-25 font-size-15'>
                        <p>Henüz bir hesabınız yok mu?</p>
                        <router-link to='/register'> Hesap Oluştur</router-link>
                    </div>

                    <div class='login-re-captcha-kvkk'>
                        <p class='font-size-13'>
                            ReCAPTCHA tarafından korunmaktadır ve
                            <br />
                            Google
                            <a href='#' class='text-color-blue font-size-13 btn-none'> Gizlilik Politikası </a>
                            ve
                            <a href='#' class='font-size-13 text-color-blue btn-none'> Hizmet Şartları</a>
                            ’na tabidir.
                        </p>
                    </div>
                </div>
            </div>

            <Popup v-if="isOpenPopup" @closePopup="isOpenPopup = false">
                <template slot="popup-alert-icon">
                    <div>
                        <img class="mt-mb-15" src="@/assets/images/widgets/pop-up/Group 1381.svg" />
                    </div>
                </template>
                <template slot="popup-body-text">
                    <p v-if="popupMessage == null" class='font-size-13 poup-model-body-text'>
                        Şifrenizi sıfırlamanız için
                        <span class='font-size-13 text-bold'>
                            {{this.email}}
                        </span>
                        adresinize bir e-posta gönderdik.
                    </p>
                    <p v-if="popupMessage != null" class='font-size-13 poup-model-body-text'>
                        {{this.popupMessage}}
                    </p>
                </template>

                <template v-if="popupMessage == null" slot="popup-body">
                    <input @input="checkForgetPasswordCode" class="border-radius-5 p-y2 mt-y3 width-40 forget-password-reset-code-input" type="number" v-model="forgetPasswordCode" />
                    <span class="font-size-10 text-color-red" v-if="isChecked">Girdiğiniz 6 Haneli Kod Hatalıdır.</span>
                </template>

                <template slot="popup-body-button">
                    <button v-if="popupMessage == null" class="font-size-15 text-color-blue mt-mb-25 btn-none">
                        Şifre Değiştir
                    </button>

                    <!-- <router-link v-if="popupMessage == null" to='/reset-password' class="font-size-15 text-color-blue mt-mb-25">
                        Şifre Değiştir
                    </router-link> -->

                    <div v-if="popupMessage != null" class="font-size-15 text-color-blue mt-mb-25">
                        Tamam
                    </div>
                </template>
            </Popup>

        </LayoutUserLoginRegister>
    </div>

</template>

<script>
import LayoutUserLoginRegister from "./_LayoutUserLoginRegister.vue";
import { validateEmail, validateRequired } from "@/Helpers/FormValidateFunctions";
import { APIUrls } from "@/Helpers/APIUrls";
import Popup from "@/components/Widgets/ModelPopup/Popup.vue";

export default {
    name: "ForgetPassword",
    components: {
        LayoutUserLoginRegister,
        Popup,
    },
    data() {
        return {
            isOpenPopup: false,
            email: "",
            errors: {},
            color: "",
            borderColor: "",
            resultModel: {
                code: 0,
                message: "",
                data: {},
            },
            popupMessage: "",
            forgetPasswordCode: null,
            isChecked: false,
        };
    },
    methods: {
        validateEmail,
        validateRequired,
        formValidation(e) {
            e.preventDefault();

            let error = 0;

            if (!validateRequired("Email", this.email).isValid) {
                this.$set(this.errors, "email", validateRequired("Email", this.email).message);
                error++;
            } else if (!validateEmail(this.email).isValid) {
                this.$set(this.errors, "email", validateEmail(this.email).message);
                error++;
            }

            return error;
        },
        postForgetPasswordInfoToAPI(e) {
            e.preventDefault();

            let error = this.formValidation(e);

            localStorage.setItem("email", this.email);

            if (error == 0) {
                this.axios({
                    method: "POST",
                    url: APIUrls.CUSTOMER_FORGET_PASSWORD_URL,
                    params: {
                        email: this.email,
                    },
                })
                    .then((response) => {
                        this.resultModel.code = response.data.code;
                        this.resultModel.message = response.data.message;
                        this.resultModel.data = response.data.data;

                        if (this.resultModel.code == 144) {
                            localStorage.setItem("forgetPasswordCode", this.resultModel.data.code);
                            this.popupMessage = null;
                            console.log(this.resultModel.data.code);
                            this.isOpenPopup = true;
                        } else if (this.resultModel.code == 7) {
                            this.popupMessage = "Kullanıcı Bulunamadı! Girdiğiniz Eposta Adresini Kontrol Ediniz !";
                            this.isOpenPopup = true;
                        }
                    })
                    .catch((error) => {
                        this.popupMessage = "Beklenmeyen Bir Hata Oluştu! Lütfen Daha Sonra Tekrar Deneyiniz.";
                        this.isOpenPopup = true;
                        //console.log(error);
                    });
            }
        },
        checkForgetPasswordCode() {
            if (this.resultModel.data.code == this.forgetPasswordCode) {
                this.isChecked = false;
                this.$router.push("/reset-password");
            } else {
                this.isChecked = true;
            }
        },
        changeFormInputIconColor() {
            this.color = "black";
            this.borderColor = "black";
        },
    },
};
</script>

<style>
@import "@/assets/css/introduction-area/forget-password.css";

.forget-password-reset-code-input {
    outline: none;
    border: 1px solid black;
}
</style>