<template>
    <div class="dp-flex-column justify-content-center text-center items-center">
        <div class="mt-mb-35">
            <figure>
                <img src="@/assets/images/customer-area/my-ads/icon1.png" alt="">
            </figure>
        </div>
        <div class="dp-flex-column justify-content-center">
            <p class="font-size-15 color-667085">{{title}}</p>
            <p class="font-size-15 color-667085">{{description}}</p>
        </div>
        <router-link v-if="isAddNewAds" to="/customer/new-ads" class="add-new-ad-btn mt-mb-15-5 font-size-15 dp-flex-row btn-none items-center text-semi-bold">
            <i class="fa-solid fa-plus icon-btn-ekle"></i>
            Yeni İlan
        </router-link>
    </div>
</template>

<script>
export default {
    props: ["title", "description", "isAddNewAds"],
    name: "EmptyAds",
};
</script>

<style>
</style>