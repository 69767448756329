<template>
    <div class='popup-model-content dp-flex-column justify-content-center items-center'>
        <div class='popup-blur '>
        </div>

        <div class='popup-model dp-flex-column'>
            <div class='popup-model-header dp-flex-row justify-content-between'>
                <div class='popup-model-header-title dp-flex-row items-center width-inherit'>
                    <slot name="popup-header"></slot>
                </div>
                <button class='btn-none popup-model-header-close-buton' @click="$emit('closePopup')">
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class='dp-flex-column justify-content-between items-center popup-model-body'>

                <slot name="popup-alert-icon">
                    <!-- <div>
                        <img src="@/assets/images/widgets/pop-up/Group 1381.svg" />
                    </div> -->
                </slot>

                <slot name="popup-body-text">
                    <!-- <p class='font-size-13 poup-model-body-text'>
                        {this.props.desc}
                        <span class='font-size-13 text-bold'>{this.props.descBold}</span>
                        {this.props.desc2}
                    </p> -->
                </slot>

                <slot name="popup-body"></slot>

                <!-- <component :is="currentTab"></component> -->

                <slot name="popup-body-button">
                    
                </slot>

            </div>
            <slot name="popup-body-footer">
                <!-- <div class='items-center popup-model-footer'>{this.props.footerTitle}</div> -->
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
@import url("@/assets/css/widgets/model-popup.css");
</style>