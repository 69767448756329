<template>
    <div class="dp-flex-column packages-content width-20">
        <div class="panel-content-title packages-title">
            <div>
                <h2 class="panel-content-title packages-title">
                    {{$t("customer-dashboard-page.PackagesContentTitle")}}
                </h2>
            </div>
        </div>
        <div class="panel-content package-info dp-flex-column justify-content-between items-center">
            <div class="package-info-title text-center">
                <h2 class="font-size-30 text-color-white">{{$t("customer-dashboard-page.PackagesInfoTitle")}} </h2>
                <h5 class="font-size-20 text-color-white">{{$t("customer-dashboard-page.PackagesInfoDesc")}}</h5>
            </div>
            <div class="dp-flex-column package-info-middle justify-content-around items-center font-size-30 text-color-white">
                <div class="finished-date-counter">
                    <p class="font-size-30 text-semi-bold">
                        {{$t("customer-dashboard-page.PackagesFinishedInfoCounterDate")}}
                    </p>
                    <p class="font-size-20">
                        {{$t("customer-dashboard-page.PackagesFinishedInfoCounterDay")}}
                    </p>
                </div>
                <div class="finished-date">
                    <p class="font-size-15">{{$t("customer-dashboard-page.PackagesFinishedDateInfo")}}</p>
                    <p class="font-size-15 text-semi-bold">{{$t("customer-dashboard-page.PackagesFinishedDate")}}</p>
                </div>
            </div>
            <button class="see-packages btn-none">
                {{$t("customer-dashboard-page.PackagesSeePackages")}}
            </button>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
@import "@/assets/css/customer-area/customer-dashboard/packages.css";
</style>