<template>
    <div>
        <Loading v-if="isLoading"></Loading>

        <EmptyAds v-if="!isLoading && unloadingAddresses.length == 0"></EmptyAds>

        <div class="dp-flex-column">
            <Title :title="$t('new-address.Unloading_Point_Title')" :description="$t('new-address.Unloading_Point_Description')">
                <template v-slot:title-icon>
                    <i class="fa-solid mr-y1 color-667085 fa-truck-ramp-box"></i>
                </template>
            </Title>
            
            <div class="dp-flex-column">
                <div class="registered-address-content">
                    <h4 class="font-size-14 text-medium">
                        {{$t("registered-addresses.RegisteredAddresses")}}
                    </h4>
                </div>
                <div class="shipment-types-search">
                    <AdsSearch @input="showSearchText()" v-model="searchText"></AdsSearch>
                </div>
                <div class="registered-address-click text-medium">
                    <p class="color-667085 font-size-13">
                        {{$t("registered-addresses.ListTitle")}}
                    </p>
                </div>
            </div>

            <div class="dp-flex-column shipment-types justify-content-between">
                <AdressRow :id="unloadingAddress.id" :title="unloadingAddress.title" :adressTypeId="2" :description="unloadingAddress.direction" :key="unloadingAddress.id" v-for="unloadingAddress in searchList"></AdressRow>
            </div>

            <div class="dp-flex-row text-center justify-content-end">
                <button @click="openPopup()" class="btn-none new-address-button font-size-14 items-center">
                    {{$t("button.AddNewAddress")}}
                </button>
            </div>

            <Popup v-if="isOpenPopup" @closePopup="isOpenPopup = false">
                <template v-slot:popup-header>
                    <i class="fa-solid map-icon color-667085 fa-location-dot"></i>
                    <p class="font-size-16 color-667085">
                        {{$t("customer-vehicle-info-page.ChooseNewAddress")}}
                    </p>
                </template>
                <template v-slot:popup-body>
                    <NewAddressMap :adressTypeId="2"></NewAddressMap>
                </template>
            </Popup>
        </div>
    </div>

</template>

<script>
import AdsSearch from "@/components/CustomerArea/MyAds/AdsSearch.vue";
import AdressRow from "@/components/CustomerArea/Addresses/LoadingPoint/AdressRow.vue";
import Title from "@/components/Widgets/CustomerPanel/Title.vue";
import Popup from "@/components/Widgets/ModelPopup/Popup.vue";
import NewAddressMap from "../LoadingPoint/NewAddressMap.vue";
import UnloadingLoadingAddressesService from "@/services/UnloadingLoadingAddressesService";
import Loading from "@/components/Widgets/Loadings/Loading.vue";
import EmptyAds from "../../MyAds/EmptyAds.vue";

export default {
    name: "RegisteredUnloadingPoints",

    components: {
        AdsSearch,
        AdressRow,
        Title,
        Popup,
        NewAddressMap,
        Loading,
        EmptyAds,
    },

    data() {
        return {
            unloadingAddresses: [],
            isOpenPopup: false,
            searchText: "",
            searchList: [],
            isLoading: true,
            unloadingLoadingAddressesService: new UnloadingLoadingAddressesService(),
        };
    },

    async mounted() {
        await this.getAddresses();
    },

    methods: {
        openPopup() {
            this.isOpenPopup = true;
        },

        showSearchText() {
            if (this.searchText != "") {
                this.searchList = [];

                this.searchList = this.unloadingAddresses.filter((x) => {
                    return x.title.toLowerCase().includes(this.searchText);
                });
            } else {
                this.searchList = this.unloadingAddresses;
            }
        },

        async getAddresses() {
            this.isLoading = true;

            await this.unloadingLoadingAddressesService.getUnloadingLoadingAddressesByCustomerUserGuid(2, true);

            if (this.unloadingLoadingAddressesService.resultModel.code == 14) {
                this.unloadingAddresses = this.unloadingLoadingAddressesService.resultModel.data;
                this.searchList = this.unloadingAddresses;
            } else {
                this.$toast.error(this.$t("resultModel." + this.unloadingLoadingAddressesService.resultModel.message));
            }

            if (this.unloadingLoadingAddressesService.errorResult != "") {
                this.$toast.error(this.$t("resultModel.Transaction_Failed"));
            }

            this.isLoading = false;
        },
    },
};
</script>

<style>
@import url("@/assets/css/customer-area/my-ads/create-new-ads/registered-addresses.css");
</style>