<template>
    <div class="dp-flex-column vehicle-item-content border-radius-5 width-100">
        <div class="dp-flex-row vehicle-item-header justify-content-between items-center width-100 p-y3">
            <div class="dp-flex-column justify-content-center items-left">
                <h2 class="font-size-20">
                    {{ $t('transporterVehicle.Vehicle_Brand') }}
                </h2>
                <p class="font-size-14 color-667085">
                    {{ vehicle.brandName }}
                </p>
            </div>
            <div class="dp-flex-row vehicle-header-plate border-radius-5 width-40 items-center">
                <div class="dp-flex-column p-y1 vehicle-header-plate-text text-color-white width-25 justify-content-end items-center">
                    <p class="font-size-12">
                        <!-- TR -->
                    </p>
                </div>

                <div class="dp-flex-row items-center justify-content-center vehicle-header-plate-code width-75 p-y3">
                    <p class="font-size-14 color-0D3CA5 text-semi-bold">
                        {{ vehicle.licencePlate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="dp-flex-column width-100 vehicle-item-body p-y3 justify-content-between">
            <div class="dp-flex-row justify-content-between">
                <div class="dp-flex-column justify-content-center">
                    <h3 class="font-size-13 text-medium">
                        {{ $t('transporterVehicle.Model_Year') }}
                    </h3>
                    <p class="font-size-13 color-667085">
                        {{ vehicle.modelYear }}
                    </p>
                </div>
                <div class="dp-flex-column justify-content-center">
                    <h3 class="font-size-13 text-medium">
                        {{ $t('transporterVehicle.Trailer_Type') }}
                    </h3>
                    <p class="font-size-13 color-667085">
                        {{ vehicle.vehicleBodyTypeName }} - {{ vehicle.vehicleBoydFloorTypeName }}
                    </p>
                </div>
                <div class="dp-flex-column justify-content-center">
                    <h3 class="font-size-13 text-medium">
                        {{ $t('transporterVehicle.Vehicle_Type') }}
                    </h3>
                    <p class="font-size-13 color-667085">
                        {{ vehicle.vehicleTypeName }}
                    </p>
                </div>
                <div class="dp-flex-column justify-content-center">
                    <h3 class="font-size-13 text-medium">
                        {{ $t('transporterVehicle.Tonnage') }}
                    </h3>
                    <p class="font-size-13 color-667085">
                        {{ vehicle.netWight }}
                    </p>
                </div>
            </div>

            <div class="dp-flex-column justify-content-start mt-y3">
                <div v-if="vehicle.vehicleDrivers == null" class="dp-flex-row width-80 justify-content-between items-center">
                    <p class="font-size-16 text-bold color-D01438">
                        {{ $t('transporterVehicle.No_Driver_Assigned') }}
                    </p>
                    <button class="btn-none color-D01438">
                    </button>
                </div>
                <div class="dp-flex-row width-80 justify-content-between items-center" v-for="vehicleDriver in vehicle.vehicleDrivers" :key="vehicleDriver.id">
                    <p class="font-size-16 text-bold">
                        * {{ vehicleDriver.name }} {{ vehicleDriver.surname }}
                    </p>
                    <button @click="removeDriverToVehicle(vehicleDriver.id)" class="btn-none color-D01438">
                        <i class="fa-solid fa-trash font-size-16"></i>
                    </button>
                </div>
            </div>

            <div class="dp-flex-row width-100 mt-y3">
                <div @click="updateVehicleStatus(true)" :class="changedWorkingStatus ? 'vehicle-working-status-active' : ''" class="dp-flex-column p-y1 justify-content-center width-50 vehicle-working-status-left text-center">
                    <p class="font-size-14">
                        {{ $t('button.Active') }}
                    </p>
                </div>
                <div @click="updateVehicleStatus(false)" :class="changedWorkingStatus == false ? 'vehicle-working-status-active' : ''" class="dp-flex-column p-y1 justify-content-center width-50 vehicle-working-status-right text-center">
                    <p class="font-size-14">
                        {{ $t('button.Passive') }}
                    </p>
                </div>
            </div>

            <button @click="getAssignableDrivers()" class="width-100 btn-none mt-mb-15 p-y3 vehicle-item-body-edit-driver-button justify-content-center items-center">
                <i class="fa-solid font-size-13 fa-pen-to-square"></i>
                {{ $t('transporterVehicle.Assigned_Drivers') }}
            </button>
            <div class="dp-flex-row width-100 justify-content-between items-center">
                <button @click="isVehicleDocument = true" class="width-40 border-radius-5 vehicle-item-body-vehicle-info btn-none">
                    {{ $t('button.Vehicle_Document') }}
                </button>
                <button @click="updateVehicle()" class="width-25 border-radius-5 vehicle-item-body-vehicle-update btn-none">
                    {{ $t('button.Update_Vehicle') }}
                </button>
                <button @click="isOpenDeletePopup = true" class="width-25 border-radius-5 vehicle-item-body-vehicle-delete btn-none">
                    {{ $t('button.Delete') }}
                </button>
            </div>
        </div>

        <Popup v-if="isVehicleDocument" @closePopup="isVehicleDocument = false">
            <template slot="popup-header">
                <h3>
                    {{ $t('button.Vehicle_Document') }}
                </h3>
            </template>
            <template slot="popup-body">
                <div class="dp-flex-column width-100">
                    <div class="dp-flex-row width-100">
                        <div class="dp-flex-column width-50">
                            <h3 class="vehicle-document-title">
                                {{ $t('transporterVehicle.Licence_Front_Page') }}
                            </h3>
                            <img class="vehicle-document-img" :src="AssetUrls.VEHICLE_DOCUMENTS_URL + vehicle.id + '/' + vehicle.licensePageFront" @error="handleImageError" alt="licensePageFront">
                        </div>
                        <div class="dp-flex-column width-50">
                            <h3 class="vehicle-document-title">
                                {{ $t('transporterVehicle.Licence_Back_Page') }}
                            </h3>
                            <img class="vehicle-document-img" :src="AssetUrls.VEHICLE_DOCUMENTS_URL + vehicle.id + '/' + vehicle.licensePageBack" @error="handleImageError" alt="licensePageBack">
                        </div>
                    </div>
                    <div class="dp-flex-row width-100">
                        <div class="dp-flex-column width-50">
                            <h3 class="vehicle-document-title">
                                {{ $t('transporterVehicle.Insurance_Policy') }}
                            </h3>
                            <img class="vehicle-document-img" :src="AssetUrls.VEHICLE_DOCUMENTS_URL + vehicle.id + '/' + vehicle.insurancePolicy" @error="handleImageError" alt="insurancePolicy">
                        </div>
                        <div class="dp-flex-column width-50">
                            <h3 class="vehicle-document-title">
                                {{ $t('transporterVehicle.K_Document') }}
                            </h3>
                            <img class="vehicle-document-img" :src="AssetUrls.VEHICLE_DOCUMENTS_URL + vehicle.id + '/' + vehicle.kDocument" @error="handleImageError" alt="kDocument">
                        </div>
                    </div>
                </div>
            </template>
        </Popup>

        <Popup v-if="isDriversPopupOpen" @closePopup="closeDriversPopup()">
            <template slot="popup-header">
                <p>
                    {{ $t('transporterVehicle.Assigned_Drivers') }}
                </p>
            </template>

            <template slot="popup-body">
                <div class="dp-flex-column width-100 justify-content-start">
                    <div v-for="driver in drivers" :key="driver.id" class="dp-flex-row justify-content-between items-center">
                        <!-- <i class="font-size-20 fa-solid fa-circle-info"></i> -->
                        <p class="font-size-20">
                            {{ driver.name }} {{ driver.surname }}
                        </p>

                        <input @change="changeCheckbox($event, driver.id)" type="checkbox" />
                    </div>

                    <div class="dp-flex-row justify-content-center items-center">
                        <button @click="addDriversToVehicle()" class="btn-none mt-y5 p-y3 border-radius-5 width-80 font-size-16 text-color-white color" style="background-color:orange;">
                            {{ $t('transporterVehicle.Assign_To_Drivers') }}
                        </button>
                    </div>
                </div>
            </template>
        </Popup>

        <TransactionPopup v-if="isOpenDeletePopup" @closePopup="isOpenDeletePopup = false" :headerClass="'header-red'">
            <template slot="popup-header">
                <p class="width-100 text-center">
                    {{ $t('transporterVehicle.Delete_Vehicle_Title') }}
                </p>
            </template>
            <template slot="popup-body">
                <div style="width:20vw!important" class="width-100 dp-flex-column">
                    <div class="dp-flex-row justify-content-center text-center items-center mt-y3">
                        <p class="font-size-16">
                            {{ $t('transporterVehicle.Delete_Vehicle_Description') }}
                        </p>
                    </div>
                </div>
            </template>
            <template slot="popup-body-button">
                <div class="dp-flex-row justify-content-center width-100 mt-y5 items-center">
                    <button @click="deleteVehicle(vehicle.id)" class="dp-flex-row btn-none delte-user-button mr-y5 border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20" v-bind:class="{ 'button--loading': isButtonLoading }">
                        {{ $t('button.Delete') }}
                    </button>
                    <button @click="isOpenDeletePopup = false" class="dp-flex-row btn-none border-radius-5 text-semi-bold justify-content-center items-center add-user-button width-20">
                        {{ $t('button.Cancel') }}
                    </button>
                </div>
            </template>
        </TransactionPopup>

    </div>
</template>

// TODO: sidebar araçlarım kısmı parlar halde olacak.
// TODO: getImagePath kısmı global yapılacak. çalışmıyor değişti
// TODO: araca şöför ekleme adımları sevis'e çekilecek.
// TODO: araca şöför eklendiğinde ana component güncellenecek.

<script>
import { APIUrls } from "@/Helpers/APIUrls";
import Popup from "@/components/Widgets/ModelPopup/Popup.vue";
import VehiclesService from "@/services/VehiclesService";
import { AssetUrls } from "@/Helpers/AssetUrls";
import TransactionPopup from "@/components/Widgets/ModelPopup/TransactionPopup.vue";
import { mapActions } from "vuex";

export default {
    props: ["vehicle"],

    name: "VehicelItem",

    components: {
        Popup,
        TransactionPopup,
    },

    data() {
        return {
            isVehicleDocument: false,
            isDriversPopupOpen: false,
            isOpenDeletePopup: false,
            isButtonLoading: false,
            drivers: [],
            selectedDriverIds: [],
            changedWorkingStatus: this.vehicle.workingStatus,
            vehiclesService: new VehiclesService(),
            AssetUrls: AssetUrls,
            defaultImage: "@/src/assets/images/img-not-found.avif",
        };
    },

    mounted() { },

    methods: {
        ...mapActions("createOrUpdateVehicle", ["updateCreateOrUpdateVehicle"]),

        updateVehicle() {
            this.updateCreateOrUpdateVehicle([this.vehicle]);

            this.$router.push("create-vehilce");
        },

        handleImageError(event) {
            event.target.src = require(`@/assets/images/img-not-found.avif`);
        },

        async deleteVehicle(vehicleId) {
            this.isButtonLoading = true;
            await this.vehiclesService.deleteOrRestoreDeleteVehicle(vehicleId, false);

            if (this.vehiclesService.resultModel.code == 74 || this.vehiclesService.resultModel.code == 73) {
                this.$toast.success(this.$t("resultModel." + this.vehiclesService.resultModel.message));
            } else {
                this.$toast.error(this.$t("resultModel." + this.vehiclesService.resultModel.message));
            }

            if (this.vehiclesService.errorResult != "") {
                this.$toast.error(this.vehiclesService.errorResult);
            }

            this.isButtonLoading = false;

            this.$parent.getDeletedVehicleId(vehicleId);

            this.isOpenDeletePopup = false;
        },

        getAssignableDrivers() {
            this.axios({
                url: APIUrls.GET_ASSIGNABLE_DRIVERS_BY_TRANSPORTER_GUID,
                method: "GET",
            })
                .then((response) => {
                    if (response.status == 200) {
                        if (response.data.code == 14) {
                            this.drivers = response.data.data;
                            this.isDriversPopupOpen = true;
                        } else {
                            this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                        }
                    } else {
                        this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                });
        },

        changeCheckbox(event, driverId) {
            if (event.target.checked) {
                this.selectedDriverIds.push(driverId);
            } else {
                this.selectedDriverIds.pop(driverId);
            }
        },

        closeDriversPopup() {
            this.selectedDriverIds = [];
            this.isDriversPopupOpen = false;
        },

        addDriversToVehicle() {
            if (this.vehicle.id > 0 && this.selectedDriverIds.length > 0) {
                this.axios({
                    url: APIUrls.ADD_DRIVERS_TO_VEHICLE,
                    method: "POST",
                    data: {
                        vehicleId: this.vehicle.id,
                        driverIds: this.selectedDriverIds,
                    },
                })
                    .then((response) => {
                        console.log(response);
                        if (response.status == 200) {
                            if (response.data.code == 42) {
                                this.$toast.success(this.$t('resultModel.Transaction_Success'));
                            } else {
                                this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                            }
                        } else {
                            this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                    });
            } else {
                this.$toast.error(this.$t('resultModel.Model_Invalid'));
            }
        },

        removeDriverToVehicle(driverId) {
            if (this.vehicle.id > 0 && driverId > 0) {
                this.axios({
                    url: APIUrls.REMOVE_DRIVER_TO_VEHICLE,
                    method: "POST",
                    params: {
                        vehicleId: this.vehicle.id,
                        driverId: driverId,
                    },
                })
                    .then((response) => {
                        console.log(response);
                        if (response.status == 200) {
                            if (response.data.code == 44) {
                                this.$toast.success(this.$t('resultModel.Transaction_Success'));
                            } else {
                                this.$toast.warning(response.data.message);
                            }
                        } else {
                            this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$toast.error(this.$t('resultModel.Transaction_Failed'));
                    });
            } else {
                this.$toast.error(this.$t('resultModel.Transaction_Failed'));
            }
        },

        async updateVehicleStatus(workingStatus) {
            this.changedWorkingStatus = workingStatus;

            if (this.vehicle.id > 0 && workingStatus != null) {
                await this.vehiclesService.updateVehicleStatus(this.vehicle.id, workingStatus);

                if (this.vehiclesService.resultModel.code == 14) {
                    if (workingStatus) {
                        this.$toast.success(this.$t("resultModel.Vehicles_Status_Active"));
                    } else {
                        this.$toast.success(this.$t("resultModel.Vehicles_Status_DeActive"));
                    }
                } else if (this.vehiclesService.resultModel.code == 75) {
                    this.$toast.error(this.$t("resultModel." + this.vehiclesService.resultModel.message));
                }

                if (this.vehiclesService.errorResult != "") {
                    this.$toast.error(this.vehiclesService.errorResult);
                }
            }
        },
    },
};
</script>

<style>
.vehicle-item-content {
    border: 1px solid #d7dddf;
}

.vehicle-item-header {
    background-color: #e2e2e2;
}

.vehicle-header-plate {
    border: 1px solid #0d3ca5;
    height: 80%;
    overflow: hidden;
}

.vehicle-header-plate-text {
    height: 100%;
    background-color: #0d3ca5;
}

.vehicle-header-plate-code {
    height: 100%;
    background-color: white;
}

.vehicle-item-body {
    background-color: white;
    height: 100%;
}

.vehicle-item-body-edit-driver-button {
    background-color: #5d7d9d3e;
    border: 1px solid #c5d0db;
    border-radius: 50px;
}

.vehicle-item-body-vehicle-info {
    background-color: white;
    border: 1px solid #26b198;
    padding: 3%;
    color: #26b198;
    font-weight: bold;
}

.vehicle-item-body-vehicle-delete {
    padding: 3%;
    /* border: 1px solid black; */
    background-color: #d01438;
    color: white;
}

.vehicle-item-body-vehicle-update {
    padding: 3%;
    /* border: 1px solid black; */
    background-color: orange;
    color: white;
}

.identity-text {
    color: #0d3ca5;
    padding: 9px 22px;
    border: 1px solid #0d3ca5;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
    background-color: #fff;
}

.identity-code {
    color: #fff;
    background-color: #0d3ca5;
    padding: 17px 7px 4px 7px;
    border: 1px solid #0f58fb;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
    font-weight: bold;
}

.vehicle-document-img {
    max-width: 180px;
    border-radius: 10px;
}

.vehicle-document-title {
    width: max-content;
}

.vehicle-working-status {
    border: 1px solid black;
    border-radius: 25px 0 0 25px;
    cursor: pointer;
}

.vehicle-working-status-left {
    border: 1px solid black;
    border-radius: 25px 0 0 25px;
    cursor: pointer;
}

.vehicle-working-status-right {
    border: 1px solid black;
    border-radius: 0 25px 25px 0;
    cursor: pointer;
}

.vehicle-working-status-active {
    background-color: green;
    border: 1px solid green;
    color: white;
}
</style>