<template>
    <div class="sidebar dp-flex-column">
        <div class="sidebar-top-content text-center">
            <router-link :to="'/'" class="items-center btn-none dp-flex-row justify-content-center">
                <figure>
                    <img src="@/assets/daliportLogo.svg" alt="daliport-logo">
                </figure>
            </router-link>
        </div>

        <div class="sidebar-menu-content dp-flex-column">
            <SidebarMenuItem :link="'/transporter'" :title="$t('transporterSideBar.Loads')" @click.native="changeToComponents('isLoad')" v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter' || this.$route.fullPath == '/transporter/load-detail' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-dolly"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem :link="'/transporter/profile'" :title="$t('transporterSideBar.My_Account')" @click.native="changeToComponents('isAccount')" v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/profile' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-user"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem :link="'/transporter/authorized-users'" :title="$t('transporterSideBar.My_Authorized_Users')" @click.native="changeToComponents('isAuthorizedUser')" v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/authorized-users' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-user"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem v-if="isOwner == true" :link="'/transporter/my-vehicles'" :title="$t('transporterSideBar.My_Vehicles')" @click.native="changeToComponents('isVehicle')" v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/my-vehicles' || this.$route.fullPath == '/transporter/create-vehilce' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-truck-moving"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem v-if="isOwner == true" :link="'/transporter/my-drivers'" :title="$t('transporterSideBar.My_Drivers')" @click.native="changeToComponents('isDriver')" v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/my-drivers' || this.$route.fullPath == '/transporter/create-driver' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-regular fa-address-card"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem v-if="isOwner == true" :link="'/transporter/offers'" :title="$t('transporterSideBar.My_Offers')" @click.native="changeToComponents('isOffers')"
                v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/offers' || this.$route.fullPath == '/transporter/offers?offerStatus=true' || this.$route.fullPath == '/transporter/offers?offerStatus' || this.$route.fullPath == '/transporter/offers?offerStatus=false' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-regular fa-file-lines"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem :link="'/transporter/voyages'" :title="$t('transporterSideBar.My_Voyages')" @click.native="changeToComponents('isVoyage')"
                v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/voyages' || this.$route.fullPath == '/transporter/voyages?voyageStatus=1' || this.$route.fullPath == '/transporter/voyages?voyageStatus=2' || this.$route.fullPath == '/transporter/voyages?voyageStatus=3' || this.$route.fullPath == '/transporter/voyages?voyageStatus=4' || this.$route.fullPath == '/transporter/voyage-detail' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-truck-fast"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem :link="'/transporter/transporter-messages'" :title="$t('transporterSideBar.My_Messages')" @click.native="changeToComponents('isMessages')" v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/transporter-messages' || this.$route.fullPath == '/transporter/incoming-messages' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-message"></i>
                </template>
            </SidebarMenuItem>

            <SidebarMenuItem :link="'/transporter/company-profile'" :title="$t('transporterSideBar.Company_Profile')" @click.native="changeToComponents('isCompanyProfile')" v-bind:class="{ [whichSidebar]: this.$route.fullPath == '/transporter/company-profile' }">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-building"></i>
                </template>
            </SidebarMenuItem>

            <!-- <SidebarMenuItem :link="'/transporter'" :title="'Yüklediğim Belgeler'" @click.native="changeToComponents('uploaded-files')" v-bind:class="{[whichSidebar]:this.$route.fullPath == 'uploaded-files'}">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-file-arrow-up"></i>
                </template>
            </SidebarMenuItem> -->

            <!-- <SidebarMenuItem :link="'/transporter'" :title="'Taşıma Bölgelerim'" @click.native="changeToComponents('transport-zones')" v-bind:class="{[whichSidebar]:this.$route.fullPath == 'transport-zones'}">
                <template slot="item-icon">
                    <i class="sidebar-menu-item-elements fa-solid fa-location-dot"></i>
                </template>
            </SidebarMenuItem> -->

            <div class="dp-flex-row justify-content-center width-100 items-center">
                <div class="dp-flex-column width-90 call-center-info-content justify-content-center items-center">
                    <i class="fa-solid color-F5FF00 fa-headset"></i>
                    <p class="font-size-16 text-center">
                        {{ $t('transporterSideBar.Call_Center') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SidebarMenuItem from "@/components/CustomerArea/CustomerPanelLayout/SidebarMenuItem.vue";
import { mapActions } from "vuex";

export default {
    name: "Sidebar",

    props: ["whichSidebar"],

    components: { SidebarMenuItem },

    data() {
        return {
            isOwner: false,
        };
    },

    mounted() {
        this.isOwner = localStorage.getItem("isOwner") == "true" ? true : false;
    },

    methods: {
        ...mapActions("header", ["updateWhichPage"]),

        changeToComponents(openCloseHeaderButon) {
            this.updateWhichPage([openCloseHeaderButon]);
        },
    },
};
</script>

<style></style>